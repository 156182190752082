import React, { Component } from "react";
import { connect } from "react-redux";
import closeIcon from "../../Resources/close-icon.svg";
import { langData } from "../Common/Language";
import {
  DroppedSite,
  ClearRegisteredStatusLog,
} from "../../Actions/RegisteredSites";
import { ShowAlert } from "../../Actions/Common";

class DropSiteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      droppedSiteRemark: "",
      droppedSiteRemarkError: false,
    };
  }

  validateRemark = () => {
    const { droppedSiteRemark } = this.state;
    if (droppedSiteRemark.trim()) {
      this.setState({ droppedSiteRemarkError: false });
      return true;
    } else {
      this.setState({ droppedSiteRemarkError: true });
      return false;
    }
  };
  

  handleSubmit = async () => {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    const { droppedSiteRemark } = this.state;
    const remark = this.validateRemark();
    if (!remark) return;

    const reqBody = {
      droppedremark: droppedSiteRemark.trim(),
      siteid: this.props.droppedSiteId,
      phonenum: this.props.dropSitePhone,
    };
    await this.props.DroppedSite(reqBody);
    if (this.props.droppedSiteStatus === 200) {
      this.props.ClearRegisteredStatusLog();
      this.props.ShowAlert({
        msg: langData[lan].droppedSiteSuccess || langData[0].droppedSiteSuccess,
        alertType: "Success",
      });

      this.props.handleCloseModal();
    }
  };

  render() {
    const { droppedSiteRemarkError } = this.state;
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    return (
      <div className="initiate-warranty-success-overlay">
        <div className="droppedSiteModal">
          <div className="heading">
            {langData[lan].droppedRemark || langData[0].droppedRemark}
            <img
              src={closeIcon}
              alt="Close"
              onClick={this.props.handleCloseModal}
            />
          </div>
          {/* REASON */}
          <div className="reason">
            {/* <div className="label compulsory">Remark</div> */}
            <textarea
              cols="5"
              rows="4"
              maxLength="950"
              placeholder={
                langData[lan].dropSiteRemarkPh || langData[0].dropSiteRemarkPh
              }
              onChange={(e) => {
                this.setState(
                  { droppedSiteRemark: e.target.value },
                  this.validateRemark
                );
              }}
              style={droppedSiteRemarkError ? { border: "1px solid red" } : {}}
            ></textarea>
          </div>

          {/* SUBMIT */}
          <div className="modal-submit">
            <div className="modal-submit-button" onClick={this.handleSubmit}>
              {langData[lan].submit || langData[0].submit}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    droppedSiteStatus: state.regSites.droppedSiteStatus,
    userPhoneNum: state.auth.userPhoneNum,
  };
};

export default connect(mapStateToProps, {
  ClearRegisteredStatusLog,
  DroppedSite,
  ShowAlert
})(DropSiteModal);
