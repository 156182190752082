import React, { Component } from "react";
import attention from "../../Resources/attention.svg";
import { langData } from "../Common/Language";
/**
 * Class LinkNotValidCom: Component
 */
class LinkNotValidCom extends Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    localStorage.removeItem("store-state");
    // localStorage.removeItem("UserInfo");
  }
  render() {
    const lan =
      localStorage.getItem("language")
        ? parseInt(localStorage.getItem("language")) - 1
        : 0;

    return (
      <div className="link-expired">
        <div className="link-expired-header">{langData[lan].attention}</div>
        <div className="link-expired-div">
          <img src={attention} alt="attention" />
          <div className="link-expired-msg">{langData[lan].tokenMiss}</div>
        </div>
      </div>
    );
  }
}

export default LinkNotValidCom;
