import React, { Component } from "react";
import searchNotFound from "../../Resources/search-not-found.svg";

class DataNotFound extends Component {
  render() {
    const { msg } = this.props;
    return (
      <div className="search-page-notFound">
        <div className="search-page-notFound-div">
          <img src={searchNotFound} alt="Search not found" />
          <div>{msg}</div>
        </div>
      </div>
    );
  }
}

export default DataNotFound;
