export const SITE_REGISTERATION = "/w4s/site-registration";
export const REGISTERED_SITE = "/w4s/registeredsite";
export const SUCCESSFULSUBMIT = "/w4s/successful";
export const EXPIRE = "/w4s/expire";
export const NOTVALID = "/w4s/token-missing";
export const EDIT_REGISTERED_SITE = "/w4s/edit-registered-site";
export const EMPTY_PAGE = "/w4s";
export const LOGIN = "/w4s/helpline";
export const HELPLINEHOME = "/w4s/helpline/home";
export const HELPLINE_NEW_REG = "/w4s/helpline/home/new-registration";
export const DSR_HOME = "/w4s/:user";
export const DOWNLOAD_FILE = "/w4s/download/:id";

// export const SITE_REGISTERATION = "/site-registration";
// export const REGISTERED_SITE = "/registeredsite";
// export const SUCCESSFULSUBMIT = "/successful";
// export const EXPIRE = "/expire";
// export const NOTVALID = "/token-missing";
// export const EDIT_REGISTERED_SITE = "/edit-registered-site";
// export const EMPTY_PAGE = "/";
// export const LOGIN = "/helpline";
// export const HELPLINEHOME = "/helpline/home";
// export const HELPLINE_NEW_REG = "/helpline/home/new-registration";
