import Server from "../API";
import {
  CLEAR_USER_STATUS,
  GET_DSR_LIST,
  GET_EXPORT_DATA,
  GET_SSO_LIST,
  GET_WSO_LIST,
  PAGE_SIZE,
  SAVE_PAGE_NUM,
  SET_SEARCH_INFO,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";


const API_LINK = "/paint/w4s/non-contractor/get-non-contractors";
/**
 * GetSSOList: Get SSO List
 * param: request body
 * return: action
 */
const GetSSOList = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `${API_LINK}?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_SSO_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetWSOList: Get WSO List
 * param: request body
 * return: action
 */
const GetWSOList = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `${API_LINK}?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_WSO_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetDSRList: Get DSR List
 * param: request body
 * return: action
 */
const GetDSRList = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `${API_LINK}?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_DSR_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

const SaveTablePageNumber = (key, value) => {
  return {
    type: SAVE_PAGE_NUM,
    payload: { key, value },
  };
};

const PersistSearchInfo = (key, value) => {
  return {
    type: SET_SEARCH_INFO,
    payload: { key, value },
  };
};

/**
 * ClearUsersStatus: Clear user  status log
 * param: request body
 * return: dispatch action
 */
const ClearUsersStatus = () => {
  return {
    type: CLEAR_USER_STATUS,
  };
};


const GetDataForExport=(params, pageNum = 0, pageSize = PAGE_SIZE)=>{
	return async (dispatch) => {
		try {
		  dispatch(StartLoader());
		  const response = await Server.post(
			`${API_LINK}?page=${pageNum}&size=${pageSize}`,
			params
		  );
		  dispatch(StopLoader());
		  dispatch({
			type: GET_EXPORT_DATA,
			payload: response.data,
		  });
		} catch (e) {
		  dispatch(HandleExceptionWithSecureCatch(e));
		}
	  };
}

export  {
  GetSSOList,
  GetWSOList,
  GetDSRList,
  ClearUsersStatus,
  SaveTablePageNumber,
  PersistSearchInfo,
  GetDataForExport
};
