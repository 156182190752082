import React, { Component } from "react";
import { Logout } from "../../Actions/AuthAction";
import { connect } from "react-redux";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import {
  AMFP_USER,
  CONTRACTOR_SIDEBAR_INDEX,
  DASHBOARD_SIDEBAR_INDEX,
  DSR_SIDEBAR_INDEX,
//   DSR_USER_TYPE,
  NEW_REG_SIDEBAR_INDEX,
  REGISTERED_SIDEBAR_INDEX,
  SSO_SIDEBAR_INDEX,
  SSO_USER,
  WSO_SIDEBAR_INDEX,
  WSO_USER,
//   WSO_USER,
} from "../../Actions/Types";

// import userAvatar from "../../Resources/user-avatar.svg";

class Sidebar extends Component {
  render() {
    const { userType, showSidebar } = this.props;
    return (
      <React.Fragment>
        <div className={`sidebar ${showSidebar ? "open-sidebar" : ""}`}>
          <div className="sidebar-container">
            {/* DASHBOARD FOR NON-CONTRACTOR */}
            {CheckNonContractorUser(userType) && (
              <div
                className={`sidebar-container-search-con ${
                  this.props.activeSidebar === DASHBOARD_SIDEBAR_INDEX &&
                  "active-sidebar"
                }`}
                id={DASHBOARD_SIDEBAR_INDEX}
                onClick={this.props.handleSidebarClick}
              >
                Dashboard
              </div>
            )}
            {/* NEW SITE REGISTRATION OPTION FOR DSR ONLY */}
            {CheckNonContractorUser(userType) && (
              <div
                className={`sidebar-container-search-con ${
                  this.props.activeSidebar === NEW_REG_SIDEBAR_INDEX &&
                  "active-sidebar"
                }`}
                id={NEW_REG_SIDEBAR_INDEX}
                onClick={this.props.handleSidebarClick}
              >
                New Registration
              </div>
            )}
            <div
              className={`sidebar-container-search-site ${
                this.props.activeSidebar === REGISTERED_SIDEBAR_INDEX &&
                "active-sidebar"
              }`}
              id={REGISTERED_SIDEBAR_INDEX}
              onClick={this.props.handleSidebarClick}
            >
              {CheckNonContractorUser(userType)
                ? "Registered sites"
                : "Search Site Registration"}
            </div>

            <div
              className={`sidebar-container-search-con ${
                this.props.activeSidebar === CONTRACTOR_SIDEBAR_INDEX &&
                "active-sidebar"
              }`}
              id={CONTRACTOR_SIDEBAR_INDEX}
              onClick={this.props.handleSidebarClick}
            >
              Search Contractor
            </div>
            {userType === AMFP_USER && (
              <div
                className={`sidebar-container-search-con ${
                  this.props.activeSidebar === SSO_SIDEBAR_INDEX &&
                  "active-sidebar"
                }`}
                id={SSO_SIDEBAR_INDEX}
                onClick={this.props.handleSidebarClick}
              >
                Search SSO
              </div>
            )}
            {(userType === AMFP_USER || userType === SSO_USER) && (
              <div
                className={`sidebar-container-search-con ${
                  this.props.activeSidebar === WSO_SIDEBAR_INDEX &&
                  "active-sidebar"
                }`}
                id={WSO_SIDEBAR_INDEX}
                onClick={this.props.handleSidebarClick}
              >
                Search WSO
              </div>
            )}
            {(userType === AMFP_USER || userType === SSO_USER || userType === WSO_USER) && (
              <div
                className={`sidebar-container-search-con ${
                  this.props.activeSidebar === DSR_SIDEBAR_INDEX &&
                  "active-sidebar"
                }`}
                id={DSR_SIDEBAR_INDEX}
                onClick={this.props.handleSidebarClick}
              >
                Search DSR
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    username: state.auth.username,
    userPhoneNum: state.auth.userPhoneNum,
    registrationNum: state.auth.registrationNum,
  };
};
export default connect(mapStateToProps, { Logout })(Sidebar);
