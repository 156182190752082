import {
  AMFP_USER,
  DSR_USER_TYPE,
  SSO_USER,
  WSO_USER,
} from "../../Actions/Types";

export const CheckNonContractorUser = (userType) => {
  if (
    userType === DSR_USER_TYPE ||
    userType === WSO_USER ||
    userType === SSO_USER ||
    userType === AMFP_USER
  )
    return true;
  return false;
};
