import {
  CLEAR_ALL_SEARCH_INFO,
  CLEAR_USER_STATUS,
  GET_DSR_LIST,
  GET_EXPORT_DATA,
  GET_SSO_LIST,
  GET_WSO_LIST,
  RESET_STATE,
  SAVE_PAGE_NUM,
  SET_SEARCH_INFO,
} from "../Actions/Types";

const INIT_STATE = {
  ssoList: [],
  getSSOListStatus: null,
  ssoCount: 0,

  wsoList: [],
  getWSOListStatus: null,
  wsoCount: 0,

  dsrList: [],
  getDSRListStatus: 0,
  dsrCount: 0,

  WSOPageNum: 0,
  SSOPageNum: 0,
  DSRPageNum: 0,

  searchSSODetails: null,
  searchWSODetails: null,
  searchDSRDetails: null,

  getExportDataStatus: null,
  exportUserList:[]
};

const UsersReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SSO_LIST:
      return {
        ...state,
        ssoList: payload.data,
        getSSOListStatus: payload.success,
        ssoCount: payload.count,
      };
	  
    case GET_WSO_LIST:
      return {
        ...state,
        wsoList: payload.data,
        getWSOListStatus: payload.success,
        wsoCount: payload.count,
      };

    case GET_DSR_LIST:
      return {
        ...state,
        dsrList: payload.data,
        getDSRListStatus: payload.success,
        dsrCount: payload.count,
      };

    case SET_SEARCH_INFO:
      state[payload.key] = payload.value;
      return {
        ...state,
      };

    case SAVE_PAGE_NUM:
      state[payload.key] = payload.value;
      return {
        ...state,
      };

    case CLEAR_ALL_SEARCH_INFO:
      return {
        ...state,
        searchSSODetails: null,
        searchWSODetails: null,
        searchDSRDetails: null,
      };

	  case GET_EXPORT_DATA:
	  return {
		...state,
		exportUserList: payload.data,
		getExportDataStatus: payload.success
	  }

    case CLEAR_USER_STATUS:
      return {
        ...state,
        getSSOListStatus: null,
        getWSOListStatus: null,
        getDSRListStatus: null,
		getExportDataStatus: null
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default UsersReducer;
