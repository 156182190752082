import React, { Component } from "react";
import { langData } from "../Common/Language";

import closeIcon from "../../Resources/close-icon.svg";
import { connect } from "react-redux";
import {
  ClearRegisteredStatusLog,
  GetLatestRegistered,
  UpdateSiteReviews,
} from "../../Actions/RegisteredSites";
import { ShowAlert } from "../../Actions/Common";
import { HELPLINE_USER } from "../../Actions/Types";

class ReviewSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewQuestions: [
        {
          question: langData[0].siteReviewQue1,
          yesHandler: 1, //1 for date
          answer: null,
          id: "visitduringwip",
          error: false,
          remark: null,
        },
        {
          question: langData[0].siteReviewQue2,
          yesHandler: 1,
          answer: null,
          id: "visitaftercomp",
          error: false,
          remark: null,
        },
        {
          question: langData[0].siteReviewQue3,
          yesHandler: 2, //2 for volume
          answer: null,
          id: "productused",
          error: false,
          remark: null,
        },
        {
          question: langData[0].siteReviewQue4,
          answer: null,
          id: "rightsystemused",
          error: false,
          //   remark: null,
          //   yesHandler: 3, //3 for remark
        },
        {
          question: langData[0].siteReviewQue5,
          //   yesHandler: 4, //4 for site completion date
            answer: 1,
          id: "sitecompletion",
          error: false,
          remark: null,
        },
      ],
    };
  }

  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;

  //FUNCTIONS REQUIRED TO DATA PARSING AFTER RECEIVED FROM SERVR
  getQuestionAns = (data) => {
    return data === 1 || data === true || data === "true"
      ? 1
      : data === 0 || data === false || data === "false"
      ? 2
      : null;
  };

  getYesHandler = (data) => {
    return data;
  };

  componentDidMount() {
    this.setAllQuestions();
  }

  setAllQuestions = () => {
    const { reviewFormData } = this.props;
    // console.log(reviewFormData);
    if (reviewFormData) {
      const objReceived = [
        {
          answer: this.getQuestionAns(reviewFormData.visitduringwip),
          remark: reviewFormData.visitduringwipremark,
        },
        {
          answer: this.getQuestionAns(reviewFormData.visitaftercomp),
          remark: reviewFormData.visitaftercompremark,
        },
        {
          answer: this.getQuestionAns(reviewFormData.productused),
          remark: reviewFormData.productusedremark,
        },
        {
          answer: this.getQuestionAns(reviewFormData.rightsystemused),
          remark: reviewFormData.rightsystemusedremark,
        },
        {
          answer: 1,
          remark: reviewFormData.sitecompletionremark,
        },
      ];

      this.setState(
        {
          reviewQuestions: this.state.reviewQuestions.map((val, index) => ({
            ...val,
            ...objReceived[index],
          })),
        },
        () => {
          //Disable all inputs
          if (
            document.getElementById("review-questions") &&
            (reviewFormData.sitereviewed === 1 ||
              reviewFormData.sitereviewed === true || this.props.userType !==HELPLINE_USER)
          ) {
            const ele = document.querySelectorAll("input");
            for (let i = 0; i < ele.length; i++) ele[i].disabled = true;
          }
        }
      );
    }
  };

  //HANDLE RADIO BUTTONS
  handleRadioButtons = (e, id) => {
    this.setState({
      reviewQuestions: this.state.reviewQuestions.map((val) => {
        if (val.id === id)
          return {
            ...val,
            answer: Number(e.target.value),
            error: false,
            remark: "",
          };
        else return val;
      }),
    });
  };

  //HANDLE DATE OR REMARK
  handleQuestionAns = (e, id) => {
    if (id === "productused" && this.state.reviewQuestions[2].answer === 1) {
      if (!isNaN(e.target.value)) {
        this.setState({
          reviewQuestions: this.state.reviewQuestions.map((val) => {
            if (val.id === id) {
              return { ...val, remark: e.target.value, remarkError: false };
            } else return val;
          }),
        });
      }
    } else
      this.setState({
        reviewQuestions: this.state.reviewQuestions.map((val) => {
          if (val.id === id) {
            return { ...val, remark: e.target.value, remarkError: false };
          } else return val;
        }),
      });
  };

  //validate all inputs
  validateAllQuestions = () => {
    let errorIndicator = false;
    let reviewQuestionsError = [];
    const { reviewQuestions } = this.state;

    for (let i = 0; i < reviewQuestions.length; i++) {
      const val = reviewQuestions[i];
      //CHECK RADIO BUTTONS ARE SELECTED OR NOT
      if (!val.answer && i !== 4) {
        errorIndicator = true;
        reviewQuestionsError.push({ ...val, error: true });
        continue;
      }

      //OLD CONDITION: i !== 3 &&
      //((!val.remark && i !== 4) || (i === 4 && val.answer && !val.remark))
      if (i !== 3 && !val.remark) {
        errorIndicator = true;
        reviewQuestionsError.push({ ...val, remarkError: true });
        continue;
      }
      reviewQuestionsError.push({ ...val, error: false });
    }

    /*  this.setState({
      reviewQuestions: this.state.reviewQuestions.map((val) => {
        if (!val.answer) {
          return { ...val, error: true };
        }
        if (!val.remark) {
          return { ...val, remarkError: true };
        }
        return { ...val, error: false };
      }),
    }); */
    this.setState({ reviewQuestions: reviewQuestionsError });
    if (errorIndicator) return false;
    else return true;
  };

  //SUBMIT REVIEWS FOR SITE
  handleSubmitButton = async () => {
    if (!this.validateAllQuestions()) return;
    const { reviewQuestions } = this.state;

    //FORM OBJECT
    const reqBody = {
      siteid: this.props.siteInfo.siteid,
    };

    let allAnsYes = true;
    for (let i = 0; i < reviewQuestions.length; i++) {
      const que = reviewQuestions[i];
        reqBody[que.id] =
          que.answer === 1 ? "true" : que.answer === 2 ? "false" : undefined;
      reqBody[`${que.id}remark`] = que.remark || undefined;

      //CHECK IF QUESTION ANS IS YES OR NO
      if (!que.answer || que.answer === 2) {
        console.log(que.answer);
        allAnsYes = false;
      }
    }

    await this.props.UpdateSiteReviews(reqBody);
    if (this.props.updateSiteReviewStatus === 200) {
      this.props.closeModal();
      this.props.ClearRegisteredStatusLog();
      console.log(allAnsYes);
      //SHOW ALERTS BASED ON ANSWERS GIVEN
      if (allAnsYes) this.props.handleInitiateWarrantySuccess();
      else
        this.props.ShowAlert({
          msg: "Site review added",
          alertType: "Success",
        });
      /* this.props.ShowAlert({
          msg: "Site review updated",
          alertType: "Success",
        });
        //    this.props.fromList && this.props.GetLatestRegistered(0, PAGE_SIZE); */
      // } else {
      /* console.log("for new review form", allAnsYes);
        //SHOW ALERTS BASED ON ANSWERS GIVEN
        if (allAnsYes) this.props.handleInitiateWarrantySuccess();
        else
          this.props.ShowAlert({
            msg: "Site review added",
            alertType: "Success",
          }); */
      // }
    }
  };

  //RESET QUESTION
  resetQuestions = () => {
    //IF FORM IS FOR EDIT THEN SET TO ALREADY FILLED DATA
    if (this.props.reviewFormData) {
      this.setAllQuestions();
    } else
      this.setState({
        reviewQuestions: this.state.reviewQuestions.map((val) => {
          return {
            question: val.question,
            answer: null,
            id: val.id,
            yesHandler: val.yesHandler,
          };
        }),
      });
  };

  //RETURN ALL QUESTIONS
  renderQuestion = (obj, index) => {
    const { question, yesHandler, answer, id, error, remarkError, remark } =
      obj;
    return (
      <div className="question" id="review-questions">
        <div className="question-label">
          <div
            className={`label compulsory ${
              (error || remarkError) && "required-que"
            }`}
          >
            {index + 1}. {question}
          </div>
          {/* RADIO BUTTONS */}
          {/* Do not show radio buttons for 5th question  */}
          {index !== 4 && (
            <div className="radio">
              <div className="radio-input">
                <input
                  type="radio"
                  name={id}
                  id={id + "yes"}
                  value="1"
                  onChange={(e) => {
                    this.handleRadioButtons(e, id);
                  }}
                  checked={answer === 1}
                />
                <label htmlFor={id + "yes"}>{langData[0].yes}</label>
              </div>
              <div className="radio-input">
                <input
                  type="radio"
                  name={id}
                  id={id + "no"}
                  value="2"
                  onChange={(e) => {
                    this.handleRadioButtons(e, id);
                  }}
                  checked={answer === 2}
                />
                <label htmlFor={id + "no"}>{langData[0].no}</label>
              </div>
            </div>
          )}
        </div>

        {answer && (
          <div className="question-explanation">
            {answer === 1 ? (
              yesHandler === 1 ? (
                //  Date
                <div className="date">
                  <div className="label">{langData[0].dateLabel}:</div>
                  <input
                    type="date"
                    name={id}
                    id=""
                    value={remark}
                    onChange={(e) => this.handleQuestionAns(e, id)}
                  />
                </div>
              ) : yesHandler === 2 ? (
                //   Volume
                <div className="date">
                  <div className="label">{langData[0].prodVol}:</div>
                  <input
                    type="text"
                    name={id}
                    id=""
                    value={remark}
                    placeholder={langData[0].prodVolPh}
                    onChange={(e) => this.handleQuestionAns(e, id)}
                  />
                </div>
              ) : yesHandler === 3 ? (
                //  Remark
                <div className="date">
                  <div className="label">{langData[0].remark}:</div>
                  <input
                    type="text"
                    name={id}
                    id=""
                    value={remark}
                    placeholder={langData[0].remarkPh}
                    onChange={(e) => this.handleQuestionAns(e, id)}
                  />
                </div>
              ) : yesHandler === 4 ? (
                // Site completion date
                <div className="date">
                  <div className="label">
                    {langData[0].siteCompletionDate}:
                  </div>
                  <input
                    type="date"
                    name={id}
                    id=""
                    value={remark}
                    onChange={(e) => this.handleQuestionAns(e, id)}
                  />
                </div>
              ) : null
            ) : answer === 2 && index !== 3 ? (
              //  Remark for no option
              <div className="date">
                <div className="label">{langData[0].remark}:</div>
                <input
                  type="text"
                  name=""
                  id=""
                  value={remark}
                  placeholder={langData[0].remarkPh}
                  onChange={(e) => {
                    this.handleQuestionAns(e, id);
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
        {index === 4 && (
          <div className="question-explanation">
            <div className="date">
              <div className="label">
                {langData[0].siteCompletionDate}:
              </div>
              <input
                type="date"
                name={id}
                id=""
                value={remark}
                onChange={(e) => this.handleQuestionAns(e, id)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { reviewQuestions } = this.state;
    const { closeModal, reviewFormData } = this.props;
    return (
      <div className="review-site-modal">
        <div className="header">
          Review site
          <img src={closeIcon} alt="" onClick={closeModal} />
        </div>
        <div className="questions-wrapper">
          {reviewQuestions.map((val, index) => (
            <div key={index}>{this.renderQuestion(val, index)}</div>
          ))}
        </div>

        {/* BUTTONS */}
        {((reviewFormData &&
          (reviewFormData.sitereviewed === 0 ||
            reviewFormData.sitereviewed === false)) ||
          !reviewFormData) && this.props.userType === HELPLINE_USER && (
          <div className="btns">
            {/* Submit */}
            <div className="field-btn">
              <button
                className="submit button"
                onClick={this.handleSubmitButton}
              >
                {langData[0].submit}
              </button>
            </div>

            {/* RESET */}
            <div className="field-btn">
              <button className="reset button" onClick={this.resetQuestions}>
                {langData[0].reset}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
	userType: state.auth.userType,
    updateSiteReviewStatus: state.regSites.updateSiteReviewStatus,
  };
};

export default connect(mapStateToProps, {
  ClearRegisteredStatusLog,
  UpdateSiteReviews,
  ShowAlert,
  GetLatestRegistered,
})(ReviewSite);
