import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ClearUsersStatus,
  GetDSRList,
  GetSSOList,
  GetWSOList,
  PersistSearchInfo,
  SaveTablePageNumber,
} from "../../Actions/UsersAction";
import {
  PersistSearchContractorInfo,
  SaveContractorPageNumber,
} from "../../Actions/Common";
import {
  CONTRACTOR_TABLE,
  DSR_TABLE,
  DSR_USER_TYPE,
  PAGE_SIZE,
  SITE_TABLE,
  SSO_TABLE,
  WSO_TABLE,
  WSO_USER,
} from "../../Actions/Types";
import SearchContractor from "../Contractor/SearchContractor";
import DSRTable from "./DSRTable";
import SSOTable from "./SSOTable";
import WSOTable from "./WSOTable";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import { GetContractorList } from "../../Actions/ContractorAction";
import nextArrow from "../../Resources/next-arrow.svg";
import RegisteredSiteSteps from "../RegisteredSiteSteps";

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableActive: SSO_TABLE,
      SSODetails: null,
      WSODetails: null,
      DSRDetails: null,
      contractorDetails: null,
    };
  }

  componentDidMount() {
    const { activeTable } = this.props;
    if (activeTable) this.setState({ tableActive: activeTable });
  }

  handleSSOClick = (SSODetails) => {
    this.props.PersistSearchInfo("searchWSODetails", null);
    this.props.SaveTablePageNumber("WSOPageNum", 0);
    this.setState({ SSODetails, tableActive: WSO_TABLE }, () => {
      this.getUserList(SSODetails.phonenum, "WSO");
    });
  };

  handleWSOClick = (WSODetails) => {
    this.props.PersistSearchInfo("searchDSRDetails", null);
    this.props.SaveTablePageNumber("DSRPageNum", 0);
    this.setState({ WSODetails, tableActive: DSR_TABLE }, () => {
      this.getUserList(WSODetails.phonenum, "DSR");
    });
  };

  handleDSRClick = (DSRDetails) => {
    this.props.PersistSearchContractorInfo(null);
    this.props.SaveContractorPageNumber(0);
    this.setState({ DSRDetails, tableActive: CONTRACTOR_TABLE }, () => {
      this.getContractorList();
    });
  };

  handleContractorClicked = (contractorDetails) => {
    this.setState({ contractorDetails, tableActive: SITE_TABLE }, () => {});
  };

  //HANDLE BACK BUTTON
  handleBackButton = () => {
    const { tableActive } = this.state;
    if (tableActive === WSO_TABLE) {
      this.setState({ tableActive: SSO_TABLE, SSODetails: null });
      this.props.PersistSearchInfo("searchWSODetails", null);
      this.props.SaveTablePageNumber("WSOPageNum", 0);
    } else if (tableActive === DSR_TABLE) {
      this.props.PersistSearchInfo("searchDSRDetails", null);
      this.props.SaveTablePageNumber("DSRPageNum", 0);
      this.setState({ tableActive: WSO_TABLE, WSODetails: null });
    } else if (tableActive === CONTRACTOR_TABLE) {
      this.props.PersistSearchContractorInfo(null);
      this.props.SaveContractorPageNumber(0);
      this.setState({ tableActive: DSR_TABLE, DSRDetails: null });
    } else if (tableActive === SITE_TABLE) {
      this.setState({ tableActive: CONTRACTOR_TABLE, contractorDetails: null });
    }
  };

  getUserList = async (requesterPhone, userType) => {
    const reqBody = {
      requesterPhone,
      userType,
    };
    if (userType === WSO_USER) {
      await this.props.GetWSOList(reqBody, 0, PAGE_SIZE);
    } else if (userType === DSR_USER_TYPE) {
      await this.props.GetDSRList(reqBody, 0, PAGE_SIZE);
    }
    if (
      this.props.getWSOListStatus === 200 ||
      this.props.getDSRListStatus === 200
    ) {
      this.props.ClearUsersStatus();
    }
  };

  getContractorList = async () => {
    const reqBody = {
      requesterPhone: this.state.DSRDetails.phonenum,
    };
    if (CheckNonContractorUser(this.props.userType)) {
      await this.props.GetContractorList(reqBody, 0, PAGE_SIZE);
    }
  };

  /**
   * handleNewReg: open site reg by setting openSiteReg to true
   */
  handleEditSite = () => {
    this.setState({ openSiteUpdate: true });
  };

  /**
   * handleCloseSiteReg:  close site reg by setting openSiteReg to false
   */
  handleCloseSiteReg = () => {
    this.setState({
      openSiteUpdate: false,
      showSiteList: true,
      tableActive: CONTRACTOR_TABLE,
      contractorDetails: null,
    });
  };

  render() {
    const {
      tableActive,
      SSODetails,
      WSODetails,
      DSRDetails,
      contractorDetails,
    } = this.state;
    return (
      <div className="user-table-container font-poppins">
        <div className="routing-back">
          <div className="routing">
            {SSODetails ? SSODetails.name + "(SSO)" : null}
            {SSODetails && WSODetails ? <img src={nextArrow} alt="" /> : null}
            {WSODetails ? WSODetails.name + "(WSO)" : null}
            {DSRDetails && WSODetails ? <img src={nextArrow} alt="" /> : null}
            {DSRDetails ? DSRDetails.name + "(DSR)" : null}
            {DSRDetails && contractorDetails ? (
              <img src={nextArrow} alt="" />
            ) : null}
            {contractorDetails ? contractorDetails.name + "(AWA)" : null}
          </div>
          {(SSODetails || WSODetails || DSRDetails) &&
            tableActive !== SITE_TABLE && (
              <div className="back bk-blue" onClick={this.handleBackButton}>
                Back
              </div>
            )}
        </div>
        {tableActive === SSO_TABLE && (
          <SSOTable onUserClick={this.handleSSOClick} />
        )}
        {tableActive === WSO_TABLE && (
          <WSOTable onUserClick={this.handleWSOClick} SSODetails={SSODetails} />
        )}
        {tableActive === DSR_TABLE && (
          <DSRTable onUserClick={this.handleDSRClick} WSODetails={WSODetails} />
        )}
        {(tableActive === CONTRACTOR_TABLE || tableActive === SITE_TABLE) && (
          <div className="search-sso">
            {this.state.openSiteUpdate ? (
              <RegisteredSiteSteps
                handleCloseSiteReg={this.handleCloseSiteReg}
              />
            ) : (
              <SearchContractor
                showUserDetails={false}
                DSRDetails={DSRDetails}
                UserContractorClicked={this.handleContractorClicked}
                showNewSiteReg={false}
                handleNewReg={this.handleEditSite}
                handleBackButton={this.handleBackButton}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    getWSOListStatus: state.user.getWSOListStatus,
    getDSRListStatus: state.user.getDSRListStatus,
  };
};
export default connect(mapStateToProps, {
  PersistSearchContractorInfo,
  PersistSearchInfo,
  SaveTablePageNumber,
  GetSSOList,
  GetWSOList,
  GetDSRList,
  ClearUsersStatus,
  GetContractorList,
  SaveContractorPageNumber,
})(UserTable);
