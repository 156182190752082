import React, { Component } from "react";
import Lottie from "react-lottie";

import animationData from "../../Resources/blue-loading-dots.json";

/*
 * Class: LoaderModal
 * Description: Shows Loader
 */
export class LoaderModal extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <Lottie options={defaultOptions} height={100} width={100} speed={2.5} />
    );
  }
}

export default LoaderModal;
