import React, { Component } from "react";
import searchNotFound from "../../Resources/search-not-found.svg";

import {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  GetRegisteredSitesForHelpLine,
  GetDetailedSiteReport,
} from "../../Actions/RegisteredSites";
import { connect } from "react-redux";
import {
  HELPLINE_USER,
  NEW_REG_SIDEBAR_INDEX,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  SITE_STATUS,
} from "../../Actions/Types";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// import exportFromJSON from "export-from-json";
import {
  PersistSearchSiteInfo,
  SavePageNumber,
  SaveSortingInfo,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import UserInfo from "../Common/UserInfo";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import { SaveSidebarIndex } from "../../Actions/AuthAction";
import { GetMonth } from "../Common/GetMonth";
import { NumberConversion } from "../Common/NumberConversion";
import { GetDateTime } from "../Common/GetDateTime";
import { ExportSheet } from "../Common/ExportSheet";
import { ExportJSONToSheet } from "../_Utils/ExportJSONToSheet";
// import { langData } from "../Common/Language";

class SearchSite extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getSitesOnLoad = async () => {
    await this.getRegisteredSitesList();
    if (this.props.getRegisteredSites !== 200) {
      console.log("REFRESH WHEN DATA IS NOT LOADED");
      window.location.reload();
    }
  };

  componentDidMount() {
    this.getSitesOnLoad();
  }

  //GET SITES ON PREV AND NEXT CLICK
  getRegisteredSitesList = async (isExport = false) => {
    const { userType, userPhoneNum, siteSortDetails, searchSiteDetails } =
      this.props;
    const { pageSize } = this.state;
    let reqBody = {
      //   phonenum: userPhoneNum || undefined,
      searchKey: searchSiteDetails
        ? searchSiteDetails.searchBy.value || undefined
        : undefined,
      searchValue: searchSiteDetails
        ? searchSiteDetails.searchQuery.trim() || undefined
        : undefined,
      requesterPhone: CheckNonContractorUser(userType)
        ? userPhoneNum
        : undefined,
      sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
      sortOrder: siteSortDetails
        ? siteSortDetails.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
    };

    //IF USER TYPE IS HELPLINE
    if (userType === HELPLINE_USER) {
      //USER IS ON SEARCH CONTRACTOR PAGE THEN GET REGISTERED SITES WITH MOBILE NUMBER
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    }
    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      /* const reqBody = {
        phonenum: userPhoneNum,
        searchBy: selectedSearchOption.value,
        searchQuery: searchQuery.trim(),
      }; */
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        isExport ? 0 : this.state.pageNum,
        isExport ? this.props.countOfData : pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }
  };

  getSiteDetails = async () => {
    await this.props.GetDetailsOfSite({
      leadid: this.state.searchQuery.trim(),
    });
    if (this.props.getSiteInfo === 200) {
      this.props.handleOpenSiteReg();
      this.props.ClearRegisteredStatusLog();
    } else {
      //   this.setState({ showNotFound: true });
      //   this.props.handleShowList(false);
    }
  };

  //DOWNLOAD ALL SITES
  downloadAllSites = async () => {
    const { siteSortDetails, searchSiteDetails } = this.props;
    const reqBody = {
      sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
      sortOrder: siteSortDetails
        ? siteSortDetails.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
      searchKey: searchSiteDetails
        ? searchSiteDetails.searchBy.value || undefined
        : undefined,
      searchValue: searchSiteDetails
        ? searchSiteDetails.searchQuery.trim() || undefined
        : undefined,
    };
    await this.props.GetAllRegisteredSites(reqBody);
    if (this.props.getAllRegisteredSites === 200) {
      const { allRegisteredSites } = this.props;
      this.props.ClearRegisteredStatusLog();
      if (allRegisteredSites && allRegisteredSites.length) {
        this.ExportToExcel();
      }
    }
  };

  //DOWNLOAD SITE DETAILED REPORT HANDLING
  downloadSiteDetailedReport = async () => {
    const { siteSortDetails, searchSiteDetails } = this.props;
    const reqBody = {
      sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
      sortOrder: siteSortDetails
        ? siteSortDetails.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
      searchKey: searchSiteDetails
        ? searchSiteDetails.searchBy.value || undefined
        : undefined,
      searchValue: searchSiteDetails
        ? searchSiteDetails.searchQuery.trim() || undefined
        : undefined,
    };
    await this.props.GetDetailedSiteReport(reqBody);
    if (this.props.getRegSiteReportStatus === 200) {
      const { detailedSitesReport } = this.props;
      this.props.ClearRegisteredStatusLog();
      if (detailedSitesReport && detailedSitesReport.length) {
        this.ExportDetailedSiteReport();
      }
    }
  };

  getEpoxyVolume = (product) => {
    console.log(product);
    if (
      product === "WoodTech Epoxy" ||
      product === "वुडटेक एपॉक्सी" ||
      product === "வூட்டெக் எபோக்சி" ||
      product === "వుడ్‌టెక్ ఎపోక్సీ" ||
      product === "ವುಡ್ಟೆಕ್ ಎಪಾಕ್ಸಿ" ||
      product === "വുഡ്ടെക് എപ്പോക്സി"
    ) {
      // console.log("Got epoxy")
      return true;
    } else return false;
  };

  //CREATE OBJECT FOR EXPORT REPORT
  getExportDataObject = (site, furniture = null, type) => {
    let siteDetails = {};
    siteDetails["Contractor Name"] = site.name;
    siteDetails["Phone Number"] = NumberConversion(site.phonenum);
    siteDetails["BP Number"] = NumberConversion(site.registrationnum) || "";
    siteDetails["Region"] = site.region;
    siteDetails["Site ID"] = NumberConversion(site.siteid);
    siteDetails["Lead ID"] = String(site.leadid);
    siteDetails["Customer Name"] = site.customername;
    siteDetails["Customer Phone"] = NumberConversion(site.customerphone);
    siteDetails["Customer Email"] = site.customeremail || "";
    siteDetails["Customer Address"] = site.customeraddress || "";
    siteDetails["Pincode"] = NumberConversion(site.pincode) || "";
    siteDetails["Dealer Code"] = NumberConversion(site.dealercode) || "";
    siteDetails["Dealer Name"] = site.dealername || "";
    siteDetails["Dealer Phone"] = NumberConversion(site.dealerphone) || "";
    siteDetails["Expected Site Value"] = site.sitevalue || "";
    siteDetails["Site Status"] = SITE_STATUS[site.sitestatus];
    siteDetails["Dropped Remark"] = site.droppedremark || "";
    siteDetails["Created At"] = GetDateTime(site.createdat);
    siteDetails["Updated At"] = GetDateTime(site.updatedat);
    siteDetails["Registration Month"] = GetMonth(site.createdat);

    //IF THERE IS FURNITURE THEN ADD FURNITURE DETAILS
    if (furniture) {
      siteDetails["Product"] = furniture.productname;
      siteDetails["Volume"] = furniture.productvolume;
      siteDetails["Room"] = furniture.roomname;
      siteDetails["Furniture"] = furniture.furniturename;
    } else if (type === "empty-furniture") {
      siteDetails["Product"] = "";
      siteDetails["Volume"] = "";
      siteDetails["Room"] = "";
      siteDetails["Furniture"] = "";
    }

    siteDetails["Site Volume"] =
      site.sitetotalvolume === "NA"
        ? ""
        : NumberConversion(site.sitetotalvolume) || "";
    siteDetails["Epoxy Volume"] =
      site.epoxyvolume === "NA"
        ? ""
        : furniture
        ? this.getEpoxyVolume(furniture.productname)
          ? NumberConversion(furniture.productvolume)
          : ""
        : NumberConversion(site.epoxyvolume) || "";

    siteDetails["Furniture pictures uploaded"] = site.furniturepicturesuploaded
      ? "Y"
      : "N";
    siteDetails["Invoice Picture uploaded"] = site.invoicepictureuploaded
      ? "Y"
      : "N";
    siteDetails["WIP DSR Visit done"] = site.wipdsrvisitdone
      ? "Y"
      : site.wipdsrvisitdone === false
      ? "N"
      : "NA";
    siteDetails["DSR visit post site completion done"] =
      site.postsitecompletiondsrvisitdone
        ? "Y"
        : site.postsitecompletiondsrvisitdone === false
        ? "N"
        : "NA";
    siteDetails["Warranty Initiated"] =
      site.sitestatus !== 0 && site.sitestatus !== 4 ? "Y" : "N";
    siteDetails["Warranty Issued"] = site.warrantyissued
      ? "Y"
      : site.warrantyissued === false
      ? "N"
      : "NA";
    siteDetails["Folder Link"] = `${
      window.location.origin
    }/w4s/download/${encodeURIComponent(
      Buffer.from(
        JSON.stringify({ siteId: site.siteid, leadId: site.leadid }).toString(
          "base64"
        )
      )
    )}`;
    return siteDetails;
  };

  //EXPORT THE ALL SITE REPORTS
  ExportToExcel = () => {
    const fileName =
      "Sites-" +
      new Date().toLocaleDateString("en-GB").toString().replace(/\//g, "-");
    let data = [];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const tempData = this.props.allRegisteredSites;
    //CREATE DESIRED OBJECT
    for (let i = 0; i < tempData.length; i++) {
      const site = tempData[i];
      const siteDetails = this.getExportDataObject(site);
      data.push(siteDetails);
    }
    const ws = XLSX.utils.json_to_sheet(data);

    //SET WIDTH FOR COLUMNS
    var wscols = [...Array(28).keys()].map(() => ({ wch: 25 }));
    wscols.push({ wch: 100 }); //width for folder link
    ws["!cols"] = wscols;

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataFromExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataFromExcel, fileName + fileExtension);
  };

  //EXPORT DETAILED SITES REPORT
  ExportDetailedSiteReport = () => {
    const fileName =
      "Detailed Site report-" +
      new Date().toLocaleDateString("en-GB").toString().replace(/\//g, "-");
    let data = [];
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const tempData = this.props.detailedSitesReport;
    //CREATE DESIRED OBJECT
    for (let i = 0; i < tempData.length; i++) {
      const site = tempData[i];
      if (site.furnitureList) {
        if (site.furnitureList.length) {
          for (let j = 0; j < site.furnitureList.length; j++) {
            const furniture = site.furnitureList[j];
            const siteDetails = this.getExportDataObject(site, furniture);
            data.push(siteDetails);
          }
        } else {
          const siteDetails = this.getExportDataObject(
            site,
            null,
            "empty-furniture"
          );
          data.push(siteDetails);
        }
      }
    }
    const ws = XLSX.utils.json_to_sheet(data);
    var wscols = [...Array(32).keys()].map(() => ({ wch: 25 }));
    wscols.push({ wch: 100 }); //width for folder link
    ws["!cols"] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataFromExcel = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataFromExcel, fileName + fileExtension);
  };

  /* ------------------ EXPORT FUNCTIONALITY FOR CONTRACTOR ----------------- */
  nonContractorExportData = (site) => {
    let siteDetails = {};
    siteDetails["Contractor Name"] = site.name;
    siteDetails["Phone Number"] = NumberConversion(site.phonenum);
    siteDetails["BP Number"] = NumberConversion(site.registrationnum) || "";
    siteDetails["Site ID"] = NumberConversion(site.siteid);
    siteDetails["Lead ID"] = String(site.leadid);
    siteDetails["Customer Name"] = site.customername;
    siteDetails["Customer Phone"] = NumberConversion(site.customerphone);
    siteDetails["Customer Email"] = site.customeremail || "";
    siteDetails["Customer Address"] = site.customeraddress || "";
    siteDetails["Pincode"] = NumberConversion(site.pincode) || "";
    siteDetails["Dealer Code"] = NumberConversion(site.dealercode) || "";
    siteDetails["Dealer Name"] = site.dealername || "";
    siteDetails["Dealer Phone"] = NumberConversion(site.dealerphone) || "";
    siteDetails["Expected Site Value"] = site.sitevalue || "";
    siteDetails["Site Status"] = SITE_STATUS[site.sitestatus];
    siteDetails["Dropped Remark"] = site.droppedremark || "";
    siteDetails["Created At"] = GetDateTime(site.createdat);
    siteDetails["Updated At"] = GetDateTime(site.updatedat);

    return siteDetails;
  };

  handleNonContractorExportData = async () => {
    await this.getRegisteredSitesList(true);
    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
      let data = [];
      const { registeredSites } = this.props;
      if (registeredSites) {
        for (let i = 0; i < registeredSites.sitesList.length; i++) {
          const site = registeredSites.sitesList[i];
          const siteDetails = this.nonContractorExportData(site);
          data.push(siteDetails);
        }
        ExportJSONToSheet(data, "Sites", 27);
      }
    }
  };

  handleNewRegistrationButton = () => {
    this.props.SaveSidebarIndex(NEW_REG_SIDEBAR_INDEX);
  };

  render() {
    //prettier-ignore
    const {userType}= this.props;
    return (
      <div className="search-page">
        {this.props.userType !== HELPLINE_USER && (
          <div className="user-info-wrapper">
            <div
              className="new-registration-button bk-blue"
              onClick={this.handleNewRegistrationButton}
            >
              New Registration
            </div>
            <UserInfo />
          </div>
        )}
        <div className="search-page-header">Search Site Registration</div>
        {/* Open Site count */}

        <div className={`count font-poppins`}>
          {/* {this.props.userType === HELPLINE_USER ? ( */}
          <div
            className={`count-wrapper ${
              CheckNonContractorUser(this.props.userType) &&
              "count-wrapper-non-contractor"
            }`}
          >
            <div className="opened">
              Open sites:{" "}
              <div className="highlighted">{this.props.openedSiteCount},</div>
            </div>
            <div className="closed">
              Under review:{" "}
              <div className="highlighted">{this.props.closedSiteCount}</div>
            </div>
            <div className="closed">
              Warranty issued:{" "}
              <div className="highlighted">
                {this.props.warrantyIssuedCount}
              </div>
            </div>
            <div className="closed">
              Warranty claimed:{" "}
              <div className="highlighted">
                {this.props.warrantyClaimedCount}
              </div>
            </div>
            <div className="closed">
              Dropped Sites:{" "}
              <div className="highlighted">
                {this.props.warrantyClaimedCount}
              </div>
            </div>
          </div>
          {/* ) : null} */}

          {/* DOWNLOAD BUTTON WRAPPERS */}
          {userType === HELPLINE_USER ? (
            <div className="download-btn-wrapper">
              <div
                className="download-sheet detailed-site bk-blue"
                onClick={this.downloadAllSites}
              >
                All Sites report
              </div>
              <div
                className="download-sheet  bk-blue"
                onClick={this.downloadSiteDetailedReport}
              >
                Detailed site report
              </div>
            </div>
          ) : (
            <ExportSheet onClickHandler={this.handleNonContractorExportData} />
          )}
        </div>

        {this.state.showNotFound ? (
          <div className="search-page-notFound">
            <div className="search-page-notFound-div">
              <img src={searchNotFound} alt="Search not found" />
              <div>Not Found</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    siteInfo: state.regSites.siteDetails,

    closedSiteCount: state.regSites.closedSiteCount,
    openedSiteCount: state.regSites.openedSiteCount,
    warrantyIssuedCount: state.regSites.warrantyIssuedCount,
    warrantyClaimedCount: state.regSites.warrantyClaimedCount,
    droppedSites: state.regSites.droppedSites,

    allRegisteredSites: state.regSites.allRegisteredSites,
    getAllRegisteredSites: state.regSites.getAllRegisteredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
    pageNum: state.common.pageNum,
    searchSiteDetails: state.common.searchSiteDetails,
    siteSortDetails: state.common.siteSortDetails,

    detailedSitesReport: state.regSites.detailedSitesReport,
    getRegSiteReportStatus: state.regSites.getRegSiteReportStatus,

    countOfData: state.regSites.countOfData,
    registeredSites: state.regSites.registeredSites,
  };
};
export default connect(mapStateToProps, {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  StartLoader,
  StopLoader,
  PersistSearchSiteInfo,
  GetRegisteredSitesForHelpLine,
  SaveSortingInfo,
  SavePageNumber,
  GetDetailedSiteReport,
  SaveSidebarIndex,
})(SearchSite);
