import React, { Component } from 'react'

class InitiateWarrantySuccess extends Component {
	render () {
		return (
			<div className="initiate-warranty-success-overlay">
				<div className='initiate-warranty-success-modal'>
					<div className="msg">Thank You for your initiation. <br/>Warranty document is available now.</div>
					<div className="close-button bk-blue" onClick={this.props.handleInitiateWarrantySuccess}>
						Close
					</div>
				</div>
			</div>
		)
	}
}

export default InitiateWarrantySuccess;