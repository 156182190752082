import React, { Component } from "react";
import { connect } from "react-redux";
import JsZip from "jszip";
import { saveAs } from "file-saver";
import SearchSite from "./SearchSite";
import RegisteredSiteSteps from "../RegisteredSiteSteps";
import SiteList from "../RegisteredSites/SiteList";

import { StartLoader, StopLoader } from "../../Actions/Common";
//Actions
import {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  ClearSearchResult,
  SetSiteDetails,
  UpdateVisitedSites,
  DownloadImages,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import {
  HELPLINE_GET_SITE_IMAGES,
  HELPLINE_USER,
  NON_CONTACTOR_GET_SITE_IMAGES,
  //   NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

//Class Component for search Site page
class SearchSitePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSiteReg: false,
      furArray: [],
      prodArray: [],
      siteId: null,
      dealerInvoiceArray: [],
      showList: true,
      pageNum: props.pageNum,
      pageSize: PAGE_SIZE,
    };
  }

  componentDidMount() {
    // const { searchSiteDetails } = this.props;
    // const { pageNum, pageSize } = this.state;
    const { userType /* userPhoneNum */ } = this.props;
    /*  if (userType === HELPLINE_USER)
      this.props.GetLatestRegistered(pageNum, pageSize);
    else if (CheckNonContractorUser(userType)) {
      this.props.GetLatestRegisteredForNonContractor(
        {
          requesterPhone: userPhoneNum,
        },
        pageNum,
        pageSize
      );
	  } */
    /*  const reqBody = {
      searchValue: searchSiteDetails
        ? searchSiteDetails.searchQuery.trim() || undefined
        : undefined,
      searchKey: searchSiteDetails
        ? searchSiteDetails.searchBy.value || undefined
        : undefined,
      requesterPhone: CheckNonContractorUser(userType)
        ? userPhoneNum
        : undefined,
    }; */
    if (userType === HELPLINE_USER) {
      //   this.props.GetRegisteredSitesForHelpLine(reqBody, pageNum, pageSize);
    } else if (CheckNonContractorUser(userType)) {
      /* this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        pageNum,
        pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      ); */
    }
  }

  componentWillUnmount() {
    this.props.ClearSearchResult();
  }

  //download zip file
  componentDidUpdate() {
    const { images } = this.props;
    const { furArray, prodArray, dealerInvoiceArray } = this.state;
    if (images) {
      const { furnitureImages, productImages, dealerinvoices } = images;
      if (furnitureImages && productImages)
        if (
          furArray.length === furnitureImages.length &&
          prodArray.length === productImages.length &&
          dealerinvoices.length === dealerInvoiceArray.length &&
          this.props.downloadStatus === 200
        ) {
          this.props.ClearRegisteredStatusLog();
          this.zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, this.state.siteId);
          });
        }
    }
  }

  handleOpenSiteReg = () => {
    this.setState({ openSiteReg: true });
  };

  handleCloseSiteReg = () => {
    this.props.UpdateVisitedSites(
      this.props.siteDetails.siteid,
      this.props.furnitureDetails
    );
    this.setState({ openSiteReg: false });
  };

  handleSiteClick = async (item) => {
    // const { visitedSiteId, visitedSites, SetSiteDetails, SetFurnitureDetails } =
    //   this.props;
    // if (visitedSiteId && visitedSiteId.includes(item.siteid)) {
    //   let index = visitedSiteId.indexOf(item.siteid);
    //   //If found then fetch that
    //   let site = visitedSites[index];
    //   SetSiteDetails(visitedSites[index]);
    //   SetFurnitureDetails(site.furnitureList);
    //   this.handleOpenSiteReg();
    // } else {
    const reqBody = { leadid: item.leadid };
    await this.props.GetDetailsOfSite(reqBody);
    if (this.props.getSiteInfo === 200) {
      this.handleOpenSiteReg();
      this.props.ClearRegisteredStatusLog();
    }
    // }
  };

  //Download images into zip file
  downloadZipFile = async (item) => {
    const { images } = this.props;
    //Create Zip structure
    let furFolder = this.zip.folder("Furniture Images");
    let prodFolder = this.zip.folder("Product Images");
    let dealer = this.zip.folder("Dealer Invoice Images");

    //Download Dealer Invoice Array
    const { dealerinvoices } = images;
    dealerinvoices &&
      dealerinvoices.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            const list = image.link.split(".");
            const ext = list[list.length - 1];
            dealer.file(image.name + "." + ext, request.response, {
              type: Blob,
            });
            this.setState({
              dealerInvoiceArray: [...this.state.dealerInvoiceArray, index],
            });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });

    //Download Furniture images
    const { furnitureImages } = images;
    furnitureImages &&
      furnitureImages.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();
        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            const list = image.link.split(".");
            const ext = list[list.length - 1];
            furFolder.file(image.name + "." + ext, request.response, {
              type: Blob,
            });
            this.setState({ furArray: [...this.state.furArray, index] });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
      });

    //Download Product images
    const { productImages } = images;
    productImages &&
      productImages.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();
        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          const list = image.link.split(".");
          const ext = list[list.length - 1];
          reader.onload = () => {
            prodFolder.file(image.name + "." + ext, request.response);
            this.setState({ prodArray: [...this.state.prodArray, index] });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
      });
  };

  handleDownloadFiles = (e, item) => {
    e.stopPropagation();
    this.zip = new JsZip();
    this.setState(
      {
        furArray: [],
        prodArray: [],
        dealerInvoiceArray: [],
        siteId: item.leadid,
      },
      async () => {
        this.props.StartLoader();

        const { userType, userPhoneNum } = this.props;
        const reqBody = {
          siteid: item.siteid,
          requesterPhone: userType !== HELPLINE_USER ? userPhoneNum : undefined,
        };
        await this.props.DownloadImages(
          reqBody,
          userType === HELPLINE_USER
            ? HELPLINE_GET_SITE_IMAGES
            : NON_CONTACTOR_GET_SITE_IMAGES
        );

        this.props.StopLoader();
        if (this.props.downloadStatus === 200) {
          const { images } = this.props;
          if (images) {
            this.downloadZipFile(item);
          }
        }
      }
    );
  };

  render() {
    const { userType } = this.props;
    return this.state.openSiteReg ? (
      <div className="main-content-page">
        <RegisteredSiteSteps handleCloseSiteReg={this.handleCloseSiteReg} />
      </div>
    ) : (
      <div className="content-page">
        <SearchSite handleOpenSiteReg={this.handleOpenSiteReg} />
        {/* {showList && ( */}

        <SiteList
          handleContractorClick={this.handleSiteClick}
          helpLine={true}
          handleDownloadFiles={this.handleDownloadFiles}
          customClass={`site-list-container ${
            userType !== HELPLINE_USER && "non-contractor-site-list-container"
          }`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    siteDetails: state.regSites.siteDetails,
    visitedSites: state.regSites.visitedSites,
    visitedSiteId: state.regSites.visitedSiteId,
    furnitureDetails: state.furniture.furnitureDetails,
    downloadStatus: state.regSites.downloadStatus,
    images: state.regSites.images,

    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,

    pageNum: state.common.pageNum,
  };
};
export default connect(mapStateToProps, {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  ClearSearchResult,
  SetSiteDetails,
  SetFurnitureDetails,
  UpdateVisitedSites,
  DownloadImages,
  StartLoader,
  StopLoader,
  GetRegisteredSitesForHelpLine,
})(SearchSitePage);
