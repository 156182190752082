import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Expired from "./Expired";
import LinkNotValid from "./LinkNotValid";
import RegisteredSites from "./RegisteredSites";
import SiteRegSteps from "./RegisteredSites/SiteRegSteps";
import SiteRegisteration from "./SiteRegisteration";
import Submit from "./Submit";
import Alerts from "./Common/Alerts";
import Login from "./Login";
import Home from "./HelpLineHome";
import LoaderModal from "./Common/LoadarModal";
import { langData } from "./Common/Language";
//Paths
import {
  REGISTERED_SITE,
  SITE_REGISTERATION,
  SUCCESSFULSUBMIT,
  EXPIRE,
  NOTVALID,
  EDIT_REGISTERED_SITE,
  LOGIN,
  HELPLINEHOME,
  DSR_HOME,
  DOWNLOAD_FILE,
  //   HELPLINE_NEW_REG,
} from "./_Utils/Routes";
import ConfirmationPopup from "./ConfirmationPopup";
import { DeleteImage } from "../Actions/AssetsAction";

import w4sLogo from "../Resources/w4s-logo.svg";
import { CONTRACTOR_USER_TYPE } from "../Actions/Types";
import { SetLanguageId } from "../Actions/AuthAction";
import DownloadFiles from "./DownloadFile/DownloadFiles";
import LoadingProgress from "./Common/LoadingProgress";

class RouterComponent extends Component {
  constructor(props) {
    super(props);
    this.language = [
      "English",
      "Hindi",
      "Tamil",
      "Telugu",
      "Kannada",
      "Malayalam",
      "Assamese",
      "Bengali",
      "Oriya",
    ];
    this.state = {
      selectedLan: 0,
      openConfirmation: false,
      lang: 0,
    };
  }

  componentDidMount() {
    window.addEventListener("DOMContentLoaded", (event) => {
      //   const userInfo = JSON.parse(localStorage.getItem("UserInfo"));

      this.setState({
        selectedLan: this.props.language,
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.language &&
      this.state.selectedLan !== nextProps.language
    ) {
      //   const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
      this.setState({
        // selectedLan: userInfo.language,
        selectedLan: nextProps.language,
      });
    }
  }

  handleCloseConfirmationForSite = () => {
    this.setState({
      openConfirmation: false,
    });
  };

  handleYes = () => {
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    localStorage.removeItem("saveFiles");
    this.props.DeleteImage({
      key: images.map((image) => image),
    });
    this.setState({ openConfirmation: false });
    const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
    userInfo.language = parseInt(this.state.lang);
    localStorage.setItem("UserInfo", JSON.stringify(userInfo));
    localStorage.setItem("language", this.state.lang);
    window.location.reload();
  };

  onLanguageChange = (e) => {
    this.setState({ lang: e.target.value });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images && images.length > 0) {
      this.setState({
        openConfirmation: true,
      });
    } else {
      const userInfo = JSON.parse(localStorage.getItem("UserInfo"));
      userInfo.language = parseInt(e.target.value);
      localStorage.setItem("UserInfo", JSON.stringify(userInfo));
      localStorage.setItem("language", e.target.value);
      this.props.SetLanguageId(e.target.value);
      window.location.reload();
    }
  };

  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    const { userType } = this.props;
    return (
      <React.Fragment>
        {
          /* !window.location.pathname.startsWith("/w4s/helpline") &&
          window.location.pathname !== "/w4s/token-missing" &&
          window.location.pathname !== "/w4s/expire" &&
          userType !== DSR_USER_TYPE &&  */
          userType === CONTRACTOR_USER_TYPE &&
            !window.location.pathname.startsWith("/w4s/helpline") && (
              <div className="user-header-wrapper">
                <img src={w4sLogo} alt="Wood4sure logo" />
                <select
                  value={this.state.selectedLan}
                  className="language-dropdown"
                  name="languages"
                  id="languages"
                  onChange={(e) => {
                    this.onLanguageChange(e);
                  }}
                >
                  {this.language.map((lan, index) => (
                    <option
                      selected={
                        index + 1 === this.state.selectedLan ? true : false
                      }
                      value={index + 1}
                      key={index}
                    >
                      {lan}
                    </option>
                  ))}
                </select>
              </div>
            )
        }
        <ReactModal
          className="loader-modal-content"
          overlayClassName="loader-modal-overlay"
          isOpen={this.props.loaderState}
          ariaHideApp={false}
        >
          <LoaderModal />
        </ReactModal>
        <ReactModal
          isOpen={this.state.openConfirmation}
          onRequestClose={this.handleCloseConfirmationForSite}
          className="confirmation-popup-modal"
          overlayClassName="confirmation-popup-overlay"
          ariaHideApp={false}
        >
          <ConfirmationPopup
            handleSiteClosure={this.handleYes}
            handleOpenConfirmation={this.handleCloseConfirmationForSite}
            msg={langData[lan].clearDataConf}
          />
        </ReactModal>
        <Alerts />
        <LoadingProgress />
        <Switch>
          <Route
            exact
            path={SITE_REGISTERATION}
            component={SiteRegisteration}
          />
          <Route exact path={REGISTERED_SITE} component={RegisteredSites} />
          <Route exact path={SUCCESSFULSUBMIT} component={Submit} />
          <Route exact path={EXPIRE} component={Expired} />
          <Route exact path={NOTVALID} component={LinkNotValid} />
          <Route exact path={EDIT_REGISTERED_SITE} component={SiteRegSteps} />
          <Route exact path={LOGIN} component={Login} />
          <Route exact path={HELPLINEHOME} component={Home} />
          <Route exact path={DSR_HOME} component={Home} />
          <Route exact path={DOWNLOAD_FILE} component={DownloadFiles} />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loaderState: state.common.loaderState,
    userType: state.auth.userType,
    language: state.auth.language,
  };
};

export default connect(mapStateToProps, {
  DeleteImage,
  SetLanguageId,
})(RouterComponent);
