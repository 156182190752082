//Import types
import {
  DELETE_IMAGE,
  GET_IMAGE_UPLOAD_LINK,
  UPDATE_EXPIRED_LINK,
  RESET_STATE,
} from "../Actions/Types";

const INIT_STATE = {
  uploadLink: "",
};

const AssetsReducer = (state = INIT_STATE, action) => {
  var { type, payload } = action;

  switch (type) {
    //to set the alert with msg and type
    case DELETE_IMAGE:
      return {
        ...state,
        payload,
      };

    //to remove the alert
    case GET_IMAGE_UPLOAD_LINK:
      return {
        ...state,
        uploadLink: payload.data,
      };

    case UPDATE_EXPIRED_LINK:
      return {
        ...state,
      };

    //reset the state
    case RESET_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

export default AssetsReducer;
