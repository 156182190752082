import {
  ADD_FURNITURE,
  DELETE_FURNITURE,
  CLEAR_FURNITURE_LOG,
  RESET_STATE,
  EDIT_FURNITURE,
  DELETE_FURNITURE_IMAGE,
  SET_FURNITURE_DETAILS,
  GET_EXPIRY_LINK_FURNITURE,
} from "../Actions/Types";

const INIT_STATE = {
  addFurnitureStatus: null,
  furnitureDetails: [],
  deleteFurnitureStatus: null,
  editFurnitureStatus: null,
  delFurnitureImageStatus: null,
};

const Furniture = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_FURNITURE:
      payload.furniture.furnitureid = payload.server.data.furnitureid;
      payload.furniture.furnitureImages = payload.server.data.furnitureImages;
      return {
        ...state,
        furnitureDetails: [...state.furnitureDetails, payload.furniture],
        addFurnitureStatus: payload.server.success,
      };

    case EDIT_FURNITURE:
      let fDetails;
      if (payload.server.success === 200) {
        fDetails = state.furnitureDetails.map((furniture) => {
          if (furniture.furnitureid === payload.furniture.furnitureid) {
            payload.furniture.furnitureImages = [
              ...furniture.furnitureImages,
              ...payload.server.data,
            ];
            return { ...furniture, ...payload.furniture };
          }
          return furniture;
        });
      }

      return {
        ...state,
        furnitureDetails: fDetails ? fDetails : state.furnitureDetails,
        editFurnitureStatus: payload.server.success,
      };

    case DELETE_FURNITURE:
      let furniture;
      if (payload.server.success === 200) {
        furniture = state.furnitureDetails.filter((item) => {
          return item.furnitureid !== payload.furnitureToDel.furnitureid;
        });
      }
      return {
        ...state,
        furnitureDetails: furniture ? furniture : state.furnitureDetails,
        deleteFurnitureStatus: payload.server.success,
      };

    case SET_FURNITURE_DETAILS:
      return {
        ...state,
        furnitureDetails: payload,
      };

    case DELETE_FURNITURE_IMAGE:
      let furImages = state.furnitureDetails.map((furniture) => {
        if (furniture.furnitureid === payload.furniture.furnitureid) {
          furniture.furnitureImages = furniture.furnitureImages.filter(
            (image) => {
              return image.furnitureimgid !== payload.furniture.furnitureimgid;
            }
          );
        }

        return furniture;
      });

      return {
        ...state,
        furnitureDetails: furImages,
        delFurnitureImageStatus: payload.server.success,
      };

    case GET_EXPIRY_LINK_FURNITURE:
      //   state.furnitureDetails.forEach((element) => {
      //     if (element.furnitureid === payload.object.furnitureid) {
      //       //   console.log("Funriture match found");
      //       element.furnitureImages.forEach((val) => {
      //         // console.log(" image match found");
      //         if (val.furnitureimgid === payload.object.furnitureimgid) {
      //           element.expiryLink = payload.object.expiryLink;
      //           console.log("Updated link ", payload.object.expiryLink);
      //         }
      //       });
      //     }
      //   });

      for (let i = 0; i < state.furnitureDetails.length; i++) {
        if (
          state.furnitureDetails[i].furnitureid === payload.object.furnitureid
        ) {
          for (
            let j = 0;
            j < state.furnitureDetails[i].furnitureImages.length;
            j++
          ) {
            let temp = state.furnitureDetails[i].furnitureImages[j];
            if (temp.furnitureimgid === payload.object.furnitureimgid) {
              temp.expiryLink = payload.object.expiryLink;
            }
          }
        }
      }

      return {
        ...state,
        furnitureDetails: state.furnitureDetails,
        getExpiryLink: payload.server.success,
      };
    case CLEAR_FURNITURE_LOG:
      return {
        ...state,
        addFurnitureStatus: null,
        deleteFurnitureStatus: null,
        editFurnitureStatus: null,
        delFurnitureImageStatus: null,
        getExpiryLink: null,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default Furniture;
