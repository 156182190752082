import {
  CLEAR_DEALER_STATUS,
  GET_DEALER_INFO,
  RESET_STATE,
} from "../Actions/Types";

const INIT_STATE = {
  dealerInfo: {},
  getDealerInfo: null,
};

const DealerReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DEALER_INFO:
      return {
        ...state,
        dealerInfo: payload.data,
        getDealerInfo: payload.success,
      };

    case CLEAR_DEALER_STATUS:
      return {
        ...state,
        getDealerInfo: payload,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default DealerReducer;
