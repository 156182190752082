import {
  GET_CONTRACTOR_DETAILS,
  GET_CONTRACTOR_LIST,
  CLEAR_CONTRACTOR_STATUS,
  RESET_STATE,
//   GET_CONTRACTOR_LIST_HELPLINE,
} from "../Actions/Types";

const INIT_STATE = {
  contractorList: [],
  getContractorList: null,

  contractorListForHelpline: null,
  contractorSuggestions: [],
  getContractorDetails: null,
  countOfData:0
};

const ContractorReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_CONTRACTOR_DETAILS:
      return {
        ...state,
        contractorSuggestions: payload.data ? payload.data : [],
        getContractorDetails: payload.success,
      };

    case GET_CONTRACTOR_LIST:
      return {
        ...state,
        contractorList: payload.data,
        getContractorList: payload.success,
        isLast: payload.isLast,
		countOfData: payload.count
      };
/* 
	  case GET_CONTRACTOR_LIST_HELPLINE: 
	  return {
		...state,
		contractorList: payload.data,
		getContractorList: payload.success,
		isLast: payload.isLast,
		countOfData: payload.count
	  } */

    case CLEAR_CONTRACTOR_STATUS:
      return {
        ...state,
        getContractorDetails: null,
        getContractorList: null,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default ContractorReducer;
