import React, { Component } from "react";
import { langData } from "./Language";

class ProgressBar extends Component {
  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    const { selectedStep } = this.props;
    return (
      <div className="progress-container">
        <div className="progress-container-steps-name">
          <p>{langData[lan].step1}</p>
          <p>{langData[lan].step2}</p>
          <p>{langData[lan].step3}</p>
        </div>
        <div className="progress-container-steps-lines">
          <div className="step">
            <div
              className={selectedStep === 1 ? "bullet active" : "bullet"}
            ></div>
            <div className={selectedStep === 1 ? "check" : "check"}></div>
          </div>
          <div className="step">
            <div
              className={selectedStep === 2 ? "bullet active" : "bullet"}
            ></div>
            <div className={selectedStep === 2 ? "check" : "check"}></div>
          </div>
          <div className="step">
            <div
              className={selectedStep === 3 ? "bullet active" : "bullet"}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
