import Server from "../API";

// Types
import {
  SET_ALERT,
  REMOVE_ALERT,
  START_LOADER,
  STOP_LOADER,
  SAVE_FILES,
  SAVE_PAGE_NUMBER,
  CLEAR_COMMON_STATUS,
  SAVE_CONTRACTOR_PAGE_NUMBER,
  GET_DOC_EXPIRY_LINK,
  UPDATE_EXPIRED_DOC_LINK,
  SET_SEARCH_SITE_INFO,
  CLEAR_ALL_SEARCH_INFO,
  SET_SEARCH_CONTRACTOR_INFO,
  SET_SITE_SORTING_COLUMN,
  SET_PROGRESS_LOADER,
  SAVE_FILE_ERROR_LOG,
} from "./Types";

import { HandleExceptionWithSecureCatch } from "./CombinedCatch";

/*
 * Action Creator: SetAlert()
 * Params: takes alert data
 * Returns: action
 * Description: returns action to show the alert
 */
const SetAlert = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ALERT,
      payload: {
        alertMsg: data.msg,
        alertType: data.alertType,
      },
    });
    //to remove the alert after 4 secs
    if (!data.toHold) {
      setTimeout(() => {
        dispatch({ type: REMOVE_ALERT, payload: null });
      }, 6000);
    }
  };
};

/*
 * Action Creator: DeleteAlert()
 * Params: takes alert data
 * Returns: action
 * Description: returns action to remove the alert
 */
const DeleteAlert = (data) => {
  return {
    type: REMOVE_ALERT,
    payload: null,
  };
};

/*
 * Action Creator: ShowAlert
 * Params: params (Request Body)
 * Description: dispatch the action to show the success alert
 */
const ShowAlert = (alertObj) => {
  return (dispatch) => {
    dispatch(SetAlert(alertObj));
  };
};

// ACTION FOR STARTING THE LOADER
const StartLoader = () => {
  return (dispatch) => {
    dispatch({
      type: START_LOADER,
      payload: null,
    });
  };
};

// ACTION FOR STOPPING THE LOADER
const StopLoader = () => {
  return (dispatch) => {
    dispatch({
      type: STOP_LOADER,
      payload: null,
    });
  };
};

const SaveImageFiles = (params) => {
  if (params) {
    localStorage.setItem("saveFiles", JSON.stringify(params));
  }
  return (dispatch) => {
    dispatch({
      type: SAVE_FILES,
      payload: {
        payload: params,
      },
    });
  };
};

const SavePageNumber = (pageNum) => {
  return {
    type: SAVE_PAGE_NUMBER,
    payload: pageNum,
  };
};

const SaveContractorPageNumber = (pageNum) => {
  return {
    type: SAVE_CONTRACTOR_PAGE_NUMBER,
    payload: pageNum,
  };
};

const SaveSortingInfo = (sort) => {
  return {
    type: SET_SITE_SORTING_COLUMN,
    payload: sort,
  };
};
//SET PROGRESS LOADER
const SetProgressLoader = (state, percentage) => {
  return {
    type: SET_PROGRESS_LOADER,
    payload: { state, percentage },
  };
};

/**
 * GetExpiryLink: Get expiry link for images
 * param: request body
 * return: dispatch action
 */
const GetDocumentExpiryLink = (params, id) => {
  return async (dispatch) => {
    try {
      const response = await Server.post("/paint/w4s/user/signed-get", {
        key: params,
      });

      dispatch({
        type: GET_DOC_EXPIRY_LINK,
        payload: { server: response.data, siteId: id },
      });

      //DISPATCH REDUCER TO SET THE LINK IN REGISTERED SITE LIST
      dispatch({
        type: UPDATE_EXPIRED_DOC_LINK,
        payload: { server: response.data, siteId: id },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Set search site info in redux
 * param: request body
 * return: dispatch action
 */
const PersistSearchSiteInfo = (params) => {
  return {
    type: SET_SEARCH_SITE_INFO,
    payload: params,
  };
};

/**
 * Set contractor search info in redux
 * param: request body
 * return: dispatch action
 */
const PersistSearchContractorInfo = (params) => {
  return {
    type: SET_SEARCH_CONTRACTOR_INFO,
    payload: params,
  };
};

const ClearAllSearchDetails = () => {
  return {
    type: CLEAR_ALL_SEARCH_INFO,
  };
};

const ClearCommonStatus = () => {
  return {
    type: CLEAR_COMMON_STATUS,
    payload: null,
  };
};

const SaveFileErrorsAction = (obj) => {
  return {
    type: SAVE_FILE_ERROR_LOG,
    payload: obj,
  };
};

//export the setAlert and the deleteAlert action
export {
  SetAlert,
  DeleteAlert,
  ShowAlert,
  StartLoader,
  StopLoader,
  SaveImageFiles,
  SavePageNumber,
  GetDocumentExpiryLink,
  ClearCommonStatus,
  SaveContractorPageNumber,
  PersistSearchSiteInfo,
  ClearAllSearchDetails,
  PersistSearchContractorInfo,
  SaveSortingInfo,
  SetProgressLoader,
  SaveFileErrorsAction,
};
