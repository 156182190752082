import { combineReducers } from "redux";

//IMPORT REDUCER!
import SiteRegistration from "./SiteRegistration";
import Furniture from "./Furniture";
import RegisteredSites from "./RegisteredSites";
import CommonReducer from "./Common";
import Auth from "./AuthReducer";
import DealerReducer from "./DealerReducer";
import AssetsReducer from "./AssetsReducer";
import ContractorReducer from "./ContractorReducer";
import DashboardReducer from "./DashboardReducer";
import UsersReducer from "./UsersReducer";

//Combine all reducerS!
export default combineReducers({
  auth: Auth,
  site: SiteRegistration,
  furniture: Furniture,
  regSites: RegisteredSites,
  common: CommonReducer,
  dealer: DealerReducer,
  assets: AssetsReducer,
  contractor: ContractorReducer,
  dashboard: DashboardReducer,
  user: UsersReducer
});
