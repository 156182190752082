import React, { Component } from "react";
import { connect } from "react-redux";
import ReactModal from "react-modal";
// import History from "../_Utils/History";
//RESOURCES
// import closeIcon from "../../Resources/close-icon.svg";

//Components
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import ConfirmationPopup from "../ConfirmationPopup";

//Actions
import {
  CloseSite,
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { ShowAlert } from "../../Actions/Common";
// import { deleteUploadedImages } from "../_Utils/DeleteUploadedImages";
import { DeleteImage } from "../../Actions/AssetsAction";
import ReviewSite from "../ReviewSite/ReviewSite";
import UploadWarrantyDocModal from "../UploadWarrantyDocModal/UploadWarrantyDocModal";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import {
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  SITE_STATUS,
} from "../../Actions/Types";
import WarrantyClaimModal from "../WarrantyClaimModal/WarrantyClaimModal";
import InitiateWarrantySuccess from "../InitiateWarrantySuccess/InitiateWarrantySuccess";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import ProgressBar from "../Common/ProgressBar";
import DropSiteModal from "../DropSiteModal/DropSiteModal";

/**
 * SiteRegistration: Class component for registered sited of helpline user
 */
class SiteRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStep: 1,
      isEditable: 0,
      siteCloseButtonClicked: false,
      isPrevClicked: 0,
      isNextClicked: 0,
      furImages: [],
      dealerInvoice: [],
      prodImages: [],
      closeButtonClicked: false,
      openReview: false,
      showUploadWarrantDoc: false,
      showWarrantyClaim: false,

      showInitiateWarrantySuccess: false,
      openDroppedSite: false,
      droppedSiteId: null,
      dropSitePhone: null,
    };
  }

  //Disable back button
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  componentWillUnmount() {
    //CLEAR FURNITURE DETAILS
    this.props.SetFurnitureDetails([]);
  }

  /**
   * handleSiteClosure: Call Action to close site
   */
  handleSiteClosure = async () => {
    const reqBody = {
      siteid: this.props.siteInfo.siteid,
      phonenum: this.props.siteInfo.phonenum,
    };
    await this.props.CloseSite(reqBody);
    if (this.props.closeSiteStatus === 200) {
      this.deleteAllImages();
      this.props.ClearRegisteredStatusLog();
      this.props.ShowAlert({
        msg: "This site is under review",
        alertType: "Success",
      });

      CheckNonContractorUser(this.props.userType) &&
        this.props.GetRegisteredSitesForHelpLine(
          {
            requesterPhone: this.props.userPhoneNum,
          },
          this.state.pageNum,
          this.state.pageSize,
          NON_CONTRACTOR_GET_REGISTERED_SITES
        );
    }
    this.handleCloseConfirmationForSite();
  };

  /*  New changes for data loss ----------------------- */
  handlePrevBtn = () => {
    this.setState({ isPrevClicked: this.state.selectedStep });
  };

  upgradeImagesFromStep3 = (dealerInvoice, prodImages) => {
    this.setState(
      {
        dealerInvoice: dealerInvoice,
        prodImages: prodImages,
      },
      () => {
        !this.state.siteCloseButtonClicked && this.handleOpenConfirmation();
      }
    );
  };

  upgradeImagesFromStep2 = (furImages) => {
    this.setState({ furImages: furImages }, () => {
      !this.state.siteCloseButtonClicked && this.handleOpenConfirmation();
    });
  };

  //Confirmation Yes button
  handlePrevYesButton = async () => {
    const {
      isPrevClicked,
      isNextClicked,
      dealerInvoice,
      prodImages,
      furImages,
      closeButtonClicked,
    } = this.state;
    if (isPrevClicked === 3) {
      dealerInvoice &&
        dealerInvoice.length &&
        this.props.DeleteImage({
          key: dealerInvoice.map((image) => image.imagelink),
        });
      prodImages &&
        prodImages.length &&
        this.props.DeleteImage({
          key: prodImages.map((image) => image.imagelink),
        });
    } else if (isPrevClicked === 2 || isNextClicked === 2) {
      this.props.DeleteImage({
        key: furImages.map((image) => image.imagelink),
      });
    }
    // else if (isNextClicked === 2) {
    //   for (let i = 0; i < furImages.length; i++) {
    //     try {
    //       await deleteUploadedImages(furImages[i].imagelink);
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // }
    if (isPrevClicked === 3 || isPrevClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: null,
        prodImages: [],
      });
    else if (isNextClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isPrevClicked: 0,
        isNextClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: null,
        prodImages: [],
      });

    if (closeButtonClicked) {
      this.setState({ closeButtonClicked: null });
      this.props.handleCloseSiteReg();
    }
  };

  //Confirmation NO button
  handlePrevNoButton = () => {
    this.setState({
      isPrevClicked: 0,
      isNextClicked: 0,
      prodImages: [],
      dealerInvoice: [],
      openConfirmation: false,
      furImages: [],
      closeButtonClicked: false,
    });
  };

  //Open Confirmation prompt
  handleOpenConfirmation = () => {
    const {
      dealerInvoice,
      prodImages,
      isPrevClicked,
      furImages,
      isNextClicked,
      closeButtonClicked,
    } = this.state;

    if (
      isPrevClicked === 3 &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (isPrevClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isNextClicked: 0,
        openConfirmation: false,
      });
    } else {
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
      });
    }

    if (
      closeButtonClicked &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked) {
      this.setState({
        openConfirmation: false,
        closeButtonClicked: null,
      });
      this.props.handleCloseSiteReg();
    }
  };

  handleNextBtn = () => {
    if (this.state.selectedStep === 1) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        showNextButton: false,
      });
    } else this.setState({ isNextClicked: this.state.selectedStep });
  };

  handleCloseConfirmationForSite = () => {
    this.setState({
      openConfirmation: false,
      siteCloseButtonClicked: false,
      isPrevClicked: 0,
      isNextClicked: 0,
      prodImages: [],
      dealerInvoice: [],
      furImages: [],
    });
  };

  /** Check images are present while closing the site*/
  checkImagesPresent = () => {
    const { dealerInvoice, furImages, prodImages } = this.state;
    if (
      (dealerInvoice && dealerInvoice.length) ||
      (furImages && furImages.length) ||
      (prodImages && prodImages.length)
    ) {
      return true;
    }
    return false;
  };
  deleteImageFromCloud = async (link) => {
    try {
      //   await deleteUploadedImages(link);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * deleteAllImages: delete all images before site close
   */
  deleteAllImages = () => {
    const { selectedStep, dealerInvoice, prodImages, furImages } = this.state;
    if (selectedStep === 3) {
      dealerInvoice &&
        dealerInvoice.length &&
        this.props.DeleteImage({
          key: dealerInvoice.map((image) => image.imagelink),
        });
      prodImages &&
        prodImages.length &&
        this.props.DeleteImage({
          key: prodImages.map((image) => image.imagelink),
        });
    } else if (selectedStep === 2) {
      this.props.DeleteImage({
        key: furImages.map((image) => image.imagelink),
      });
    }
  };

  handleSiteCloseButton = () => {
    this.setState({ siteCloseButtonClicked: true, openConfirmation: true });
  };

  //open review form
  handleReviewForm = () => {
    this.setState({
      openReview: !this.state.openReview,
    });
  };

  //SUCCESS POPUP FOR REVIEW FORM
  handleInitiateWarrantySuccess = () => {
    console.log("all answers are yes");
    this.setState({
      showInitiateWarrantySuccess: true,
    });
  };
  //close warranty success
  handleCloseInitiateWarrantySuccess = () => {
    this.setState({
      showInitiateWarrantySuccess: false,
    });
  };

  handleCloseSites = () => {
    this.setState(
      {
        closeButtonClicked: true,
        isPrevClicked: this.state.selectedStep,
      },
      () => {
        if (this.state.selectedStep === 1) {
          this.props.handleCloseSiteReg();
        }
      }
    );
  };

  //   OPEN UPLOAD WARRANTY DOCUMENT
  handleOpenWarrantyDocument = () => {
    this.setState({ showUploadWarrantDoc: !this.state.showUploadWarrantDoc });
  };

  //HANDLE WARRANTY CLAIM
  handleWarrantyClaim = () => {
    this.setState({ showWarrantyClaim: !this.state.showWarrantyClaim });
  };

  //SITE STATUS UPDATE HANDLER
  handleSiteStatusUpdate = () => {
    const { siteInfo, userType } = this.props;
    if (!siteInfo) return;
    if (CheckNonContractorUser(userType) && siteInfo.sitestatus === 0) {
      this.handleSiteCloseButton();
      return;
    }
    //UNDER REVIEW
    if (siteInfo.sitestatus === 1) {
      this.handleReviewForm();
    }

    //WARRANTY ISSUED
    else if (
      siteInfo.sitestatus === 2 &&
      (!siteInfo.warrantyInfo || !siteInfo.warrantyInfo.warrantydoc)
    ) {
      this.handleOpenWarrantyDocument();
    }

    //WARRANTY CLAIM
    else if (this.isWarrantyDocIssued()) this.handleWarrantyClaim();
  };

  //handle dropped site button
  handleDropSiteButton = () => {
    this.setState({
      openDroppedSite: true,
      droppedSiteId: this.props.siteInfo.siteid,
      dropSitePhone: CheckNonContractorUser(this.props.userType)
        ? this.props.siteInfo.phonenum
        : this.props.userPhoneNum,
    });
  };

  handleCloseDropSiteModal = () => {
    this.setState({
      openDroppedSite: false,
      droppedSiteId: null,
      dropSitePhone: null,
    });
  };

  //CHECK WARRANTY DOC IS ISSUED OR NOT
  isWarrantyDocIssued = () => {
    const { siteInfo } = this.props;
    if (
      siteInfo.sitestatus === 2 &&
      siteInfo.warrantyInfo &&
      siteInfo.warrantyInfo.warrantydoc
    ) {
      return true;
    }
    return false;
  };

  //CHECK WHETHER SITE IS UNDER REVIEW
  isSiteUnderReview = () => {
    const { siteInfo, userType } = this.props;
    if (userType !== HELPLINE_USER) return false;
    if (
      siteInfo.sitestatus === 1 &&
      !this.isWarrantyDocIssued() &&
      (!siteInfo.reviewInfo || (siteInfo && !siteInfo.reviewInfo.sitereviewed))
    ) {
      return true;
    }
    return false;
  };

  //CHECK WHETHER WARRANTY NO ISSUED
  isWarrantyNoIssued = () => {
    const { siteInfo } = this.props;
    if (
      siteInfo.sitestatus === 2 &&
      siteInfo.warrantyInfo &&
      siteInfo.warrantyInfo.warrantyid
    )
      return true;
    return false;
  };

  showUpdateSiteStatus = () => {
    const { siteInfo, userType } = this.props;
    const { sitestatus } = siteInfo;
    if (!siteInfo) return false;
    //when site status is warranty claim
    if (siteInfo.sitestatus === 3) {
      return false;
    }

    //for contractor show status only when site is open
    if (userType !== HELPLINE_USER) {
      if (sitestatus === 0 || sitestatus === 4) return true;
      else return false;
    } else if (userType === HELPLINE_USER) {
      if (sitestatus === 0) return false;
      if (
        sitestatus === 1 &&
        siteInfo.reviewInfo &&
        siteInfo.reviewInfo.sitereviewed &&
        !this.isWarrantyNoIssued()
      )
        return false;
    }
    return true;
  };

  render() {
    const { selectedStep, showInitiateWarrantySuccess, openDroppedSite } =
      this.state;
    const { siteInfo, userType } = this.props;
    return (
      <React.Fragment>
        <div className="site-reg-page site-reg-page-padding">
          <div className="site-reg-page-close">
            {/* <img src={closeIcon} alt="close" onClick={this.handleCloseSites} /> */}
            <span onClick={this.handleCloseSites} className="bk-blue">
              Back
            </span>
          </div>

          <div className="site-reg-page-header">
            {/* LEAD ID */}
            <div className="site-reg-page-header-title lead-id-wrapper font-blue">
              <span className="site-status-text">Lead ID : </span>
              {siteInfo.leadid}
            </div>
            {/* SITE STATUS */}
            <div className="site-reg-page-header-title font-blue lead-id-wrapper">
              <span className="site-status-text">Site Status : </span>
              {siteInfo
                ? userType === HELPLINE_USER &&
                  siteInfo.sitestatus === 1 &&
                  siteInfo.reviewInfo &&
                  siteInfo.reviewInfo.sitereviewed
                  ? "Initiated for warranty"
                  : SITE_STATUS[siteInfo.sitestatus]
                : null}
            </div>

            {/* WARRATY ID*/}
            {siteInfo &&
            siteInfo.warrantyInfo &&
            siteInfo.warrantyInfo.warrantyid ? (
              <div className="site-reg-page-header-title font-blue">
                <span className="site-status-text">Warranty ID : </span>
                {siteInfo.warrantyInfo.warrantyid}
              </div>
            ) : null}

            {/* UPDATE SITE STATUS BUTTON */}
            <div className="site-reg-page-header-buttons">
              {this.showUpdateSiteStatus() ? (
                <React.Fragment>
                  {siteInfo.sitestatus !== 4 && (
                    <div
                      className="site-reg-page-header-buttons-site bk-blue"
                      onClick={this.handleSiteStatusUpdate}
                    >
                      {this.isWarrantyDocIssued()
                        ? "Warranty Claimed"
                        : this.isSiteUnderReview()
                        ? "Review warranty generation"
                        : this.isWarrantyNoIssued()
                        ? "Warranty document upload"
                        : "Initiate warranty generation"}
                    </div>
                  )}
                  {CheckNonContractorUser(userType) &&
                    siteInfo.sitestatus === 0 && (
                      <div
                        className="site-reg-page-header-buttons-site bk-blue"
                        onClick={this.handleDropSiteButton}
                      >
                        Dropped site
                      </div>
                    )}
                </React.Fragment>
              ) : null}
            </div>
          </div>
          <div className="site-reg-page-progress">
            <div className="site-reg-page-progress-btn">
              {selectedStep === 2 || selectedStep === 3 ? (
                <div
                  className="site-reg-page-progress-prev bk-blue"
                  onClick={this.handlePrevBtn}
                  id="prev"
                >
                  Previous
                </div>
              ) : null}
            </div>
            <ProgressBar selectedStep={selectedStep} />
            <div className="site-reg-page-progress-btn">
              {selectedStep !== 3 ? (
                <div
                  className="site-reg-page-progress-next bk-blue"
                  onClick={this.handleNextBtn}
                  id="next"
                >
                  Next
                </div>
              ) : null}
            </div>
          </div>
          <div className="site-reg-page-step">
            {selectedStep === 1 ? <Step1 /> : null}
            {selectedStep === 2 ? (
              <Step2
                upgradeImagesToParent={this.upgradeImagesFromStep2}
                isPrevClicked={this.state.isPrevClicked}
                isNextClicked={this.state.isNextClicked}
                siteCloseButtonClicked={this.state.siteCloseButtonClicked}
              />
            ) : null}
            {selectedStep === 3 ? (
              <Step3
                upgradeImagesToParent={this.upgradeImagesFromStep3}
                isPrevClicked={this.state.isPrevClicked}
                siteCloseButtonClicked={this.state.siteCloseButtonClicked}
                helpline={true}
              />
            ) : null}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.openConfirmation}
          className="confirmation-popup-modal"
          overlayClassName="confirmation-popup-overlay"
          ariaHideApp={false}
        >
          <ConfirmationPopup
            handleSiteClosure={
              this.state.siteCloseButtonClicked
                ? this.handleSiteClosure
                : this.handlePrevYesButton
            }
            handleOpenConfirmation={
              this.state.siteCloseButtonClicked
                ? this.handleCloseConfirmationForSite
                : this.handlePrevNoButton
            }
            msg={
              this.state.siteCloseButtonClicked
                ? this.checkImagesPresent()
                  ? "Unsaved Data will be lost. Want to continue?"
                  : "Are you sure you want to initiate warranty generation?"
                : "Unsaved Data will be lost. Want to continue?"
            }
          />
        </ReactModal>
        {/* OPEN REVIEW FORM */}
        {this.state.openReview && (
          <div className="review-site-overlay">
            <ReviewSite
              closeModal={this.handleReviewForm}
              reviewFormData={this.props.siteInfo.reviewInfo}
              siteInfo={this.props.siteInfo}
              handleInitiateWarrantySuccess={this.handleInitiateWarrantySuccess}
            />
          </div>
        )}

        {/* UPLOAD WARRANTY DOCUMENT */}
        {this.state.showUploadWarrantDoc && (
          <div className="upload-warranty-doc-overlay">
            <UploadWarrantyDocModal
              closeModal={this.handleOpenWarrantyDocument}
              siteInfo={this.props.siteInfo}
            />
          </div>
        )}

        {/* WARRANTY CLAIM POPUP */}
        {this.state.showWarrantyClaim && (
          <div className="upload-warranty-doc-overlay">
            <WarrantyClaimModal
              closeModal={this.handleWarrantyClaim}
              siteInfo={this.props.siteInfo}
            />
          </div>
        )}

        {showInitiateWarrantySuccess && (
          <InitiateWarrantySuccess
            handleInitiateWarrantySuccess={
              this.handleCloseInitiateWarrantySuccess
            }
          />
        )}
        {openDroppedSite && (
          <DropSiteModal
            handleCloseModal={this.handleCloseDropSiteModal}
            droppedSiteId={this.state.droppedSiteId}
            dropSitePhone={this.state.dropSitePhone}
          />
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    siteInfo: state.regSites.siteDetails,
    closeSiteStatus: state.regSites.closeSiteStatus,
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
  };
};

export default connect(mapStateToProps, {
  CloseSite,
  ClearRegisteredStatusLog,
  ShowAlert,
  DeleteImage,
  SetFurnitureDetails,
  GetRegisteredSitesForHelpLine,
})(SiteRegistration);
