import {
  CLEAR_DASHBOARD_STATUS,
  DASHBOARD_MONTH_FILTER,
  DASHBOARD_YEAR_FILTER,
  GET_DASHBOARD_SUMMARY,
  GET_SUMMARY_LIST,
  RESET_STATE,
} from "../Actions/Types";

const INIT_STATE = {
  dashboardData: [],
  getDashboardDataStatus: null,
  getDashContractorSummaryStatus: null,
  getFilteredDataStatus: null,
  summaryList: [],
  getSummaryDataStatus: null,
  countOfData: 0,
};

const DashboardReducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_DASHBOARD_SUMMARY:
      return {
        ...state,
        dashboardData: payload.data,
        summaryList: payload.data.entries,
        getDashboardDataStatus: payload.success,
        entries: payload.data.entries,
        countOfData: payload.data.totalCount,
      };

    case DASHBOARD_MONTH_FILTER:
      return {
        ...state,
        getFilteredDataStatus: payload.success,
        dashboardData: {
          ...state.dashboardData,
          monthSiteCount: payload.data.monthSiteCount,
          monthContractorCount: payload.data.monthContractorCount,
        },
      };

    case DASHBOARD_YEAR_FILTER:
      return {
        ...state,
        getFilteredDataStatus: payload.success,
        dashboardData: {
          ...state.dashboardData,
          yearSiteCount: payload.data.yearSiteCount,
          yearContractorCount: payload.data.yearContractorCount,
        },
      };

    case GET_SUMMARY_LIST:
      return {
        ...state,
        summaryList: payload.data.entries,
        getSummaryDataStatus: payload.success,
      };

    case CLEAR_DASHBOARD_STATUS:
      return {
        ...state,
        getDashboardDataStatus: null,
        getDashContractorSummaryStatus: null,
        getFilteredDataStatus: null,
        getSummaryDataStatus: null,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default DashboardReducer;
