import React, { Component } from "react";
import LinkNotValidCom from "../_Utils/LinkNotValid";
import ReactModal from "react-modal";

/**
 * LinkNotValid: Class Component for popup
 */
class LinkNotValid extends Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    localStorage.removeItem("store-state");
  }
  render() {
    return (
      <ReactModal
        isOpen={true}
        overlayClassName="link-expired-overlay"
        className="link-expired-modal"
        ariaHideApp={false}
      >
        <LinkNotValidCom />
      </ReactModal>
    );
  }
}

export default LinkNotValid;
