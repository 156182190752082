import Server from "../API";
import {
  GET_REGISTERED_SITE,
  CLOSE_SITE,
  CLEAR_REGISTERED_SITE_LOG,
  GET_SITE_DETAILS,
  EDIT_REG_SITE,
  SET_FURNITURE_DETAILS,
  EDIT_PRODUCT_IMAGES,
  DELETE_DEALER_INVOICE,
  DELETE_PRODUCT_IMAGES,
  CLEAR_SEARCHED_RESULT,
  DOWNLOAD_IMAGES,
  GET_REGISTERED_SITE_FOR_HELPLINE,
  GET_LASTEST_REG_SITE,
  SET_SITE_DETAILS,
  UPDATE_VISITED_SITES,
  GET_IMAGE_EXPIRY_LINK,
  GET_SITE_REVIEWS,
  UPDATE_SITE_REVIEWS,
  UPLOAD_WARRANTY_DOC,
  ADD_WARRANTY_CLAIM,
  PAGE_SIZE,
  HELPLINE_GET_REGISTERED_SITES,
  HELPLINE_GET_SITE_IMAGES,
  GET_ALL_REGISTERED_SITES,
  GET_REGISTERED_SITE_REPORT,
  DOWNLOAD_FOLDER,
  GET_MONTH_YEAR_WISE_SITES,
  DROPPED_SITE,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * GetRegisteredSites: Get registered sites
 * param: request body
 * return: action to store sites
 */
const GetRegisteredSites = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/user/get-registered?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_REGISTERED_SITE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetRegisteredSitesForHelpLine: API to get registered sites for helpline user
 * param: request body
 * return: dispatch action to store reg sites
 */
const GetRegisteredSitesForHelpLine = (
  params,
  pageNum = 0,
  pageSize = PAGE_SIZE,
  API_TYPE = HELPLINE_GET_REGISTERED_SITES
) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `${API_TYPE}?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_REGISTERED_SITE_FOR_HELPLINE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetMonthYearWiseSites: Get registered sites for month or year
 * param: request body
 * return: action to store sites
 */
const GetMonthYearWiseSites = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/non-contractor/dashboard/detailed/get-sites?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_MONTH_YEAR_WISE_SITES,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * CloseSite: Close site api
 * param: request body
 * return: action to close site
 */
const CloseSite = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/close-site", params);
      dispatch(StopLoader());
      dispatch({
        type: CLOSE_SITE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetDetailsOfSite: Get site details API
 * param: request body
 * return: dispatch action to store site details
 */
const GetDetailsOfSite = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/get-site-info",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_SITE_DETAILS,
        payload: response.data,
      });
      dispatch({
        type: SET_FURNITURE_DETAILS,
        payload: response.data.data.furnitureList,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * SetSiteDetails: Set site details
 * param: request body
 * return: dispatch action to store  site details
 */
const SetSiteDetails = (params) => {
  return {
    type: SET_SITE_DETAILS,
    payload: params,
  };
};

/**
 * UpdateVisitedSites: Update visited sites
 * param: request body
 * return: dispatch action to store sites
 */
const UpdateVisitedSites = (siteId, furnitureDetails) => {
  return {
    type: UPDATE_VISITED_SITES,
    payload: {
      siteId: siteId,
      furnitureDetails: furnitureDetails,
    },
  };
};

/**
 * EditRegSite: Edit Site API
 * param: request body
 * return: dispatch action
 */
const EditRegSite = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/edit-site", params);
      dispatch(StopLoader());
      dispatch({
        type: EDIT_REG_SITE,
        payload: { server: response.data, siteData: params },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * EditProductImages: Edit product images api
 * param: request body
 * return: dispatch action
 */
const EditProductImages = (params, obj) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/add-product-images",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: EDIT_PRODUCT_IMAGES,
        payload: {
          prodObj: obj,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DeleteDealerInvoice: Delete Dealer invoice API
 * param: request body
 * return: dispatch action
 */
const DeleteDealerInvoice = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/delete-dealer-invoice",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DELETE_DEALER_INVOICE,
        payload: {
          deletedImage: params,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DeleteProductImages: Delete product images
 * param: request body
 * return: dispatch action
 */
const DeleteProductImages = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/delete-product-image",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DELETE_PRODUCT_IMAGES,
        payload: {
          deletedImage: params,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * ClearRegisteredStatusLog: Clear registered site status log
 * param: request body
 * return: dispatch action
 */
const ClearRegisteredStatusLog = () => {
  return {
    type: CLEAR_REGISTERED_SITE_LOG,
    payload: null,
  };
};

/**
 * DownloadImages: Download site images API
 * param: request body
 * return: dispatch action
 */
const DownloadImages = (params, API_TYPE = HELPLINE_GET_SITE_IMAGES) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(API_TYPE, params);
      dispatch(StopLoader());
      dispatch({
        type: DOWNLOAD_IMAGES,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DownloadFolder: Download site images API
 * param: request body
 * return: dispatch action
 */
const DownloadFolder = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/get-detailed-site-images",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DOWNLOAD_FOLDER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetLatestRegistered: Get latest reg sites API for helpline
 * param: request body
 * return: dispatch action
 */
const GetLatestRegistered = (pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.get(
        // "/paint/w4s/helpline/get-latest-registered?page=0&size=20",
        `/paint/w4s/helpline/get-latest-registered?page=${pageNum}&size=${pageSize}`
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_LASTEST_REG_SITE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetLatestRegistered: Get latest reg sites API for non-contractor user
 * param: request body
 * return: dispatch action
 */
const GetLatestRegisteredForNonContractor = (
  params,
  pageNum = 0,
  pageSize = PAGE_SIZE
) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        // "/paint/w4s/helpline/get-latest-registered?page=0&size=20",
        `/paint/w4s/non-contractor/get-latest-registered?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_LASTEST_REG_SITE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetExpiryLink: Get expiry link for images
 * param: request body
 * return: dispatch action
 */
const GetExpiryLink = (params, id) => {
  return async (dispatch) => {
    try {
      const response = await Server.post("/paint/w4s/user/signed-get", {
        key: params.imagelink,
      });

      dispatch({
        type: GET_IMAGE_EXPIRY_LINK,
        payload: {
          object: { ...params, expiryLink: response.data.data },
          id,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * ClearSearchResult: Clear Searched result for helpline
 * param: request body
 * return: dispatch action
 */
const ClearSearchResult = () => {
  return {
    type: CLEAR_SEARCHED_RESULT,
  };
};

/**
 * GetSiteReviews: Get Site reviews
 * param: request body
 * return: dispatch action
 */
const GetSiteReviews = () => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/signed-get");
      dispatch(StopLoader());
      dispatch({
        type: GET_SITE_REVIEWS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * UpdateSiteReviews: Get Site reviews
 * param: request body
 * return: dispatch action
 */
const UpdateSiteReviews = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/add-site-review",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: UPDATE_SITE_REVIEWS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * UploadWarrantyDoc: Get Site reviews
 * param: request body
 * return: dispatch action
 */
const UploadWarrantyDoc = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/add-warranty-doc",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: UPLOAD_WARRANTY_DOC,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * UpdateWarrantyClaim: Update warranty claim
 * param: request body
 * return: dispatch action
 */
const AddWarrantyClaim = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/add-warranty-claim",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: ADD_WARRANTY_CLAIM,
        payload: { server: response.data, warrantyClaimDetails: params },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetAllRegisteredSites: Update warranty claim
 * param: request body
 * return: dispatch action
 */
const GetAllRegisteredSites = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/get-all-registered",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_ALL_REGISTERED_SITES,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetDetailedSiteReport: Download site reports
 * param: request body
 * return: dispatch action
 */
const GetDetailedSiteReport = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/helpline/get-all-registered-details",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_REGISTERED_SITE_REPORT,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DroppedSite: Site drop API
 * param: request body
 * return: dispatch action
 */
const DroppedSite = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/drop-site",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DROPPED_SITE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

// Named Exports
export {
  GetRegisteredSites,
  CloseSite,
  EditRegSite,
  GetDetailsOfSite,
  DeleteDealerInvoice,
  ClearRegisteredStatusLog,
  DeleteProductImages,
  EditProductImages,
  ClearSearchResult,
  DownloadImages,
  GetRegisteredSitesForHelpLine,
  GetLatestRegistered,
  SetSiteDetails,
  UpdateVisitedSites,
  GetExpiryLink,
  GetSiteReviews,
  UpdateSiteReviews,
  UploadWarrantyDoc,
  AddWarrantyClaim,
  GetLatestRegisteredForNonContractor,
  GetAllRegisteredSites,
  GetDetailedSiteReport,
  DownloadFolder,
  GetMonthYearWiseSites,
  DroppedSite,
};
