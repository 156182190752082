import React, { Component } from "react";
import { langData } from "../Common/Language";

/*
 * Class: ConfirmationPopup
 * Description: Shows confirmation popup
 */
class ConfirmationPopup extends Component {
  render() {
    let lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    return (
      <div className="confirmation-popup">
        <div className="confirmation-popup-msg">{this.props.msg}</div>
        <div className="confirmation-popup-btns">
          <div
            className="confirmation-popup-btns-yes bk-blue"
            onClick={this.props.handleSiteClosure}
          >
            {langData[lan].yes}
          </div>
          <div
            className="confirmation-popup-btns-no"
            onClick={this.props.handleOpenConfirmation}
          >
            {langData[lan].no}
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationPopup;
