import React, { Component } from "react";
// import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import SiteList from "../RegisteredSites/SiteList";

import {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  GetRegisteredSitesForHelpLine,
  GetMonthYearWiseSites,
} from "../../Actions/RegisteredSites";
import { connect } from "react-redux";
import {
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";

import {
  PersistSearchSiteInfo,
  SavePageNumber,
  SaveSortingInfo,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import RegisteredSiteSteps from "../RegisteredSiteSteps";
// import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

class DashboardSites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showNotFound: false,
      pageNum: props.pageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
      openUpdateSite: false,
    };
  }

  componentDidMount() {
    // this.getRegisteredSitesList();
  }
  //GET SITES ON PREV AND NEXT CLICK
  getRegisteredSitesList = async (lastPageNUm) => {
    const {
      userType,
      userPhoneNum,
      siteSortDetails,
      searchSiteDetails,
      sitesType,
    } = this.props;
    const { pageSize } = this.state;
    let reqBody = {
      searchKey: searchSiteDetails
        ? searchSiteDetails.searchBy.value || undefined
        : undefined,
      searchValue: searchSiteDetails
        ? searchSiteDetails.searchQuery.trim() || undefined
        : undefined,
      requesterPhone: CheckNonContractorUser(userType)
        ? userPhoneNum
        : undefined,
      sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
      sortOrder: siteSortDetails
        ? siteSortDetails.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
    };

    //MONTH AND YEAR WISE SITES
    if (sitesType === "monthWiseSites") {
      await this.props.GetMonthYearWiseSites(reqBody);
    }

    if (CheckNonContractorUser(userType)) {
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }

    //IF USER TYPE IS HELPLINE
    /* if (userType === HELPLINE_USER) {
      //USER IS ON SEARCH CONTRACTOR PAGE THEN GET REGISTERED SITES WITH MOBILE NUMBER
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    } */
  };

  handleOpenUpdateSite = () => {
    this.setState({ openUpdateSite: true });
  };
  handleCloseSiteReg = () => {
    this.setState({ openUpdateSite: false });
  };

  //GET SITE DETAILS
  handleSiteClick = async (item) => {
    const reqBody = { leadid: item.leadid };
    await this.props.GetDetailsOfSite(reqBody);
    if (this.props.getSiteInfo === 200) {
      this.handleOpenUpdateSite();
      this.props.ClearRegisteredStatusLog();
    }
  };

 

  render() {
    const { msg, countOfData } = this.props;
    const { openUpdateSite } = this.state;
    return (
      <div className="dashboard-site-container">
        {!openUpdateSite && (
          <div className="data-count-header">
            <div className="back bk-blue" onClick={this.props.handleBackButton}>
              Back
            </div>
            <div className="msg">
              {msg}:<span>{countOfData}</span>
            </div>
          </div>
        )}

        {/* {!openUpdateSite && <ExportSheet />} */}

        {openUpdateSite ? (
          <RegisteredSiteSteps handleCloseSiteReg={this.handleCloseSiteReg} />
        ) : (
          <SiteList
            handleContractorClick={this.handleSiteClick}
            sitesType={this.props.sitesType}
            month={this.props.month}
            year={this.props.year}
            searchable={false}
			sortable={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    siteInfo: state.regSites.siteDetails,
    closedSiteCount: state.regSites.closedSiteCount,
    openedSiteCount: state.regSites.openedSiteCount,
    allRegisteredSites: state.regSites.allRegisteredSites,
    getAllRegisteredSites: state.regSites.getAllRegisteredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
    pageNum: state.common.pageNum,
    searchSiteDetails: state.common.searchSiteDetails,
    siteSortDetails: state.common.siteSortDetails,
    countOfData: state.regSites.countOfData,
  };
};

export default connect(mapStateToProps, {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  StartLoader,
  StopLoader,
  PersistSearchSiteInfo,
  GetRegisteredSitesForHelpLine,
  SaveSortingInfo,
  SavePageNumber,
  GetMonthYearWiseSites,
})(DashboardSites);
