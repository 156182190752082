import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import historyObj from "../../_Utils/History";
import Compressor from "compressorjs";

import {
  AddProductImages,
  clearSiteLogs,
  ClearAddedSite,
} from "../../../Actions/SiteRegistration";
import { DeleteImage, GetLinkForUpload } from "../../../Actions/AssetsAction";

//Digital Ocean configuration
import {
  ShowAlert,
  StopLoader,
  StartLoader,
  SaveImageFiles,
  SetProgressLoader,
} from "../../../Actions/Common";
import delImage from "../../../Resources/close-img.svg";

//Language data
import { langData } from "../../Common/Language";
import ReactModal from "react-modal";
import ImagePreview from "../../Common/ImagePreview";
import { SUCCESSFULSUBMIT } from "../../_Utils/Routes";
import { HELPLINE_USER } from "../../../Actions/Types";
import axios from "axios";
import { GenerateKeyForUpload } from "../../_Utils/GenerateKeyForUpload";

class Step3 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;
  constructor(props) {
    super(props);
    this.state = {
      dealerInvoice: [],
      productImages: [],
      invoiceError: false,
      prodImgError: false,
    };
  }

  componentDidMount() {
    //Disable inputs type file for laptops
    let fileArr = document.querySelectorAll("input[type=file]");
    if (
      window.innerWidth > 768 &&
      !window.location.pathname.startsWith("/w4s/helpline")
    ) {
      fileArr.forEach((element) => {
        element.disabled = true;
      });
    } else {
      fileArr.forEach((element) => {
        element.disabled = false;
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isPrevClicked === 3) {
      nextProps.upgradeImagesToParent(
        prevState.dealerInvoice,
        prevState.productImages
      );
    }
    return null;
  }

  validateInvoice = () => {
    if (!this.state.dealerInvoice || !this.state.dealerInvoice.length) {
      this.setState({ invoiceError: true });
      return false;
    }
    this.setState({ invoiceError: false });
    return true;
  };
  validateProdImg = () => {
    if (!this.state.productImages || !this.state.productImages.length) {
      this.setState({ prodImgError: true });
      return false;
    }
    this.setState({ prodImgError: false });
    return true;
  };

  validateAll = () => {
    let a = this.validateInvoice();
    let b = this.validateProdImg();
    if (a && b) return true;
    else return false;
  };

  onSubmitClick = async () => {
    if (!this.validateAll()) return;
    let reqBody = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline

      siteid: this.props.siteId,
      phonenum: this.props.phoneNum,
      dealerinvoices: this.state.dealerInvoice.map((image) => image.imagelink),
      productImages: this.state.productImages.map((image) => image.imagelink),
    };
    await this.props.AddProductImages(reqBody);
    if (this.props.addProductImgStatus === 200) {
      localStorage.removeItem("saveFiles");
      this.props.clearSiteLogs();
      this.setState({ dealerInvoice: [], productImages: [] });
      if (this.props.helpline) {
        // historyObj.push("/helpline/home");
        this.props.handleNewSiteReg();
        this.props.ClearAddedSite();
      } else {
        historyObj.push(SUCCESSFULSUBMIT);
      }
    }
  };

  handleAddProductImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          /*  fetch(url, { method: "PUT", body: compressedResult })
            .then((data) => {
              this.props.StopLoader();
              this.setState(
                {
                  productImages: [
                    {
                      imagelink: key,
                      file: compressedResult,
                    },
                    ...this.state.productImages,
                  ],
                },
                this.validateProdImg
              );
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.StopLoader();
            })
            .catch((err) => {
              console.log(err);
              this.props.StopLoader();
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            }); */
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.setState(
                {
                  productImages: [
                    {
                      imagelink: key,
                      file: compressedResult,
                    },
                    ...this.state.productImages,
                  ],
                },
                this.validateProdImg
              );
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.SetProgressLoader(false, null);
              console.log(err);
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            });
        },
        error: () => {
          this.props.StopLoader();
        },
      });
    }
  };

  updateStateDealerInvoice = (key, compressedResult) => {
    this.setState(
      {
        dealerInvoice: [
          {
            imagelink: key,
            file: compressedResult,
          },
          ...this.state.dealerInvoice,
        ],
      },
      this.validateInvoice
    );
    this.props.StopLoader();
  };

  handleDealerInvoice = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          /*   fetch(url, { method: "PUT", body: compressedResult })
            .then((data) => {
              this.updateStateDealerInvoice(key, compressedResult);
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.StopLoader();
            })
            .catch((err) => {
              console.log(err);
              this.props.StopLoader();
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            }); */
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.updateStateDealerInvoice(key, compressedResult);
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.SetProgressLoader(false, null);
              console.log(err);
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            });
        },
        error: () => {
          this.props.StopLoader();
        },
      });
    }
  };

  //Delete dealer invoice
  deleteDealerInvoice = async (file) => {
    const reqBody = {
      key: [file.imagelink],
    };
    await this.props.DeleteImage(reqBody);
    this.setState({
      dealerInvoice: this.state.dealerInvoice.filter((image) => image !== file),
    });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images) {
      _.remove(images, (e) => {
        return e === file.imagelink;
      });
    }
    if (images.length < 1) {
      localStorage.removeItem("saveFiles");
    } else {
      this.props.SaveImageFiles(images);
    }
  };

  deleteProductImage = async (file) => {
    const reqBody = {
      key: [file.imagelink],
    };
    await this.props.DeleteImage(reqBody);
    this.setState({
      productImages: this.state.productImages.filter((image) => image !== file),
    });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images) {
      _.remove(images, (e) => {
        return e === file.imagelink;
      });
    }
    if (images.length < 1) {
      localStorage.removeItem("saveFiles");
    } else {
      this.props.SaveImageFiles(images);
    }
    //   this.props.ShowAlert({
    //     msg: langData[this.lan].failedToDel,
    //     alertType: "Error",
    //   });
  };

  handleCloseImagePreview = () => {
    this.setState({ imagePreview: false, images: [] });
  };
  handleOpenImagePreview = (id, index) => {
    this.setState({
      imagePreview: true,
      images: id === 1 ? this.state.dealerInvoice : this.state.productImages,
      imageIndex: index,
    });
  };

  //HANDLE RESET BUTTON
  handleResetButton = () => {
    const { dealerInvoice, productImages } = this.state;
    if (dealerInvoice && dealerInvoice.length) {
      this.props.DeleteImage({
        key: dealerInvoice.map((val) => val.imagelink),
      });
    }
    if (productImages && productImages.length) {
      this.props.DeleteImage({
        key: productImages.map((val) => val.imagelink),
      });
    }

    this.setState({
      dealerInvoice: [],
      productImages: [],
    });
  };

  render() {
    const { productImages, dealerInvoice, invoiceError, prodImgError } =
      this.state;
    return (
      <React.Fragment>
        <div className="page slide-page">
          <div className="field">
            <div className="label">{langData[this.lan].upInvoice}</div>
            <div className="img-container">
              <div className="container">
                <div className="product-img">
                  <input
                    type="file"
                    accept="image/*"
                    className="file-input"
                    id="upload-dealer-invoice"
                    onChange={this.handleDealerInvoice}
                  />
                  <label
                    htmlFor="upload-dealer-invoice"
                    className="upload-icon"
                  >
                    <img
                      src="/Assets/upload.svg"
                      alt="uploadImg"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                </div>
              </div>
              {dealerInvoice.map((obj, index) => (
                <div className="container" key={index}>
                  <div className="container-image-div">
                    <img
                      className="product-img"
                      src={
                        obj.file ? URL.createObjectURL(obj.file) : obj.imagelink
                      }
                      alt="Dealer invoice"
                      onClick={() => this.handleOpenImagePreview(1, index)}
                    />
                    <img
                      src={delImage}
                      alt="delete"
                      className="delete-icon"
                      onClick={() => this.deleteDealerInvoice(obj)}
                    />
                  </div>
                </div>
              ))}
            </div>
            {invoiceError ? (
              <div className="input-error">
                {langData[this.lan].upInvoiceEr}{" "}
              </div>
            ) : null}
          </div>
          <div className="field">
            <div className="label step-3-label">
              {langData[this.lan].upProdImg}
            </div>
            <div className="img-container">
              <div className="container">
                <div className="product-img">
                  <input
                    type="file"
                    accept="image/*"
                    className="file-input"
                    id="upload-product"
                    onChange={this.handleAddProductImage}
                  />
                  <label htmlFor="upload-product" className="upload-icon">
                    <img
                      src="/Assets/upload.svg"
                      alt="uploadImg"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                </div>
              </div>
              {productImages.map((obj, index) => (
                <div className="container" key={index}>
                  <div className="container-image-div">
                    <img
                      className="product-img"
                      src={
                        obj.file ? URL.createObjectURL(obj.file) : obj.imagelink
                      }
                      alt="Product"
                      onClick={() => this.handleOpenImagePreview(2, index)}
                    />
                    <img
                      src={delImage}
                      alt="delete"
                      className="delete-icon"
                      onClick={() => this.deleteProductImage(obj)}
                    />
                  </div>
                </div>
              ))}
            </div>
            {prodImgError ? (
              <div className="input-error">
                {langData[this.lan].upProdImgEr}{" "}
              </div>
            ) : null}
          </div>

          {/* SUBMIT AND RESET*/}
          <div className="btn-wrapper">
            <div className="field-btn">
              <button
                className="firstNext next"
                onClick={() => this.onSubmitClick()}
              >
                {langData[this.lan].submit}
              </button>
            </div>

            <div className="field-btn">
              <button className="reset" onClick={this.handleResetButton}>
                {langData[this.lan].reset || langData[0].reset}
              </button>
            </div>
          </div>
        </div>
        <ReactModal
          onRequestClose={this.handleCloseImagePreview}
          isOpen={this.state.imagePreview}
          className="image-preview"
          overlayClassName="image-preview-overlay"
        >
          <ImagePreview
            handleCloseImagePreview={this.handleCloseImagePreview}
            images={this.state.images}
            imageIndex={this.state.imageIndex}
          />
        </ReactModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // phoneNum: state.site.phoneNum,
    siteId: state.site.siteId,
    addProductImgStatus: state.site.addProductImgStatus,

    deleteDealerInvoiceStatus: state.regSites.deleteDealerInvoiceStatus,
    deleteProductImages: state.regSites.deleteProductImages,

    phoneNum: state.site.siteData.phonenum,
    userId: state.auth.userId,
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps, {
  AddProductImages,
  clearSiteLogs,
  ShowAlert,
  ClearAddedSite,
  StartLoader,
  StopLoader,
  DeleteImage,
  SaveImageFiles,
  SetProgressLoader,
})(Step3);
