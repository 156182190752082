import React, { Component } from "react";
import { connect } from "react-redux";
//Component
import SearchContractor from "./SearchContractor";
import RegisteredSiteSteps from "../RegisteredSiteSteps";
//Actions
import {
  ClearSearchResult,
  UpdateVisitedSites,
} from "../../Actions/RegisteredSites";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import {
  GetContractorList,
  GetContractorListForHelpline,
} from "../../Actions/ContractorAction";
import { HELPLINE_USER } from "../../Actions/Types";

/*
 * Class: Contractor
 * Description: Search Contractor Page for helpline user
 */
class Contractor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSiteReg: false,
      showSiteList: false,
    };
  }
  componentDidMount() {
    this.getContractorList();
  }

  getContractorList = async () => {
    const { searchContractorDetails, userPhoneNum } = this.props;
    let reqBody = {
      searchKey: searchContractorDetails
        ? searchContractorDetails.searchBy.value
        : undefined,
      searchValue: searchContractorDetails
        ? searchContractorDetails.searchQuery.trim() || undefined
        : undefined,
    };

    if (CheckNonContractorUser(this.props.userType)) {
      reqBody["requesterPhone"] = userPhoneNum;
      await this.props.GetContractorList(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    } else if (this.props.userType === HELPLINE_USER) {
      await this.props.GetContractorListForHelpline(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    }
  };

  /**
   * handleNewReg: open site reg by setting openSiteReg to true
   */
  handleNewReg = () => {
    this.setState({ openSiteReg: true });
  };

  /**
   * handleCloseSiteReg:  close site reg by setting openSiteReg to false
   */
  handleCloseSiteReg = () => {
    this.setState({ openSiteReg: false, showSiteList: true });
  };

  /**
   * render: Function
   * return: JSX to render search contractor page for helpline user
   */
  render() {
    return (
      <div className="contractor-page">
        {this.state.openSiteReg ? (
          <RegisteredSiteSteps handleCloseSiteReg={this.handleCloseSiteReg} />
        ) : (
          <SearchContractor
            handleNewReg={this.handleNewReg}
            handleSidebarClick={this.props.handleSidebarClick}
            handleNewSiteReg={this.props.handleNewSiteReg}
            showSiteList={this.state.showSiteList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteDetails: state.regSites.siteDetails,
    furnitureDetails: state.furniture.furnitureDetails,
    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    searchContractorDetails: state.common.searchContractorDetails,
  };
};

export default connect(mapStateToProps, {
  ClearSearchResult,
  UpdateVisitedSites,
  GetContractorList,
  GetContractorListForHelpline,
})(Contractor);
