import React, { Component } from "react";
import { connect } from "react-redux";
import ContractorList from "../Contractor/ContractorList";

//ACTIONS
import {
  GetRegisteredSites,
  ClearSearchResult,
  DownloadImages,
  ClearRegisteredStatusLog,
  GetDetailsOfSite,
  SetSiteDetails,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import {
  PersistSearchContractorInfo,
  PersistSearchSiteInfo,
  SaveContractorPageNumber,
  SavePageNumber,
  ShowAlert,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import { SaveUserDetails } from "../../Actions/SiteRegistration";
import {
  ClearContractorStatus,
  GetContractorList,
} from "../../Actions/ContractorAction";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import ContractorSiteList from "../Contractor/ContractorSiteList";
import {
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import RegisteredSiteSteps from "../RegisteredSiteSteps";

class DashboardContractorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      dealerInvoiceArray: [],
      furArray: [],
      prodArray: [],
      siteId: null,
      showSiteList: props.showSiteList,

      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      //   showSearchSuggestions: false,
      openUpdateSite: false,
    };
  }

  getContractorList = async () => {
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      searchKey: this.state.selectedSearchOption.value || undefined,
      searchValue: this.state.searchQuery.trim() || undefined,
    };
    if (CheckNonContractorUser(this.props.userType)) {
      await this.props.GetContractorList(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    }
  };

  /**
   * handleSiteClick: On site click get the site info
   */
  handleSiteClick = async (item) => {
    const reqBody = { leadid: item.leadid };
    await this.props.GetDetailsOfSite(reqBody);
    if (this.props.getSiteInfo === 200) {
      this.handleOpenUpdateSite();
      this.props.ClearRegisteredStatusLog();
    }
    // }
  };

  //HANDLE CONTRACTOR CLICK
  handleSelectContractor = async (contractor) => {
    if (contractor && contractor.phonenum) {
      const reqBody = {
        phonenum: contractor.phonenum,
        requesterPhone: this.props.userPhoneNum,
      };
      this.props.SavePageNumber(0);
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        0,
        PAGE_SIZE,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );

      if (this.props.getRegisteredSites === 200) {
        // this.props.ClearRegisteredStatusLog();
        await this.props.PersistSearchSiteInfo(null);
        this.setState({
          showSiteList: true,
        });
      }
    }
  };

  handleBackButton = () => {
    this.setState({ showSiteList: false });
  };

  handleOpenUpdateSite = () => {
    this.setState({ openUpdateSite: true });
  };
  handleCloseSiteReg = () => {
    this.setState({ openUpdateSite: false });
  };

  render() {
    const { registeredSites, userType } = this.props;

    const { showSiteList, searchQuery, openUpdateSite } = this.state;
    return (
      <div className="dashboard-site-container">
        {!openUpdateSite && (
          <div className="data-count-header">
            <div
              className="back bk-blue"
              onClick={
                showSiteList
                  ? this.handleBackButton
                  : this.props.handleBackButton
              }
            >
              Back
            </div>
            {!showSiteList && (
              <div className="msg">
				Contractor list: 
                <span>{this.props.countOfData}</span>
              </div>
            )}
          </div>
        )}
        {openUpdateSite && (
          <RegisteredSiteSteps handleCloseSiteReg={this.handleCloseSiteReg} />
        )}
        {showSiteList && registeredSites ? (
          <ContractorSiteList
            searchQuery={searchQuery}
            handleSiteClick={this.handleSiteClick}
			searchable={false}
			/>
        ) : null}

        {!showSiteList && CheckNonContractorUser(userType) && (
          <ContractorList
            handleSelectContractor={this.handleSelectContractor}
			month={this.props.month}
            year={this.props.year}
			dataFor={this.props.dataFor}
			searchable={false}
			sorting={false}
			exportable={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    registeredSites: state.regSites.registeredSites,
    downloadStatus: state.regSites.downloadStatus,
    images: state.regSites.images,
    getRegisteredSites: state.regSites.getRegisteredSites,

    visitedSites: state.regSites.visitedSites,
    visitedSiteId: state.regSites.visitedSiteId,

    contractorList: state.contractor.contractorList,
	countOfData: state.contractor.countOfData,
    getContractorList: state.contractor.getContractorList,
    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    searchContractorDetails: state.common.searchContractorDetails,
  };
};

export default connect(mapStateToProps, {
  GetRegisteredSites,
  GetRegisteredSitesForHelpLine,
  GetDetailsOfSite,
  ClearSearchResult,
  DownloadImages,
  ClearRegisteredStatusLog,
  ShowAlert,
  SaveUserDetails,
  SavePageNumber,
  SetSiteDetails,
  SetFurnitureDetails,
  StopLoader,
  StartLoader,
  ClearContractorStatus,
  GetContractorList,
  PersistSearchContractorInfo,
  SaveContractorPageNumber,
  PersistSearchSiteInfo,
})(DashboardContractorList);
