import { Component } from "react";

export class ExportSheet extends Component {
  render() {
    const { onClickHandler = () => null } = this.props;
    return (
      <div className="export-button-wrapper">
        <div className="export-button bk-blue" onClick={onClickHandler}>
          Export
        </div>
      </div>
    );
  }
}
