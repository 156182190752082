import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import _ from "lodash";
import Creatable from "react-select/creatable";
import Compressor from "compressorjs";
import ImagePreview from "../../Common/ImagePreview";

//Data
import {
  Products,
  Room,
  Exterior,
  Kitchen,
  LivingRoom,
  BedRoom1,
  BedRoom2,
  BedRoom3,
  BedRoom4,
  OtherAreas,
} from "./furnitureData";
import { langData } from "../../Common/Language";
import delImage from "../../../Resources/close-img.svg";
import editIcon from "../../../Resources/edit.svg";

//Digital Ocean configuration

//Actions
import {
  AddFurniture,
  DeleteFurniture,
  EditFurniture,
  DeleteFurnitureImages,
  clearFurnitureLogs,
} from "../../../Actions/FurnitureActions";
import {
  ShowAlert,
  StopLoader,
  StartLoader,
  SaveImageFiles,
  SetProgressLoader,
} from "../../../Actions/Common";
import { DeleteImage, GetLinkForUpload } from "../../../Actions/AssetsAction";
import EditImage from "../../Common/EditImage";
import { HELPLINE_USER } from "../../../Actions/Types";
import axios from "axios";
import { GenerateKeyForUpload } from "../../_Utils/GenerateKeyForUpload";

class Step2 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;

  constructor(props) {
    super(props);
    this.state = {
      selectedRoom: null,
      selectedFurniture: null,
      selectedProduct: null,
      productVol: "",
      furnitureArray: [],
      imageUploaded: [],
      detailProductArray: [],
      isEditClicked: false,
      furnitureToEditId: null,
      itemToEdit: null,

      //errorIndicators
      roomError: false,
      furError: false,
      prodError: false,
      volError: false,
      imageError: false,

      //STATES FOR EDIT IMAGE
      openUploadImagePopup: false,
      editImageObject: null,
      deleteFurnitureImageObject: null,
    };
  }

  componentDidMount() {
    const { furnitureDetails } = this.props;
    if (furnitureDetails.length > 0) {
      this.props.handleShowNextButton();
    }
    //Disable inputs type file for laptops
    let fileArr = document.querySelectorAll("input[type=file]");
    if (
      window.innerWidth > 768 &&
      !window.location.pathname.startsWith("/w4s/helpline")
    ) {
      fileArr.forEach((element) => {
        element.disabled = true;
      });
    } else {
      fileArr.forEach((element) => {
        element.disabled = false;
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isPrevClicked === 2 || nextProps.isNextClicked === 2) {
      nextProps.upgradeImagesToParent(
        prevState.imageUploaded.filter((image) => !image.furnitureimgid)
      );
    }
    return {
      detailProductArray: nextProps.furnitureDetails,
    };
  }

  onSubmitClick = () => {
    this.props.onSubmit(3);
  };

  selectRoomType = (value) => {
    const tempArr =
      value === "Kitchen + Dining Room"
        ? Kitchen[this.lan]
        : value === "Exterior + Entrance"
        ? Exterior[this.lan]
        : value === "Living Room"
        ? LivingRoom[this.lan]
        : value === "Bed Room 1"
        ? BedRoom1[this.lan]
        : value === "Bed Room 2"
        ? BedRoom2[this.lan]
        : value === "Bed Room 3"
        ? BedRoom3[this.lan]
        : value === "Bed Room 4"
        ? BedRoom4[this.lan]
        : OtherAreas[this.lan];
    return tempArr;
  };

  onSelectRoom = (e) => {
    const value = e.value;
    let tempArr = this.selectRoomType(value);
    this.setState(
      {
        selectedRoom: e,
        selectedFurniture: null,
        furnitureArray: tempArr,
      },
      this.validateRoom
    );
  };

  onSelectFurniture = (e) => {
    this.setState(
      {
        selectedFurniture: e,
      },
      this.validateFurniture
    );
  };

  onSelectProduct = (e) => {
    this.setState(
      {
        selectedProduct: e,
      },
      this.validateProduct
    );
  };

  onSelectVol = (e) => {
    this.setState(
      {
        productVol: e.target.value,
      },
      this.validateVolumn
    );
  };

  //File upload for furniture
  fileUpload = (e, file) => {
    if (file) {
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          if (!url) {
            this.props.ShowAlert({
              msg: langData[this.lan].failedToUp,
              alertType: "Error",
            });
            //     this.props.StopLoader();
            this.props.SetProgressLoader(false, null);

            return;
          }
          /*   fetch(url, { method: "PUT", body: compressedResult })
            .then(() => {
              this.setState({
                imageUploaded: [
                  { file: compressedResult, imagelink: key },
                  ...this.state.imageUploaded,
                ],
              });
             if(this.state.openUploadImagePopup) this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.StopLoader();
            })
            .catch((err) => {
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
              console.log(err);
              this.props.StopLoader();
            }); */
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.setState({
                imageUploaded: [
                  { file: compressedResult, imagelink: key },
                  ...this.state.imageUploaded,
                ],
              });
              if (this.state.openUploadImagePopup) this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
              console.log(err);
              this.props.SetProgressLoader(false, null);
            });
        },
        error: () => {
          this.props.SetProgressLoader(false, null);
          //       this.props.StopLoader();
        },
      });
    }
    if (e) e.target.value = "";
  };

  handleAddFurniture = async () => {
    const reqObj = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: this.props.siteId,
      phonenum: this.props.phoneNum,
      roomname: this.state.selectedRoom.label,
      furniturename: this.state.selectedFurniture.label,
      productname: this.state.selectedProduct.label,
      productvolume: this.state.productVol,
      furnitureImages: this.state.imageUploaded.map((file) => file.imagelink),
    };

    const furniture = {
      room: this.state.selectedRoom,
      furniture: this.state.selectedFurniture,
      product: this.state.selectedProduct,
      productVolume: this.state.productVol,
      furnitureImages: this.state.imageUploaded,
    };
    await this.props.AddFurniture(reqObj, furniture);
    this.props.handleShowNextButton();

    if (this.props.addFurnitureStatus === 200) {
      localStorage.removeItem("saveFiles");
      this.props.clearFurnitureLogs();
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureAdd,
        alertType: "Success",
      });
      this.setState({
        selectedRoom: null,
        selectedFurniture: null,
        selectedProduct: null,
        productVol: "",
        imageUploaded: [],
      });
    }
  };

  editFurniture = async () => {
    const { itemToEdit } = this.state;
    let furImages = this.state.imageUploaded
      .filter((file) => {
        if (!itemToEdit.furnitureImages.includes(file)) {
          return file.imagelink;
        }
        return false;
      })
      .map((file) => file.imagelink);
    const reqObj = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline

      siteid: this.props.siteId,
      phonenum: this.props.phoneNum,
      furnitureid: this.state.furnitureToEditId,
      roomname:
        itemToEdit.room.label !== this.state.selectedRoom.label
          ? this.state.selectedRoom.label
          : undefined,
      furniturename:
        itemToEdit.furniture.label !== this.state.selectedFurniture.label
          ? this.state.selectedFurniture.label
          : undefined,
      productname:
        itemToEdit.product.label !== this.state.selectedProduct.label
          ? this.state.selectedProduct.label
          : undefined,
      productvolume:
        itemToEdit.productVolume !== this.state.productVol
          ? this.state.productVol
          : undefined,

      furnitureImages: furImages.length ? furImages : undefined,
    };

    const furniture = {
      room: this.state.selectedRoom,
      furniture: this.state.selectedFurniture,
      product: this.state.selectedProduct,
      productVolume: this.state.productVol,
      furnitureImages: this.state.imageUploaded,
      furnitureid: this.state.furnitureToEditId,
    };
    await this.props.EditFurniture(reqObj, furniture);
    this.props.handleShowNextButton();
    if (this.props.editFurnitureStatus === 200) {
      localStorage.removeItem("saveFiles");
      this.props.clearFurnitureLogs();
      this.props.clearFurnitureLogs();
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureEdit,
        alertType: "Success",
      });
      this.setState({
        selectedRoom: null,
        selectedFurniture: null,
        selectedProduct: null,
        productVol: "",
        imageUploaded: [],
        isEditClicked: false,
      });
    }
  };

  validateRoom = () => {
    if (!this.state.selectedRoom) {
      this.setState({ roomError: true });
      return false;
    }
    this.setState({ roomError: false });
    return true;
  };

  validateFurniture = () => {
    if (!this.state.selectedFurniture) {
      this.setState({ furError: true });
      return false;
    }
    this.setState({ furError: false });
    return true;
  };

  validateProduct = () => {
    if (!this.state.selectedProduct) {
      this.setState({ prodError: true });
      return false;
    }
    this.setState({ prodError: false });
    return true;
  };

  validateVolumn = () => {
    if (!this.state.productVol) {
      this.setState({ volError: true });
      return false;
    } else if (isNaN(Number(this.state.productVol.trim()))) {
      this.setState({ volError: true });
      return false;
    }
    this.setState({ volError: false });
    return true;
  };

  validateImages = () => {
    if (!this.state.imageUploaded || this.state.imageUploaded.length === 0) {
      this.setState({ imageError: true });
      return false;
    }
    this.setState({ imageError: false });
    return true;
  };

  validateAll = () => {
    let a, b, c, d, e;
    a = this.validateRoom();
    b = this.validateFurniture();
    c = this.validateProduct();
    d = this.validateVolumn();
    e = this.validateImages();
    if (a && b && c && d && e) return true;
    else return false;
  };

  onAddClick = async () => {
    if (this.validateAll()) {
      if (this.state.isEditClicked) {
        this.editFurniture();
      } else {
        this.handleAddFurniture();
      }
    }
  };

  //Delete furniture from list
  deleteFurniture = async (id) => {
    const reqBody = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline

      siteid: this.props.siteId,
      phonenum: this.props.phoneNum,
      furnitureid: id,
    };
    await this.props.DeleteFurniture(reqBody);
    if (this.props.deleteFurnitureStatus === 200) {
      this.props.clearFurnitureLogs();
      this.setState({
        selectedRoom: null,
        selectedFurniture: null,
        selectedProduct: null,
        productVol: "",
        imageUploaded: [],
      });
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureDelete,
        alertType: "Success",
      });
    }
  };

  handleEditFurniture = async (furniture) => {
    const element = document.getElementById("create-site-step2-room");
    element && element.scrollIntoView();
    const tempArr = this.selectRoomType(furniture.room.value);
    let imageArray = [];
    for (let i = 0; i < furniture.furnitureImages.length; i++) {
      const temp = furniture.furnitureImages[i];
      imageArray.push({ ...temp });
    }
    this.setState({
      selectedRoom: furniture.room,
      selectedFurniture: furniture.furniture,
      selectedProduct: furniture.product,
      productVol: furniture.productVolume,
      imageUploaded: imageArray,
      isEditClicked: true,
      furnitureToEditId: furniture.furnitureid,
      furnitureArray: tempArr,
      itemToEdit: { ...furniture, furnitureImages: imageArray },
    });
  };

  deleteFurnitureImage = async (file) => {
    let reqBody = {};
    if (file.furnitureimgid) {
      reqBody = {
        hluserid:
          this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
        requesterId:
          this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
        siteid: this.props.siteId,
        furnitureid: file.furnitureid,
        furnitureimgid: file.furnitureimgid,
        phonenum: this.props.phoneNum,
      };
      this.props.StartLoader();
      await this.props.DeleteFurnitureImages(reqBody);
      if (this.props.delFurnitureImageStatus === 200) {
        this.props.clearFurnitureLogs();
        this.setState({
          imageUploaded: this.state.imageUploaded.filter(
            (image) => image !== file
          ),
        });
        /* this.props.ShowAlert({
          msg: langData[this.lan].furnitureImageDelete,
          alertType: "Success",
        }); */
        this.props.StopLoader();

        //Edit file for upload
        this.fileUpload(null, this.state.editImageObject);
      }
    } else {
      reqBody = {
        key: file.imagelink,
      };
      this.props.DeleteImage({ key: [file.imagelink] });
      this.setState({
        imageUploaded: this.state.imageUploaded.filter(
          (image) => image !== file
        ),
      });
      const images = JSON.parse(localStorage.getItem("saveFiles"));
      if (images) {
        _.remove(images, (e) => {
          return e === file.imagelink;
        });
      }
      if (images.length < 1) {
        localStorage.removeItem("saveFiles");
      } else {
        this.props.SaveImageFiles(images);
      }
      this.props.StopLoader();
    }
  };

  customStyle = {
    input: (provided) => {
      return { ...provided, margin: "0px", padding: "0px" };
    },
    indicatorSeparator: (provided) => {
      return { ...provided, width: "0px" };
    },
  };

  handleCloseImagePreview = () => {
    this.setState({ imagePreview: false, images: [] });
  };

  handleOpenImagePreview = (index) => {
    this.setState({
      imagePreview: true,
      images: this.state.imageUploaded,
      imageIndex: index,
    });
  };

  //HANDLE RESET BUTTON
  handleResetBtn = () => {
    const imagesToDelete = this.state.imageUploaded.filter(
      (val) => !val.furnitureimgid
    );
    if (imagesToDelete.length)
      this.props.DeleteImage({
        key: imagesToDelete.map((val) => val.imagelink),
      });
    this.setState({
      selectedRoom: null,
      selectedFurniture: null,
      selectedProduct: null,
      productVol: "",
      imageUploaded: [],
    });
  };

  //HANDLE OPEN POPUP FOR UPLOAD
  handleOpenUploadPopup = (deleteFurnitureImageObject) => {
    this.setState({ openUploadImagePopup: true, deleteFurnitureImageObject });
  };

  //HANDLE CLOSE POPUP
  handleCloseEditImage = () => {
    this.setState({
      openUploadImagePopup: false,
      deleteFurnitureImageObject: null,
      editImageObject: null,
    });
  };

  handleEditImageYes = (editImageObject) => {
    this.setState(
      {
        editImageObject,
      },
      () => this.deleteFurnitureImage(this.state.deleteFurnitureImageObject)
    );
  };

  render() {
    const {
      imageUploaded,
      roomError,
      selectedRoom,
      selectedFurniture,
      furError,
      selectedProduct,
      prodError,
      productVol,
      volError,
      imageError,
      furnitureArray,
      openUploadImagePopup,
    } = this.state;
    const { furnitureDetails } = this.props;

    return (
      <div className="page slide-page">
        {/* CREATE FURNITURE */}
        <div id="create-site-step2-room">
          {/* ROOM */}
          <div className="field">
            <div className="label">{langData[this.lan].room}</div>
            <Creatable
              styles={this.customStyle}
              options={Room[this.lan]}
              value={selectedRoom}
              className={roomError ? "creatable" : ""}
              placeholder={langData[this.lan].roomPh}
              onChange={(e) => {
                this.onSelectRoom(e);
              }}
              classNamePrefix="select-dropdown"
            />
            {roomError ? (
              <div className="input-error">{langData[this.lan].roomError}</div>
            ) : null}
          </div>

          {/* FURNITURE NAME */}
          <div className="field">
            <div className="label">{langData[this.lan].fName}</div>
            <Creatable
              styles={this.customStyle}
              options={furnitureArray}
              placeholder={langData[this.lan].furNamePh}
              onChange={(e) => {
                this.onSelectFurniture(e);
              }}
              value={selectedFurniture}
              className={furError ? "creatable" : ""}
              classNamePrefix="select-dropdown"
            />
            {furError ? (
              <div className="input-error">{langData[this.lan].fNameError}</div>
            ) : null}
          </div>

          {/* PRODUCT NAME */}
          <div className="field">
            <div className="label">{langData[this.lan].pName}</div>
            <Creatable
              styles={this.customStyle}
              options={Products[this.lan]}
              placeholder={langData[this.lan].prodNamePh}
              onChange={(e) => {
                this.onSelectProduct(e);
              }}
              value={selectedProduct}
              className={prodError ? "creatable" : ""}
              classNamePrefix="select-dropdown"
            />
            {prodError ? (
              <div className="input-error">{langData[this.lan].pNameError}</div>
            ) : null}
          </div>

          {/* VOLUME */}
          <div className="field">
            <div className="label">{langData[this.lan].prodVol}</div>
            <input
              type="text"
              placeholder={langData[this.lan].prodVolPh}
              onChange={(e) => {
                this.onSelectVol(e);
              }}
              value={productVol}
              className={volError ? "creatable" : ""}
            />
            {volError ? (
              <div className="input-error">{langData[this.lan].volError}</div>
            ) : null}
          </div>

          {/* UPLOAD IMAGES */}
          <div className="field">
            <div className="label">{langData[this.lan].upload}</div>
            <div className="img-container">
              <div className="container">
                <div className="product-img">
                  <input
                    type="file"
                    //   capture={true}
                    accept="image/png, image/jpeg, image/jpg"
                    className="file-input"
                    id="upload"
                    onChange={(event) => {
                      let file = event.target.files[0];
                      if (file) {
                        this.fileUpload(event, file);
                      }
                    }}
                  />
                  <label htmlFor="upload" className="upload-icon">
                    <img
                      src="/Assets/upload.svg"
                      alt="uploadIcon"
                      style={{ cursor: "pointer" }}
                    />
                  </label>
                </div>
              </div>
              {imageUploaded.length > 0 &&
                imageUploaded.map((file, index) => {
                  return (
                    <div className="container" key={index}>
                      <div className="container-image-div">
                        <img
                          className="product-img"
                          src={
                            file.file
                              ? URL.createObjectURL(file.file)
                              : file.expiryLink
                          }
                          alt="furniture"
                          onClick={() => this.handleOpenImagePreview(index)}
                        />
                        {!file.furnitureimgid ? (
                          <img
                            src={delImage}
                            alt="delete"
                            className="delete-icon"
                            onClick={() => this.deleteFurnitureImage(file)}
                          />
                        ) : null}
                      </div>
                      {imageUploaded.filter((val) => val.furnitureimgid)
                        .length !== 1 && file.furnitureid ? (
                        <img
                          className="del-icon"
                          src={editIcon}
                          alt="Edit"
                          onClick={(e) => {
                            this.handleOpenUploadPopup(file);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      ) : /* <img
                          className="del-icon"
                          src="/Assets/del-icon.svg"
                          alt="Delete"
                          onClick={() => this.deleteFurnitureImage(file)}
                          style={{ cursor: "pointer" }}
                        /> */
                      null}
                    </div>
                  );
                })}
            </div>
            {imageError ? (
              <div className="input-error">{langData[this.lan].upError}</div>
            ) : null}
          </div>

          {/* ADD AND RESET BUTTON */}
          <div className="btn-wrapper">
            <div className="field-btn">
              <button className="firstNext next" onClick={this.onAddClick}>
                {langData[this.lan].add}
              </button>
            </div>
            <div className="field-btn">
              <button className="firstNext reset" onClick={this.handleResetBtn}>
                {langData[this.lan].reset || langData[0].reset}
              </button>
            </div>
          </div>
        </div>

        {/* FURNITURE TABLE */}
        {furnitureDetails.length > 0 && (
          <div className="field">
            <div className="field-table">
              <table>
                <tr>
                  {langData[this.lan].furnitureTable.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
                {furnitureDetails.length &&
                  furnitureDetails.map((item, index) => {
                    return (
                      <tr
                        className={
                          index === furnitureDetails.length - 1
                            ? ""
                            : "border_bottom"
                        }
                        // onClick={() => this.handleEditFurniture(item)}
                        key={index}
                      >
                        <td>{item.room && item.room.label}</td>
                        <td>{item.furniture && item.furniture.label}</td>
                        <td>{item.product && item.product.label}</td>
                        <td>{item.productVolume && item.productVolume}</td>

                        <td>
                          <img
                            src={editIcon}
                            alt="Edit"
                            onClick={() => this.handleEditFurniture(item)}
                            style={{ cursor: "pointer" }}
                          />
                          {/* <img
                            src="/Assets/del-icon.svg"
                            alt="Delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.deleteFurniture(item.furnitureid);
                            }}
                            style={{ cursor: "pointer" }}
                          /> */}
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        )}

        {openUploadImagePopup && (
          <EditImage
            handleCloseEditImage={this.handleCloseEditImage}
            handleEditImageYes={this.handleEditImageYes}
          />
        )}
        <ReactModal
          onRequestClose={this.handleCloseImagePreview}
          isOpen={this.state.imagePreview}
          className="image-preview"
          overlayClassName="image-preview-overlay"
        >
          <ImagePreview
            handleCloseImagePreview={this.handleCloseImagePreview}
            images={this.state.images}
            imageIndex={this.state.imageIndex}
          />
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteId: state.site.siteId,
    // phoneNum: state.site.phoneNum,
    addFurnitureStatus: state.furniture.addFurnitureStatus,
    furnitureDetails: state.furniture.furnitureDetails,
    deleteFurnitureStatus: state.furniture.deleteFurnitureStatus,
    delFurnitureImageStatus: state.furniture.delFurnitureImageStatus,
    editFurnitureStatus: state.furniture.editFurnitureStatus,

    //Get phone num of registered site
    phoneNum: state.site.siteData.phonenum,
    userId: state.auth.userId,
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps, {
  AddFurniture,
  EditFurniture,
  DeleteFurniture,
  DeleteFurnitureImages,
  clearFurnitureLogs,
  ShowAlert,
  StartLoader,
  StopLoader,
  DeleteImage,
  SaveImageFiles,
  SetProgressLoader,
})(Step2);
