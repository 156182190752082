import Server from "../API";
import {
  ADD_FURNITURE,
  EDIT_FURNITURE,
  DELETE_FURNITURE,
  DELETE_FURNITURE_IMAGE,
  CLEAR_FURNITURE_LOG,
  SET_FURNITURE_DETAILS,
  GET_EXPIRY_LINK_FURNITURE,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * SetFurnitureDetails: Save furniture details
 * param: Furniture array
 * return: dispatch action to save furniture array
 */
const SetFurnitureDetails = (params) => {
  return (dispatch) => {
    dispatch({
      type: SET_FURNITURE_DETAILS,
      payload: params,
    });
  };
};

/**
 * AddFurniture: Call API to add furniture
 * param: request body
 * return: dispatch action to store added furniture
 */
const AddFurniture = (params, furniture) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/add-furniture",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: ADD_FURNITURE,
        payload: {
          furniture: furniture,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * EditFurniture: API to edit furniture
 * param: params - reqBody
 * 	      furniture - replace edited furniture in redux
 * return: dispatch action to save furniture
 */
const EditFurniture = (params, furniture) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/edit-furniture",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: EDIT_FURNITURE,
        payload: {
          furniture: furniture,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DeleteFurnitureImages: Delete Furniture images API call
 * param: request object
 * return: dispatch action to delete furniture
 */
const DeleteFurnitureImages = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/delete-furniture-image",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DELETE_FURNITURE_IMAGE,
        payload: {
          furniture: params,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * DeleteFurniture: Delete Furniture API
 * param: request body
 * return: Action
 */
const DeleteFurniture = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/delete-furniture",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DELETE_FURNITURE,
        payload: {
          furnitureToDel: params,
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

const GetExpiryLinkForFurniture = (params) => {
  return async (dispatch) => {
    try {
      const response = await Server.post("/paint/w4s/user/signed-get", {
        key: params.imagelink,
      });

      dispatch({
        type: GET_EXPIRY_LINK_FURNITURE,
        payload: {
          object: { ...params, expiryLink: response.data.data },
          server: response.data,
        },
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * clearFurnitureLogs: Clear statues
 * return: dispatch action to clear status log
 */
const clearFurnitureLogs = () => {
  return { type: CLEAR_FURNITURE_LOG, payload: null };
};

// Named Exports
export {
  AddFurniture,
  EditFurniture,
  DeleteFurniture,
  DeleteFurnitureImages,
  clearFurnitureLogs,
  SetFurnitureDetails,
  GetExpiryLinkForFurniture,
};
