import React, { Component } from "react";
import { connect } from "react-redux";
import { SaveSidebarIndex } from "../../Actions/AuthAction";
import { REGISTERED_SIDEBAR_INDEX } from "../../Actions/Types";
import DSRUserInfo from "../Common/UserInfo";
import SiteRegisteration from "../SiteRegisteration";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

class DRSNewSiteRegistration extends Component {
  handleRegisteredClick = () => {
    this.props.SaveSidebarIndex(REGISTERED_SIDEBAR_INDEX);
  };
  
  render() {
    const { userType } = this.props;
    return (
      <div className="dsr-new-registration-page">
        {CheckNonContractorUser(userType) && (
          <div className="header">
            <div className="registered-site-button bk-blue" onClick={this.handleRegisteredClick}>
              Registered sites
            </div>
            <DSRUserInfo />
          </div>
        )}
        <SiteRegisteration
          dsr={true}
          handleNewSiteReg={this.props.handleNewSiteReg}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    username: state.auth.username,
    userPhoneNum: state.auth.userPhoneNum,
  };
};
export default connect(mapStateToProps, { SaveSidebarIndex })(
  DRSNewSiteRegistration
);
