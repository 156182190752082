import React, { Component } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";

//COMPONENTS and UTIL
import Step1 from "../RegisteredSiteSteps/Step1";
import Step2 from "../RegisteredSiteSteps/Step2";
import Step3 from "../RegisteredSiteSteps/Step3";
import historyObj from "../_Utils/History";
import ConfirmationPopup from "../ConfirmationPopup";
import { REGISTERED_SITE, SUCCESSFULSUBMIT } from "../_Utils/Routes";
import { langData } from "../Common/Language";

//ACTIONS
import {
  CloseSite,
  ClearRegisteredStatusLog,
  GetDetailsOfSite,
  UpdateVisitedSites,
} from "../../Actions/RegisteredSites";
import { ShowAlert } from "../../Actions/Common";
import { DeleteImage } from "../../Actions/AssetsAction";
import History from "../_Utils/History";
import ProgressBar from "../Common/ProgressBar";
import DropSiteModal from "../DropSiteModal/DropSiteModal";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

/**
 * SiteRegSteps:Class Component for Registered sites steps
 */
class SiteRegSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStep: 1,
      //   isEditable: 0,
      openConfirmation: false,
      siteCloseButtonClicked: false,
      isPrevClicked: 0,
      isNextClicked: 0,
      furImages: [],
      dealerInvoice: [],
      prodImages: [],
      closeButtonClicked: null,
      openDroppedSite: false,
      droppedSiteId: null,
      dropSitePhone: null,
    };
  }

  getSiteDetails = () => {
    const reqBody = { leadid: this.props.leadId };
    this.props.GetDetailsOfSite(reqBody);
  };
  //Disable back button
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    window.addEventListener("load", this.getSiteDetails);
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.getSiteDetails);
    //     if (this.props.siteDetails)
    //       this.props.UpdateVisitedSites(
    //         this.props.siteDetails.siteid,
    //         this.props.furnitureDetails
    //       );
  }

  handleOpenCloseConfirmation = () => {
    this.setState({ openConfirmation: !this.state.openConfirmation });
  };

  /**
   * handleSiteClosure: Call Action to close site
   */
  handleSiteClosure = async () => {
    const reqBody = {
      siteid: this.props.siteDetails.siteid,
      phonenum: this.props.userPhoneNum,
    };
    await this.props.CloseSite(reqBody);
    if (this.props.closeSiteStatus === 200) {
      localStorage.removeItem("saveFiles");
      this.deleteAllImages();
      this.props.ClearRegisteredStatusLog();
      /*       this.props.ShowAlert({
        msg: langData[lan].siteClose,
        alertType: "Success",
      }); */

      this.props.ShowAlert({
        msg:
          langData[this.props.language - 1].siteUnderReview ||
          langData[0].siteUnderReview,
        alertType: "Success",
      });

      History.push(SUCCESSFULSUBMIT);
    }
    this.handleCloseConfirmationForSite();
  };

  /**
   * handleCloseSites: push to back after close
   */
  handleCloseSites = () => {
    this.setState(
      {
        closeButtonClicked: true,
        isPrevClicked: this.state.selectedStep,
      },
      () => {
        if (this.state.selectedStep === 1) {
          historyObj.push(REGISTERED_SITE);
        }
      }
    );
  };

  handleCloseConfirmationForSite = () => {
    this.setState({
      openConfirmation: false,
      siteCloseButtonClicked: false,
      isPrevClicked: 0,
      isNextClicked: 0,
      prodImages: [],
      dealerInvoice: [],
      furImages: [],
    });
  };

  /** Check images are present while closing the site*/
  checkImagesPresent = () => {
    const { dealerInvoice, furImages, prodImages } = this.state;
    if (
      (dealerInvoice && dealerInvoice.length) ||
      (furImages && furImages.length) ||
      (prodImages && prodImages.length)
    ) {
      return true;
    }
    return false;
  };

  /**
   * deleteAllImages: delete all images before site close
   */
  deleteAllImages = () => {
    const { selectedStep, dealerInvoice, prodImages, furImages } = this.state;
    if (selectedStep === 3) {
      dealerInvoice &&
        dealerInvoice.length &&
        this.props.DeleteImage({
          key: dealerInvoice.map((image) => image.imagelink),
        });

      prodImages &&
        prodImages.length &&
        this.props.DeleteImage({
          key: prodImages.map((image) => image.imagelink),
        });
    } else if (selectedStep === 2) {
      this.props.DeleteImage({
        key: furImages.map((image) => image.imagelink),
      });
    }
  };

  //handle site close button
  handleSiteCloseButton = () => {
    this.setState({
      openConfirmation: true,
      siteCloseButtonClicked: true,
    });
  };

  //handle dropped site button
  handleDropSiteButton = () => {
    this.setState({
      openDroppedSite: true,
      droppedSiteId: this.props.siteDetails.siteid,
      dropSitePhone: CheckNonContractorUser(this.props.userType)
        ? this.props.siteDetails.phonenum
        : this.props.userPhoneNum,
    });
  };

  handleCloseDropSiteModal = () => {
    this.setState({ openDroppedSite: false, dropSitePhone: null });
  };

  handlePrevBtn = () => {
    this.setState({ isPrevClicked: this.state.selectedStep });
  };

  upgradeImagesFromStep3 = (dealerInvoice, prodImages) => {
    this.setState(
      {
        dealerInvoice: dealerInvoice,
        prodImages: prodImages,
      },
      () => {
        !this.state.siteCloseButtonClicked && this.handleOpenConfirmation();
      }
    );
  };

  upgradeImagesFromStep2 = (furImages) => {
    this.setState({ furImages: furImages }, () => {
      !this.state.siteCloseButtonClicked && this.handleOpenConfirmation();
    });
  };

  //Confirmation Yes button
  handlePrevYesButton = async () => {
    const {
      isPrevClicked,
      isNextClicked,
      dealerInvoice,
      prodImages,
      furImages,
      closeButtonClicked,
    } = this.state;
    localStorage.removeItem("saveFiles");
    if (isPrevClicked === 3) {
      dealerInvoice &&
        dealerInvoice.length &&
        this.props.DeleteImage({
          key: dealerInvoice.map((image) => image.imagelink),
        });
      prodImages &&
        prodImages.length &&
        this.props.DeleteImage({
          key: prodImages.map((image) => image.imagelink),
        });
    } else if (isPrevClicked === 2 || isNextClicked === 2) {
      this.props.DeleteImage({
        key: furImages.map((image) => image.imagelink),
      });
    }
    // else if (isNextClicked === 2) {
    //   for (let i = 0; i < furImages.length; i++)
    //     try {
    // 		await deleteUploadedImages(furImages[i].imagelink);
    // 		this.props.DeleteImage(furImages[i].imagelink);
    //     } catch (err) {
    //       console.log(err);
    //     }
    // }
    if (isPrevClicked === 3 || isPrevClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: [],
        prodImages: [],
      });
    else if (isNextClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isPrevClicked: 0,
        isNextClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: [],
        prodImages: [],
      });

    if (closeButtonClicked) {
      this.setState({ closeButtonClicked: null });
      historyObj.push(REGISTERED_SITE);
    }
  };

  //Confirmation NO button
  handlePrevNoButton = () => {
    this.setState({
      isPrevClicked: 0,
      isNextClicked: 0,
      prodImages: [],
      dealerInvoice: [],
      openConfirmation: false,
      furImages: [],
      closeButtonClicked: false,
    });
  };

  //Open Confirmation prompt
  handleOpenConfirmation = () => {
    const {
      dealerInvoice,
      prodImages,
      isPrevClicked,
      furImages,
      isNextClicked,
      closeButtonClicked,
    } = this.state;
    if (
      isPrevClicked === 3 &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (isPrevClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isNextClicked: 0,
        openConfirmation: false,
      });
    } else {
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
      });
    }

    if (
      closeButtonClicked &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked) {
      this.setState({
        openConfirmation: false,
        closeButtonClicked: null,
      });
      historyObj.push(REGISTERED_SITE);
    }
  };

  handleNextBtn = () => {
    if (this.state.selectedStep === 1) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        showNextButton: false,
      });
    } else this.setState({ isNextClicked: this.state.selectedStep });
  };

  render() {
    const { selectedStep, openDroppedSite } = this.state;
    const { siteDetails } = this.props;
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    //SITE STATUS
    const siteStatus = [
      langData[lan].nonClosed || langData[0].nonClosed,
      langData[lan].underReview || langData[0].underReview,
      langData[lan].warrantyIssue || langData[0].warrantyIssue,
      langData[lan].warrantyClaim || langData[0].warrantyClaim,
      langData[lan].droppedSite || langData[0].droppedSite,
    ];

    return (
      <React.Fragment>
        <div className="registered-main-container">
          <div className="registered-container">
            <div className="registered-header-container">
              <img
                src="/Assets/back-arrow.svg"
                alt="Back"
                onClick={this.handleCloseSites}
              />

              <div>{langData[lan].registeredSites}</div>
            </div>

            <div className="registered-list-container site-update-page">
              <div className="site-reg-page">
                <div className="site-reg-page-header">
                  <div className="site-reg-page-header-title lead-id-wrapper font-blue">
                    {/* LEAD ID */}
                    <span className="site-status-text lead-id">
                      {langData[lan].leadId} :{" "}
                    </span>
                    {siteDetails && siteDetails.leadid}
                  </div>
                  {/* SITE STATUS */}
                  <div className="site-reg-page-header-title lead-id-wrapper font-blue">
                    <span className="site-status-text">
                      {langData[lan].siteStatus} :{" "}
                    </span>
                    {siteDetails && siteStatus[siteDetails.sitestatus]}
                  </div>

                  {/* WARRANTY ID */}
                  {siteDetails &&
                  siteDetails.warrantyInfo &&
                  siteDetails.warrantyInfo.warrantyid ? (
                    <div className="site-reg-page-header-title font-blue">
                      <span className="site-status-text">
                        {langData[lan].warrantyId} :{" "}
                      </span>
                      {siteDetails.warrantyInfo.warrantyid}
                    </div>
                  ) : null}

                  {/* UPDATE SITE STATUS */}
                  <div className="site-reg-page-header-buttons">
                    {siteDetails && siteDetails.sitestatus === 0 ? (
                      <div
                        className="site-reg-page-header-buttons-site bk-blue"
                        onClick={this.handleSiteCloseButton}
                      >
                        {langData[lan].initiateWarrantyGeneration ||
                          langData[0].initiateWarrantyGeneration}
                      </div>
                    ) : null}
                    {siteDetails && siteDetails.sitestatus === 0 ? (
                      <div
                        className="site-reg-page-header-buttons-site bk-blue"
                        onClick={this.handleDropSiteButton}
                      >
                        {langData[lan].droppedSiteButton ||
                          langData[0].droppedSiteButton}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="site-reg-page-progress">
                  <div className="site-reg-page-progress-btn">
                    {selectedStep === 2 || selectedStep === 3 ? (
                      <div
                        className="site-reg-page-progress-prev bk-blue"
                        onClick={this.handlePrevBtn}
                        id="prev"
                      >
                        {langData[lan].prev}
                      </div>
                    ) : null}
                  </div>
                  <ProgressBar selectedStep={selectedStep} />
                  <div className="site-reg-page-progress-btn">
                    {selectedStep !== 3 ? (
                      <div
                        className="site-reg-page-progress-next bk-blue"
                        onClick={this.handleNextBtn}
                        id="next"
                      >
                        {langData[lan].next}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="site-reg-page-step">
                  {selectedStep === 1 ? <Step1 /> : null}
                  {selectedStep === 2 ? (
                    <Step2
                      upgradeImagesToParent={this.upgradeImagesFromStep2}
                      isPrevClicked={this.state.isPrevClicked}
                      isNextClicked={this.state.isNextClicked}
                      siteCloseButtonClicked={this.state.siteCloseButtonClicked}
                    />
                  ) : null}
                  {selectedStep === 3 ? (
                    <Step3
                      upgradeImagesToParent={this.upgradeImagesFromStep3}
                      isPrevClicked={this.state.isPrevClicked}
                      siteCloseButtonClicked={this.state.siteCloseButtonClicked}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Confirmation modal */}
        <ReactModal
          isOpen={this.state.openConfirmation}
          onRequestClose={this.handleCloseConfirmationForSite}
          className="confirmation-popup-modal"
          overlayClassName="confirmation-popup-overlay"
          ariaHideApp={false}
        >
          <ConfirmationPopup
            handleSiteClosure={
              this.state.siteCloseButtonClicked
                ? this.handleSiteClosure
                : this.handlePrevYesButton
            }
            handleOpenConfirmation={
              this.state.siteCloseButtonClicked
                ? this.handleCloseConfirmationForSite
                : this.handlePrevNoButton
            }
            msg={
              this.state.siteCloseButtonClicked
                ? this.checkImagesPresent()
                  ? langData[lan].clearDataConf
                  : langData[lan].initiateSiteWarrantyConf ||
                    langData[0].initiateSiteWarrantyConf
                : langData[lan].clearDataConf
            }
          />
        </ReactModal>

        {openDroppedSite && (
          <DropSiteModal
            handleCloseModal={this.handleCloseDropSiteModal}
            droppedSiteId={this.state.droppedSiteId}
            dropSitePhone={this.state.dropSitePhone}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
    leadId: state.regSites.leadId,
    siteDetails: state.regSites.siteDetails,
    closeSiteStatus: state.regSites.closeSiteStatus,
    furnitureDetails: state.furniture.furnitureDetails,

    language: state.auth.language,
  };
};

export default connect(mapStateToProps, {
  CloseSite,
  ClearRegisteredStatusLog,
  GetDetailsOfSite,
  ShowAlert,
  UpdateVisitedSites,
  DeleteImage,
})(SiteRegSteps);
