import axios from "axios";
var axiosInstance = axios.create({
    baseURL: "https://auto1.wood4sure.com",
    //   baseURL: "http://165.22.208.169",
    //   baseURL: "http://192.168.0.135:81",
    headers: {
        "Content-Type": "application/json",
        "Content-Language": "browser",
    },
});

/* 
>>>> Uncomment this code only in case of JWT integration!

axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('authToken');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
}); 
*/

export default axiosInstance;