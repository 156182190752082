import React, { Component } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";

class LoadingProgress extends Component {
  render() {
    const { progressLoader } = this.props;
    if (!progressLoader || !progressLoader.state) return null;
    return (
      <div className="progress-loading">
        <div className="progress-loading-bar font-poppins">
          <CircularProgressbar
            value={progressLoader.percentage}
            text={`${progressLoader.percentage}%`}
            strokeWidth={2}
            styles={buildStyles({
              textSize: "16px",

              pathTransitionDuration: 0.5,
              // Colors
              pathColor: `#6563ff`,
              textColor: "#fff",
              trailColor: "#d2d2d2",
              backgroundColor: "#3e98c7",
            })}
          />
          <div className="label">
            <p>Uploading data...</p>
            <p> Please wait</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    progressLoader: state.common.progressLoader,
  };
};
export default connect(mapStateToProps)(LoadingProgress);
