import React, { Component } from "react";
import { connect } from "react-redux";
import {
  ClearUsersStatus,
  GetDataForExport,
  GetSSOList,
  PersistSearchInfo,
  SaveTablePageNumber,
} from "../../Actions/UsersAction";
import { PAGE_SIZE, SSO_USER } from "../../Actions/Types";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import { ExportSheet } from "../Common/ExportSheet";
import CustomTable from "../CustomTable/CustomTable";
import { ExportJSONToSheet } from "../_Utils/ExportJSONToSheet";
import DataNotFound from "../Common/DataNotFound";
import { NumberConversion } from "../Common/NumberConversion";

class SSOTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //SSO TABLE HEADER
      ssoTableHeader: [
        {
          label: "SSO Name",
          sortBy: "name",
          customClass: "name",
          sortOrder: 1,
        },
        {
          label: "SSO Phone No.",
          sortBy: "phonenum",
          customClass: "num",
          sortOrder: 1,
        },
        {
          label: "No. of contractors",
          customClass: "id",
        },
        {
          label: "No. of sites registered by contractor",
          customClass: "id",
        },
        {
          label: "No. of warranty issued",
          customClass: "id",
        },
        {
          label: "No.of warranty claimed",
          sortBy: "",
          customClass: "id",
        },
        {
          label: "No. of sites Pending",
          sortBy: "",
          customClass: "id",
        },
      ],

      searchQuery: "",
      showNotFound: false,
      pageNum: props.SSOPageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
    };
  }

  componentDidMount() {
    const { searchSSODetails } = this.props;
    if (searchSSODetails) {
      this.setState({
        selectedSearchOption: searchSSODetails.searchBy,
        searchQuery: searchSSODetails.searchQuery,
      });
    }
  }

  /* ------------------------ GET SSO LIST FROM BACKEND AND PAGINATION ----------------------- */
  getSSOList = async (exportCount) => {
    const {
      pageNum,
      pageSize,
      selectedSearchOption,
      searchQuery,
      sortingColumn,
    } = this.state;
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      userType: SSO_USER,
      searchKey: selectedSearchOption.value
        ? selectedSearchOption.value
        : undefined,
      searchValue: searchQuery.trim() || undefined,
      sortBy: sortingColumn.sortBy || undefined,
      sortOrder:
        sortingColumn.sortOrder === 2
          ? "DESC"
          : sortingColumn.sortOrder === 1
          ? "ASC"
          : undefined,
    };
    if (!exportCount)
      await this.props.GetSSOList(
        reqBody,
        exportCount ? 0 : pageNum,
        exportCount ? exportCount : pageSize
      );
    else await this.props.GetDataForExport(reqBody, 0, exportCount);
  };

  getDataForPageChange = async () => {
    await this.getSSOList();
    if (this.props.getSSOListStatus === 200) {
      this.props.ClearUsersStatus();
    }
  };

  handlePageChange = (e) => {
    if (e.selected === this.state.pageNum) return;
    this.setState(
      {
        pageNum: e.selected,
      },
      () => {
        const { searchSSODetails } = this.props;
        if (searchSSODetails) {
          this.getDataForPageChange();
        } else if (
          !searchSSODetails &&
          (this.state.selectedSearchOption.value || this.state.searchQuery)
        ) {
          this.setState(
            {
              selectedSearchOption: { value: null, label: "Search by" },
              searchQuery: "",
            },
            () => {
              this.getDataForPageChange();
            }
          );
        } else {
          this.getDataForPageChange();
        }
      }
    );
  };

  /* --------------------- MANAGE SEARCH DETAILS IN REDUX --------------------- */
  SaveSearchDetails = () => {
    this.props.PersistSearchInfo("searchSSODetails", {
      searchBy: this.state.selectedSearchOption,
      searchQuery: this.state.searchQuery,
    });
    this.props.SaveTablePageNumber("SSOPageNum", this.state.pageNum);
  };

  ClearSearchDetails = () => {
    this.setState({
      searchQuery: "",
      selectedSearchOption: { value: null, label: "Search by" },
    });
    this.props.PersistSearchInfo("searchSSODetails", null);
    this.props.SaveTablePageNumber("SSOPageNum", 0);
  };
  /* ------------------------ handle search sso details ----------------------- */

  //CALL SEARCH API
  handleSearch = async () => {
    this.setState({ pageNum: 0 }, async () => {
      await this.getSSOList();
      this.props.SaveTablePageNumber("SSOPageNum", 0);
      if (this.props.getSSOListStatus === 200) {
        this.SaveSearchDetails();
        this.props.ClearUsersStatus();
      }
    });
  };

  //HANDLE INPUT CHANGE
  handleInputChange = (e) => {
    if (!e.target.value.trim()) {
      this.setState({ showNotFound: false });
      //   this.props.handleShowList(true);
    }
    this.setState({ searchQuery: e.target.value });
  };

  //CLEAR INPUT FIELD
  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  //HANDLE CLEAR SEARCH
  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.getSSOList();
        this.ClearSearchDetails();
        this.setState({ pageNum: 0 });
      }
    );
  };
  //SEARCH BY OPTIONS SELECTION
  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  /* ----------------------------- SSO TABLE DATA ----------------------------- */
  ssoTableData = () => {
    const { ssoList } = this.props;
    if (!ssoList || !ssoList.length) return;
    return ssoList.map((item) => {
      return {
        data: [
          { data: item.name, customClass: "name" },
          { data: item.phonenum, customClass: "num" },
          { data: item.regSumContrCount, customClass: "address" },
          { data: item.regSumRegSiteCount, customClass: "address" },
          { data: item.warrantyIssuedSites, customClass: "address" },
          { data: item.warrantyClaimedSites, customClass: "address" },
          { data: item.pendingSites, customClass: "address" },
        ],
        actualObject: item,
      };
    });
  };

  //click handler of sso row
  ssoClickHandler = (item) => {
    this.props.PersistSearchInfo("searchSSODetails", {
      searchBy: this.state.selectedSearchOption,
      searchQuery: this.state.searchQuery,
    });
    this.props.SaveTablePageNumber("SSOPageNum", this.state.pageNum);
    this.props.onUserClick(item);
  };

  handleSorting = (col) => {
    this.setState(
      {
        sortingColumn: col,
        ssoTableHeader: this.state.ssoTableHeader.map((val) => {
          if (col.sortBy === val.sortBy)
            return { ...val, sortOrder: val.sortOrder === 1 ? 2 : 1 };
          else return { ...val, sortOrder: 1 };
        }),
      },
      this.getSSOList
    );
    if (this.props.getSSOListStatus === 200) {
      this.props.ClearUsersStatus();
    }
  };

  getDataForExport = async () => {
    await this.getSSOList(this.props.ssoCount);
    if (this.props.getExportDataStatus === 200) {
      this.props.ClearUsersStatus();
      const { exportUserList } = this.props;
      let dataToExport = [];
      if (exportUserList) {
        for (let i = 0; i < exportUserList.length; i++) {
          const sso = exportUserList[i];
          let ssoDetails = {};
          ssoDetails["SSO name"] = sso.name;
          ssoDetails["SSO Phone Number"] = NumberConversion(sso.phonenum);
          ssoDetails["No. of contractors"] = NumberConversion(
            sso.regSumContrCount
          );
          ssoDetails["No. of sites registered by contractor "] =
            NumberConversion(sso.regSumRegSiteCount);
          ssoDetails["No. of warranty issued"] = NumberConversion(
            sso.warrantyIssuedSites
          );
          ssoDetails["No. of warranty claimed"] = NumberConversion(
            sso.warrantyClaimedSites
          );
          ssoDetails["No. of sites pending"] = NumberConversion(
            sso.pendingSites
          );
          dataToExport.push(ssoDetails);
        }
        ExportJSONToSheet(dataToExport, "SSO", 7);
      }
    }
  };

  handleExportDataButton = async () => {
    const { searchSSODetails } = this.props;
    if (searchSSODetails) {
      this.getDataForExport();
    } else if (
      !searchSSODetails &&
      (this.state.selectedSearchOption.value || this.state.searchQuery)
    ) {
      this.setState(
        {
          selectedSearchOption: { value: null, label: "Search by" },
          searchQuery: "",
        },
        () => {
          this.getDataForExport();
        }
      );
    } else {
      this.getDataForExport();
    }
  };

  render() {
    const { selectedSearchOption, searchQuery, showSearchResults } = this.state;

    return (
      <div className="search-sso">
        <div className="search-sso-header">Search SSO</div>
        <AdvanceSearch
          searchQuery={searchQuery}
          selectedSearchOption={selectedSearchOption}
          showSearchResults={showSearchResults}
          showClearButton={this.props.searchSSODetails}
          searchOptionsFor="SSO"
          handleSearch={this.handleSearch}
          handleInputChange={this.handleInputChange}
          handleClearSearch={this.handleClearSearch}
          handleSearchByOptionSelection={this.handleSearchByOptionSelection}
          handleClearAllSearch={this.handleClearAllSearch}
        />

        {/* EXPORT DATA */}
        {this.props.ssoCount ? (
          <ExportSheet onClickHandler={this.handleExportDataButton} />
        ) : null}

        {this.props.ssoList && this.props.ssoList.length ? (
          <CustomTable
            tableHeader={this.state.ssoTableHeader}
            tableBody={this.ssoTableData()}
            tableWrapperClass="user-table-wrapper"
            tableClass="search-sso-table"
            rowClickable={true}
            rowClickHandler={this.ssoClickHandler}
            pagination={true}
            handlePageChange={this.handlePageChange}
            pageNum={this.state.pageNum}
            pageSize={this.state.pageSize}
            countOfData={this.props.ssoCount}
            handleSort={this.handleSorting}
          />
        ) : (
          <DataNotFound msg={"Not found!"} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPhoneNum: state.auth.userPhoneNum,
    searchSSODetails: state.user.searchSSODetails,
    ssoList: state.user.ssoList,
    getSSOListStatus: state.user.getSSOListStatus,
    ssoCount: state.user.ssoCount,
    SSOPageNum: state.user.SSOPageNum,

    getExportDataStatus: state.user.getExportDataStatus,
    exportUserList: state.user.exportUserList,
  };
};

export default connect(mapStateToProps, {
  PersistSearchInfo,
  SaveTablePageNumber,
  GetSSOList,
  ClearUsersStatus,
  GetDataForExport,
})(SSOTable);
