import React, { Component } from "react";
import { connect } from "react-redux";
import { SaveSidebarIndex } from "../../Actions/AuthAction";
import { CONTRACTOR_USER_TYPE } from "../../Actions/Types";
import { langData } from "../Common/Language";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import History from "../_Utils/History";
import { REGISTERED_SITE } from "../_Utils/Routes";
// import ReactWhatsapp from "react-whatsapp";
class Submit extends Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    // localStorage.removeItem("store-state");
  }

  handleRegisteredSiteButtons = () => {
    const { userType } = this.props;
    //IF USER IS CONTRACTOR USER
    if (userType === CONTRACTOR_USER_TYPE) {
      History.push(REGISTERED_SITE);
    }
    //IF USER IS DSR THEN GO TO REGISTERED SITES TAB
    else if (CheckNonContractorUser(userType)) {
      this.props.SaveSidebarIndex(0);
      //   History.push(DSR_HOME);
      History.push(`/w4s/${userType}`);
    }
  };

  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    return (
      <div className="thank-you">
        <div className="thank-you-msg">
          <div className="thank-you-msg-ty">{langData[lan].thankYou}!</div>
          <div className="thank-you-msg-info">{langData[lan].submitMsg}</div>
          <div className="thank-you-msg-info-wp">
            <a className="whatsapp-button" href="https://wa.me/919854985985">
              {langData[lan].openWp}
            </a>

            {/*  <Link className="registered-button" to={REGISTERED_SITE }>
              {langData[lan].registeredSites}
            </Link> */}
            <button
              className="registered-button"
              onClick={this.handleRegisteredSiteButtons}
            >
              {langData[lan].registeredSites}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps, { SaveSidebarIndex })(Submit);
