import Server from "../API";
import {
  REGISTER_SITE,
  EDIT_SITE,
  ADD_PRODUCT_IMAGES,
  CLEAR_SITE_LOG,
  SAVE_USER_INFO,
  CLEAR_ADDED_SITE,
  CONTRACTOR_USER_TYPE,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * SaveUserDetails: Save user details using token
 * param: params - JSON object of user info
 *        token - JSON object of user info
 * return: dispatch action
 */
const SaveUserDetails = (params, token) => {
  if (token) {
    localStorage.setItem("UserInfo", JSON.stringify(token));
    localStorage.setItem("language",token.userType===CONTRACTOR_USER_TYPE ? token.language : 1);
  }

  //   localStorage.setItem("persist-site-info", JSON.stringify(token));
  return (dispatch) => {
    try {
      dispatch({
        type: SAVE_USER_INFO,
        payload: params,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * RegisterSite: Register Site API
 * param: request body
 * return: dispatch action
 */
const RegisterSite = (params, contractorDetails) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/register-site",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: REGISTER_SITE,
        payload: {
          serverRes: response.data,
          customerData: params,
          contractorDetails,
        },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * EditSite: Edit Site API for new registration
 * param: request body
 * return: dispatch action
 */
const EditSite = (params, contractorDetails) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/edit-site", params);
      dispatch(StopLoader());
      dispatch({
        type: EDIT_SITE,
        payload: {
          serverRes: response.data,
          customerData: params,
          contractorDetails,
        },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * AddProductImages: Add product images API
 * param: request body
 * return: dispatch action
 */
const AddProductImages = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/user/add-product-images",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: ADD_PRODUCT_IMAGES,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * ClearAddedSite
 * return: dispatch action to clear added site
 */

const ClearAddedSite = () => {
  return {
    type: CLEAR_ADDED_SITE,
  };
};

/**
 * clearSiteLogs: Clear Site logs
 * param: request body
 * return: dispatch action
 */
const clearSiteLogs = () => {
  return { type: CLEAR_SITE_LOG, payload: null };
};

// Named Exports
export {
  RegisterSite,
  EditSite,
  AddProductImages,
  clearSiteLogs,
  SaveUserDetails,
  ClearAddedSite,
};
