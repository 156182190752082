import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import Reducers from "./Reducers";

const persistStateToLocalStorage = function (prevState) {
  //   console.log("persistStateToLocalStorage");
  try {
    const serializedState = JSON.stringify(prevState);
    localStorage.setItem("store-state", serializedState); // Make sure to give unique name to local storage variable.
  } catch (e) {
    console.log(e);
  }
};

const fetchPersistedStateFromLocalStorage = function () {
  //   console.log("fetchPersistedStateFromLocalStorage");
  try {
    const serializedState = localStorage.getItem("store-state"); // Make sure to give name same as above.
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
  }
};

const persistedState = fetchPersistedStateFromLocalStorage();
const dataStore = createStore(
  Reducers,
  persistedState,
  applyMiddleware(ReduxThunk)
);

dataStore.subscribe(() => persistStateToLocalStorage(dataStore.getState()));

export default dataStore;
