import React, { Component } from "react";
import Creatable from "react-select/creatable";
import { connect } from "react-redux";
import _ from "lodash";
import Compressor from "compressorjs";
import axios from "axios";
//DROPDOWN DATA
import {
  Products,
  Room,
  Exterior,
  Kitchen,
  LivingRoom,
  BedRoom1,
  BedRoom2,
  BedRoom3,
  BedRoom4,
  OtherAreas,
} from "../SiteRegisteration/Step2/furnitureData";

//RESOURCES
import delImage from "../../Resources/close-img.svg";
import uploadIcon from "../../Resources/upload.svg";
// import delIcon from "../../Resources/del-icon.svg";
import editIcon from "../../Resources/edit.svg";

//Actions
import {
  AddFurniture,
  EditFurniture,
  DeleteFurniture,
  clearFurnitureLogs,
  DeleteFurnitureImages,
  GetExpiryLinkForFurniture,
} from "../../Actions/FurnitureActions";
import { DeleteImage, GetLinkForUpload } from "../../Actions/AssetsAction";
import {
  ShowAlert,
  StartLoader,
  StopLoader,
  SaveImageFiles,
  SetProgressLoader,
} from "../../Actions/Common";
import { langData } from "../Common/Language";
import ReactModal from "react-modal";
import ImagePreview from "../Common/ImagePreview";
import {
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import {
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import EditImage from "../Common/EditImage";
import { GenerateKeyForUpload } from "../_Utils/GenerateKeyForUpload";

/**
 * Step2: Class component for step2
 * Used to edit step2 of registered sites
 */
class Step2 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;
  constructor(props) {
    super(props);
    this.state = {
      dropDEnabled: true,
      selectedRoom: null,
      selectedFurniture: null,
      selectedProduct: null,
      productVol: "",
      furnitureArray: [],
      imageUploaded: [],
      detailProductArray: [],
      isEditClicked: false,
      furnitureToEditId: null,
      itemToEdit: null,

      //errorIndicators
      roomError: false,
      furError: false,
      prodError: false,
      volError: false,
      imageError: false,

      //Image Preview
      imagePreview: false,
      images: [],
      imageIndex: 0,
      dummy: false,

      showAddButton: false,

      //STATES FOR EDIT IMAGE
      openUploadImagePopup: false,
      editImageObject: null,
      deleteFurnitureImageObject: null,

      loading: false,
      chunks: [],
    };
  }

  componentDidMount() {
    this.props.SetProgressLoader(false, null);
    const { siteDetails, userType } = this.props;
    if (!siteDetails) return;
    let arr = document.querySelectorAll("input");

    //IF USER IS HELPLINE THEN FOR OPEN, DROPPED SITE AND UNDER REVIEW SITE , SITE IS EDITABLE
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 ||
        siteDetails.sitestatus === 1 ||
        siteDetails.sitestatus === 4)
    ) {
      arr.forEach((element) => {
        element.disabled = false;
      });
    }
    //FOR OTHER USERS, SITE IS EDITABLE ONLY FOR OPEN AND UNDER REVIEW STATUS
    else if (
      userType !== HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1)
    ) {
      let fileArr = document.querySelectorAll("input[type=file]");
      if (window.innerWidth > 768) {
        fileArr.forEach((element) => {
          element.disabled = true;
        });
      } else {
        fileArr.forEach((element) => {
          element.disabled = false;
        });
      }
    } else {
      arr.forEach((element) => {
        element.disabled = true;
      });
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (
      nextProps.isPrevClicked === 2 ||
      nextProps.isNextClicked === 2 ||
      nextProps.siteCloseButtonClicked
    ) {
      nextProps.upgradeImagesToParent(
        prevState.imageUploaded.filter((image) => !image.furnitureimgid)
      );
    }

    //If site is closed then disable the dropdown and clear images
    if (
      nextProps.siteDetails /* (nextProps.userType !== HELPLINE_USER &&
        nextProps.siteDetails.sitestatus === 1) || */ &&
      nextProps.userType === HELPLINE_USER &&
      nextProps.siteDetails.sitestatus === 2
    ) {
      let arr = document.querySelectorAll("input");
      arr.forEach((element) => {
        element.disabled = true;
      });
      return {
        dropDEnabled: true,
        imageUploaded: prevState.imageUploaded.filter(
          (image) => image.furnitureimgid
        ),
      };
    } else if (nextProps.getExpiryLink === 200) {
      nextProps.clearFurnitureLogs();
      let images = [];
      nextProps.furnitureDetails.map((val) => {
        if (val && val.furnitureid === prevState.furnitureToEditId) {
          images = val.furnitureImages;
        }
        return null;
      });
      return {
        dummy: true,
        imageUploaded: images,
      };
    } else return { dropDEnabled: false };
  };

  //Disable/enable inputs
  componentDidUpdate() {
    //Disable inputs type file for laptops
    let fileArr = document.querySelectorAll("input[type=file]");
    if (
      window.innerWidth > 768 &&
      !window.location.pathname.startsWith("/w4s/helpline")
    ) {
      fileArr.forEach((element) => {
        element.disabled = true;
      });
    } else {
      fileArr.forEach((element) => {
        element.disabled = false;
      });
    }
    // } else {
    //   let arr = document.querySelectorAll("input");
    //   arr.forEach((element) => {
    //     element.disabled = true;
    //   });
    // }
  }

  selectRoomType = (value) => {
    const tempArr =
      value === "Kitchen + Dining Room"
        ? Kitchen[this.lan]
        : value === "Exterior + Entrance"
        ? Exterior[this.lan]
        : value === "Living Room"
        ? LivingRoom[this.lan]
        : value === "Bed Room 1"
        ? BedRoom1[this.lan]
        : value === "Bed Room 2"
        ? BedRoom2[this.lan]
        : value === "Bed Room 3"
        ? BedRoom3[this.lan]
        : value === "Bed Room 4"
        ? BedRoom4[this.lan]
        : OtherAreas[this.lan];
    return tempArr;
  };

  onSelectRoom = (e) => {
    const value = e.value;
    let tempArr = this.selectRoomType(value);
    this.setState(
      {
        selectedRoom: e,
        selectedFurniture: null,
        furnitureArray: tempArr,
        showAddButton: true,
      },
      this.validateRoom
    );
  };

  onSelectFurniture = (e) => {
    this.setState(
      {
        selectedFurniture: e,
        showAddButton: true,
      },
      this.validateFurniture
    );
  };

  onSelectProduct = (e) => {
    this.setState(
      {
        selectedProduct: e,
        showAddButton: true,
      },
      this.validateProduct
    );
  };

  onSelectVol = (e) => {
    this.setState(
      {
        productVol: e.target.value,
        showAddButton: true,
      },
      this.validateVolumn
    );
  };

  updateFurnitureImage = (key, compressedResult) => {
    this.setState(
      {
        imageUploaded: [
          {
            imagelink: key,
            file: compressedResult,
          },
          ...this.state.imageUploaded,
        ],
        showAddButton: true,
        editImageObject: null,
      },
      () => {
        // console.log("UPLOADED IMAGES", this.state.imageUploaded);
        this.validateImages();
      }
    );
    let images = [];
    let files = JSON.parse(localStorage.getItem("saveFiles"));
    if (files) {
      images = files;
    }
    images.push(key);
    this.props.SaveImageFiles(images);
    this.props.StopLoader();
  };

  //NEW : WITH PROGRESS LOADER
  fileUpload = (e, file) => {
    if (file) {
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          if (!url) {
            this.props.SetProgressLoader(false, null);
            this.props.ShowAlert({
              msg: langData[this.lan].failedToUp,
              alertType: "Error",
            });
            return;
          }
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.updateFurnitureImage(key, compressedResult);
              this.handleCloseEditImage();
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
              // console.log(err);
              this.props.SetProgressLoader(false, null);
            });
        },
        error: (e) => {
          this.props.StopLoader();
          this.props.SetProgressLoader(false, null);
        },
      });
    }
    if (e) e.target.value = "";
  };

  //OLD REMOVED : DUE TO FETCH IS NOT SUPPORTED TO PROGRESS
  /*  fileUpload = (e, file) => {
    if (file) {
      this.props.StartLoader();
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = Date.now() + file.name.replaceAll(" ", "-");
          const url = await GetLinkForUpload({ key: key });
          if (!url) {
            this.props.ShowAlert({
              msg: langData[this.lan].failedToUp,
              alertType: "Error",
            });
            this.props.StopLoader();
            return;
          }
          fetch(url, { method: "PUT", body: compressedResult })
            .then(() => {
              this.updateFurnitureImage(key, compressedResult);
              this.handleCloseEditImage();
            })
            .catch((err) => {
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
              console.log(err);
              this.props.StopLoader();
            });
        },
        error: () => {
          this.props.StopLoader();
        },
      });
    }
    if (e) e.target.value = "";
  }; */

  getSiteList = async () => {
    const { userType, userPhoneNum, registeredSites } = this.props;

    //IF USER TYPE IS HELPLINE
    if (userType === HELPLINE_USER) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum },
        0,
        PAGE_SIZE
      );
    }

    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum, requesterPhone: userPhoneNum },
        0,
        PAGE_SIZE,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }

    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
    }
  };
  /**
   * handleAddFurniture : Call Action to add furniture
   */
  handleAddFurniture = async () => {
    if (!this.props.siteDetails) return;
    const reqObj = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: this.props.siteid,
      phonenum: this.props.siteDetails.phonenum,
      roomname: this.state.selectedRoom.label,
      furniturename: this.state.selectedFurniture.label,
      productname: this.state.selectedProduct.label,
      productvolume: this.state.productVol,
      furnitureImages: this.state.imageUploaded.map((file) => file.imagelink),
    };
    const furniture = {
      siteid: this.props.siteid,
      roomname: this.state.selectedRoom.label,
      furniturename: this.state.selectedFurniture.label,
      productname: this.state.selectedProduct.label,
      productvolume: this.state.productVol,
      furnitureImages: this.state.imageUploaded,
    };
    // this.props.StartLoader();
    await this.props.AddFurniture(reqObj, furniture);
    // this.props.StopLoader();
    if (this.props.addFurnitureStatus === 200) {
      localStorage.removeItem("saveFiles");
      this.props.clearFurnitureLogs();
      //   this.props.handleCancelBtn();
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureEdit,
        alertType: "Success",
      });

      //   this.getSiteList();
    }
    this.setState({
      selectedRoom: null,
      selectedFurniture: null,
      selectedProduct: null,
      productVol: "",
      imageUploaded: [],
    });
  };

  /**
   * editFurniture : Call Action to edit furniture
   */
  editFurniture = async () => {
    const { itemToEdit } = this.state;
    let furImages = this.state.imageUploaded
      .filter((file) => {
        if (!itemToEdit.furnitureImages.includes(file)) {
          return true;
        } else return false;
      })
      .map((file) => file.imagelink);

    const reqObj = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: itemToEdit.siteid,
      phonenum: this.props.siteDetails.phonenum,
      furnitureid: this.state.furnitureToEditId,
      roomname:
        itemToEdit.roomname !== this.state.selectedRoom.label
          ? this.state.selectedRoom.label
          : undefined,
      furniturename:
        itemToEdit.furniturename !== this.state.selectedFurniture.label
          ? this.state.selectedFurniture.label
          : undefined,
      productname:
        itemToEdit.productname !== this.state.selectedProduct.label
          ? this.state.selectedProduct.label
          : undefined,
      productvolume:
        itemToEdit.productvolume !== this.state.productVol
          ? this.state.productVol
          : undefined,

      furnitureImages: furImages.length ? furImages : undefined,
    };

    const furniture = {
      siteid: itemToEdit.siteid,
      roomname: this.state.selectedRoom.label,
      furnitureid: this.state.furnitureToEditId,
      furniturename: this.state.selectedFurniture.label,
      productname: this.state.selectedProduct.label,
      productvolume: this.state.productVol,
    };

    await this.props.EditFurniture(reqObj, furniture);
    if (this.props.editFurnitureStatus === 200) {
      //   this.props.handleCancelBtn();
      localStorage.removeItem("saveFiles");
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureEdit,
        alertType: "Success",
      });
      this.props.clearFurnitureLogs();
      this.setState({
        selectedRoom: null,
        selectedFurniture: null,
        selectedProduct: null,
        productVol: "",
        imageUploaded: [],
        isEditClicked: false,
      });

      //   this.getSiteList();
    }
  };

  validateRoom = () => {
    if (!this.state.selectedRoom) {
      this.setState({ roomError: true });
      return false;
    }
    this.setState({ roomError: false });
    return true;
  };

  validateFurniture = () => {
    if (!this.state.selectedFurniture) {
      this.setState({ furError: true });
      return false;
    }
    this.setState({ furError: false });
    return true;
  };

  validateProduct = () => {
    if (!this.state.selectedProduct) {
      this.setState({ prodError: true });
      return false;
    }
    this.setState({ prodError: false });
    return true;
  };

  validateVolumn = () => {
    if (!this.state.productVol) {
      this.setState({ volError: true });
      return false;
    } else if (isNaN(Number(this.state.productVol.trim()))) {
      this.setState({ volError: true });
      return false;
    }
    this.setState({ volError: false });
    return true;
  };

  validateImages = () => {
    if (!this.state.imageUploaded || this.state.imageUploaded.length === 0) {
      this.setState({ imageError: true });
      return false;
    }
    this.setState({ imageError: false });
    return true;
  };

  validateAll = () => {
    let a, b, c, d, e;
    a = this.validateRoom();
    b = this.validateFurniture();
    c = this.validateProduct();
    d = this.validateVolumn();
    e = this.validateImages();
    if (a && b && c && d && e) return true;
    else return false;
  };

  /**
   * onAddClick : Call add or edit function
   */
  onAddClick = async () => {
    if (this.validateAll()) {
      if (this.state.isEditClicked) {
        this.editFurniture();
      } else {
        this.handleAddFurniture();
      }
    }
  };

  /* ----------------------- Delete furniture from list ----------------------- */
  deleteFurniture = async (item) => {
    const reqBody = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: item.siteid,
      phonenum: this.props.siteDetails.phonenum,
      furnitureid: item.furnitureid,
    };
    await this.props.DeleteFurniture(reqBody);
    if (this.props.deleteFurnitureStatus === 200) {
      this.props.clearFurnitureLogs();
      this.setState({
        selectedRoom: null,
        selectedFurniture: null,
        selectedProduct: null,
        productVol: "",
        imageUploaded: [],
      });
      this.props.ShowAlert({
        msg: langData[this.lan].furnitureDelete,
        alertType: "Success",
      });
    }
  };

  /**
   * handleEditFurniture : Function to set input fields data
   */
  handleEditFurniture = async (furniture) => {
    // if (this.props.siteDetails.sitestatus) return;
    const element = document.getElementById("step2-inputs");
    element && element.scrollIntoView({ behavior: "smooth" });

    const value = furniture.roomname;
    const tempArr = this.selectRoomType(value);

    let imageArray = [];
    for (let i = 0; i < furniture.furnitureImages.length; i++) {
      const temp = furniture.furnitureImages[i];
      imageArray.push({ ...temp });
    }

    this.setState({
      selectedRoom: {
        value: furniture.roomname,
        label: furniture.roomname,
      },
      selectedFurniture: {
        value: furniture.furniturename,
        label: furniture.furniturename,
      },
      selectedProduct: {
        value: furniture.productname,
        label: furniture.productname,
      },
      productVol: furniture.productvolume,
      imageUploaded: imageArray,
      isEditClicked: true,
      furnitureToEditId: furniture.furnitureid,
      furnitureArray: tempArr,
      itemToEdit: { ...furniture, furnitureImages: imageArray },
      showAddButton: false,
    });
  };

  /**
   * deleteImageFromCloud : Delete Image from cloud
   */
  deleteImageFromCloud = async (file) => {
    this.props.DeleteImage({ key: [file.imagelink] });
    this.setState({
      imageUploaded: this.state.imageUploaded.filter((image) => image !== file),
    });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images) {
      _.remove(images, (e) => {
        return e === file.imagelink;
      });
    }
    if (images.length < 1) {
      localStorage.removeItem("saveFiles");
    } else {
      this.props.SaveImageFiles(images);
    }
    // this.props.ShowAlert({
    //     msg: langData[this.lan].failedToDel,
    //     alertType: "Error",
    //   });
  };

  /**
   * deleteFurnitureImage : Delete Image from server
   */
  deleteFurnitureImage = async () => {
    const file = this.state.deleteFurnitureImageObject;
    if (file.furnitureimgid) {
      const reqBody = {
        hluserid:
          this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
        requesterId:
          this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
        siteid: this.props.siteDetails.siteid,
        furnitureid: file.furnitureid,
        furnitureimgid: file.furnitureimgid,
        phonenum: this.props.siteDetails.phonenum,
      };
      this.props.StartLoader();
      await this.props.DeleteFurnitureImages(reqBody);
      if (this.props.delFurnitureImageStatus === 200) {
        this.setState({
          imageUploaded: this.state.imageUploaded.filter(
            (image) => image !== file
          ),
          deleteFurnitureImageObject: null,
        });
        this.props.StopLoader();
        this.fileUpload(null, this.state.editImageObject);
      } else {
        this.props.StopLoader();
      }
    }
  };

  handleCloseImagePreview = () => {
    this.setState({ imagePreview: false, images: [] });
  };

  handleOpenImagePreview = (index) => {
    this.setState({
      imagePreview: true,
      images: this.state.imageUploaded,
      imageIndex: index,
    });
  };

  //HANDLE RESET BUTTON
  handleResetBtn = () => {
    const imagesToDelete = this.state.imageUploaded.filter(
      (val) => !val.furnitureimgid
    );
    if (imagesToDelete.length)
      this.props.DeleteImage({
        key: imagesToDelete.map((val) => val.imagelink),
      });
    this.setState({
      selectedRoom: null,
      selectedFurniture: null,
      selectedProduct: null,
      productVol: "",
      imageUploaded: [],
    });
  };

  //HANDLE OPEN POPUP FOR UPLOAD
  handleOpenUploadPopup = (deleteFurnitureImageObject) => {
    this.setState({ openUploadImagePopup: true, deleteFurnitureImageObject });
  };

  //HANDLE CLOSE POPUP
  handleCloseEditImage = () => {
    this.setState({
      openUploadImagePopup: false,
      deleteFurnitureImageObject: null,
      editImageObject: null,
    });
  };

  handleEditImageYes = (editImageObject) => {
    this.setState(
      {
        editImageObject,
      },
      () => this.deleteFurnitureImage()
    );
  };

  showEditButton = () => {
    const { siteDetails, userType } = this.props;
    if (!siteDetails) return false;
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 ||
        siteDetails.sitestatus === 1 ||
        siteDetails.sitestatus === 4)
    ) {
      return true;
    } else if (
      userType !== HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1)
    )
      return true;
    return false;
  };

  reportErrors = () => {
    const { fileErrors } = this.props;
    if (fileErrors && fileErrors.length) {
      const errorString = JSON.stringify(fileErrors);
      const encodedError = encodeURI(errorString);
      console.log(encodedError);

      const blob = new Blob([encodedError], { type: "text" });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = "Errors.log";
      a.click();
    }
  };

  render() {
    const {
      imageUploaded,
      roomError,
      selectedRoom,
      selectedFurniture,
      furError,
      selectedProduct,
      prodError,
      productVol,
      volError,
      imageError,
      furnitureArray,
      dropDEnabled,
      showAddButton,
      openUploadImagePopup,
    } = this.state;
    const { furnitureDetails } = this.props;

    return (
      <div className="step2">
        {/* {this.props.isEditable === 2 ? ( */}

        <div className="step2-editable" id="step2-inputs">
          <div className="field step-2-creatble">
            <div className="label">{langData[this.lan].room}</div>
            <div className="dropdown-inputs">
              <Creatable
                options={Room[this.lan]}
                placeholder={langData[this.lan].roomPh}
                onChange={(e) => {
                  this.onSelectRoom(e);
                }}
                value={selectedRoom}
                className={roomError ? "creatable" : "disabled"}
                isDisabled={dropDEnabled}
              />
              {roomError ? (
                <div className="input-error">
                  {langData[this.lan].roomError}
                </div>
              ) : null}
            </div>
          </div>

          <div className="field step-2-creatble">
            <div className="label">{langData[this.lan].fName}</div>
            <div className="dropdown-inputs">
              <Creatable
                options={furnitureArray}
                placeholder={langData[this.lan].furNamePh}
                onChange={(e) => {
                  this.onSelectFurniture(e);
                }}
                value={selectedFurniture}
                className={furError ? "creatable" : "disabled"}
                isDisabled={dropDEnabled}
              />
              {furError ? (
                <div className="input-error">
                  {langData[this.lan].fNameError}
                </div>
              ) : null}
            </div>
          </div>
          <div className="field step-2-creatble">
            <div className="label">{langData[this.lan].pName}</div>
            <div className="dropdown-inputs">
              <Creatable
                options={Products[this.lan]}
                placeholder={langData[this.lan].prodNamePh}
                onChange={(e) => {
                  this.onSelectProduct(e);
                }}
                value={selectedProduct}
                className={prodError ? "creatable" : "disabled"}
                isDisabled={dropDEnabled}
              />
              {prodError ? (
                <div className="input-error">
                  {langData[this.lan].pNameError}
                </div>
              ) : null}
            </div>
          </div>

          <div className="field">
            <div className="label">{langData[this.lan].prodVol}</div>
            <div className="dropdown-inputs">
              <input
                type="text"
                placeholder={langData[this.lan].prodVolPh}
                onChange={(e) => {
                  this.onSelectVol(e);
                }}
                value={productVol}
                className={volError ? "creatable" : ""}
                disabled={false}
              />
              {volError ? (
                <div className="input-error">{langData[this.lan].volError}</div>
              ) : null}
            </div>
          </div>

          <div className="field image-field">
            <div className="label ">{langData[this.lan].upload} </div>
            <div className="img-container">
              <div className="container">
                <div className="product-img">
                  <input
                    type="file"
                    accept="image/*"
                    className="file-input"
                    id="upload-furniture-image"
                    // capture={true}
                    onChange={(event) => {
                      let file = event.target.files[0];
                      if (file) {
                        this.fileUpload(event, file);
                      }
                    }}
                    disabled={true}
                  />
                  <label
                    htmlFor="upload-furniture-image"
                    className="upload-icon"
                  >
                    <img src={uploadIcon} alt="Upload" />
                  </label>
                </div>
              </div>
              {imageUploaded.length > 0 &&
                imageUploaded.map((file, index) => {
                  return (
                    <div className="container" key={index}>
                      <div className="container-image-div">
                        <img
                          id={`furniture${file.furnitureid}`}
                          className="product-img"
                          src={
                            file.file
                              ? URL.createObjectURL(file.file)
                              : file.expiryLink
                          }
                          //   src={file.expiryLink}
                          key={file.expiryLink}
                          alt="furniture img"
                          onClick={() => this.handleOpenImagePreview(index)}
                          onError={async () => {
                            await this.props.GetExpiryLinkForFurniture(file);
                          }}
                        />
                        {!file.furnitureimgid ? (
                          <img
                            src={delImage}
                            alt="delete"
                            className="delete-icon"
                            onClick={() => this.deleteImageFromCloud(file)}
                          />
                        ) : null}
                      </div>

                      {/* SHOW DELETE ICON FOR */}
                      {imageUploaded.filter((val) => val.furnitureimgid)
                        .length !== 1 && file.furnitureimgid ? (
                        <img
                          className="del-icon"
                          src={editIcon}
                          alt="Edit"
                          onClick={(e) => {
                            this.handleOpenUploadPopup(file);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      ) : null}
                      {/* SHOW DELETE ICON FOR */}
                      {/*  {imageUploaded.filter((val) => val.furnitureimgid)
                        .length !== 1 && file.furnitureimgid ? (
                        <img
                          className="del-icon"
                          src={delIcon}
                          alt="Delete"
                          onClick={() => this.deleteFurnitureImage(file)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : null} */}
                    </div>
                  );
                })}
            </div>
            {imageError ? (
              <div className="input-error">{langData[this.lan].upError} </div>
            ) : null}
          </div>

          {this.showEditButton() && showAddButton ? (
            <div className="field field-btn-wrapper">
              <div
                className="field-btn-add bk-blue"
                onClick={() => {
                  this.onAddClick();
                }}
              >
                {langData[this.lan].add}
              </div>

              <div
                className="field-btn-reset bk-white"
                onClick={this.handleResetBtn}
              >
                {langData[this.lan].reset || langData[0].reset}
              </div>
            </div>
          ) : null}
        </div>

        {/* ADDED FURNITURE DETAILS */}
        {furnitureDetails && furnitureDetails.length > 0 && (
          <div className="step2-table">
            <table>
              <thead>
                <tr>
                  {langData[this.lan].furnitureTable.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {furnitureDetails.length
                  ? furnitureDetails.map((item, index) => (
                      <tr
                        className={
                          index === furnitureDetails.length - 1
                            ? ""
                            : "border_bottom"
                        }
                        // onClick={() => this.handleEditFurniture(item)}
                        key={index}
                      >
                        <td>{item.roomname}</td>
                        <td>{item.furniturename}</td>
                        <td>{item.productname}</td>
                        <td>{item.productvolume}</td>
                        {/* <td>
							{item.furnitureImages && item.furnitureImages.length}{" "}
							{langData[this.lan].images}
						  </td> */}
                        {this.showEditButton() ? (
                          <td>
                            {/*  <img
                              src="/Assets/del-icon.svg"
                              alt="Delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.deleteFurniture(item);
                              }}
                              style={{ cursor: "pointer" }}
                            /> */}
                            <img
                              src={editIcon}
                              alt="Edit"
                              onClick={() => this.handleEditFurniture(item)}
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        ) : null}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        )}

        {openUploadImagePopup && (
          <EditImage
            handleCloseEditImage={this.handleCloseEditImage}
            handleEditImageYes={this.handleEditImageYes}
          />
        )}

        <ReactModal
          onRequestClose={this.handleCloseImagePreview}
          isOpen={this.state.imagePreview}
          className="image-preview"
          overlayClassName="image-preview-overlay"
        >
          <ImagePreview
            handleCloseImagePreview={this.handleCloseImagePreview}
            images={this.state.images}
            imageIndex={this.state.imageIndex}
          />
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // phoneNum: state.site.phoneNum,
    siteid: state.regSites.siteDetails
      ? state.regSites.siteDetails.siteid
      : null,
    addFurnitureStatus: state.furniture.addFurnitureStatus,
    furnitureDetails: state.furniture.furnitureDetails,
    deleteFurnitureStatus: state.furniture.deleteFurnitureStatus,
    editFurnitureStatus: state.furniture.editFurnitureStatus,
    delFurnitureImageStatus: state.furniture.delFurnitureImageStatus,
    siteDetails: state.regSites.siteDetails,
    editRegSiteStatus: state.regSites.editRegSiteStatus,
    getExpiryLink: state.furniture.getExpiryLink,

    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    userId: state.auth.userId,
    registeredSites: state.regSites.registeredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,
    sidebarId: state.auth.sidebarId,

    fileErrors: state.common.fileErrors,
  };
};
export default connect(mapStateToProps, {
  AddFurniture,
  EditFurniture,
  DeleteFurniture,
  clearFurnitureLogs,
  DeleteFurnitureImages,
  ShowAlert,
  StartLoader,
  StopLoader,
  DeleteImage,
  //   GetLinkForUpload,
  GetExpiryLinkForFurniture,
  SaveImageFiles,
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
  SetProgressLoader,
})(Step2);
