import React, { Component } from "react";
import { connect } from "react-redux";

class DSRUserInfo extends Component {
  render() {
	const { userType, userPhoneNum, username } = this.props;
    return (
      <div className="dsr-user-info">
        <div className="name">
          {username}
          <span className="usertype">({userType})</span>
        </div>
        <div className="mobile">
          <img src="/Assets/phn-icon.svg" alt="phn-icon" />
          {userPhoneNum}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    username: state.auth.username,
    userPhoneNum: state.auth.userPhoneNum,
  };
};
export default connect(mapStateToProps)(DSRUserInfo);
