import React, { Component } from "react";
import { connect } from "react-redux";
import { PAGE_SIZE, SSO_TABLE, SSO_USER } from "../../Actions/Types";
import { GetSSOList } from "../../Actions/UsersAction";
import UserTable from "../UserTable";

class SearchSSO extends Component {
  /*  constructor(props) {
    super(props);
  } */

  componentDidMount() {
    this.getSSOList();
  }

  getSSOList = async () => {
    const {
      searchSSODetails,
    //   sortingColumn,
    } = this.props;
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      userType: SSO_USER,
      searchKey: searchSSODetails
        ? searchSSODetails.searchBy.value || undefined
        : undefined,
      searchValue: searchSSODetails ? searchSSODetails.searchQuery.trim() || undefined : undefined,
      /* sortBy: sortingColumn.sortBy || undefined,
      sortOrder:
        sortingColumn.sortOrder === 2
          ? "DESC"
          : sortingColumn.sortOrder === 1
          ? "ASC"
          : undefined, */
    };
    await this.props.GetSSOList(reqBody, this.props.SSOPageNum, PAGE_SIZE);
  };

  render() {
    return (
      <div
        className="dashboard-main-container font-poppins"
        onClick={() => this.setState({ showSearchOptions: true })}
      >
        <UserTable activeTable={SSO_TABLE} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userPhoneNum: state.auth.userPhoneNum,
    searchSSODetails: state.user.searchSSODetails,
    SSOPageNum: state.user.SSOPageNum,
  };
};
export default connect(mapStateToProps,{GetSSOList})(SearchSSO);
