import React, { Component } from "react";
import { connect } from "react-redux";
//Actions
import { GetRegisteredSites } from "../../Actions/RegisteredSites";
//RESOURCES
import descending from "../../Resources/descending.svg";
import ascending from "../../Resources/ascending.svg";

// import ReactPaginate from "react-paginate";
import {
  ClearContractorStatus,
  DashboardGetContractors,
  GetContractorList,
  GetContractorListForHelpline,
} from "../../Actions/ContractorAction";
import { PAGE_SIZE, HELPLINE_USER } from "../../Actions/Types";
import {
  PersistSearchContractorInfo,
  SaveContractorPageNumber,
} from "../../Actions/Common";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import DataNotFound from "../Common/DataNotFound";
import CustomTable from "../CustomTable/CustomTable";
import { ExportSheet } from "../Common/ExportSheet";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import { ExportJSONToSheet } from "../_Utils/ExportJSONToSheet";

/**
 * ContractorList: Component for ContractorList
 */
class ContractorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNum: props.contractorPageNum,
      pageSize: PAGE_SIZE,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
      contractorTableHeader: [
        {
          label: "Contractor BP No.",
          customClass: "BP-id",
          sortOrder: 1,
          sortBy: "registrationnum",
        },
        {
          label: "Contractor Name",
          customClass: "name",
          sortOrder: 1,
          sortBy: "name",
        },
        {
          label: "Contractor No.",
          customClass: "num",
          sortOrder: 1,
          sortBy: "phonenum",
        },
        {
          label: "Region",
          customClass: "address",
          sortOrder: 1,
          sortBy: "region",
        },
        {
          label: "Division",
          customClass: "address",
          sortOrder: 1,
          sortBy: "division",
        },
        {
          label: "Town mapping",
          customClass: "address",
          sortOrder: 1,
          sortBy: "townmapping",
        },
      ],

      searchQuery: "",
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
    };
  }

  componentDidMount() {
    const { searchContractorDetails } = this.props;
    if (searchContractorDetails) {
      this.setState({
        selectedSearchOption: searchContractorDetails.searchBy,
        searchQuery: searchContractorDetails.searchQuery,
        showSearchResults: true,
      });
    }
  }

  getContractorList = async (exportPageSize = null) => {
    // const { searchContractorDetails } = this.props;
    const { sortingColumn, selectedSearchOption, searchQuery } = this.state;
    const { dataFor, userPhoneNum, DSRDetails } = this.props;
    let reqBody = {
      searchKey:
        selectedSearchOption.value && searchQuery
          ? selectedSearchOption.value || undefined
          : undefined,
      searchValue:
        selectedSearchOption.value && searchQuery
          ? searchQuery.trim() || undefined
          : undefined,
      sortBy: sortingColumn.sortBy || undefined,
      sortOrder: sortingColumn.sortOrder
        ? sortingColumn.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
    };

    //MONTH AND YEAR WISE SITES
    if (
      dataFor === "monthWiseContractors" ||
      dataFor === "yearWiseContractors" ||
      dataFor === "totalNoOfContractorsWithSites"
    ) {
      reqBody["requesterPhone"] = userPhoneNum;
      if (dataFor === "monthWiseContractors")
        reqBody["month"] = this.props.month;
      else if (dataFor === "yearWiseContractors")
        reqBody["year"] = this.props.year;
      await this.props.DashboardGetContractors(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    } else if (CheckNonContractorUser(this.props.userType)) {
      await this.props.GetContractorList(
        {
          requesterPhone: DSRDetails ? DSRDetails.phonenum : userPhoneNum,
          ...reqBody,
        },
        exportPageSize ? 0 : this.state.pageNum,
        exportPageSize ? exportPageSize : this.state.pageSize
      );
    } else if (this.props.userType === HELPLINE_USER) {
      await this.props.GetContractorListForHelpline(
        reqBody,
        exportPageSize ? 0 : this.state.pageNum,
        exportPageSize ? exportPageSize : this.state.pageSize
      );
    }
  };

  getDataForPageChange = async () => {
    await this.getContractorList();
    if (this.props.getContractorList === 200) {
      this.props.ClearContractorStatus();
      this.props.SaveContractorPageNumber(this.state.pageNum);
    }
  };

  handlePageChange = (e) => {
    const { selected } = e;
    if (selected === this.props.contractorPageNum) return;
    this.setState({ pageNum: selected }, async () => {
      const { searchContractorDetails } = this.props;
      if (searchContractorDetails) {
        this.getDataForPageChange();
      } else if (
        !searchContractorDetails &&
        (this.state.selectedSearchOption.value || this.state.searchQuery)
      ) {
        this.setState(
          {
            selectedSearchOption: { value: null, label: "Search by" },
            searchQuery: "",
          },
          async () => {
            this.getDataForPageChange();
          }
        );
      } else {
        this.getDataForPageChange();
      }
    });
  };

  //SORTING ICON
  renderSortingIcon = (status) => {
    return (
      <img
        src={status === 1 ? ascending : descending}
        alt="Sort"
        className="sorting-icon"
      />
    );
  };

  //SORTING FOR EACH COLUMN
  handleSortColumn = async (col) => {
    this.setState(
      {
        sortingColumn: col,
        contractorTableHeader: this.state.contractorTableHeader.map((val) => {
          if (col.sortBy === val.sortBy)
            return { ...val, sortOrder: val.sortOrder === 1 ? 2 : 1 };
          else return { ...val, sortOrder: 1 };
        }),
      },
      this.getContractorList
    );
    if (this.props.getContractorList === 200) {
      this.props.ClearContractorStatus();
    }
  };

  //CONTRACTOR DATA
  contractorData = () => {
    const { contractorList } = this.props;
    return contractorList.map((item) => {
      return {
        data: [
          { data: item.registrationnum, customClass: "BP-id" },
          { data: item.name, customClass: "name" },
          { data: item.phonenum, customClass: "num" },
          { data: item.region, customClass: "address" },
          { data: item.division, customClass: "address" },
          { data: item.townmapping, customClass: "address" },
        ],
        actualObject: item,
      };
    });
  };

  /* ----------------------- SEARCH FOR CONTRACTOR LIST ----------------------- */
  handleSearchContractor = async (e) => {
    if (e.keyCode === 13) {
      this.getContractorSites();
    }
  };

  handleSearchChange = (e) => {
    if (this.props.userType === HELPLINE_USER) {
      this.setState(
        { searchQuery: e.target.value }
        /*  () => {
        if (
          this.state.searchQuery.trim().length === 10 ||
          this.state.searchQuery.trim().length === 12
        ) {
          this.props.userType === HELPLINE_USER && this.getContractorSites();
          PersistSearchContractorInfo({
            searchBy: null,
            searchQuery: this.state.searchQuery,
          });
        } 
      }*/
      );
    } else this.setState({ searchQuery: e.target.value });
  };

  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.getContractorList();
        this.props.SaveContractorPageNumber(0);
        this.props.PersistSearchContractorInfo(null);
      }
    );
  };

  handleSearch = async () => {
    await this.getContractorList();
    if (this.props.getContractorList === 200) {
      this.props.ClearContractorStatus();
      this.setState({ showSearchResults: true });
      this.props.SaveContractorPageNumber(0);
      this.props.PersistSearchContractorInfo({
        searchBy: this.state.selectedSearchOption,
        searchQuery: this.state.searchQuery,
      });
    }
  };


  getExportableData=async ()=>{
	await this.getContractorList(this.props.countOfData);
    if (this.props.getContractorList === 200) {
      this.props.ClearContractorStatus();
      const { contractorList } = this.props;
      let dataToExport = [];
       if (contractorList) {
        for (let i = 0; i < contractorList.length; i++) {
          const contractor = contractorList[i];
          let contractorDetails = {};
          contractorDetails["Contractor name"] = contractor.name;
          contractorDetails["Contractor BP Number"] =
            contractor.registrationnum;
          contractorDetails["Contractor Number"] = contractor.phonenum;
          contractorDetails["Region"] = contractor.region;
          contractorDetails["Division"] = contractor.division;
          contractorDetails["Town Mapping"] = contractor.townmapping;
          dataToExport.push(contractorDetails);
        }
        ExportJSONToSheet(dataToExport, "Contractors", 6);
      } 
    }
  }

  //handle export data button
  handleExportData = async () => {
	const { searchContractorDetails } = this.props;
      if (searchContractorDetails) {
        this.getExportableData();
      } else if (
        !searchContractorDetails &&
        (this.state.selectedSearchOption.value || this.state.searchQuery)
      ) {
        this.setState(
          {
            selectedSearchOption: { value: null, label: "Search by" },
            searchQuery: "",
          },
          async () => {
            this.getExportableData();
          }
        );
      } else {
        this.getExportableData();
      }
  };

  render() {
    const {
      contractorList,
      countOfData,
      searchable = true,
      sorting = true,
      exportable = true,
    } = this.props;
    const { pageSize, contractorTableHeader } = this.state;

    /* const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0; */

    const { selectedSearchOption, searchQuery, showSearchResults } = this.state;

    return (
      <React.Fragment>
        {/* ADVANCE SEARCH FOR CONTRACTOR LIST */}
        {searchable && (
          <AdvanceSearch
            searchQuery={searchQuery}
            selectedSearchOption={selectedSearchOption}
            showSearchResults={showSearchResults}
            searchOptionsFor="contractor"
            showClearButton={this.props.searchContractorDetails}
            handleSearch={this.handleSearch}
            handleInputChange={this.handleSearchChange}
            handleClearSearch={this.handleClearSearch}
            handleSearchByOptionSelection={this.handleSearchByOptionSelection}
            handleClearAllSearch={this.handleClearAllSearch}
          />
        )}

        {/* EXPORT DATA */}
        {exportable && this.props.countOfData ? (
          <ExportSheet onClickHandler={this.handleExportData} />
        ) : null}

        {contractorList && contractorList.length ? (
          <CustomTable
            tableHeader={contractorTableHeader}
            tableBody={this.contractorData()}
            countOfData={countOfData}
            pageNum={this.props.contractorPageNum}
            pageSize={pageSize}
            paginationId="contractor-pagination"
            pagination={true}
            handlePageChange={this.handlePageChange}
            rowClickHandler={this.props.handleSelectContractor}
            rowClickable={true}
            handleSort={this.handleSortColumn}
            tableWrapperClass="contractor-table-wrapper"
            sortable={sorting}
          />
        ) : (
          <DataNotFound msg="Contractor not found" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.auth.userType,
    contractorList: state.contractor.contractorList,
    isLast: state.contractor.isLast,
    userPhoneNum: state.auth.userPhoneNum,

    contractorPageNum: state.common.contractorPageNum,

    getContractorList: state.contractor.getContractorList,
    countOfData: state.contractor.countOfData,
    searchContractorDetails: state.common.searchContractorDetails,
  };
};
export default connect(mapStateToProps, {
  GetRegisteredSites,
  GetContractorList,
  ClearContractorStatus,
  SaveContractorPageNumber,
  PersistSearchContractorInfo,
  DashboardGetContractors,
  GetContractorListForHelpline,
})(ContractorList);

/* <div className="contractor-table-wrapper">
<div className="contractor-table" id="site-table">
  <div className="contractor-table-header">

	<div className="contractor-table-header-item id">
	  {langData[lan].contractorBPNo}
	  {this.renderSortingIcon(1)}
	</div>
	<div className="contractor-table-header-item name">
	  {langData[lan].contractorName}
	  {this.renderSortingIcon(1)}
	</div>
	<div className="contractor-table-header-item num">
	  Contractor No.
	  {this.renderSortingIcon(1)}
	</div>
	<div className="contractor-table-header-item num">
	  Region
	  {this.renderSortingIcon(1)}
	</div>
	<div className="contractor-table-header-item num">
	  Division
	  {this.renderSortingIcon(1)}
	</div>
	<div className="contractor-table-header-item num">
	  Town mapping
	</div>
  </div>
  <div className="contractor-table-body">
	{contractorList.map((item, index) => {
	  return (
		<div className="contractor-table-data" key={index}>
		  <div
			className="contractor-table-data-row"
			onClick={() => this.props.handleSelectContractor(item)}
		  >

			<div className="BP-id">{item.registrationnum}</div>

			<div className="name">{item.name}</div>

			<div className="num">{item.phonenum}</div>
			<div className="address">{item.region || "--"}</div>
			<div className="address">{item.division || "--"}</div>
			<div className="address">{item.townmapping || "--"}</div>
		  </div>
		</div>
	  );
	})}
  </div>

</div>
</div> 

<div className="pagination-wrapper">
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            initialPage={pageNum}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={Math.ceil(countOfData / PAGE_SIZE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={this.handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
*/
