//Import types
import {
  SET_ALERT,
  REMOVE_ALERT,
  RESET_STATE,
  START_LOADER,
  STOP_LOADER,
  SAVE_FILES,
  SAVE_PAGE_NUMBER,
  GET_DOC_EXPIRY_LINK,
  CLEAR_COMMON_STATUS,
  SAVE_CONTRACTOR_PAGE_NUMBER,
  SET_SEARCH_SITE_INFO,
  CLEAR_ALL_SEARCH_INFO,
  SET_SEARCH_CONTRACTOR_INFO,
  SET_SITE_SORTING_COLUMN,
  SET_PROGRESS_LOADER,
  SAVE_FILE_ERROR_LOG,
} from "../Actions/Types";

const INIT_STATE = {
  alertMsg: null,
  alertType: null,

  docExpiryLink: null,
  getDocumentExpiryLink: null,

  //SEARCH DETAILS
  searchSiteDetails: null,
  searchContractorDetails: null,

  //PAGE NUMBERS
  pageNum: 0,
  contractorPageNum: 0,

  siteSortDetails: null,
  progressLoader: { state: false, percentage: 0 },

  fileErrors: [],
};

const CommonReducer = (state = INIT_STATE, action) => {
  var { type, payload } = action;

  switch (type) {
    //to set the alert with msg and type
    case SET_ALERT:
      return {
        ...state,
        alertMsg: payload.alertMsg,
        alertType: payload.alertType,
      };

    //to remove the alert
    case REMOVE_ALERT:
      return {
        ...state,
        alertMsg: null,
        alertType: null,
      };

    case START_LOADER:
      return {
        ...state,
        loaderState: true,
      };

    case STOP_LOADER:
      return {
        ...state,
        loaderState: false,
      };

    case SET_PROGRESS_LOADER:
      return {
        ...state,
        progressLoader: payload,
      };

    case SAVE_FILES:
      return {
        ...state,
        savedFiles: payload,
      };
    case SAVE_PAGE_NUMBER:
      return {
        ...state,
        pageNum: payload,
      };
    case SAVE_CONTRACTOR_PAGE_NUMBER:
      return {
        ...state,
        contractorPageNum: payload,
      };

    case SET_SEARCH_SITE_INFO:
      return {
        ...state,
        searchSiteDetails: payload,
      };

    case SET_SEARCH_CONTRACTOR_INFO:
      return {
        ...state,
        searchContractorDetails: payload,
      };

    case GET_DOC_EXPIRY_LINK:
      return {
        ...state,
        docExpiryLink: payload.server.data,
        getDocumentExpiryLink: payload.server.success,
      };

    case SET_SITE_SORTING_COLUMN:
      return {
        ...state,
        siteSortDetails: payload,
      };

    case SAVE_FILE_ERROR_LOG:
      return {
        ...state,
        fileErrors: state.fileErrors
          ? [...state.fileErrors, payload]
          : [payload],
      };

    case CLEAR_ALL_SEARCH_INFO:
      return {
        ...state,
        searchSiteDetails: null,
        searchContractorDetails: null,
      };

    case CLEAR_COMMON_STATUS:
      return {
        ...state,
        getDocumentExpiryLink: null,
      };

    //reset the state
    case RESET_STATE:
      return { ...INIT_STATE };
    default:
      return state;
  }
};

export default CommonReducer;
