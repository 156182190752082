/*  Language codes for languages
1. English
2. Hindi
3. Tamil
4. Telugu
5. Kannada
6. Malayalam
7. Assamese
8. Bengali
9. Oriya
 */

/*
 * Language data for Step1 form
 */
export const langData = [
  //English object
  {
    //Form Labels====================================================================
    //for step1
    name: "Customer Name",
    email: "Email ID",
    phone: "Phone Number",
    pincode: "Pincode",
    address: "Customer Address",
    dCode: "Dealer Code",
    dName: "Dealer Name",
    dPhone: "Dealer Phone No.",
    siteValue: "Expected Site Value",
    //for step3
    room: "Room",
    fName: "Furniture Name",
    pName: "Product Name",
    prodVol: "Volume",
    upload: "Upload Furniture Images",
    //for step3
    upInvoice: "Upload Dealer Invoices",
    upProdImg:
      " Upload image of products at site including Filler/Thinner/Stains",

    //Error Indicators ====================================================================
    //For Step1
    nameError: "Please enter valid name",
    emailError: "Enter correct email id",
    phoneError: "Please enter valid phone number",
    pinError: "Please enter 6 digit valid pincode",
    // addressError: "Please add address",
    dCodeError: "Please enter 6 digit dealer code",
    dNameError: "Please provide dealer name",
    dPhoneError: "Please provide dealer phone number",
    siteError: "Please enter site value",

    //step2
    roomError: "Please select room",
    fNameError: "Please select furniture name",
    pNameError: "Please select product",
    upError: "Please upload images",

    //step3
    upInvoiceEr: "Please upload dealer invoices",
    upProdImgEr: "Please upload product images",

    //Placeholders=============================================================================================
    //For step1
    namePh: "Enter customer name",
    emailPh: "Enter customer email id eg. username@gmail.com",
    contactPh: "Enter customer phone number",
    pinPh: "Enter area pincode e.g. 411407",
    addressPh: "Enter customer address",
    dealerCodePh: "Enter 6 digit dealer code",
    dealerNamePh: "Dealer name",
    dealerPhonePh: "Dealer phone number",
    siteValuePh: "Enter expected site value",
    //For step2
    roomPh: "Select room",
    furNamePh: "Select furniture",
    prodNamePh: "Select product",

    //Table Headers ==================================================================================
    //For furniture table
    furnitureTable: [
      "Room Name",
      "Furniture Name",
      "Product Name",
      "Volume",
      //   "Upload Furniture image",
      "",
    ],

    //For site list table
    status: "Status",
    leadId: "Lead ID",
    date: "Date Of Registration",
    custName: "Customer Name",
    contactNo: "Contact Number",
    pinCode: "Pincode",
    expSiteValue: "Expected Site Value",

    //Buttons
    save: "Save",
    submit: "Submit",
    prev: "Previous",
    next: "Next",
    add: "Add",
    cancel: "Cancel",
    newRegistration: "New Registration",
    yes: "Yes",
    no: "No",
    //Static text
    siteRegistration: "Site Registration",
    images: "images",
    registeredSites: "Registered Site",
    siteClosure: "Close Site",
    closed: "Closed",
    nonClosed: "Open",
    pending: "Pending",

    /*  step1: "1st Step",
    step2: "2st Step",
    step3: "3rd Step", */

    step1: "Site Details",
    step2: "Furniture Details",
    step3: "Supporting Documents",

    closeSiteConf: "Do you want to close this site?",
    closeSiteConfDataLoss:
      "Do you want to clear the data and initiate warranty generation?",
    clearDataConf: "Unsaved Data will be lost. Want to continue?",
    none: "--",
    //Notification Messages
    //For site
    siteEdit: "Site updated Successfully", //=>changed
    siteAdd: "Site Registered Successfully. Lead ID for site: ",
    siteClose: "Site Closed",
    //For furniture
    furnitureAdd: "Furniture added successfully",
    furnitureEdit: "Furniture updated successfully", //updated
    furnitureDelete: "Furniture deleted successfully",
    furnitureImageDelete: "Furniture image deleted successfully", ///	===>new added

    //Product Images
    addProdImgs: "Products images added",
    editProdImgs: "Product Images and dealer invoice updated successfully", //updated
    deleteProdImgs: "Product image deleted",
    deleteDealerInvoice: "Dealer invoice deleted",
    step3Success: "Warranty Request Sent",

    //Fail Response
    failedToUp: "Failed to upload. Please try again later",
    failedToDel: "Failed to delete. Please try again later",

    //Invalid popup
    tokenMiss: "Token is missing or not valid.",
    tokenExp: "Token expired!",
    attention: "Attention",

    //Thank you page
    thankYou: "Thank you",
    submitMsg: "Your information has been submitted",
    openWp: "Open whatsapp",
    siteStatus: "Site status",

    /* ----------------------- NEWLY ADDED AFTER DSR ROLE ----------------------- */
    contractorBPNo: "Contractor BP No.",
    contractorName: "Contractor Name",
    contractorNumber: "Contractor Number",
    warrantyId: "Warranty ID",
    siteCompletionDate: "Site completion date",
    siteSubmissionDate: "Site submission date",
    warrantyDoc: "Warranty document",

    // STEP 1 FORM LABELS AND PLACEHOLDER   ---> removed
    contractorBPNoPh: "Enter contractor BP number",
    contractorNamePh: "Contractor name",
    contractorNumberPh: "Contractor phone number",

    //ERRORS     ---> removed
    contractorBPNoError: "Please enter valid BP number",
    contractorPhoneError: "Contact number is not valid",
    contractorNameError: "Name is not valid",

    // SITE REVIEW
    // SiteCompletionDate:"Site completion date",

    //REMOVED
    remark: "Remark",
    remarkPh: "Enter remark",
    dateLabel: "Date",

    siteReviewQue1: "DSR Site Visit during Work-In-Progress",
    siteReviewQue2: "DSR Site Visit after Site Completion",
    siteReviewQue3: "20ltr of PU+ and Ingenio product used",
    siteReviewQue4: "Right System used",
    siteReviewQue5: "Site registered within 1 month of completion",

    reset: "Reset",

    //------------
    noSites: "Sites are not registered",
    review: "Review",

    //newly added
    initiateWarrantyGeneration: "Initiate warranty generation",
    siteUnderReview: "This site is under review",
    initiateSiteWarrantyConf:
      "Are you sure you want to initiate warranty generation?",

    warrantyClaimDoc: "Warranty Claim Doc",
    customerEmail: "Customer Email",
    customerPin: "Customer Pin",

    //SITE STATUS
    warrantyIssue: "Warranty Issued",
    warrantyClaim: "Warranty Claimed",
    underReview: "Under Review",
	droppedSite:"Dropped Site",

    WarrantyIssuedMsg:
      "Warranty id <Warranty number> is generated against <LeadID> for <CustomerName> registered on <RegistrationDate>",
    WarrantyClaimedMsg:
      "Warranty has been claimed against <Warranty number> <LeadID> for <CustomerName> registered on <RegistrationDate>",

    //advance search options
    advanceSearchOptions: {
      siteStatus: "Enter site status",
      leadId: "Enter 6 digit lead id",
      dealerName: "Enter dealer name",
      dealerPhone: "Enter dealer phone number",
      warrantyId: "Enter warranty ID",
    },
    search: "Search",
    clear: "Clear",
    searchQuery: "Enter search query",
    searchBy: "Search By",
    warrantyClaimRemark: "Warranty claim remark",
    warrantyClaimDocument: "Warranty claim document",

    volError: "Please enter the volume as a number",
    prodVolPh: "Enter volume(in Litres)",
    uploadButton: "Upload",
    editImageNote:
      "The selected image will get replaced with the existing one. Please confirm before upload",

    //language change for dropped site
    droppedSiteButton: "Dropped site",
    droppedRemark: "Dropped remark",
    dropSiteRemarkPh: "Enter dropped remark",
    droppedSiteSuccess: "Dropped site remark added successfully",
  },
  //Hindi object
  {
    //Form Labels====================================================================
    //for step1
    name: "कस्टमर का नाम",
    email: "ईमेल आईडी",
    phone: "फोन नंबर",
    pincode: "पिन कोड",
    address: "कस्टमर का पता",
    dCode: "डीलर कोड",
    dName: "डीलर का नाम",
    dPhone: "डीलर का फोन नंबर",
    siteValue: "अनुमानित साइट वैल्यू",
    //for step3
    room: "रूम",
    fName: "फर्नीचर का नाम",
    pName: "प्रोडक्ट का नाम",
    prodVol: "मात्रा",
    upload: "फर्नीचर की तस्वीर अपलोड करें",
    //for step3
    upInvoice: "डीलर की इन्वॉइस अपलोड करें",
    upProdImg:
      " साइट पर मौजूद फिलर/थिनर/स्टेन्स सहित अन्य प्रोडक्ट्स की तस्वीर अपलोड करें",

    //Error Indicators ====================================================================
    //For Step1
    nameError: "कृपया सही नाम एंटर करें",
    emailError: "कृपया सही ईमेल आईडी एंटर करें",
    // phoneError: "कृपया सही फोन नंबर एंटर करें",
    // pinError: "कृपया पिन कोड एंटर करें",
    // addressError: "Please add address",
    dCodeError: "कृपया 6 अंकों वाला डीलर कोड एंटर करें",
    dNameError: "कृपया डीलर का नाम डालें",
    dPhoneError: "कृपया डीलर का फोन नंबर डालें",
    siteError: "कृपया साइट वैल्यू एंटर करें",

    //step2
    roomError: "कृपया रूम चुनें",
    // fNameError: "कृपया फर्नीचर चुनें",
    pNameError: "कृपया प्रोडक्ट चुनें",
    volError: "कृपया मात्रा चुनें",
    upError: "कृपया तस्वीरें अपलोड करें",

    //step3
    upInvoiceEr: "कृपया डीलर इन्वॉइस अपलोड करें",
    upProdImgEr: "कृपया प्रोडक्ट की तस्वीरें अपलोड करें",

    //Placeholders=============================================================================================
    //For step1
    namePh: "कस्टमर का नाम एंटर करें",
    emailPh: "कस्टमर की ईमेल आईडी एंटर करें, जैसे username@gmail.com",
    contactPh: "कस्टमर का फोन नंबर एंटर करें",
    pinPh: "एरिया पिन कोट एंटर करें, जैसे 41140",
    addressPh: "कस्टरम का पता एंटर करें",
    dealerCodePh: "6 अंकों का डीलर कोड एंटर करें",
    dealerNamePh: "डीलर का नाम",
    dealerPhonePh: "डीलर का फोन नंबर",
    siteValuePh: "अनुमानित साइट वैल्यू एंटर करें",

    //For step2
    roomPh: "रुम चुनें",
    furNamePh: "फर्नीचर चुनें",
    prodNamePh: "प्रोडक्ट चुनें",
    prodVolPh: "मात्रा एंटर करें",

    //Table Headers ==================================================================================
    //For furniture table
    furnitureTable: [
      "रुम का नाम",
      "फर्नीचर का नाम",
      "प्रोडक्ट का नाम",
      "मात्रा",
      //   "Upload Furniture image",
      "",
    ],

    //For site list table
    status: "स्टेटस",
    leadId: "लीड आईडी",
    date: "रजिस्ट्रेशन की तारीख",
    custName: "कस्टमर का नाम",
    contactNo: "कॉन्टैक्ट नंबर",
    pinCode: "पिन कोड",
    expSiteValue: "अनुमानित साइट वैल्यू",

    //Buttons
    save: "सेव करें",
    submit: "सबमिट करें",
    prev: "पीछे",
    next: "आगे",
    add: "ऐड करें",
    cancel: "कैंसल करें",
    newRegistration: "नया रजिस्ट्रेशन",
    yes: "हां",
    no: "ना",
    //Static text
    siteRegistration: "साइट रजिस्ट्रेशन",
    images: "तस्वीरें",
    registeredSites: "रजिस्टर्ड साइट",
    siteClosure: "साइट बंद करें",
    closed: "बंद कर दी गई",
    nonClosed: "खोलें",
    pending: "बाकी",
    step1: "स्टेप 1",
    step2: "स्टेप 2",
    step3: "स्टेप 3",
    closeSiteConf: "क्या आप इस साइट को बंद करना चाहते हैं?",
    closeSiteConfDataLoss:
      "क्या आप यह डेटा हटा कर इस साइट को बंद करना चाहते हैं? ",
    clearDataConf:
      "बिना सेव किया गया डेटा मिट जाएगा। क्या आप जारी रखना चाहते हैं? ",
    none: "--",
    //Notification Messages
    //For site
    siteEdit: "साइट को अपडेट किया जा चुका है", //=>changed
    siteAdd: "साइट को रजिस्टर किया जा चुका है। साइट की लीड आईडीः",
    siteClose: "साइट बंद कर दी गई",
    //For furniture
    furnitureAdd: "फर्नीचर को ऐड कर दिये गये हैं",
    furnitureEdit: "फर्नीचर को अपडेट कर दिया गया है", //updated
    furnitureDelete: "फर्नीचर को डिलीट कर दिया गया है",
    furnitureImageDelete: "फर्नीचर की तस्वीर डिलीट हो चुकी है", ///	===>new added

    //Product Images
    addProdImgs: "प्रोडक्ट की तस्वीरें ऐड कर दी गई है",
    editProdImgs: "प्रोडक्ट की तस्वीरें और डीलर इन्वॉइसेज़ अपडेट कर दी गई है", //updated
    deleteProdImgs: "प्रोडक्ट की तस्वीर डिलीट कर दी गई है",
    deleteDealerInvoice: "डीलर इन्वॉइस डिलीट कर दी गई है",
    step3Success: "वारंटी रिक्वेस्ट भेजा गया",

    //Fail Response
    failedToUp: "अपलोड नहीं हो पाया। कृपया बाद में दोबारा कोशिश करें",
    failedToDel: "डिलीट नहीं हो पाया। कृपया बाद में दोबारा कोशिश करें",

    //Invalid popup
    tokenMiss: "टोकन मौजूद नहीं है या अमान्य है। ",
    tokenExp: "टोकन एक्सपायर हो चुका है!",
    attention: "ध्यान दें",

    //Thank you page
    thankYou: "धन्यवाद",
    submitMsg: "आपकी जानकारी सबमिट हो चुकी है",
    openWp: "वॉट्सऐप खोलें",
    // siteStatus: "साइट स्टेटस ",
    //newly added
    WarrantyClaimedMsg:
      "<RegistrationDate> को रजिस्टर किये गए <CustomerName> के लिए <LeadID> <Warranty number> के समक्ष वारंटी क्लेम की जा चुकी है",
    WarrantyIssuedMsg:
      "<RegistrationDate> को रजिस्टर किये गए <CustomerName> के लिए <LeadID> के समक्ष वारंटी आईडी <Warranty number> जारी की गई",

    contractorBPNo: "कॉन्ट्रैक्टर का बीपी नं",
    contractorName: "कॉन्ट्रैक्टर का नाम",
    contractorNumber: "कॉन्ट्रैक्टर का नंबर",
    customerEmail: "कस्टमर का ईमेल",
    customerPin: "कस्टमर का पिन",
    fNameError: "कृपया फर्नीचर का नाम चुनें",
    initiateSiteWarrantyConf:
      "क्या आप वाकई वारंटी जारी करने की प्रक्रिया शुरु करना चाहते हैं",
    initiateWarrantyGeneration: "वारंटी जारी करना शुरु करें",
    mandatoryFieldsError: "कृपया सबमिट करने के लिए अनिवार्य जानकारी डालें",
    noSites: "साइट्स रजिस्टर नहीं की गई",
    phoneError: "कृपया सही फोन नंबर एंटर करें",
    pinError: "कृपया 6 अंक का सही पिनकोड एंटर करें",
    reset: "रिसेट करें",
    review: "रिव्यू करें",
    searchNotFound: "सर्च विवरण के अनुसार कोई साइट्स नहीं मिली",
    siteCompletionDate: "साइट पूरी करने की तारीख",
    siteStatus: "साइट का स्टेटस",
    siteSubmissionDate: "साइट सबमिट करने की तारीख",
    siteUnderReview: "यह साइट रिव्यू की जा रही है",
    underReview: "रिव्यू किया जा रहा",
    warrantyClaim: "वारंटी क्लेम",
    warrantyClaimDoc: "वारंटी क्लेम डॉक्यूमेंट",
    warrantyDoc: "वारंटी डॉक्यूमेंट",
    warrantyId: "वारंटी आईडी",
    warrantyIssue: "वारंटी जारी की गई",
    wood4Sure: "वुड4श्योर",

    advanceSearchOptions: {
      siteStatus: "साइट का स्टेटस एंटर करें",
      leadId: "6 अंक की लीड आईडी एंटर करें",
      dealerName: "डीलर का नाम एंटर करें",
      dealerPhone: "डीलर का फोन नंबर एंटर करें",
      warrantyId: "वारंटी आईडी एंटर करें",
    },
    search: "खोजें",
    clear: "हटाएं / मिटाएं",
    searchQuery: "खोज प्रश्न एंटर करें",
    searchBy: "खोजें",
    warrantyClaimRemark: "वारंटी क्लेम रिमार्क",
    warrantyClaimDocument: "वारंटी क्लेम डॉक्यूमेंट",
  },
  //Tamil Obj
  {
    add: "சேர்க்க",
    addProdImgs: "புராடெக்ட்களின் புகைப்படங்கள் சேர்க்கப்பட்டன",
    address: "கஸ்டமர் முகவரி",
    addressPh: "கஸ்டமர் முகவரியை உள்ளிடவும்",
    attention: "கவனத்திற்கு",
    cancel: "கேன்சல் செய்க",
    clearDataConf:
      "சேவ் செய்யப்படாத டேட்டாக்கள் இழக்கக்கூடும். தொடர விரும்புகிறீர்களா?",
    closeSiteConf:
      "இந்த சைட்டின் செயல்பாட்டை முடித்துக் கொள்ள விரும்புகிறீர்களா?",
    closeSiteConfDataLoss:
      "டேட்டாவை அழித்து, சைட் செயல்பாட்டை முடித்துக் கொள்ள விரும்புகிறீர்களா?",
    closed: "முடிக்கப்பட்டது",
    contactNo: "கான்டாக்ட் நம்பர்",
    contactPh: "கஸ்டமர் போன் நம்பரை உள்ளிடவும்",
    custName: "கஸ்டமர் பெயர்",
    dCode: "டீலர் கோடு",
    dCodeError: "6 இலக்கு டீலர் கோடு உள்ளிடவும்",
    dName: "டீலர் பெயர்",
    dNameError: "டீலர் பெயரை பதிவிடவும்",
    dPhone: "டீலர் போன் நம்பர்",
    dPhoneError: "டீலர் போன் நம்பரை பதிவிடவும்",
    date: "ரிஜிஸ்ட்ரேஷன் தேதி",
    dealerCodePh: "6 இலக்க டீலர் கோடு உள்ளிடவும்",
    dealerNamePh: "டீலர் பெயர்",
    dealerPhonePh: "டீலர் போன் நம்பர்",
    deleteDealerInvoice: "டீலர் இன்வாய்ஸ் டெலிட் செய்யப்பட்டது",
    deleteProdImgs: "புராடெக்ட் புகைப்படம் டெலிட் செய்யப்பட்டது",
    edit: "எடிட் செய்க",
    editProdImgs:
      "புராடெக்ட் புகைப்படங்கள் மற்றும் டீலர் இன்வாய்ஸ்கள் வெற்றிகரமாக அப்டேட் செய்யப்பட்டன",
    email: "இமெயில் ஐடி",
    emailError: "சரியான இமெயில் ஐடியை உள்ளிடவும்",
    emailPh: "கஸ்டமர் இமெயில் ஐடி உள்ளிடவும், எ.கா: username@gmail.com",
    expSiteValue: "தோராயமான சைட் வாலியூ",
    fName: "ஃபர்னிச்சர் பெயர்",
    // fNameError: "பர்னிச்சரை தேர்வு செய்யவும்",
    failedToDel:
      "டெலிட் செய்யப்படவில்லை. சிறிதுநேரத்திற்கு பின் மீண்டும் முயற்சிக்கவும்",
    failedToUp:
      "அப்லோடு செய்யப்படவில்லை. சிறிதுநேரத்திற்கு பின் மீண்டும் முயற்சிக்கவும்",
    furNamePh: "பர்னிச்சர் தேர்வு செய்யவும்",
    furnitureAdd: "வெற்றிகரமாக பர்னிச்சர் சேர்க்கப்பட்டது",
    furnitureDelete: "வெற்றிகரமாக பர்னிச்சர் டெலிட் செய்யப்பட்டது",
    furnitureEdit: "வெற்றிகரமாக பர்னிச்சர் அப்டேட் செய்யப்பட்டது",
    furnitureImageDelete:
      "பர்னிச்சர் புகைப்படம் வெற்றிகரமாக டெலிட் செய்யப்பட்டது",
    images: "புகைப்படங்கள்",
    leadId: "லீட் ஐடி",
    name: "கஸ்டமர் பெயர்",
    nameError: "சரியான பெயரை உள்ளிடவும்",
    namePh: "கஸ்டமர் பெயரை உள்ளிடவும்",
    newRegistration: "புதிய ரிஜிஸ்ட்ரேஷன்",
    next: "பிறகு",
    no: "இல்லை",
    nonClosed: "திறக்கவும்",
    openWp: "வாட்ஸ்அப்பை திறக்க",
    pName: "புராடெக்ட் பெயர்",
    pNameError: "புராடெக்ட்டை தேர்வு செய்யவும்",
    pending: "நிலுவை",
    phone: "போன் நம்பர்",
    // phoneError: "சரியான போன் நம்பரை உள்ளிடவும்",
    pinCode: "பின்கோடு",
    // pinError: "பின் கோடு உள்ளிடவும்",
    pinPh: "ஏரியா பின்கோடு உள்ளிடவும், எ.கா: 41140",
    pincode: "பின்கோடு",
    prev: "முந்தையது",
    prodNamePh: "புராடெக்ட் தேர்வு செய்யவும்",
    prodVol: "எண்ணிக்கை",
    prodVolPh: "எண்ணிக்கையை உள்ளிடவும்",
    registeredSites: "ரிஜிஸ்டர் செய்யப்பட்ட சைட்",
    room: "ரூம்",
    roomError: "ரூமை தேர்வு செய்யவும்",
    roomPh: "ரூம் தேர்வு செய்யவும்",
    save: "சேமிக்கவும்",
    siteAdd: "வெற்றிகரமாக சைட் ரிஜிஸ்டர் செய்யப்பட்டது. சைட்டிற்கான லீட் ஐடி:",
    siteClose: "சைட் முடித்துக் கொள்ளப்பட்டது",
    siteClosure: "சைட்டை முடித்தல்",
    siteEdit: "வெற்றிகரமாக சைட் அப்டேட் செய்யப்பட்டது",
    siteError: "சைட் வாலியூவை உள்ளிடவும்",
    siteRegistration: "சைட் ரிஜிஸ்ட்ரேஷன்",
    siteValue: "தோராயமான சைட் வாலியூ",
    siteValuePh: "தோராயமான சைட் வாலியூவை உள்ளிடவும்",
    status: "ஸ்டேட்டஸ்",
    step1: "முதல் ஸ்டெப்",
    step2: "2வது ஸ்டெப்",
    step3: "3வது ஸ்டெப்",
    step3Success: "வாரின்டி கோரிக்கை அனுப்பப்பட்டது",
    submit: "சப்மிட் செய்க",
    submitMsg: "உங்கள் தகவல்கள் சப்மிட் செய்யப்பட்டது",
    thankYou: "நன்றி",
    tokenExp: "டோக்கன் எக்ஸ்பரி ஆகிவிட்டது!",
    tokenMiss: "டோக்கன் இல்லை அல்லது செல்லத்தக்கதாக இல்லை.",
    upError: "புகைப்படங்களை பதிவேற்றவும்",
    upInvoice: "டீலர் இன்வாய்ஸ் பதிவேற்றவும்",
    upInvoiceEr: "டீலர் இன்வாய்ஸ்களை பதிவேற்றவும்",
    upProdImg:
      "சைட்டில் உள்ள ஃபில்லர்/தின்னர்/ஸ்டைன்ஸ் உள்ளிட்ட புராடெக்ட்களின் புகைப்படங்களை அப்லோடு செய்யவும்",
    upProdImgEr: "புராடெக்ட் புகைப்படங்களை பதிவேற்றவும்",
    upload: "பர்னிச்சர் புகைப்படங்களை பதிவேற்றவும்",
    volError: "அளவை தேர்வு செய்க",
    windows: "ஜன்னல்",
    yes: "ஆம்",
    furnitureTable: [
      "ரூம் பெயர்",
      "பர்னிச்சர் பெயர்",
      "புராடெக்ட் பெயர்",
      "எண்ணிக்கை",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "சைட் ஸ்டேட்டஸ்",

    WarrantyClaimedMsg:
      "<RegistrationDate> அன்று ரிஜிஸ்டர் செய்யப்பட்ட <CustomerName>க்கான <Warranty number> <LeadID> இன் வாரண்டி கிளைம் செய்யப்பட்டது",
    WarrantyIssuedMsg:
      "<RegistrationDate> அன்று ரிஜிஸ்டர் செய்யப்பட்ட <CustomerName>-க்கான <LeadID> இன் வாரண்டி ஐடி  <Warranty number> உருவாக்கப்பட்டது",

    contractorBPNo: "கான்ட்ராக்டர் பீபி எண்",
    contractorName: "கான்ட்ராக்டரின் பெயர்",
    contractorNumber: "கான்ட்ராக்டரின் நம்பர்",
    customerEmail: "கஸ்டமரின் இமெயில்",
    customerPin: "கஸ்டமரின் பின்கோடு",
    fNameError: "பர்னிச்சர் பெயரை தேர்வு செய்க",
    initiateSiteWarrantyConf:
      "வாரண்டி பெறுவதை நிச்சயமாக தொடங்க விரும்புகிறீர்களா?",
    initiateWarrantyGeneration: "வாரண்டி பெறுவதை தொடங்கவும்",
    mandatoryFieldsError: "சப்மிட் செய்வதற்கு முன் கட்டாய விவரங்களை வழங்கவும்",
    noSites: "சைட்கள் ரிஜிஸ்டர் செய்யப்படவில்லை",
    phoneError: "செல்லத்தக்க போன் நம்பரை என்டர் செய்யவும்",
    pinError: "செல்லத்தக்க 6 இலக்க பின்கோடை என்டர் செய்யவும்",
    reset: "ரீசெட்",
    review: "மதிப்பாய்வு",
    searchNotFound: "வழங்கப்பட்ட தேடல் அளவுகோலின்படி, எந்த சைட்களும் இல்லை",
    siteCompletionDate: "சைட்டில் பணி முடிக்கும் தேதி",
    siteStatus: "சைட்டின் நிலை",
    siteSubmissionDate: "சைட்டை ஒப்படைக்கும் தேதி",
    siteUnderReview: "இந்த சைட் மதிப்பாய்வில் உள்ளது",
    underReview: "மதிப்பாய்வில் உள்ளது",
    warrantyClaim: "வாரண்டி கிளைம்",
    warrantyClaimDoc: "வாரண்டி கிளைம் டாக்குமென்ட்",
    warrantyDoc: "வாரண்டி டாக்குமென்ட்",
    warrantyId: "வாரண்டி ஐடி",
    warrantyIssue: "வாரண்டி வழங்கப்பட்டது",
    wood4Sure: "வுட்4ஸ்யூர்",

    advanceSearchOptions: {
      Key: "Tamil",
      siteStatus: "சைட்டின் ஸ்டேட்டசை என்டர் செய்யவும்",
      leadId: "6 இலக்க லீட் ஐடியை என்டர் செய்யவும்",
      dealerName: "டீலரின் பெயரை என்டர் செய்யவும்",
      dealerPhone: "டீலரின் போன் நம்பரை என்டர் செய்யவும்",
      warrantyId: "வாரண்டி ஐடியை என்டர் செய்யவும்",
    },
    search: "தேடு",
    clear: "நீக்கவும்",
    searchQuery: "தேடல் கேள்வியை என்டர் செய்யவும்",
    searchBy: "இவைகளின் மூலம் தேடு",
    warrantyClaimRemark: "வாரண்டி கிளைம் ரிமார்க்",
    warrantyClaimDocument: "வாரண்டி கிளைம் டாக்குமென்ட்",
  },
  //Telugu Obj
  {
    add: "జోడించండి",
    addProdImgs: "ప్రోడక్ట్స్ ఫోటోలు జోడించబడ్డాయి",
    address: "కస్టమర్ చిరునామా",
    addressPh: "కస్టమర్ చిరునామా రాయండి",
    attention: "శ్రద్ధ వహించండి",
    cancel: "క్యాన్సిల్ చేయండి",
    clearDataConf: "సేవ్‌ చేయబడిన డేటా పోగొట్టుకుంటారు. కొనసాగించాలా?",
    closeSiteConf: "మీరు ఈ సైట్‌ను క్లోజ్ చేయాలనుకుంటున్నారా?",
    closeSiteConfDataLoss:
      "మీరు డేటాను తొలగించి, సైట్‌ను క్లోజ్ చేయాలని అనుకుంటున్నారా?",
    closed: "క్లోజ్ అయింది",
    contactNo: "కాంటాక్ట్‌ నెంబర్‌",
    contactPh: "కస్టమర్ ఫోన్ నెంబర్‌ రాయండి",
    custName: "కస్టమర్ పేరు",
    dCode: "డీలర్‌ కోడ్‌",
    dCodeError: "దయచేసి 6 అంకెల డీలర్‌ కోడ్‌ రాయండి",
    dName: "డీలర్ పేరు",
    dNameError: "దయచేసి డీలర్ పేరు ఇవ్వండి",
    dPhone: "డీలర్ ఫోన్‌ నెంబర్‌",
    dPhoneError: "దయచేసి డీలర్‌ ఫోన్ నెంబర్‌ ఇవ్వండి",
    date: "రిజిస్ట్రేషన్ తేదీ",
    dealerCodePh: "6 అంకెల డీలర్‌ కోడ్ రాయండి",
    dealerNamePh: "డీలర్ పేరు",
    dealerPhonePh: "డీలర్ ఫోన్‌ నెంబర్‌",
    deleteDealerInvoice: "డీలర్ ఇన్వాయిస్ డిలీట్ చేయబడింది",
    deleteProdImgs: "ప్రోడక్ట్ ఫోటో డిలీట్ చేయబడింది",
    edit: "ఎడిట్‌ చేయండి",
    editProdImgs:
      "ప్రోడక్ట్ ఫోటోలు మరియు డీలర్ ఇన్వాయిస్‌లు విజయవంతంగా అప్‌డేట్ చేయబడ్డాయి",
    email: "ఈమెయిల్ ఐడీ",
    emailError: "దయచేసి సరైన ఈమెయిల్ ఐడీ రాయండి",
    emailPh: "కస్టమర్ ఈమెయిల్ ఐడీ రాయండి, ఉదా., username@gmail.com",
    expSiteValue: "అంచనా వేసిన సైట్ వాల్యూ",
    fName: "ఫర్నీచర్‌ పేరు",
    // fNameError: "దయచేసి ఫర్నీచర్‌ను ఎంచుకోండి",
    failedToDel: "డిలీట్ చేయడం విఫలమైంది. దయచేసి మళ్లీ ప్రయత్నించండి",
    failedToUp: "అప్‌లోడ్ విఫలమైంది. దయచేసి మళ్లీ ప్రయత్నించండి",
    furNamePh: "ఫర్నీచర్‌ను ఎంచుకోండి",
    furnitureAdd: "ఫర్నీచర్ విజయవంతంగా జోడించబడింది",
    furnitureDelete: "ఫర్నీచర్ విజయవంతంగా డిలీట్ చేయబడింది",
    furnitureEdit: "ఫర్నీచర్ విజయవంతంగా అప్‌డేట్ చేయబడింది",
    furnitureImageDelete: "ఫర్నీచర్ ఫోటో విజయవంతంగా డిలీట్ చేయబడింది",
    images: "ఫోటోలు",
    leadId: "లీడ్ ఐడీ",
    name: "కస్టమర్ పేరు",
    nameError: "దయచేసి చెల్లుబాటు అయ్యే పేరు రాయండి",
    namePh: "కస్టమర్ పేరు రాయండి",
    newRegistration: "కొత్త రిజిస్ట్రేషన్‌",
    next: "తరువాత",
    no: "కాదు",
    nonClosed: "తెరవండి",
    openWp: "వాట్సాప్‌ తెరవండి",
    pName: "ప్రోడక్ట్‌ పేరు",
    pNameError: "దయచేసి ప్రోడక్ట్‌ను ఎంచుకోండి",
    pending: "పెండింగ్‌లో ఉంది",
    phone: "ఫోన్‌ నెంబర్‌",
    // phoneError: "దయచేసి సరైన ఫోన్‌ నెంబర్ రాయండి",
    pinCode: "పీన్‌కోడ్‌",
    // pinError: "దయచేసి పిన్‌కోడ్ రాయండి",
    pinPh: "ఏరియా పిన్‌కోడ్ రాయండి, ఉదా., 41140",
    pincode: "పిన్‌కోడ్‌",
    prev: "ముందరి",
    prodNamePh: "ప్రోడక్ట్‌ను ఎంచుకోండి",
    prodVol: "పరిమాణం",
    prodVolPh: "పరిమాణం రాయండి",
    registeredSites: "రిజిస్టర్డ్ సైట్‌",
    room: "రూమ్‌",
    roomError: "దయచేసి రూమ్‌ను ఎంచుకోండి",
    roomPh: "రూమ్‌ను ఎంచుకోండి",
    save: "సేవ్‌ చేయండి",
    siteAdd: "సైట్‌ విజయవంతంగా రిజిస్టర్ చేయబడింది. సైట్ కోసం లీడ్ ఐడీ:",
    siteClose: "సైట్ క్లోజ్ చేయబడింది",
    siteClosure: "సైట్‌ను క్లోజ్ చేయండి",
    siteEdit: "సైట్ విజయవంతంగా అప్‌డేట్ చేయబడింది",
    siteError: "దయచేసి సైట్ వాల్యూ రాయండి",
    siteRegistration: "సైట్ రిజిస్ట్రేషన్‌",
    siteValue: "అంచనా వేసిన సైట్ వాల్యూ",
    siteValuePh: "అంచనా వేసిన సైట్‌ వాల్యూ రాయండి",
    status: "స్టేటస్‌",
    step1: "1వ స్టెప్‌",
    step2: "2వ స్టెప్‌",
    step3: "3వ స్టెప్‌",
    step3Success: "వారంటీ అభ్యర్ధన పంపబడింది",
    submit: "సబ్మిట్‌ చేయండి",
    submitMsg: "మీ సమాచారం సబ్మిట్ చేయబడింది",
    thankYou: "ధన్యవాదాలు",
    tokenExp: "టోకెన్ ఎక్స్‌పైర్ అయింది!",
    tokenMiss: "టోకెన్ కనబడడం లేదు లేదా చెల్లుబాటు కాదు.",
    upError: "దయచేసి ఫోటోలను అప్‌లోడ్‌ చేయండి",
    upInvoice: "డీలర్‌ ఇన్వాయిస్‌లను అప్‌లోడ్‌ చేయండి",
    upInvoiceEr: "దయచేసి డీలర్ ఇన్వాయిస్‌లను అప్‌లోడ్ చేయండి",
    upProdImg:
      "ఫిల్లర్/థిన్నర్/స్టెయిన్స్‌తో సహా సైట్ వద్ద ప్రోడక్ట్స్‌ ఫోటోను అప్‌లోడ్‌ చేయండి",
    upProdImgEr: "దయచేసి ప్రొడక్ట్ ఫోటోలను అప్‌లోడ్ చేయండి",
    upload: "ఫర్నీచర్ ఫోటోలను అప్‌లోడ్‌ చేయండి",
    volError: "దయచేసి పరిమాణం ఎంచుకోండి",
    windows: "కిటికీలు",
    yes: "అవును",
    furnitureTable: [
      "రూమ్ పేరు",
      "ఫర్నీచర్ పేరు",
      "ప్రోడక్ట్‌ పేరు",
      "పరిమాణం",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "సైట్ స్టేటస్‌",

    WarrantyClaimedMsg:
      "<RegistrationDate> తేదీన రిజిస్టర్ అయిన <CustomerName> కు <LeadID>, <Warranty number> పై వారంటీ క్లెయిం చేయడం జరిగింది",
    WarrantyIssuedMsg:
      "<RegistrationDate> తేదీన రిజిస్టర్ అయిన <CustomerName> కు <LeadID> పై <Warranty number> అనే వారంటీ గుర్తింపు రిజిస్టర్ అయింది",
    WoodTech_Ingenio_PU: "వుడ్‌టెక్ ఇంజీనియో పీయూ",
    WoodTech_PU_Palette: "వుడ్‌టెక్ పీయూ ప్యాలెట్",
    contractorBPNo: "కాంట్రాక్టర్ బీపీ నెం.",
    contractorName: "కాంట్రాక్టర్ యొక్క పేరు",
    contractorNumber: "కాంట్రాక్టర్ యొక్క నెంబర్",
    customerEmail: "కస్టమర్ యొక్క ఈమెయిల్",
    customerPin: "కస్టమర్ యొక్క పిన్",
    fNameError: "దయచేసి ఫర్నీచర్ పేరు ఎంచుకోండి",
    initiateSiteWarrantyConf:
      "మీరు వారంటీ రూపకల్పన ప్రారంభించడాన్ని నిర్ధారిస్తారా?",
    initiateWarrantyGeneration: "వారంటీ రూపకల్పన ప్రారంభించండి",
    mandatoryFieldsError: "సబ్మిట్ చేసేందుకు దయచేసి అవసరమైన వివరాలు ఇవ్వండి",
    noSites: "సైట్స్‌ను రిజిస్టర్ చేయలేదు",
    phoneError: "దయచేసి చెల్లుబాటు అయ్యే ఫోన్ నెంబర్ ఎంటర్ చేయండి",
    pinError: "దయచేసి చెల్లుబాటు అయ్యే 6 అంకెల పిన్‌కోడ్ ఎంటర్ చేయండి",
    reset: "రీసెట్ చేయండి",
    review: "సమీక్ష",
    searchNotFound: "అందించిన సెర్చ్ ప్రమాణాలతో ఏ సైట్స్ కనబడలేదు",
    siteCompletionDate: "సైట్ పూర్తి చేసిన తేదీ",
    siteStatus: "సైట్ యొక్క స్థితి",
    siteSubmissionDate: "సైట్‌ను సబ్మిట్ చేసిన తేదీ",
    siteUnderReview: "ఈ సైట్ సమీక్షలో ఉంది",
    underReview: "సమీక్షలో ఉంది",

    warrantyClaim: "వారంటీ క్లెయిం",
    warrantyClaimDoc: "వారంటీ క్లెయిం డాక్యుమెంట్",
    warrantyDoc: "వారంటీ డాక్యుమెంట్",
    warrantyId: "వారంటీ గుర్తింపు",
    warrantyIssue: "వారంటీ జారీ అయింది",
    wood4Sure: "వుడ్4ష్యూర్",

    advanceSearchOptions: {
      siteStatus: "సైట్ స్థితిని ఎంటర్‌ చేయండి",
      leadId: "6 అంకెల లీడ్ ఐడీని ఎంటర్‌ చేయండి",
      dealerName: "డీలర్‌ పేరును ఎంటర్‌ చేయండి",
      dealerPhone: "డీలర్‌ ఫోన్‌ నెంబర్‌ను ఎంటర్‌ చేయండి",
      warrantyId: "వారంటీ ఐడీని ఎంటర్‌ చేయండి",
    },
    search: "శోధించండి",
    clear: "తొలగించండి",
    searchQuery: "శోధన ప్రశ్నను ఎంటర్‌ చేయండి",
    searchBy: "దీని ద్వారా శోధించండి",
    warrantyClaimRemark: "వారంటీ క్లెయిమ్‌ రిమార్క్‌",
    warrantyClaimDocument: "వారంటీ క్లెయిం డాక్యుమెంట్",
  },
  //Kannada Object
  {
    add: "ಸೇರಿಸಿ",
    addProdImgs: "ಪ್ರಾಡಕ್ಟ್‌ಗಳ ಇಮೇಜ್‌ಗಳನ್ನು ಸೇರಿಸಲಾಗಿದೆ",
    address: "ಕಸ್ಟಮರ್ ವಿಳಾಸ",
    addressPh: "ಕಸ್ಟಮರ್ ವಿಳಾಸ",
    attention: "ಗಮನಹರಿಸಿ",
    cancel: "ಕ್ಯಾನ್ಸಲ್ ಮಾಡಿ",
    clearDataConf: "ಸೇವ್ ಮಾಡದ ಡೇಟಾ ಕಳೆದುಹೋಗುತ್ತದೆ. ಮುಂದುವರಿಯಲು ಬಯಸುವಿರಾ?",
    closeSiteConf: "ಈ ಸೈಟ್ ಮುಚ್ಚಲು ನೀವು ಬಯಸುವಿರಾ?",
    closeSiteConfDataLoss:
      "ಡೇಟಾ ಕ್ಲಿಯರ್ ಮಾಡಲು ಮತ್ತು ಈ ಸೈಟ್ ಮುಚ್ಚಲು ನೀವು ಬಯಸುವಿರಾ?",
    closed: "ಮುಚ್ಚಲಾಗಿದೆ",
    contactNo: "ಕಾಂಟ್ಯಾಕ್ಟ್ ನಂಬರ್",
    contactPh: "ಕಸ್ಟಮರ್ ಫೋನ್ ನಂಬರ್ ನಮೂದಿಸಿ",
    custName: "ಕಸ್ಟಮರ್ ಹೆಸರು",
    dCode: "ಡೀಲರ್ ಕೋಡ್",
    dCodeError: "ದಯವಿಟ್ಟು 6 ಅಂಕಿಯ ಡೀಲರ್ ಕೋಡ್ ನಮೂದಿಸಿ",
    dName: "ಡೀಲರ್ ಹೆಸರು",
    dNameError: "ದಯವಿಟ್ಟು ಡೀಲ್ ಹೆಸರು ಒದಗಿಸಿ",
    dPhone: "ಡೀಲರ್ ಫೋನ್ ನಂಬರ್",
    dPhoneError: "ದಯವಿಟ್ಟು ಡೀಲರ್ ಫೋನ್ ನಂಬರ್ ಒದಗಿಸಿ",
    date: "ರಿಜಿಸ್ಟ್ರೇಶನ್ ದಿನಾಂಕ",
    dealerCodePh: "6 ಅಂಕಿಯ ಡೀಲರ್ ಕೋಡ್ ನಮೂದಿಸಿ",
    dealerNamePh: "ಡೀಲರ್ ಹೆಸರು",
    dealerPhonePh: "ಡೀಲರ್ ಫೋನ್ ನಂಬರ್",
    deleteDealerInvoice: "ಡೀಲರ್ ಇನ್‌ವಾಯ್ಸ್ ಡಿಲೀಟ್ ಮಾಡಲಾಗಿದೆ",
    deleteProdImgs: "ಪ್ರಾಡಕ್ಟ್ ಇಮೇಜ್ ಡಿಲೀಟ್ ಮಾಡಲಾಗಿದೆ",
    edit: "ಎಡಿಟ್ ಮಾಡಿ",
    editProdImgs:
      "ಪ್ರಾಡಕ್ಟ್ ಇಮೇಜ್‌ಗಳು ಮತ್ತು ಡೀಲ್ ಇನ್‌ವಾಯ್ಸ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಡೇಟ್ ಮಾಡಲಾಗಿದೆ",
    email: "ಇಮೇಲ್ ID",
    emailError: "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಇಮೇಲ್ ಐಡಿ ನಮೂದಿಸಿ",
    emailPh: "ಕಸ್ಟಮರ್ ಇಮೇಲ್ ಐಡಿ ನಮೂದಿಸಿ ಉದಾ. username@gmail.com",
    expSiteValue: "ನಿರೀಕ್ಷಿತ ಸೈಟ್ ವ್ಯಾಲ್ಯೂ",
    fName: "ಫರ್ನಿಚರ್ ಹೆಸರು",
    // fNameError: "ದಯವಿಟ್ಟು ಫರ್ನಿಚರ್ ಆಯ್ಕೆಮಾಡಿ",
    failedToDel: "ಡಿಲೀಟ್ ಮಾಡಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    failedToUp: "ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    furNamePh: "ಫರ್ನಿಚರ್ ಆಯ್ಕೆಮಾಡಿ",
    furnitureAdd: "ಫರ್ನಿಚರ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
    furnitureDelete: "ಫರ್ನಿಚರ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಡಿಲೀಟ್ ಮಾಡಲಾಗಿದೆ",
    furnitureEdit: "ಫರ್ನಿಚರ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಡೇಟ್ ಮಾಡಲಾಗಿದೆ",
    furnitureImageDelete: "ಫರ್ನಿಚರ್ ಇಮೇಜ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ.",
    images: "ಇಮೇಜ್‌ಗಳು",
    leadId: "ಲೀಡ್ ID",
    name: "ಕಸ್ಟಮರ್ ಹೆಸರು",
    nameError: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಹೆಸರು ನಮೂದಿಸಿ",
    namePh: "ಕಸ್ಟಮರ್ ಹೆಸರು ನಮೂದಿಸಿ",
    newRegistration: "ಹೊಸ ರಿಜಿಸ್ಟ್ರೇಶನ್",
    next: "ಮುಂದಿನ",
    no: "ಇಲ್ಲ",
    nonClosed: "ತೆರೆಯಿರಿ",
    openWp: "ವಾಟ್ಸ್ಅಪ್ ತೆರೆಯಿರಿ",
    pName: "ಪ್ರಾಡಕ್ಟ್ ಹೆಸರು",
    pNameError: "ದಯವಿಟ್ಟು ಪ್ರಾಡಕ್ಟ್ ಆಯ್ಕೆಮಾಡಿ",
    pending: "ಬಾಕಿ ಉಳಿದಿದೆ",
    phone: "ಫೋನ್ ನಂಬರ್",
    // phoneError: "ದಯವಿಟ್ಟು ಸರಿಯಾದ ಫೋನ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
    pinCode: "ಪಿನ್‌ಕೋಡ್",
    // pinError: "ದಯವಿಟ್ಟು ಪಿನ್‌ಕೋಡ್ ನಮೂದಿಸಿ",
    pinPh: "ಏರಿಯಾ ಪಿನ್‌ಕೋಡ್ ನಮೂದಿಸಿ ಉದಾ. 41140",
    pincode: "ಪಿನ್‌ಕೋಡ್",
    prev: "ಹಿಂದಿನ",
    prodNamePh: "ಪ್ರಾಡಕ್ಟ್ ಆಯ್ಕೆಮಾಡಿ",
    prodVol: "ಪ್ರಮಾಣ",
    prodVolPh: "ಪ್ರಮಾಣ ನಮೂದಿಸಿ",
    registeredSites: "ರಿಜಿಸ್ಟರ್ಡ್ ಸೈಟ್",
    room: "ರೂಮ್",
    roomError: "ದಯವಿಟ್ಟು ರೂಮ್ ಆಯ್ಕೆಮಾಡಿ",
    roomPh: "ರೂಮ್ ಆಯ್ಕೆಮಾಡಿ",
    save: "ಸೇವ್ ಮಾಡಿ",
    siteAdd: "ಸೈಟ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಿಜಿಸ್ಟರ್ ಮಾಡಲಾಗಿದೆ. ಸೈಟ್‌ಗಾಗಿ ಲೀಡ್ ID:",
    siteClose: "ಸೈಟ್ ಮುಚ್ಚಿದೆ",
    siteClosure: "ಸೈಟ್ ಮುಚ್ಚಿ",
    siteEdit: "ಸೈಟ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಡೇಟ್ ಮಾಡಲಾಗಿದೆ",
    siteError: "ದಯವಿಟ್ಟು ಸೈಟ್ ವ್ಯಾಲ್ಯೂ ನಮೂದಿಸಿ",
    siteRegistration: "ಸೈಟ್ ರಿಜಿಸ್ಟ್ರೇಶನ್",
    siteValue: "ನಿರೀಕ್ಷಿತ ಸೈಟ್ ವ್ಯಾಲ್ಯೂ",
    siteValuePh: "ನಿರೀಕ್ಷಿತ ಸೈಟ್ ವ್ಯಾಲ್ಯೂ ನಮೂದಿಸಿ",
    status: "ಸ್ಟೇಟಸ್",
    step1: "1ನೇ ಸ್ಟೆಪ್",
    step2: "2ನೇ ಸ್ಟೆಪ್",
    step3: "3ನೇ ಸ್ಟೆಪ್",
    step3Success: "ವಾರಂಟಿ ವಿನಂತಿ ಕಳುಹಿಸಲಾಗಿದೆ",
    submit: "ಸಬ್ಮಿಟ್ ಮಾಡಿ",
    submitMsg: "ನಿಮ್ಮ ಮಾಹಿತಿಯನ್ನು ಸಲ್ಲಿಸಲಾಗಿದೆ",
    thankYou: "ಧನ್ಯವಾದಗಳು",
    tokenExp: "ಟೋಕನ್ ಅವಧಿ ಮೀರಿದೆ!",
    tokenMiss: "ಟೋಕನ್ ಕಾಣೆಯಾಗಿದೆ ಅಥವಾ ಮಾನ್ಯವಾಗಿಲ್ಲ.",
    upError: "ದಯವಿಟ್ಟು ಇಮೇಜ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    upInvoice: "ಡೀಲರ್ ಇನ್‌ವಾಯ್ಸ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    upInvoiceEr: "ದಯವಿಟ್ಟು ಡೀಲ್ ಇನ್‌ವಾಯ್ಸ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    upProdImg:
      "ಫಿಲ್ಲರ್/ಥಿನ್ನರ್/ಸ್ಟ್ರೈನ್ಸ್ ಒಳಗೊಂಡಂತೆ ಸೈಟ್‌ನಲ್ಲಿನ ಪ್ರಾಡಕ್ಟ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    upProdImgEr: "ದಯವಿಟ್ಟು ಪ್ರಾಡಕ್ಟ್ ಇಮೇಜ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    upload: "ಫರ್ನಿಚರ್ ಚಿತ್ರಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    volError: "ದಯವಿಟ್ಟು ಪ್ರಮಾಣವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    windows: "ಕಿಟಕಿಗಳು",
    yes: "ಹೌದು",
    furnitureTable: [
      "ರೂಮ್ ಹೆಸರು",
      "ಫರ್ನಿಚರ್ ಹೆಸರು",
      "ಪ್ರಾಡಕ್ಟ್ ಹೆಸರು",
      "ಪ್ರಮಾಣ",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "ಸೈಟ್ ಸ್ಟೇಟಸ್",

    WarrantyClaimedMsg:
      "ವಾರಂಟಿ ಅನ್ನು <Warranty number> <LeadID> ಗೆ ಪ್ರತಿಯಾಗಿ <RegistrationDate> ರಂದು ರಿಜಿಸ್ಟರ್ ಮಾಡಲಾಗಿರುವ <CustomerName> ಅವರಿಗಾಗಿ ಕ್ಲೇಮ್ ಮಾಡಲಾಗಿದೆ",
    WarrantyIssuedMsg:
      "ವಾರಂಟಿ ಐಡಿ <Warranty number> ಅನ್ನು <LeadID> ಗೆ ಪ್ರತಿಯಾಗಿ <RegistrationDate> ರಂದು ರಿಜಿಸ್ಟರ್ ಮಾಡಲಾಗಿರುವ <CustomerName> ಅವರಿಗಾಗಿ ಜೆನರೇಟ್ ಮಾಡಲಾಗಿದೆ",
    WoodTech_Ingenio_PU: "ವುಡ್‌ಟೆಕ್ ಇಂಜೆನಿಯೋ PU",
    WoodTech_PU_Palette: "ವುಡ್‌ಟೆಕ್ PU ಪ್ಯಾಲೆಟ್",
    contractorBPNo: "ಕಾಂಟ್ರಾಕ್ಟರ್ BP ಸಂ.",
    contractorName: "ಕಾಂಟ್ರಾಕ್ಟರ್ ಹೆಸರು",
    contractorNumber: "ಕಾಂಟ್ರಾಕ್ಟರ್ ಸಂಖ್ಯೆ",
    customerEmail: "ಕಸ್ಟಮರ್ ಇಮೇಲ್",
    customerPin: "ಕಾಂಟ್ರಾಕ್ಟರ್ ಪಿನ್",
    fNameError: "ದಯವಿಟ್ಟು ಫರ್ನಿಚರ್ ಹೆಸರು ಆಯ್ಕೆಮಾಡಿ",
    initiateSiteWarrantyConf: "ವಾರಂಟಿ ಜೆನರೇಶನ್ ಆರಂಭಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ",
    initiateWarrantyGeneration: "ವಾರಂಟಿ ಜೆನರೇಶನ್ ಆರಂಭಿಸಿ",
    mandatoryFieldsError:
      "ದಯವಿಟ್ಟು ಸಬ್ಮಿಟ್ ಮಾಡುವುದಕ್ಕಾಗಿ ಕಡ್ಡಾಯ ವಿವರಗಳನ್ನು ಒದಗಿಸಿ",
    noSites: "ಸೈಟ್‌ಗಳು ರಿಜಿಸ್ಟರ್ ಆಗಿಲ್ಲ",
    phoneError: "ದಯವಿಟ್ಟು ಮಾನ್ಯವಾದ ಫೋನ್ ನಂಬರ್ ಎಂಟರ್ ಮಾಡಿ",
    pinError: "ದಯವಿಟ್ಟು 6 ಅಂಕಿಯ ಮಾನ್ಯವಾದ ಪಿನ್‌ಕೋಡ್ ಎಂಟರ್ ಮಾಡಿ",
    reset: "ರೀಸೆಟ್ ಮಾಡಿ",
    review: "ಪರಿಶೀಲನೆ",
    searchNotFound: "ಒದಗಿಸಿದ ಸೈಟ್ ಮಾನದಂಡದ ಮೂಲಕ ಯಾವುದೇ ಸೈಟ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ",
    siteCompletionDate: "ಸೈಟ್ ಪೂರ್ಣಗೊಂಡ ದಿನಾಂಕ",
    siteStatus: "ಸೈಟ್‌ನ ಸ್ಥಿತಿ",
    siteSubmissionDate: "ಸೈಟ್ ಸಲ್ಲಿಕೆ ದಿನಾಂಕ",
    siteUnderReview: "ಈ ಸೈಟ್ ಪರಿಶೀಲನೆಯಲ್ಲಿದೆ",
    underReview: "ಪರಿಶೀಲನೆಯಲ್ಲಿದೆ",

    warrantyClaim: "ವಾರಂಟಿ ಕ್ಲೇಮ್",
    warrantyClaimDoc: "ವಾರಂಟಿ ಕ್ಲೇಮ್ ಡಾಕ್ಯುಮೆಂಟ್",
    warrantyDoc: "ವಾರಂಟಿ ಡಾಕ್ಯುಮೆಂಟ್",
    warrantyId: "ವಾರಂಟಿ ID",
    warrantyIssue: "ವಾರಂಟಿ ನೀಡಲಾಗಿದೆ",
    wood4Sure: "ವುಡ್4ಶ್ಯೂರ್",

    advanceSearchOptions: {
      Key: "Kannada",
      siteStatus: "ಸೈಟ್ ಸ್ಥಿತಿಯನ್ನು ಎಂಟರ್ ಮಾಡಿ",
      leadId: "6 ಅಂಕಿಯ ಲೀಡ್ ಐಡಿ ಎಂಟರ್ ಮಾಡಿ",
      dealerName: "ಡೀಲರ್ ಹೆಸರನ್ನು ಎಂಟರ್ ಮಾಡಿ",
      dealerPhone: "ಡೀಲರ್ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಎಂಟರ್ ಮಾಡಿ",
      warrantyId: "ವಾರಂಟಿ ಐಡಿ ಎಂಟರ್ ಮಾಡಿ",
    },
    search: "ಹುಡುಕಿ",
    clear: "ತೆಗೆದುಹಾಕಿ",
    searchQuery: "ಹುಡುಕಾಟ ಕ್ವೆರಿಯನ್ನು ಎಂಟರ್ ಮಾಡಿ",
    searchBy: "ಈ ಪ್ರಕಾರ ಹುಡುಕಿ",
    warrantyClaimRemark: "ವಾರಂಟಿ ಕ್ಲೇಮ್ ರಿಮಾರ್ಕ್",
    warrantyClaimDocument: "ವಾರಂಟಿ ಕ್ಲೇಮ್ ಡಾಕ್ಯುಮೆಂಟ್",
  },
  //Malayalam Obj
  {
    add: "ചേര്‍ക്കുക",
    addProdImgs: "പ്രോഡക്റ്റ് ചിത്രങ്ങൾ ചേർത്തു",
    address: "കസ്റ്റമറുടെ വിലാസം",
    addressPh: "കസ്റ്റമറുടെ വിലാസം നൽകുക",
    attention: "ശ്രദ്ധിക്കൂ",
    cancel: "ക്യാന്‍സല്‍ ചെയ്യുക",
    clearDataConf: "സേവ് ചെയ്യാത്ത ഡാറ്റ നഷ്ടപ്പെടും. തുടരണോ?",
    closeSiteConf: "നിങ്ങൾക്ക് ഈ സൈറ്റ് അടയ്‌ക്കണോ?",
    closeSiteConfDataLoss:
      "നിങ്ങൾക്ക് ഡാറ്റ മായ്‌ക്കാനും ഈ സൈറ്റ് അടയ്‌ക്കാനും താൽപ്പര്യമുണ്ടോ?",
    closed: "അടച്ചു",
    contactNo: "കോണ്‍ടാക്റ്റ്‌ നമ്പര്‍",
    contactPh: "കസ്റ്റമറുടെ ഫോൺ നമ്പർ നൽകുക",
    custName: "കസ്റ്റമറിന്‍റെ പേര്",
    dCode: "ഡീലർ കോഡ്",
    dCodeError: "ദയവായി 6 അക്ക ഡീലർ കോഡ് നൽകുക",
    dName: "ഡീലറുടെ പേര്",
    dNameError: "ഡീലറുടെ പേര് നൽകുക",
    dPhone: "ഡീലർ ഫോൺ നമ്പർ",
    dPhoneError: "ദയവായി ഡീലറുടെ ഫോൺ നമ്പർ നൽകുക",
    date: "രജിസ്ട്രേഷൻ തീയതി",
    dealerCodePh: "6 അക്ക ഡീലർ കോഡ് നൽകുക",
    dealerNamePh: "ഡീലറുടെ പേര്",
    dealerPhonePh: "ഡീലർ ഫോൺ നമ്പർ",
    deleteDealerInvoice: "ഡീലർ ഇൻവോയ്സ് ഡിലീറ്റ് ചെയ്തു",
    deleteProdImgs: "പ്രോഡക്റ്റ് ചിത്രം ഡിലീറ്റ് ചെയ്തു",
    edit: "എഡിറ്റ്‌ ചെയ്യുക",
    editProdImgs:
      "പ്രോഡക്റ്റ് ചിത്രങ്ങളും ഡീലർ ഇൻവോയ്സുകളും വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു",
    email: "ഇ മെയിൽ ഐഡി",
    emailError: "ദയവായി ശരിയായ ഇമെയിൽ ഐഡി നൽകുക",
    emailPh: "കസ്റ്റമറുടെ ഇമെയിൽ ഐഡി നൽകുക ഉദാ. username@gmail.com",
    expSiteValue: "പ്രതീക്ഷിക്കുന്ന സൈറ്റ് വാല്യു",
    fName: "ഫർണിച്ചറിന്‍റെ പേര്",
    // fNameError: "ദയവായി ഫർണിച്ചര്‍ തിരഞ്ഞെടുക്കുക",
    failedToDel:
      "ഡിലീറ്റ് ചെയ്യുന്നതില്‍ പരാജയപ്പെട്ടു. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    failedToUp:
      "അപ്‌ലോഡ് ചെയ്യുന്നതിൽ പരാജയപ്പെട്ടു. ദയവായി പിന്നീട് വീണ്ടും ശ്രമിക്കുക",
    furNamePh: "ഫർണിച്ചര്‍ തിരഞ്ഞെടുക്കുക",
    furnitureAdd: "ഫർണിച്ചറുകൾ വിജയകരമായി ചേർത്തു",
    furnitureDelete: "ഫർണിച്ചറുകൾ വിജയകരമായി ഡിലീറ്റ് ചെയ്തു",
    furnitureEdit: "ഫർണിച്ചറുകൾ വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു",
    furnitureImageDelete: "ഫർണിച്ചർ ചിത്രം വിജയകരമായി ഡിലീറ്റ് ചെയ്തു",
    images: "ചിത്രങ്ങൾ",
    leadId: "ലീഡ് ഐഡി",
    name: "കസ്റ്റമറിന്‍റെ പേര്",
    nameError: "ദയവായി സാധുവായ പേര് നൽകുക",
    namePh: "കസ്റ്റമറുടെ പേര് നല്‍കുക",
    newRegistration: "പുതിയ രജിസ്ട്രേഷൻ",
    next: "അടുത്തത്",
    no: "ഇല്ല",
    nonClosed: "തുറക്കുക",
    openWp: "വാട്ട്‌സ്ആപ്പ് തുറക്കുക",
    pName: "പ്രോഡക്റ്റിന്‍റെ പേര്",
    pNameError: "ദയവായി പ്രോഡക്റ്റ് തിരഞ്ഞെടുക്കുക",
    pending: "തീർപ്പാക്കാത്തത്",
    phone: "ഫോൺ നമ്പർ",
    // phoneError: "ദയവായി ശരിയായ ഫോൺ നമ്പർ നൽകുക",
    pinCode: "പിന്‍കോഡ്",
    // pinError: "ദയവായി പിൻകോഡ് നൽകുക",
    pinPh: "ഏരിയ പിൻകോഡ് നൽകുക ഉദാ. 41140",
    pincode: "പിൻ കോഡ്",
    prev: "മുന്‍പത്തേത്",
    prodNamePh: "പ്രോഡക്റ്റ് തിരഞ്ഞെടുക്കുക",
    prodVol: "വ്യാപ്തം",
    prodVolPh: "വ്യാപ്തം നൽകുക",
    registeredSites: "രജിസ്റ്റേഡ് സൈറ്റ്",
    room: "റൂം",
    roomError: "ദയവായി റൂം തിരഞ്ഞെടുക്കുക",
    roomPh: "റൂം തിരഞ്ഞെടുക്കുക",
    save: "സേവ് ചെയ്യുക",
    siteAdd: "സൈറ്റ് വിജയകരമായി രജിസ്റ്റർ ചെയ്തു. സൈറ്റിനായുള്ള ലീഡ് ഐഡി:",
    siteClose: "സൈറ്റ് അടച്ചു",
    siteClosure: "സൈറ്റ് അടയ്ക്കുക",
    siteEdit: "സൈറ്റ് വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു",
    siteError: "ദയവായി സൈറ്റ് വാല്യു നൽകുക",
    siteRegistration: "സൈറ്റ് രജിസ്ട്രേഷൻ",
    siteValue: "പ്രതീക്ഷിക്കുന്ന സൈറ്റ് വാല്യു",
    siteValuePh: "പ്രതീക്ഷിക്കുന്ന സൈറ്റ് വാല്യു നൽകുക",
    status: "സ്റ്റാറ്റസ്",
    step1: "സ്റ്റെപ്പ് 1",
    step2: "സ്റ്റെപ്പ് 2",
    step3: "സ്റ്റെപ്പ് 3",
    step3Success: "വാറന്‍റി അഭ്യർത്ഥന അയച്ചു",
    submit: "സബ്മിറ്റ് ചെയ്യുക",
    submitMsg: "നിങ്ങളുടെ വിവരങ്ങൾ സമർപ്പിച്ചു",
    thankYou: "നന്ദി",
    tokenExp: "ടോക്കൺ കാലഹരണപ്പെട്ടു!",
    tokenMiss: "ടോക്കൺ കാണുന്നില്ല അല്ലെങ്കിൽ സാധുതയില്ല.",
    upError: "ദയവായി ചിത്രങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
    upInvoice: "ഡീലർ ഇൻവോയ്‌സുകൾ അപ്‌ലോഡ് ചെയ്യുക",
    upInvoiceEr: "ഡീലർ ഇൻവോയ്‌സുകൾ അപ്‌ലോഡ് ചെയ്യുക",
    upProdImg:
      "ഫില്ലർ/തിന്നർ/സ്റ്റെയിൻസ് ഉൾപ്പെടെയുള്ള പ്രോഡക്റ്റ്സിന്‍റെ ചിത്രം സൈറ്റിൽ അപ്‌ലോഡ് ചെയ്യുക",
    upProdImgEr: "ദയവായി പ്രോഡക്റ്റ് ചിത്രങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
    upload: "ഫർണിച്ചർ ചിത്രങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
    volError: "ദയവായി വോളിയം തിരഞ്ഞെടുക്കുക",
    windows: "ജനാലകൾ",
    yes: "അതേ",
    furnitureTable: [
      "മുറിയുടെ പേര്",
      "ഫർണിച്ചറിന്‍റെ പേര്",
      "പ്രോഡക്റ്റിന്‍റെ പേര്",
      "വ്യാപ്തം",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "സൈറ്റ് സ്റ്റാറ്റസ്",

    WarrantyClaimedMsg:
      "<RegistrationDate>- ൽ രജിസ്റ്റർ ചെയ്ത <CustomerName> എന്നതിന് <Warranty number><LeadID> എന്നതിനുള്ള വാറന്‍റി ക്ലെയിം ചെയ്തിട്ടുണ്ട്",
    WarrantyIssuedMsg:
      "<RegistrationDate>- ന് രജിസ്റ്റർ ചെയ്ത <CustomerName> എന്നതിന്‍റെ <LeadID> എന്നതിനായുള്ള വാറന്‍റി ഐഡി <Warranty number> ജനറേറ്റുചെയ്യുന്നു",
    WoodTech_Ingenio_PU: "വുഡ്‌ടെക് ഇൻജെനിയോ പി.യു",
    WoodTech_PU_Palette: "വുഡ്‌ടെക് പിയു പാലറ്റ്",
    contractorBPNo: "കോണ്‍ട്രാക്ടര്‍ ബിപി നമ്പര്‍",
    contractorName: "കോണ്‍ട്രാക്ടറുടെ പേര്",
    contractorNumber: "കോണ്‍ട്രാക്ടറുടെ നമ്പര്‍",
    customerEmail: "കസ്റ്റമറുടെ ഇമെയില്‍",
    customerPin: "കസ്റ്റമറുടെ പിന്‍",
    fNameError: "ഫർണിച്ചറിന്‍റെ പേര് തിരഞ്ഞെടുക്കുക",
    initiateSiteWarrantyConf: "വാറന്‍റി ജനറേഷൻ ആരംഭിക്കണമെന്ന് തീർച്ചയാണോ?",
    initiateWarrantyGeneration: "വാറന്‍റി ജനറേഷൻ ആരംഭിക്കുക",
    mandatoryFieldsError: "സബ്മിറ്റ് ചെയ്യാന്‍ നിർബന്ധിത വിശദാംശങ്ങൾ നൽകുക",
    noSites: "സൈറ്റുകൾ രജിസ്റ്റർ ചെയ്തിട്ടില്ല",
    phoneError: "ദയവായി സാധുവായ ഫോൺ നമ്പർ നൽകുക",
    pinError: "ദയവായി സാധുവായ 6 അക്ക പിൻകോഡ് നൽകുക",
    reset: "റീസെറ്റ്",
    review: "പരിശോധന",
    searchNotFound:
      "നൽകിയ സെര്‍ച്ച് ക്രൈറ്റീരിയ പ്രകാരമുള്ള സൈറ്റുകളൊന്നും കണ്ടെത്തിയില്ല",
    siteCompletionDate: "സൈറ്റ് കംപ്ലീഷന്‍ ഡേറ്റ്",
    siteStatus: "സൈറ്റിന്‍റെ സ്റ്റാറ്റസ്",
    siteSubmissionDate: "സൈറ്റ് സബ്മിറ്റ് ചെയ്യുന്ന ഡേറ്റ്",
    siteUnderReview: "ഈ സൈറ്റ് പരിശോധിച്ചു കൊണ്ടിരിക്കുകയാണ്",
    underReview: "പരിശോധിച്ചു കൊണ്ടിരിക്കുന്നു",

    warrantyClaim: "വാറന്‍റി ക്ലെയിം",
    warrantyClaimDoc: "വാറന്‍റി ക്ലെയിം ഡോക്യുമെന്‍റ്",
    warrantyDoc: "വാറന്‍റി ഡോക്യുമെന്‍റ്",
    warrantyId: "വാറന്‍റി ഐഡി",
    warrantyIssue: "വാറന്‍റി നൽകി",
    wood4Sure: "വുഡ്4ഷുവര്‍",
    advanceSearchOptions: {
      Key: "Malayalam",
      siteStatus: "സൈറ്റ് സ്റ്റാറ്റസ് എന്‍റര്‍ ചെയ്യുക",
      leadId: "6 അക്ക ലീഡ് ഐഡി എന്‍റര്‍ ചെയ്യുക",
      dealerName: "ഡീലറുടെ പേര് എന്‍റര്‍ ചെയ്യുക",
      dealerPhone: "ഡീലറുടെ ഫോണ്‍ നമ്പര്‍ എന്‍റര്‍ ചെയ്യുക",
      warrantyId: "വാറന്‍റി ഐഡി എന്‍റര്‍ ചെയ്യുക",
    },
    search: "സെര്‍ച്ച് ചെയ്യുക",
    clear: "ഡിലീറ്റ് ചെയ്യുക",
    searchQuery: "എന്‍റര്‍ ചെയ്യുക",
    searchBy: "ഇതിലൂടെ തിരയുക",
    warrantyClaimRemark: "വാറന്റി ക്ലെയിം റിമാർക്ക്",
    warrantyClaimDocument: "വാറന്‍റി ക്ലെയിം ഡോക്യുമെന്‍റ്",
  },
  //Assamese Object
  {
    add: "এড কৰক",
    addProdImgs: "প্ৰ’ডাক্টছ ইমেজ এড কৰা হৈছে",
    address: "কাষ্টমাৰৰ ঠিকনা",
    addressPh: "কাষ্টমাৰৰ ঠিকনা এণ্টাৰ কৰক",
    attention: "এটেনশ্যন",
    cancel: "কেন্সেল কৰক",
    clearDataConf: "আনছেভড ডেটা নষ্ট হ’ব৷ কণ্টিনিউ কৰিবলৈ বিচাৰেনে?",
    closeSiteConf: "আপুনি এই ছাইট বন্ধ কৰিবলৈ বিচাৰে নেকি?",
    closeSiteConfDataLoss:
      "আপুনি ডেটা ক্লীয়েৰ কৰি এই ছাইটটো বন্ধ কৰিবলৈ বিচাৰে নেকি?",
    closed: "বন্ধ",
    contactNo: "কণ্টেক্ট নাম্বাৰ",
    contactPh: "কাষ্টমাৰ ফোন নাম্বাৰ এণ্টাৰ কৰক",
    custName: "কাষ্টমাৰৰ নাম",
    dCode: "ডীলাৰ ক’ড",
    dCodeError: "অনুগ্ৰহ কৰি 6 ডিজিটৰ ডীলাৰ ক’ড এণ্টাৰ কৰক",
    dName: "ডীলাৰৰ নাম",
    dNameError: "অনুগ্ৰহ কৰি ডীলাৰৰ নাম দিয়ক",
    dPhone: "ডীলাৰ ফোন নাম্বাৰ",
    dPhoneError: "অনুগ্ৰহ কৰি ডীলাৰ ফোন নাম্বাৰ দিয়ক",
    date: "ৰেজিষ্ট্ৰেশ্যনৰ তাৰিখ",
    dealerCodePh: "6 ডিজিটৰ ডীলাৰ ক’ড এণ্টাৰ কৰক",
    dealerNamePh: "ডীলাৰৰ নাম",
    dealerPhonePh: "ডীলাৰ ফোন নাম্বাৰ",
    deleteDealerInvoice: "ডীলাৰ ইনভইচ ডিলিট কৰা হৈছে",
    deleteProdImgs: "প্ৰ’ডাক্ট ইমেজ ডিলিট কৰা হৈছে",
    edit: "এডিট কৰক",
    editProdImgs: "প্ৰ’ডাক্ট ইমেজেছ আৰু ডীলাৰ ইনভইচেছ সফলতাৰে আপডেট কৰা হৈছে",
    email: "ইমেইল ID",
    emailError: "অনুগ্ৰহ কৰি শুদ্ধ ইমেইল ID এণ্টাৰ কৰক",
    emailPh: "কাষ্টমাৰ ইমেইল ID এণ্টাৰ কৰক, উদাহৰণস্বৰূপে username@gmail.com",
    expSiteValue: "আনুমানিক ছাইট ভেল্যু",
    fName: "ফাৰ্নিচাৰৰ নাম",
    // fNameError: "অনুগ্ৰহ কৰি ফাৰ্নিচাৰ বাছনি কৰক",
    failedToDel: "ডিলিট বিফল হৈছে৷ অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক",
    failedToUp: "আপলোড বিফল হৈছে৷ অনুগ্ৰহ কৰি পিছত পুনৰ চেষ্টা কৰক",
    furNamePh: "ফাৰ্নিচাৰ বাছনি কৰক",
    furnitureAdd: "ফাৰ্নিচাৰ সফলতাৰে এড কৰা হৈছে",
    furnitureDelete: "ফাৰ্নিচাৰ সফলতাৰে ডিলিট কৰা হৈছে",
    furnitureEdit: "ফাৰ্নিচাৰ সফলতাৰে আপডেট কৰা হৈছে",
    furnitureImageDelete: "ফাৰ্নিচাৰ ইমেজ সফলতাৰে ডিলিট কৰা হৈছে",
    images: "ইমেজেছ",
    leadId: "লীড ID",
    name: "কাষ্টমাৰৰ নাম",
    nameError: "অনুগ্ৰহ কৰি বৈধ নাম এণ্টাৰ কৰক",
    namePh: "কাষ্টমাৰৰ নাম এণ্টাৰ কৰক",
    newRegistration: "নতুন ৰেজিষ্ট্ৰেশ্যন",
    next: "পৰৱৰ্তী",
    no: "নহয়",
    nonClosed: "খোলা",
    openWp: "হোৱাটছএপ খোলক",
    pName: "প্ৰ’ডাক্টৰ নাম",
    pNameError: "অনুগ্ৰহ কৰি প্ৰ’ডাক্ট বাছনি কৰক",
    pending: "পেণ্ডিং",
    phone: "ফোন নাম্বাৰ",
    // phoneError: "অনুগ্ৰহ কৰি শুদ্ধ ফোন নাম্বাৰ এণ্টাৰ কৰক",
    pinCode: "পিনক’ড",
    // pinError: "অনুগ্ৰহ কৰি পিনক’ড এণ্টাৰ কৰক",
    pinPh: "এৰিয়া পিনক’ড এণ্টাৰ কৰক, উদাহৰণস্বৰূপে 41140",
    pincode: "পিনক’ড",
    prev: "পূৰ্বৱৰ্তী",
    prodNamePh: "প্ৰ’ডাক্ট বাছনি কৰক",
    prodVol: "ভলিউম",
    prodVolPh: "ভলিউম এণ্টাৰ কৰক",
    registeredSites: "ৰেজিষ্টাৰ্ড ছাইট",
    room: "ৰূম",
    roomError: "অনুগ্ৰহ কৰি ৰূম বাছনি কৰক",
    roomPh: "ৰূম বাছনি কৰক",
    save: "ছেভ কৰক",
    siteAdd: "ছাইট সফলতাৰে ৰেজিষ্টাৰ কৰা হৈছে৷ ছাইটৰ বাবে লীড ID:",
    siteClose: "ছাইট বন্ধ কৰা হৈছে",
    siteClosure: "ছাইট বন্ধ কৰক",
    siteEdit: "ছাইট সফলতাৰে আপডেট কৰা হৈছে",
    siteError: "অনুগ্ৰহ কৰি ছাইট ভেল্যু এণ্টাৰ কৰক",
    siteRegistration: "ছাইট ৰেজিষ্ট্ৰেশ্যন",
    siteValue: "আনুমানিক ছাইট ভেল্যু",
    siteValuePh: "আনুমানিক ছাইট ভেল্যু এণ্টাৰ কৰক",
    status: "ষ্টেটাছ",
    step1: "প্ৰথম ষ্টেপ",
    step2: "দ্বিতীয় ষ্টেপ",
    step3: "তৃতীয় ষ্টেপ",
    step3Success: "ৱাৰেণ্টী ৰিকুৱেষ্ট প্ৰেৰণ কৰা হৈছে",
    submit: "ছাবমিট কৰক",
    submitMsg: "আপোনাৰ ইনফৰ্মেশ্যন ছাবমিট কৰা হৈছে",
    thankYou: "ধন্যবাদ",
    tokenExp: "ট’কেন এক্সপায়াৰ হৈছে!",
    tokenMiss: "ট’কেন নিৰুদ্দিষ্ট বা বৈধ নহয়৷",
    upError: "অনুগ্ৰহ কৰি ইমেজেছ আপলোড কৰক",
    upInvoice: "ডীলাৰ ইনভইচেছ আপলোড কৰক",
    upInvoiceEr: "অনুগ্ৰহ কৰি ডীলাৰ ইনভইচেছ আপলোড কৰক",
    upProdImg: "ফিলাৰ/থিনাৰ/ষ্টেইনছকে ধৰি ছাইটৰ প্ৰ’ডাক্টছৰ ইমেজ আপলোড কৰক",
    upProdImgEr: "অনুগ্ৰহ কৰি প্ৰ’ডাক্ট ইমেজেছ আপলোড কৰক",
    upload: "ফাৰ্নিচাৰ ইমেজেছ আপলোড কৰক",
    volError: "অনুগ্ৰহ কৰি ভলিউম বাছনি কৰক",
    windows: "খিৰিকী",
    yes: "হয়",
    furnitureTable: [
      "ৰূমৰ নাম",
      "ফাৰ্নিচাৰৰ নাম",
      "প্ৰ’ডাক্টৰ নাম",
      "ভলিউম",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "ছাইট ষ্টেটাছ",

    siteStatus: "ছাইটৰ ষ্টেটাছ",
    contractorBPNo: "কণ্ট্ৰেক্টৰ BP নাম্বাৰ",
    contractorName: "কণ্ট্ৰেক্টৰৰ নাম",
    contractorNumber: "কণ্ট্ৰেক্টৰৰ নম্বৰ",
    warrantyId: "ৱাৰেণ্টী ID",
    siteCompletionDate: "ছাইটৰ কাম সম্পূৰ্ণ কৰাৰ তাৰিখ",
    siteSubmissionDate: "ছাইট ছাবমিট কৰাৰ তাৰিখ",
    warrantyDoc: "ৱাৰেণ্টী ডকুমেণ্ট",
    customerPin: "কাষ্টমাৰৰ পিন",
    customerEmail: "কাষ্টমাৰৰ ইমেইল",
    warrantyClaimDoc: "ৱাৰেণ্টী ক্লেইম ডকুমেণ্ট",
    noSites: "ছাইট্‌ছ ৰেজিষ্টাৰ কৰা হোৱা নাই",
    underReview: "পুনৰীক্ষণাধীন",
    warrantyIssue: "ৱাৰেণ্টী জাৰি কৰা হৈছে",
    warrantyClaim: "ৱাৰেণ্টী ক্লেইম",
    review: "পুনৰীক্ষণ",
    phoneError: "অনুগ্ৰহ কৰি বৈধ ফোন নাম্বাৰ এণ্টাৰ কৰক",
    pinError: "অনুগ্ৰহ কৰি 6 ডিজিটৰ বৈধ পিনক’ড এণ্টাৰ কৰক",
    searchNotFound: "প্ৰদান কৰা ছাৰ্চ ক্ৰাইটেৰিয়াৰ অধীনত কোনো ছাইট পোৱা নগ’ল",
    mandatoryFieldsError:
      "অনুগ্ৰহ কৰি বাধ্যতামূলক বিস্তৃত তথ্যসমূহ ছাবমিট কৰিবলৈ প্ৰদান কৰক",
    fNameError: "অনুগ্ৰহ কৰি ফাৰ্নিচাৰৰ নাম বাছনি কৰক",

    reset: "ৰিছেট",
    initiateWarrantyGeneration: "ৱাৰেণ্টী প্ৰস্তুতকৰণ আৰম্ভ কৰক",
    siteUnderReview: "এই ছাইটটো পুনৰীক্ষণাধীন হৈ আছে",
    initiateSiteWarrantyConf:
      "আপুনি ৱাৰেণ্টী প্ৰস্তুতকৰণ আৰম্ভ কৰিবলৈ বিচাৰে বুলি নিশ্চিতনে",
    wood4Sure: "উড4ছিওৰ",

    WarrantyIssuedMsg:
      "<RegistrationDate>ত ৰেজিষ্টাৰ কৰা <CustomerName>ৰ <LeadID>ৰ বিপৰীতে ৱাৰেণ্টী আইডি <Warranty number> প্ৰস্তুত কৰা হৈছে",
    WarrantyClaimedMsg:
      "<RegistrationDate>ত ৰেজিষ্টাৰ কৰা <CustomerName>ৰ <Warranty number> <LeadID>ৰ বিপৰীতে ৱাৰেণ্টী ক্লেইম কৰা হৈছে",

    advanceSearchOption: {
      siteStatus: "ছাইটৰ ষ্টেটাছ এণ্টাৰ কৰক",
      leadId: "6 ডিজিট লীড আইডি এণ্টাৰ কৰক",
      dealerName: "ডীলাৰৰ নাম এণ্টাৰ কৰক",
      dealerPhone: "ডীলাৰৰ ফোন নম্বৰ এণ্টাৰ কৰক",
      warrantyId: "ৱাৰেণ্টী আইডি এণ্টাৰ কৰক",
    },
    search: "সন্ধান কৰক",
    clear: "আঁতৰাওক",
    searchQuery: "ছাৰ্চ কোৱেৰী এণ্টাৰ কৰক",
    searchBy: "দ্বাৰা সন্ধান কৰক",
    warrantyClaimRemark: "ৱাৰেণ্টী ক্লেইম ৰিমাৰ্ক",
    warrantyClaimDocument: "ৱাৰেণ্টী ক্লেইম ডকুমেণ্ট",
  },
  //Bengali Object
  {
    add: "যোগ করুন",
    addProdImgs: "প্রোডাক্ট ইমেজ যোগ করা হয়েছে",
    address: "কাস্টমারের ঠিকানা",
    addressPh: "কাস্টমারের ঠিকানা লিখুন",
    attention: "অবহিত করা হচ্ছে",
    cancel: "ক্যানসেল করুন",
    clearDataConf: "ডেটা সেভ না করা থাকলে হারিয়ে যাবে। এগোতে চান?",
    closeSiteConf: "আপনি কি এই সাইটটি বন্ধ করতে চান?",
    closeSiteConfDataLoss: "আপনি কি ডেটা সাফ করে সাইটটি বন্ধ করতে চান?",
    closed: "বন্ধ হয়ে গেছে",
    contactNo: "কনট্যাক্ট নম্বর",
    contactPh: "কাস্টমারের ফোন নম্বর লিখুন",
    custName: "কাস্টমারের নাম",
    dCode: "ডিলার কোড",
    dCodeError: "৬ সংখ্যার ডিলার কোড দাখিল করুন",
    dName: "ডিলারের নাম",
    dNameError: "ডিলারের নাম লিখুন",
    dPhone: "ডিলারের ফোন নম্বর",
    dPhoneError: "ডিলারের ফোন নম্বর লিখুন",
    date: "রেজিস্ট্রেশনের তারিখ",
    dealerCodePh: "৬ সংখ্যার ডিলার কোড লিখুন",
    dealerNamePh: "ডিলারের নাম",
    dealerPhonePh: "ডিলারের ফোন নম্বর",
    deleteDealerInvoice: "ডিলারের ইনভয়েস ডিলিট করা হয়েছে",
    deleteProdImgs: "প্রোডাক্টের ছবি ডিলিট করা হয়েছে",
    edit: "এডিট করুন",
    editProdImgs: "প্রোডাক্টের ছবি এবং ডিলারের ইনভয়েসগুলি আপডেট করা সফল হয়েছে",
    email: "ইমেল আইডি",
    emailError: "সঠিক ইমেল আইডি দাখিল করুন",
    emailPh: "কাস্টমারের ইমেল আইডি লিখুন, যেমন username@gmail.com",
    expSiteValue: "প্রত্যাশিত সাইট ভ্যালু",
    fName: "ফার্নিচারের নাম",
    // fNameError: "ফার্নিচার বেছে নিন",
    failedToDel: "ডিলিট করা অসফল। পরে পুনরায় চেষ্টা করুন",
    failedToUp: "আপলোড অসফল। পরে পুনরায় চেষ্টা করুন",
    furNamePh: "ফার্নিচার বাছুন",
    furnitureAdd: "ফার্নিচার সফলভাবে যোগ করা হয়েছে",
    furnitureDelete: "ফার্নিচার সফলভাবে ডিলিট করা হয়েছে",
    furnitureEdit: "ফার্নিচার সফলভাবে আপডেট করা হয়েছে",
    furnitureImageDelete: "ফার্নিচারের ছবি সফলভাবে ডিলিট হয়েছে",
    images: "ছবি",
    leadId: "লিড আইডি",
    name: "কাস্টমারের নাম",
    nameError: "বৈধ নাম দাখিল করুন",
    namePh: "কাস্টমারের নাম দাখিল করুন",
    newRegistration: "নতুন রেজিস্ট্রেশন",
    next: "পরবর্তী",
    no: "না",
    nonClosed: "খুলুন",
    openWp: "হোয়াটস্‌অ্যাপ খুলুন",
    pName: "প্রোডাক্টের নাম",
    pNameError: "প্রোডাক্ট বেছে নিন",
    pending: "বাকি রয়েছে",
    phone: "ফোন নম্বর",
    // phoneError: "সঠিক ফোন নম্বর দাখিল করুন",
    pinCode: "পিনকোড",
    // pinError: "পিনকোড দাখিল করুন",
    pinPh: "এরিয়া পিনকোড লিখুন, যেমন 41140",
    pincode: "পিনকোড",
    prev: "পূর্ববর্তী",
    prodNamePh: "প্রোডাক্ট বাছুন",
    prodVol: "পরিমাণ",
    prodVolPh: "পরিমাণ লিখুন",
    registeredSites: "রেজিস্টার করা সাইট",
    room: "রুম",
    roomError: "রুম বেছে নিন",
    roomPh: "রুম বাছুন",
    save: "সেভ করুন",
    siteAdd: "সাইটটি সফলভাবে রেজিস্টার করা হয়েছে। সাইটের লিড আইডি:",
    siteClose: "সাইট বন্ধ করা হয়েছে",
    siteClosure: "সাইট বন্ধ করুন",
    siteEdit: "সাইটটি সফলভাবে আপডেট হয়েছে",
    siteError: "সাইট ভ্যালু লিখুন",
    siteRegistration: "সাইট রেজিস্ট্রেশন",
    siteValue: "প্রত্যাশিত সাইট ভ্যালু",
    siteValuePh: "প্রত্যাশিত সাইট ভ্যালু লিখুন",
    status: "স্টেটাস",
    step1: "প্রথম স্টেপ",
    step2: "দ্বিতীয় স্টেপ",
    step3: "তৃতীয় স্টেপ",
    step3Success: "ওয়ার‍্যান্টির আবেদন পাঠানো হয়েছে",
    submit: "সাবমিট করুন",
    submitMsg: "আপনার তথ্য জমা করা হয়েছে",
    thankYou: "ধন্যবাদ",
    tokenExp: "টোকেন এক্সপায়ার হয়ে গিয়েছে!",
    tokenMiss: "টোকেন নেই অথবা বৈধ নয়",
    upError: "ছবি আপলোড করুন",
    upInvoice: "ডিলার ইনভয়েসগুলি আপলোড করুন",
    upInvoiceEr: "ডিলার ইনভয়েসগুলি আপলোড করুন",
    upProdImg: "ফিলার/থিনার/স্টেন সহ সাইটে থাকা প্রোডাক্টগুলির ছবি আপলোড করুন",
    upProdImgEr: "প্রোডাক্টের ছবিগুলি আপলোড করুন",
    upload: "ফার্নিচারের ছবি আপলোড করুন",
    volError: "পরিমাণ বেছে নিন",
    windows: "জানলা",
    yes: "হ্যাঁ",
    furnitureTable: [
      "রুমের নাম",
      "ফার্নিচারের নাম",
      "প্রোডাক্টের নাম",
      "পরিমাণ",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",
    // siteStatus: "সাইট স্টেটাস",

    WarrantyClaimedMsg:
      "<RegistrationDate>-তে রেজিস্টার হওয়া <CustomerName>-এর <LeadID><Warranty number>-র সাপেক্ষে ওয়ার‍্যান্টি ক্লেম করা হয়েছে।",
    WarrantyIssuedMsg:
      "<RegistrationDate>-তে রেজিস্টার হওয়া <CustomerName>-এর <LeadID>-র সাপেক্ষে ওয়ার‍্যান্টি আইডি <Warranty number> জেনারেট হয়েছে।",

    contractorBPNo: "কনট্র্যাক্টরের বিপি নং.",
    contractorName: "কনট্র্যাক্টরের নাম",
    contractorNumber: "কনট্র্যাক্টরের নম্বর",
    customerEmail: "কাস্টমারের ইমেল",
    customerPin: "কাস্টমারের পিন",
    fNameError: "ফার্নিচারের নাম বেছে নিন",
    initiateSiteWarrantyConf:
      "আপনি কি নিশ্চিতভাবে ওয়ার‍্যান্টি জেনারেশন শুরু করতে চান",
    initiateWarrantyGeneration: "ওয়ার‍্যান্টি জেনারেশন শুরু করুন",
    mandatoryFieldsError: "সাবমিট করার জন্য বাধ্যতামূলক তথ্যগুলি দিন",
    noSites: "সাইটগুলি রেজিস্টার করা নেই/সাইটগুলি নথিভুক্ত করা নেই",
    phoneError: "বৈধ ফোন নম্বর দিন",
    pinError: "৬ সংখ্যার বৈধ পিনকোড দিন",
    reset: "রিসেট করুন",
    review: "রিভিউ করুন/পুনর্বিবেচনা করুন",
    searchNotFound: "এই সার্চ ক্রাইটেরিয়ায় কোনও সাইট পাওয়া যায়নি",
    siteCompletionDate: "সাইটের কাজ শেষ হওয়ার তারিখ",
    siteStatus: "সাইটের স্টেটাস",
    siteSubmissionDate: "সাইট সাবমিট করার তারিখ",
    siteUnderReview: "সাইটটি পুনর্বিবেচনা করা হচ্ছে",
    underReview: "পুনর্বিবেচনা করা হচ্ছে",

    warrantyClaim: "ওয়ার‍্যান্টি ক্লেম",
    warrantyClaimDoc: "ওয়ার‍্যান্টি ক্লেম ডকুমেন্ট",
    warrantyDoc: "ওয়ার‍্যান্টি ডকুমেন্ট",
    warrantyId: "ওয়ার‍্যান্টি আইডি",
    warrantyIssue: "ওয়ার‍্যান্টি ইস্যু করা হয়েছে",
    wood4Sure: "উড ফর শিওর",
    advanceSearchOption: {
      siteStatus: "সাইটের বর্তমান অবস্থা লিখুন",
      leadId: "৬ সংখ্যার লিড আইড লিখুন",
      dealerName: "ডিলারের নাম লিখুন",
      dealerPhone: "ডিলারের ফোন নম্বর লিখুন",
      warrantyId: "ওয়ার‍্যান্টি আইডি লিখুন",
    },
    search: "খুঁজুন",
    clear: "মুছে ফেলুন",
    searchQuery: "সার্চ কোয়েরি এন্টার করুন",
    searchBy: "---দিয়ে খুঁজুন",
    warrantyClaimRemark: "ওয়ার‍্যান্টি ক্লেম রিমার্ক",
    warrantyClaimDocument: "ওয়ার‍্যান্টি ক্লেম ডকুমেন্ট",
    woodTechEpoxy: null,
  },
  //Oriya Object
  {
    add: "ଯୋଗ କରନ୍ତୁ",
    addProdImgs: "ପ୍ରଡକ୍ଟର ଛବି ଯୋଗ କରାଯାଇଛି",
    address: "କଷ୍ଟମର୍ ର ଠିକଣା",
    addressPh: "କଷ୍ଟମର୍ ର ଠିକଣା ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    attention: "ଧ୍ୟାନ ଦିଅନ୍ତୁ",
    cancel: "କ୍ୟାନସଲ୍",
    clearDataConf:
      "ସେଭ୍ ହୋଇ ନଥିବା ଡାଟା ନଷ୍ଟ ହୋଇଯିବ। ଆପଣ ଜାରି ରଖିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    closeSiteConf: "ଆପଣ ଏହି ସାଇଟ୍‌ ବନ୍ଦ କରିବାକୁ ଚାହାଁନ୍ତି କି?",
    closeSiteConfDataLoss:
      "ଆପଣ ଡାଟା କ୍ଲିଅର୍ କରି ଏହି ସାଇଟ୍ ବନ୍ଦ କରିବାକୁ ଚାହୁଁଛନ୍ତି କି?",
    closed: "ବନ୍ଦ ଅଛି",
    contactNo: "କଣ୍ଟାକ୍ଟ ନମ୍ବର",
    contactPh: "କଷ୍ଟମର୍ ଫୋନ୍ ନମ୍ବର ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    custName: "କଷ୍ଟମର୍‌ଙ୍କ ନାମ",
    dCode: "ଡିଲର୍ କୋଡ୍",
    dCodeError: "ଦୟାକରି 6 ଅଙ୍କ ବିଶିଷ୍ଟ ଡିଲର୍ କୋଡ୍ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    dName: "ଡିଲରଙ୍କ ନାମ",
    dNameError: "ଦୟାକରି ଡିଲର୍ ନାମ ଲେଖନ୍ତୁ",
    dPhone: "ଡିଲରଙ୍କ ଫୋନ୍ ନମ୍ବର",
    dPhoneError: "ଦୟାକରି ଡିଲର୍ ଫୋନ୍ ନମ୍ବର ଲେଖନ୍ତୁ",
    date: "ରେଜିଷ୍ଟ୍ରେସନ୍ ତାରିଖ",
    dealerCodePh: "6 ଅଙ୍କ ବିଶିଷ୍ଟ ଡିଲର୍ କୋଡ୍ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    dealerNamePh: "ଡିଲରଙ୍କ ନାମ",
    dealerPhonePh: "ଡିଲରଙ୍କ ଫୋନ୍ ନମ୍ବର",
    deleteDealerInvoice: "ଡିଲର୍ ଇନଭଏସ୍‌ ଡିଲିଟ୍ ହୋଇଯାଇଛି",
    deleteProdImgs: "ପ୍ରଡକ୍ଟର ଛବି ଡିଲିଟ୍ ହୋଇଯାଇଛି",
    edit: "ଏଡିଟ୍",
    editProdImgs:
      "ପ୍ରଡକ୍ଟର ଛବି ଏବଂ ଡିଲର୍ ଇନଭଏସ୍‌ଗୁଡ଼ିକ ସଫଳତାର ସହିତ ଅପଡେଟ୍ ହୋଇଛି",
    email: "ଇମେଲ୍ ଆଇଡି",
    emailError: "ଦୟାକରି ସଠିକ୍ ଇମେଲ୍ ଆଇଡି ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    emailPh:
      "କଷ୍ଟମର୍ ଇମେଲ୍ ଆଇଡି ପ୍ରବିଷ୍ଟ କରନ୍ତୁ, ଉଦାହରଣ ସ୍ୱରୂପ username@gmail.com",
    expSiteValue: "ପ୍ରତ୍ୟାଶିତ ସାଇଟ୍ ଭାଲ୍ୟୁ",
    fName: "ଫର୍ଣ୍ଣିଚର୍ ନାମ",
    // fNameError: "ଦୟାକରି ଫର୍ଣ୍ଣିଚର୍ ଚୟନ କରନ୍ତୁ",
    failedToDel:
      "ଡିଲିଟ୍‌ କରିବାରେ ବିଫଳ ହୋଇଛି। ଦୟାକରି ପରେ ପୁଣି ଥରେ ଚେଷ୍ଟା କରନ୍ତୁ",
    failedToUp: "ଅପଲୋଡ୍ କରିବାରେ ବିଫଳ ହୋଇଛି। ଦୟାକରି ପରେ ପୁଣି ଥରେ ଚେଷ୍ଟା କରନ୍ତୁ",
    furNamePh: "ଫର୍ଣ୍ଣିଚର୍ ଚୟନ କରନ୍ତୁ",
    furnitureAdd: "ଫର୍ଣ୍ଣିଚର୍ ସଫଳତାର ସହିତ ଯୋଗ କରାଯାଇଛି",
    furnitureDelete: "ଫର୍ଣ୍ଣିଚର୍ ସଫଳତାର ସହିତ ଡିଲିଟ୍ ହୋଇଛି",
    furnitureEdit: "ଫର୍ଣ୍ଣିଚର୍ ସଫଳତାର ସହିତ ଅପଡେଟ୍ ହୋଇଛି",
    furnitureImageDelete: "ଫର୍ଣ୍ଣିଚର୍ ଛବି ସଫଳତାର ସହିତ ଡିଲିଟ୍ ହୋଇଯାଇଛି",
    images: "ଛବି",
    leadId: "ଲିଡ୍ ଆଇଡି",
    name: "କଷ୍ଟମର୍‌ଙ୍କ ନାମ",
    nameError: "ଦୟାକରି ବୈଧ ନାମ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    namePh: "କଷ୍ଟମର୍ ନାମ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    newRegistration: "ନୂଆ ରେଜିଷ୍ଟ୍ରେସନ୍",
    next: "ପରବର୍ତ୍ତୀ",
    no: "ନା",
    nonClosed: "ଖୋଲା ଅଛି",
    openWp: "ହ୍ୱାଟସ୍ଆପ୍ ଖୋଲନ୍ତୁ",
    pName: "ପ୍ରଡକ୍ଟ ନାମ",
    pNameError: "ଦୟାକରି ପ୍ରଡକ୍ଟ ଚୟନ କରନ୍ତୁ",
    pending: "ବିଚାରାଧୀନ",
    phone: "ଫୋନ୍ ନମ୍ବର",
    // phoneError: "ଦୟାକରି ସଠିକ୍ ଫୋନ୍ ନମ୍ବର ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    pinCode: "ପିନକୋଡ୍",
    // pinError: "ଦୟାକରି ପିନ୍‌କୋଡ୍ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    pinPh: "ଏରିଆ ପିନକୋଡ୍ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ, ଉଦାହରଣ ସ୍ୱରୂପ 41140",
    pincode: "ପିନକୋଡ୍",
    prev: "ପୂର୍ବବର୍ତ୍ତୀ",
    prodNamePh: "ପ୍ରଡକ୍ଟ ଚୟନ କରନ୍ତୁ",
    prodVol: "ସଂଖ୍ୟା",
    prodVolPh: "ସଂଖ୍ୟା ଚୟନ କରନ୍ତୁ",
    registeredSites: "ରେଜିଷ୍ଟର ସାଇଟ୍",
    room: "ରୁମ୍",
    roomError: "ଦୟାକରି ରୁମ୍ ଚୟନ କରନ୍ତୁ",
    roomPh: "ରୁମ୍ ଚୟନ କରନ୍ତୁ",
    save: "ସେଭ୍",
    siteAdd: "ସାଇଟ୍‌ ସଫଳତାର ସହ ରେଜିଷ୍ଟର୍ ହୋଇଛି। ସାଇଟ୍ ପାଇଁ ଲିଡ୍ ଆଇଡି:",
    siteClose: "ସାଇଟ୍ ବନ୍ଦ ଅଛି",
    siteClosure: "ସାଇଟ୍ ବନ୍ଦ କରନ୍ତୁ",
    siteEdit: "ସାଇଟ୍‌ ସଫଳତାର ସହ ଅପଡେଟ୍ ହୋଇଛି",
    siteError: "ଦୟାକରି ସାଇଟ୍ ଭାଲ୍ୟୁ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    siteRegistration: "ସାଇଟ୍ ରେଜିଷ୍ଟ୍ରେସନ୍",
    siteValue: "ପ୍ରତ୍ୟାଶିତ ସାଇଟ୍ ଭାଲ୍ୟୁ",
    siteValuePh: "ପ୍ରତ୍ୟାଶିତ ସାଇଟ୍ ଭାଲ୍ୟୁ ପ୍ରବିଷ୍ଟ କରନ୍ତୁ",
    status: "ଷ୍ଟାଟସ୍",
    step1: "ପ୍ରଥମ ଷ୍ଟେପ୍",
    step2: "ଦ୍ୱିତୀୟ ଷ୍ଟେପ୍",
    step3: "ତୃତୀୟ ଷ୍ଟେପ୍",
    step3Success: "ୱାରେଣ୍ଟି ଅନୁରୋଧ ପଠାଯାଇଛି",
    submit: "ସବମିଟ୍",
    submitMsg: "ଆପଣଙ୍କ ତଥ୍ୟ ସବମିଟ୍ କରାଯାଇଛି",
    thankYou: "ଧନ୍ୟବାଦ",
    tokenExp: "ଟୋକନ୍ ଏକ୍ସପାୟାର୍ ହୋଇଯାଇଛି!",
    tokenMiss: "ଟୋକନ୍ ଉପଲବ୍ଧ ନାହିଁ କିମ୍ବା ବୈଧ ନୁହେଁ।",
    upError: "ଦୟାକରି ଛବିଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ",
    upInvoice: "ଡିଲର୍ ଇନଭଏସ୍‌ ଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ",
    upInvoiceEr: "ଦୟାକରି ଡିଲର୍ ଇନଭଏସ୍‌ଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ",
    upProdImg:
      "ଫିଲର୍/ଥିନର୍/ଷ୍ଟେନ୍ ଥିବା ସାଇଟରେ ପ୍ରଡକ୍ଟଗୁଡ଼ିକର ଛବି ଅପଲୋଡ୍ କରନ୍ତୁ",
    upProdImgEr: "ଦୟାକରି ପ୍ରଡକ୍ଟ ଛବିଗୁଡ଼ିକ ଅପଲୋଡ୍ କରନ୍ତୁ",
    upload: "ଫର୍ଣ୍ଣିଚର୍ ଛବି ଅପଲୋଡ୍ କରନ୍ତୁ",
    volError: "ଦୟାକରି ଭଲ୍ୟୁମ୍ ଚୟନ କରନ୍ତୁ",
    windows: "ଝରକା",
    yes: "ହଁ",
    furnitureTable: [
      "ରୁମ୍ ନାମ",
      "ଫର୍ଣ୍ଣିଚର୍ ନାମ ",
      "ପ୍ରଡକ୍ଟ ନାମ",
      "ସଂଖ୍ୟା",
      //   "Upload Furniture image",
      "",
    ],
    none: "--",

    siteStatus: "ସାଇଟର ଷ୍ଟାଟସ୍",
    contractorBPNo: "କଣ୍ଟ୍ରାକ୍ଟରଙ୍କର ବିପି ନମ୍ବର",
    contractorName: "କଣ୍ଟ୍ରାକ୍ଟରଙ୍କର ନାମ",
    contractorNumber: "କଣ୍ଟ୍ରାକ୍ଟରଙ୍କର ନମ୍ବର",
    warrantyId: "ୱାରେଣ୍ଟି ଆଇଡି",
    siteCompletionDate: "ସାଇଟ ସମ୍ପୂର୍ଣ୍ଣ କରିବା ତାରିଖ",
    siteSubmissionDate: "ସାଇଟ୍ ଦାଖଲ କରିବା ତାରିଖ",
    warrantyDoc: "ୱାରେଣ୍ଟି ଦଲିଲ୍",
    customerPin: "ଗ୍ରାହକଙ୍କର ପିନ୍",
    customerEmail: "ଗ୍ରାହକଙ୍କର ଇମେଲ୍",
    warrantyClaimDoc: "ୱାରେଣ୍ଟି କ୍ଲେମ ଡକ୍ୟୁମେଣ୍ଟ୍",
    noSites: "ସାଇଟ୍ ରେଜିଷ୍ଟର ହୋଇନାହିଁ",
    underReview: "ସମୀକ୍ଷା ଅଧିନରେ ରହିଛି",
    warrantyIssue: "ୱାରେଣ୍ଟି ଜାରି ହୋଇଛି",
    warrantyClaim: "ୱାରେଣ୍ଟି କ୍ଲେମ",
    review: "ସମୀକ୍ଷା କରନ୍ତୁ",
    phoneError: "ଦୟାକରି ଏକ ବୈଧ ଫୋନ୍ ନମ୍ବର ଏଣ୍ଟର କରାନ୍ତୁ",
    pinError: "ଦୟାକରି 6 ଅଙ୍କ ବିଶିଷ୍ଟ ବୈଧ ପିନ୍ କୋଡ୍ ଏଣ୍ଟର କରାନ୍ତୁ",
    searchNotFound: "ଦିଆଯାଇଥିବା ସର୍ଚ ମାନଦଣ୍ଡ ଅନୁସାରେ କୌଣସି ସାଇଟ୍ ମିଳିଲା ନାହିଁ",
    mandatoryFieldsError:
      "ଦୟାକରି ଦାଖଲ କରିବା ପାଇଁ ବାଧ୍ୟତାମୂଳକ ବିବରଣୀ ପ୍ରଦାନ କରନ୍ତୁ",
    fNameError: "ଦୟାକରି ଫର୍ନିଚର ନାମ ଚୟନ କରନ୍ତୁ",

    reset: "ପୁନଃ ସେଟ୍ କରନ୍ତୁ",
    initiateWarrantyGeneration: "ୱାରେଣ୍ଟି ଉତ୍ପନ୍ନ ଆରମ୍ଭ କରନ୍ତୁ",
    siteUnderReview: "ଏହି ସାଇଟ୍ ସମୀକ୍ଷାଧୀନ ରହିଛି",
    initiateSiteWarrantyConf:
      "ଆପଣ ୱାରେଣ୍ଟି ଉତ୍ପନ୍ନ ଆରମ୍ଭ କରିବାକୁ ଚାହାଁନ୍ତି ସୁନିଶ୍ଚିତ କି?",
    wood4Sure: "ଉଡ୍4ସିଓର୍",

    WarrantyIssuedMsg:
      "ୱାରେଣ୍ଟି ଆଇଡି id <Warranty number> ଉତ୍ପନ୍ନ ହୋଇଛି  <LeadID> ବାବଦକୁ <CustomerName> ଙ୍କ ପାଇଁ <RegistrationDate>ରେ ରେଜିଷ୍ଟର ହୋଇଛି",
    WarrantyClaimedMsg:
      "ୱାରେଣ୍ଟି କ୍ଲେମ କରାଯାଇଛି  <Warranty number> <LeadID> ପାଇଁ<CustomerName> ଙ୍କ ପାଇଁ  <RegistrationDate>ରେ ରେଜିଷ୍ଟର ହୋଇଛି",

    advanceSearchOption: {
      siteStatus: "ସାଇଟର ସ୍ଥିତି ଏଣ୍ଟର କରନ୍ତୁ",
      leadId: "6 ଅଙ୍କ ବିଶିଷ୍ଟ ଲିଡ୍ ଆଇଡି ଏଣ୍ଟର କରନ୍ତୁ",
      dealerName: "ଡିଲରଙ୍କ ନାମ ଏଣ୍ଟର କରନ୍ତୁ",
      dealerPhone: "ଡିଲରଙ୍କ ଫୋନ୍ ନମ୍ବର ଏଣ୍ଟର କରନ୍ତୁ",
      warrantyId: "ୱାରେଣ୍ଟି ଆଇଡି ଏଣ୍ଟର କରନ୍ତୁ",
    },
    search: "ସନ୍ଧାନ କରନ୍ତୁ",
    clear: "ବିଲୋପ କରନ୍ତୁ",
    searchQuery: "ସନ୍ଧାନ ପ୍ରଶ୍ନ ପ୍ରବେଶ କରାନ୍ତୁ",
    searchBy: "ଏହା ଅନୁଯାୟୀ ସନ୍ଧାନ କରନ୍ତୁ",
    warrantyClaimRemark: "ୱାରେଣ୍ଟି କ୍ଲେମ୍‌ ରିମାର୍କ",
    warrantyClaimDocument: "ୱାରେଣ୍ଟି କ୍ଲେମ ଡକ୍ୟୁମେଣ୍ଟ୍",
  },
];
