import React, { Component } from "react";
import attention from "../../Resources/attention.svg";
import { langData } from "../Common/Language";

/**
 * Class LinkExpired: Component for expiry link popup
 */
class LinkExpired extends Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    localStorage.removeItem("store-state");
  }
  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    return (
      <div className="link-expired">
        <div className="link-expired-header">{langData[lan].attention}</div>
        <div className="link-expired-div">
          <img src={attention} alt="attention" />
          <div className="link-expired-msg">{langData[lan].tokenExp}</div>
        </div>
      </div>
    );
  }
}

export default LinkExpired;
