import Server from "../API";
import {
  CLEAR_CONTRACTOR_STATUS,
  GET_CONTRACTOR_DETAILS,
  GET_CONTRACTOR_LIST,
  PAGE_SIZE,
} from "./Types";

import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * GetContractorList: GET CONTRACTORS LIST
 * param: request body
 * return: dispatch action
 */
const GetContractorList = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/non-contractor/get-contractors?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_CONTRACTOR_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};
/**
 * GetContractorListForHelpline: GET CONTRACTORS LIST
 * param: request body
 * return: dispatch action
 */
const GetContractorListForHelpline = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/helpline/get-contractors?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_CONTRACTOR_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * GetContractorList: GET CONTRACTORS LIST
 * param: request body
 * return: dispatch action
 */
const DashboardGetContractors = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
	return async (dispatch) => {
	  try {
		dispatch(StartLoader());
		const response = await Server.post(
		  `/paint/w4s/non-contractor/dashboard/detailed/get-contractors?page=${pageNum}&size=${pageSize}`,
		  params
		);
		dispatch(StopLoader());
		dispatch({
		  type: GET_CONTRACTOR_LIST,
		  payload: response.data,
		});
	  } catch (e) {
		dispatch(StopLoader());
		dispatch(HandleExceptionWithSecureCatch(e));
	  }
	};
  };


/**
 * UploadWarrantyDoc: Get Site reviews
 * param: request body
 * return: dispatch action
 */
const GetContractorDetails = (params) => {
  return async (dispatch) => {
    try {
      //   dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/non-contractor/suggest-contractors",
        params
      );
      //   dispatch(StopLoader());
      dispatch({
        type: GET_CONTRACTOR_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * ClearContractorStatus
 * return: Action to clear all status
 */
const ClearContractorStatus = () => {
  return {
    type: CLEAR_CONTRACTOR_STATUS,
    payload: null,
  };
};

export { GetContractorList, GetContractorDetails, ClearContractorStatus, GetContractorListForHelpline, DashboardGetContractors };
