import React, { Component } from "react";
import { connect } from "react-redux";
import historyObj from "../_Utils/History";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import userAvatar from "../../Resources/user-avatar.svg";

//Actions
import {
  GetRegisteredSites,
  ClearRegisteredStatusLog,
} from "../../Actions/RegisteredSites";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";

//Resource
// import close from "../../Resources/close-icon.svg";
import { langData } from "../Common/Language";
import ReactModal from "react-modal";
import ConfirmationPopup from "../ConfirmationPopup";
import { DeleteImage } from "../../Actions/AssetsAction";
import { REGISTERED_SITE } from "../_Utils/Routes";
import reactImg from "../../Resources/signup-rect.svg";
import { ClearAddedSite } from "../../Actions/SiteRegistration";
import { CONTRACTOR_USER_TYPE, HELPLINE_USER } from "../../Actions/Types";
import ProgressBar from "../Common/ProgressBar";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

class SiteRegistration extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;

  constructor(props) {
    super(props);
    this.state = {
      selectedStep: 1,
      showNextButton: false,
      showPrevButton: false,
      openConfirmation: false,
      isPrevClicked: 0,
      isNextClicked: 0,
      furImages: [],
      dealerInvoice: [],
      prodImages: [],
      closeButtonClicked: false,
    };
  }

  componentDidMount() {
    //Disable back button
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
  }

  componentWillUnmount() {
    if (this.props.helpline) this.props.handleCloseSiteReg();
    this.props.ClearAddedSite();
  }

  onSubmit = (id) => {
    this.setState({
      selectedStep: id,
      showNextButton: false,
    });
  };

  handlePrevBtn = () => {
    this.setState({ isPrevClicked: this.state.selectedStep });
  };

  upgradeImagesFromStep3 = (dealerInvoice, prodImages) => {
    this.setState(
      { dealerInvoice: dealerInvoice, prodImages: prodImages },
      () => {
        this.handleOpenConfirmation();
      }
    );
  };

  upgradeImagesFromStep2 = (furImages) => {
    this.setState({ furImages: furImages }, () => {
      this.handleOpenConfirmation();
    });
  };

  //Confirmation Yes button
  handlePrevYesButton = () => {
    const {
      isPrevClicked,
      isNextClicked,
      dealerInvoice,
      prodImages,
      furImages,
      closeButtonClicked,
    } = this.state;
    localStorage.removeItem("saveFiles");
    if (isPrevClicked === 3) {
      dealerInvoice &&
        dealerInvoice.length &&
        this.props.DeleteImage({
          key: dealerInvoice.map((image) => image.imagelink),
        });
      prodImages &&
        prodImages.length &&
        this.props.DeleteImage({
          key: prodImages.map((image) => image.imagelink),
        });
    } else if (isPrevClicked === 2 || isNextClicked === 2) {
      this.props.DeleteImage({
        key: furImages.map((image) => image.imagelink),
      });
    }
    // else if (isNextClicked === 2) {
    //   for (let i = 0; i < furImages.length; i++)
    //     deleteUploadedImages(furImages[i].imagelink);
    // }
    if (isPrevClicked === 3 || isPrevClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: [],
        prodImages: [],
      });
    else if (isNextClicked === 2)
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isPrevClicked: 0,
        isNextClicked: 0,
        openConfirmation: false,
        furImages: [],
        dealerInvoice: [],
        prodImages: [],
      });

    if (closeButtonClicked) {
      this.setState({ closeButtonClicked: null });
      this.props.handleCloseSiteReg();
    }
  };

  //Confirmation NO button
  handlePrevNoButton = () => {
    this.setState({
      isPrevClicked: 0,
      isNextClicked: 0,
      prodImages: [],
      dealerInvoice: [],
      openConfirmation: false,
      furImages: [],
    });
  };

  //Open Confirmation prompt
  handleOpenConfirmation = () => {
    const {
      dealerInvoice,
      prodImages,
      isPrevClicked,
      furImages,
      isNextClicked,
      closeButtonClicked,
    } = this.state;

    if (
      isPrevClicked === 3 &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (isPrevClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2 && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (isNextClicked === 2) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        isNextClicked: 0,
        openConfirmation: false,
      });
    } else {
      this.setState({
        selectedStep: this.state.selectedStep - 1,
        isPrevClicked: 0,
        openConfirmation: false,
      });
    }

    if (
      closeButtonClicked &&
      ((dealerInvoice && dealerInvoice.length) ||
        (prodImages && prodImages.length))
    ) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked && furImages && furImages.length) {
      this.setState({ openConfirmation: true });
    } else if (closeButtonClicked) {
      this.setState({
        openConfirmation: false,
        closeButtonClicked: null,
      });
      this.props.handleCloseSiteReg();
    }
  };

  handleNextBtn = () => {
    // this.setState({
    //   selectedStep: this.state.selectedStep + 1,
    //   showNextButton: false,
    // });

    if (this.state.selectedStep === 1) {
      this.setState({
        selectedStep: this.state.selectedStep + 1,
        showNextButton: false,
      });
    } else this.setState({ isNextClicked: this.state.selectedStep });
  };

  handleShowNextButton = () => {
    this.setState({ showNextButton: true });
  };
  handleShowPrevButton = () => {
    this.setState({ showPrevButton: true });
  };

  handleRegisteredSite = async () => {
    historyObj.push(REGISTERED_SITE);
    return; //remove later

    /*   const reqBody = {
      phonenum: this.props.phoneNum,
    };
    await this.props.GetRegisteredSites(reqBody);
    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
      historyObj.push(REGISTERED_SITE);
    } */
  };

  handleCloseSiteReg = () => {
    this.setState(
      {
        closeButtonClicked: true,
        isPrevClicked: this.state.selectedStep,
      },
      () => {
        if (this.state.selectedStep === 1) {
          this.props.handleCloseSiteReg();
        }
      }
    );
    // this.props.handleCloseSiteReg();
  };

  render() {
    const { selectedStep } = this.state;
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    const { userType } = this.props;

    return (
      <React.Fragment>
        <div
          className={`site-registeration-main-container ${
            this.props.helpline || this.props.dsr ? "helpline-user-new-reg" : ""
          }  `}
        >
          <div className="site-registeration-container step1-width">
            {/* <div className="legend">{langData[lan].siteRegistration}</div> */}
            <div className="legend">
              <img src={reactImg} alt="" />
              <div className="text">{langData[lan].siteRegistration}</div>
            </div>
            <div className="header-conatiner">
              {!this.props.helpline && !this.props.dsr ? (
                <div
                  className="resigteration-btn"
                  onClick={this.handleRegisteredSite}
                >
                  {langData[lan].registeredSites}
                </div>
              ) : this.props.helpline ? (
                <div
                  className="header-conatiner-close"
                  onClick={this.handleCloseSiteReg}
                >
                  <span className="close-site-registration bk-blue">Back</span>
                </div>
              ) : null}

              {/* USER INFO */}
              {(!CheckNonContractorUser(userType) ||
                (userType === HELPLINE_USER &&
                  this.props.contractorDetails)) && (
                <div className="user-info">
                  <div className="name">
                    {this.props.contractorDetails
                      ? this.props.contractorDetails.name
                      : this.props.username}
                    <span>
                      (
                      {this.props.contractorDetails
                        ? "AWA"
                        : this.props.userType}
                      )
                    </span>
                  </div>
                  {(this.props.userType === CONTRACTOR_USER_TYPE &&
                    this.props.registrationNum) ||
                  (userType === HELPLINE_USER &&
                    this.props.contractorDetails &&
                    this.props.contractorDetails.registrationNum) ? (
                    <div className="phn-no">
                      <img src={userAvatar} alt="number" />
                      {this.props.contractorDetails &&
                      this.props.contractorDetails.registrationNum
                        ? this.props.contractorDetails.registrationNum
                        : this.props.registrationNum}
                    </div>
                  ) : null}

                  <div className="phn-no">
                    <img src="/Assets/phn-icon.svg" alt="phn-icon" />
                    <div>
                      {userType === HELPLINE_USER &&
                      this.props.contractorDetails
                        ? this.props.contractorDetails.contractorPhoneNum
                        : this.props.userPhoneNum}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* PROGRESS BAR */}
            <div className="site-registeration-container-progress">
              <div className="site-registeration-container-progress-btn">
                {selectedStep === 2 || selectedStep === 3 ? (
                  <div
                    className="site-registeration-container-progress-prev bk-blue"
                    onClick={this.handlePrevBtn}
                    id="prev"
                  >
                    {langData[lan].prev}
                  </div>
                ) : null}{" "}
              </div>

              <ProgressBar selectedStep={selectedStep} />
              <div className="site-registeration-container-progress-btn">
                {selectedStep !== 3 && this.state.showNextButton ? (
                  <div
                    className="site-registeration-container-progress-next bk-blue"
                    onClick={this.handleNextBtn}
                    id="next"
                  >
                    {langData[lan].next}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-container">
              {this.state.selectedStep === 1 && (
                <Step1
                  onSubmit={this.onSubmit}
                  handleShowNextButton={this.handleShowNextButton}
                  contractorPhoneNum={
                    userType === HELPLINE_USER
                      ? this.props.contractorDetails &&
                        this.props.contractorDetails.contractorPhoneNum
                      : undefined
                  }
                />
              )}
              {this.state.selectedStep === 2 && (
                <Step2
                  onSubmit={this.onSubmit}
                  handleShowNextButton={this.handleShowNextButton}
                  upgradeImagesToParent={this.upgradeImagesFromStep2}
                  isPrevClicked={this.state.isPrevClicked}
                  isNextClicked={this.state.isNextClicked}
                />
              )}
              {this.state.selectedStep === 3 && (
                <Step3
                  onSubmit={this.onSubmit}
                  handleShowNextButton={this.handleShowNextButton}
                  helpline={this.props.helpline}
                  handleNewSiteReg={this.props.handleNewSiteReg}
                  upgradeImagesToParent={this.upgradeImagesFromStep3}
                  isPrevClicked={this.state.isPrevClicked}
                />
              )}
            </div>
          </div>
        </div>
        <ReactModal
          isOpen={this.state.openConfirmation}
          onRequestClose={this.handlePrevNoButton}
          className="confirmation-popup-modal"
          overlayClassName="confirmation-popup-overlay"
          ariaHideApp={false}
        >
          <ConfirmationPopup
            handleSiteClosure={this.handlePrevYesButton}
            handleOpenConfirmation={this.handlePrevNoButton}
            msg={langData[lan].clearDataConf}
          />
        </ReactModal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    getRegisteredSites: state.regSites.getRegisteredSites,
    registrationNum: state.auth.registrationNum,
  };
};
export default connect(mapStateToProps, {
  GetRegisteredSites,
  ClearRegisteredStatusLog,
  SetFurnitureDetails,
  DeleteImage,
  ClearAddedSite,
})(SiteRegistration);

/* 
  <div className="progress-container">
                <div className="step">
                  <p> {langData[lan].step1}</p>
                  <div
                    className={
                      selectedStep === 1 ||
                      selectedStep === 2 ||
                      selectedStep === 3
                        ? "bullet active"
                        : "bullet"
                    }
                  ></div>
                  <div
                    className={
                      selectedStep === 1 ||
                      selectedStep === 2 ||
                      selectedStep === 3
                        ? "check active"
                        : "check"
                    }
                  ></div>
                </div>
                <div className="step">
                  <p> {langData[lan].step2}</p>
                  <div
                    className={
                      selectedStep === 2 || selectedStep === 3
                        ? "bullet active"
                        : "bullet"
                    }
                  ></div>
                  <div
                    className={
                      selectedStep === 2 || selectedStep === 3
                        ? "check active"
                        : "check"
                    }
                  ></div>
                </div>
                <div className="step">
                  <p>{langData[lan].step3}</p>
                  <div
                    className={selectedStep === 3 ? "bullet active" : "bullet"}
                  ></div>
                </div>
              </div>  */
