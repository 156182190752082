import React, { Component } from "react";
import { langData } from "./Language";

class EditImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editImageObject: null,
      editImageObjectError: false,
    };
  }

  //HANDLE SELECT FILE AND GET URL
  handleSelectEditImage = (e) => {
    if (!e.target.files[0]) return;
    this.setState({
      editImageObject: e.target.files[0],
      editImageObjectError: false,
    });
  };

  handleClose = () => {
    this.setState({ editImageObject: null });
    this.props.handleCloseEditImage();
  };

  handleUploadButton = () => {
    if (this.state.editImageObject) {
      this.props.handleEditImageYes(this.state.editImageObject);
    } else {
      this.setState({ editImageObjectError: true });
    }
  };

  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    const { editImageObject, editImageObjectError } = this.state;
    return (
      <div className="edit-image-wrapper font-poppins">
        <div className="edit-image">
          <div className="upload-file">
            {editImageObject ? (
              <img
                src={URL.createObjectURL(editImageObject)}
                alt="File"
                onClick={() => {
                  document.getElementById("upload-file").click();
                  return false;
                }}
                className="uploaded-file"
              />
            ) : (
              <label
                htmlFor="upload-file"
                className={editImageObjectError ? "error" : ""}
              >
                <img
                  src="/Assets/upload.svg"
                  alt="uploadIcon"
                  style={{ cursor: "pointer" }}
                />
              </label>
            )}
            <input
              type="file"
              onChange={this.handleSelectEditImage}
              id="upload-file"
			  accept="image/*"
            />
          </div>
          {editImageObject && (
            <div className="file-name">{editImageObject.name}</div>
          )}

          <div className="note">{langData[lan].editImageNote || langData[0].editImageNote }</div>

          <div className="upload-btns">
            <div
              className="upload bk-blue button"
              onClick={this.handleUploadButton}
            >
              {langData[lan].uploadButton || langData[0].uploadButton}
            </div>
            <div className="cancel button" onClick={this.handleClose}>
              {langData[0].cancel}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditImage;
