import React, { Component } from "react";

class Analytics extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="analytics">
        <div className="analytics-wrapper">
          {data &&
            data.map((val, index) => {
              const { onClickHandler = () => null } = val;
              return (
                <div
                  className="analytics-card"
                  key={index}
                  onClick={() => onClickHandler(val)}
                >
                  <div className="count">{val.count || 0}</div>
                  <div className="title">{val.title}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

export default Analytics;
