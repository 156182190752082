export const Exterior = [
  [
    { value: "Main door", label: "Main door" },
    { value: "Wooden Frames", label: "Wooden Frames" },
    { value: "windows", label: "windows" },
    { value: "Garden Chair", label: "Garden Chair" },
    { value: "Garden Table", label: "Garden Table" },
    { value: "Wooden Fence", label: "Wooden Fence" },
  ],
  [
    { value: "मेन डोर", label: "मेन डोर" },
    { value: "वूडन फ्रेम्स", label: "वूडन फ्रेम्स" },
    { value: "खिड़कियां", label: "खिड़कियां" },
    { value: "गार्डन चेयर", label: "गार्डन चेयर" },
    { value: "गार्डन टेबल", label: "गार्डन टेबल" },
    { value: "वूडन फेन्स", label: "वूडन फेन्स" },
  ],
  [
    { value: "மெயின் டோர்", label: "மெயின் டோர்" },
    { value: "மர ஃபிரேம்கள்", label: "மர ஃபிரேம்கள்" },
    { value: "ஜன்னல்", label: "ஜன்னல்" },
    { value: "கார்டர்ன் சேர்", label: "கார்டர்ன் சேர்" },
    { value: "கார்டர்ன் டேபிள்", label: "கார்டர்ன் டேபிள்" },
    { value: "மர வேலி", label: "மர வேலி" },
  ],
  [
    { value: "మెయిన్‌ డోర్‌", label: "మెయిన్‌ డోర్‌" },
    { value: "చెక్క ఫ్రేమ్స్‌", label: "చెక్క ఫ్రేమ్స్‌" },
    { value: "కిటికీలు", label: "కిటికీలు" },
    { value: "గార్డెన్ చైర్‌", label: "గార్డెన్ చైర్‌" },
    { value: "గార్డెన్ టేబుల్‌", label: "గార్డెన్ టేబుల్‌" },
    { value: "చెక్క ఫెన్స్‌", label: "చెక్క ఫెన్స్‌" },
  ],
  [
    { value: "ಮೈನ್ ಡೋರ್", label: "ಮೈನ್ ಡೋರ್" },
    { value: "ವುಡನ್ ಫ್ರೇಮ್ಸ್", label: "ವುಡನ್ ಫ್ರೇಮ್ಸ್" },
    { value: "ಕಿಟಕಿಗಳು", label: "ಕಿಟಕಿಗಳು" },
    { value: "ಗಾರ್ಡನ್ ಚೇರ್", label: "ಗಾರ್ಡನ್ ಚೇರ್" },
    { value: "ಗಾರ್ಡನ್ ಟೇಬಲ್", label: "ಗಾರ್ಡನ್ ಟೇಬಲ್" },
    { value: "ಮರದ ಫೆನ್ಸ್", label: "ಮರದ ಫೆನ್ಸ್" },
  ],
  [
    { value: "മെയിന്‍ ഡോര്‍", label: "മെയിന്‍ ഡോര്‍" },
    { value: "തടികൊണ്ടുള്ള ഫ്രെയിമുകൾ", label: "തടികൊണ്ടുള്ള ഫ്രെയിമുകൾ" },
    { value: "ജനാലകൾ", label: "ജനാലകൾ" },
    { value: "ഗാർഡൻ ചെയർ", label: "ഗാർഡൻ ചെയർ" },
    { value: "ഗാർഡൻ ടേബിള്‍", label: "ഗാർഡൻ ടേബിള്‍" },
    { value: "തടികൊണ്ടുള്ള വേലി", label: "തടികൊണ്ടുള്ള വേലി" },
  ],
  [
    { value: "মেইন ডোৰ", label: "মেইন ডোৰ" },
    { value: "কাঠৰ ফ্ৰেম", label: "কাঠৰ ফ্ৰেম" },
    { value: "খিৰিকী", label: "খিৰিকী" },
    { value: "গাৰ্ডেন চেয়াৰ", label: "গাৰ্ডেন চেয়াৰ" },
    { value: "গাৰ্ডেন টেবুল", label: "গাৰ্ডেন টেবুল" },
    { value: "কাঠৰ ফেন্স", label: "কাঠৰ ফেন্স" },
  ],
  [
    { value: "মেন ডোর", label: "মেন ডোর" },
    { value: "কাঠের ফ্রেম", label: "কাঠের ফ্রেম" },
    { value: "জানলা", label: "জানলা" },
    { value: "গার্ডেন চেয়ার", label: "গার্ডেন চেয়ার" },
    { value: "গার্ডেন টেব্‌ল", label: "গার্ডেন টেব্‌ল" },
    { value: "কাঠের ফেন্স", label: "কাঠের ফেন্স" },
  ],
  [
    { value: "ମେନ୍ ଡୋର୍", label: "ମେନ୍ ଡୋର୍" },
    { value: "କାଠ ଫ୍ରେମ୍", label: "କାଠ ଫ୍ରେମ୍" },
    { value: "ଝରକା", label: "ଝରକା" },
    { value: "ଗାର୍ଡେନ୍ ଚେୟାର୍", label: "ଗାର୍ଡେନ୍ ଚେୟାର୍" },
    { value: "ଗାର୍ଡେନ୍ ଟେବୁଲ୍", label: "ଗାର୍ଡେନ୍ ଟେବୁଲ୍" },
    { value: "କାଠର ବାଡ଼", label: "କାଠର ବାଡ଼" },
  ],
];

export const Kitchen = [
  [
    { value: "Dining Tables", label: "Dining Tables" },
    { value: "Chairs", label: "Chairs" },
    { value: "Kitchen Cabinets", label: "Kitchen Cabinets" },
    { value: "Wooden racks/shelf", label: "Wooden racks/shelf" },
  ],
  [
    { value: "डाइनिंग टेबल", label: "डाइनिंग टेबल" },
    { value: "कुर्सियां", label: "कुर्सियां" },
    { value: "किचन कैबिनेट्स", label: "किचन कैबिनेट्स" },
    { value: "वूडन रैक्स/शेल्फ", label: "वूडन रैक्स/शेल्फ" },
  ],
  [
    { value: "டைனிங் டேபிள்கள்", label: "டைனிங் டேபிள்கள்" },
    { value: "சேர்கள்", label: "சேர்கள்" },
    { value: "கிச்சன் கேபினட்கள்", label: "கிச்சன் கேபினட்கள்" },
    { value: "மர ரேக்ஸ்/ஷெல்ப்", label: "மர ரேக்ஸ்/ஷெல்ப்" },
  ],
  [
    { value: "డైనింగ్ టేబుల్స్‌", label: "డైనింగ్ టేబుల్స్‌" },
    { value: "కుర్చీలు", label: "కుర్చీలు" },
    { value: "కిచెన్ క్యాబినెట్స్‌", label: "కిచెన్ క్యాబినెట్స్‌" },
    { value: "చెక్క ర్యాక్స్‌/షెల్ఫ్‌", label: "చెక్క ర్యాక్స్‌/షెల్ఫ్‌" },
  ],
  [
    { value: "ಡೈನಿಂಗ್ ಟೇಬಲ್‌ಗಳು", label: "ಡೈನಿಂಗ್ ಟೇಬಲ್‌ಗಳು" },
    { value: "ಚೇರ್‌ಗಳು", label: "ಚೇರ್‌ಗಳು" },
    { value: "ಕಿಚನ್ ಕ್ಯಾಬಿನೆಟ್‌ಗಳು", label: "ಕಿಚನ್ ಕ್ಯಾಬಿನೆಟ್‌ಗಳು" },
    { value: "ವುಡನ್ ರಾಕ್ಸ್/ಶೆಲ್ಫ್", label: "ವುಡನ್ ರಾಕ್ಸ್/ಶೆಲ್ಫ್" },
  ],
  [
    { value: "ഡൈനിംഗ് ടേബിളുകൾ", label: "ഡൈനിംഗ് ടേബിളുകൾ" },
    { value: "കസേരകൾ", label: "കസേരകൾ" },
    { value: "കിച്ചന്‍ കാബിനറ്റുകൾ", label: "കിച്ചന്‍ കാബിനറ്റുകൾ" },
    {
      value: "തടികൊണ്ടുള്ള റാക്കുകൾ / ഷെൽഫ്",
      label: "തടികൊണ്ടുള്ള റാക്കുകൾ / ഷെൽഫ്",
    },
  ],
  [
    { value: "ডাইনিং টেবুলছ", label: "ডাইনিং টেবুলছ" },
    { value: "চকী", label: "চকী" },
    { value: "কিচ্চেন কেবিনেটছ", label: "কিচ্চেন কেবিনেটছ" },
    { value: "কাঠৰ ৰেকছ/শ্বেল্ফ", label: "কাঠৰ ৰেকছ/শ্বেল্ফ" },
  ],
  [
    { value: "ডাইনিং টেব্‌ল", label: "ডাইনিং টেব্‌ল" },
    { value: "চেয়ার", label: "চেয়ার" },
    { value: "কিচেন ক্যাবিনেট", label: "কিচেন ক্যাবিনেট" },
    { value: "কাঠের র‍্যাক/শেল্ফ", label: "কাঠের র‍্যাক/শেল্ফ" },
  ],
  [
    { value: "ଡାଇନିଂ ଟେବୁଲ୍", label: "ଡାଇନିଂ ଟେବୁଲ୍" },
    { value: "ଚେୟାର୍", label: "ଚେୟାର୍" },
    { value: "କିଚେନ୍ କ୍ୟାବିନେଟ୍", label: "କିଚେନ୍ କ୍ୟାବିନେଟ୍" },
    { value: "କାଠନିର୍ମିତ ଥାକ/ସେଲ୍ଫ", label: "କାଠନିର୍ମିତ ଥାକ/ସେଲ୍ଫ" },
  ],
];

export const LivingRoom = [
  [
    { value: "Wooden Sofa", label: "Wooden Sofa" },
    { value: "Sofa Chairs", label: "Sofa Chairs" },
    { value: "TV Unit", label: "TV Unit" },
    { value: "Side Tables", label: "Side Tables" },
    { value: "Coffee Table", label: "Coffee Table" },
    { value: "Study Table with chair", label: "Study Table with chair" },
  ],
  [
    { value: "वूडन सोफा", label: "वूडन सोफा" },
    { value: "सोफा चेयर", label: "सोफा चेयर" },
    { value: "टीवी यूनिट", label: "टीवी यूनिट" },
    { value: "साइड टेबल", label: "साइड टेबल" },
    { value: "कॉफी टेबल", label: "कॉफी टेबल" },
    { value: "स्टडी टेबल कुर्सी के साथ", label: "स्टडी टेबल कुर्सी के साथ" },
  ],
  [
    { value: "மர சோபா", label: "மர சோபா" },
    { value: "சோபா சேர்கள்", label: "சோபா சேர்கள்" },
    { value: "டிவி யூனிட்", label: "டிவி யூனிட்" },
    { value: "சைடு டேபிள்கள்", label: "சைடு டேபிள்கள்" },
    { value: "காபி டேபிள்", label: "காபி டேபிள்" },
    { value: "ஸ்டடி டேபிள் மற்றும் சேர்", label: "ஸ்டடி டேபிள் மற்றும் சேர்" },
  ],
  [
    { value: "చెక్క సోఫా", label: "చెక్క సోఫా" },
    { value: "సోఫా చైర్స్‌", label: "సోఫా చైర్స్‌" },
    { value: "టీవీ యూనిట్‌", label: "టీవీ యూనిట్‌" },
    { value: "సైడ్ టేబుల్స్‌", label: "సైడ్ టేబుల్స్‌" },
    { value: "కాఫీ టేబుల్‌", label: "కాఫీ టేబుల్‌" },
    { value: "చైర్‌తో సహా స్టడీ టేబుల్‌", label: "చైర్‌తో సహా స్టడీ టేబుల్‌" },
  ],
  [
    { value: "ವುಡನ್ ಸೋಫಾ", label: "ವುಡನ್ ಸೋಫಾ" },
    { value: "ಸೋಫಾ ಚೇರ್‌ಗಳು", label: "ಸೋಫಾ ಚೇರ್‌ಗಳು" },
    { value: "TV ಯುನಿಟ್", label: "TV ಯುನಿಟ್" },
    { value: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು", label: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು" },
    { value: "ಕಾಫಿ ಟೇಬಲ್", label: "ಕಾಫಿ ಟೇಬಲ್" },
    { value: "ಚೇರ್ ಜೊತೆಗೆ ಸ್ಟಡಿ ಟೇಬಲ್", label: "ಚೇರ್ ಜೊತೆಗೆ ಸ್ಟಡಿ ಟೇಬಲ್" },
  ],
  [
    { value: "തടികൊണ്ടുള്ള സോഫ", label: "തടികൊണ്ടുള്ള സോഫ" },
    { value: "സോഫ ചെയറുകള്‍", label: "സോഫ ചെയറുകള്‍" },
    { value: "ടിവി യൂണിറ്റ്", label: "ടിവി യൂണിറ്റ്" },
    { value: "സൈഡ് ടേബിളുകൾ", label: "സൈഡ് ടേബിളുകൾ" },
    { value: "കോഫി ടേബിള്‍", label: "കോഫി ടേബിള്‍" },
    {
      value: "കസേരയോടു കൂടിയ സ്റ്റഡി ടേബിള്‍",
      label: "കസേരയോടു കൂടിയ സ്റ്റഡി ടേബിള്‍",
    },
  ],
  [
    { value: "কাঠৰ ছোফা", label: "কাঠৰ ছোফা" },
    { value: "ছোফা চেয়াৰ্ছ", label: "ছোফা চেয়াৰ্ছ" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "ছাইড টেবুলছ", label: "ছাইড টেবুলছ" },
    { value: "কফি টেবুল", label: "কফি টেবুল" },
    { value: "চেয়াৰৰ সৈতে ষ্টাডী টেবুল", label: "চেয়াৰৰ সৈতে ষ্টাডী টেবুল" },
  ],
  [
    { value: "কাঠের সোফা", label: "কাঠের সোফা" },
    { value: "সোফা চেয়ার", label: "সোফা চেয়ার" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "সাইড টেব্‌ল", label: "সাইড টেব্‌ল" },
    { value: "কফি টেব্‌ল", label: "কফি টেব্‌ল" },
    { value: "চেয়ার সহ স্টাডি টেব্‌ল", label: "চেয়ার সহ স্টাডি টেব্‌ল" },
  ],
  [
    { value: "କାଠର ସୋଫା", label: "କାଠର ସୋଫା" },
    { value: "ସୋଫା ଚେୟାର୍", label: "ସୋଫା ଚେୟାର୍" },
    { value: "ଟିଭି ୟୁନିଟ୍", label: "ଟିଭି ୟୁନିଟ୍" },
    { value: "ସାଇଡ ଟେବୁଲ୍", label: "ସାଇଡ ଟେବୁଲ୍" },
    { value: "କଫି ଟେବୁଲ୍", label: "କଫି ଟେବୁଲ୍" },
    { value: "ଚେୟାର ସହିତ ଷ୍ଟଡି ଟେବୁଲ୍", label: "ଚେୟାର ସହିତ ଷ୍ଟଡି ଟେବୁଲ୍" },
  ],
];

export const BedRoom1 = [
  [
    { value: "Bed", label: "Bed" },
    { value: "Side Tables", label: "Side Tables" },
    { value: "Wooden door with Frames", label: "Wooden door with Frames" },
    { value: "TV Unit", label: "TV Unit" },
    { value: "Cupboards", label: "Cupboards" },
  ],
  [
    { value: "बेड", label: "बेड" },
    { value: "साइड टेबल", label: "साइड टेबल" },
    { value: "वूडन डोर फ्रेम्स के साथ", label: "वूडन डोर फ्रेम्स के साथ" },
    { value: "टीवी यूनिट", label: "टीवी यूनिट" },
    { value: "कपबोर्ड", label: "कपबोर्ड" },
  ],
  [
    { value: "பெட்", label: "பெட்" },
    { value: "சைடு டேபிள்கள்", label: "சைடு டேபிள்கள்" },
    { value: "பிரேமுடன் கூடிய மர கதவு", label: "பிரேமுடன் கூடிய மர கதவு" },
    { value: "டிவி யூனிட்", label: "டிவி யூனிட்" },
    { value: "கப்போர்டுகள்", label: "கப்போர்டுகள்" },
  ],
  [
    { value: "బెడ్‌", label: "బెడ్‌" },
    { value: "సైడ్ టేబుల్స్‌", label: "సైడ్ టేబుల్స్‌" },
    { value: "ఫ్రేమ్‌లతో చెక్క తలుపు", label: "ఫ్రేమ్‌లతో చెక్క తలుపు" },
    { value: "టీవీ యూనిట్‌", label: "టీవీ యూనిట్‌" },
    { value: "కప్‌బోర్డ్స్‌", label: "కప్‌బోర్డ్స్‌" },
  ],
  [
    { value: "ಬೆಡ್", label: "ಬೆಡ್" },
    { value: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು", label: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು" },
    {
      value: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
      label: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
    },
    { value: "TV ಯುನಿಟ್", label: "TV ಯುನಿಟ್" },
    { value: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು", label: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು" },
  ],
  [
    { value: "ബെഡ്", label: "ബെഡ്" },
    { value: "സൈഡ് ടേബിളുകൾ", label: "സൈഡ് ടേബിളുകൾ" },
    {
      value: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
      label: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
    },
    { value: "ടിവി യൂണിറ്റ്", label: "ടിവി യൂണിറ്റ്" },
    { value: "കബോഡുകള്‍", label: "കബോഡുകള്‍" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "ছাইড টেবুলছ", label: "ছাইড টেবুলছ" },
    { value: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ", label: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপব’ৰ্ডছ", label: "কাপব’ৰ্ডছ" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "সাইড টেব্‌ল", label: "সাইড টেব্‌ল" },
    { value: "ফ্রেম সহ কাঠের চেয়ার", label: "ফ্রেম সহ কাঠের চেয়ার" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপবোর্ড", label: "কাপবোর্ড" },
  ],
  [
    { value: "ବେଡ୍", label: "ବେଡ୍" },
    { value: "ସାଇଡ ଟେବୁଲ୍", label: "ସାଇଡ ଟେବୁଲ୍" },
    { value: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ", label: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ" },
    { value: "ଟିଭି ୟୁନିଟ୍", label: "ଟିଭି ୟୁନିଟ୍" },
    { value: "କପ୍‌ବୋର୍ଡ", label: "କପ୍‌ବୋର୍ଡ" },
  ],
];

export const BedRoom2 = [
  [
    { value: "Bed", label: "Bed" },
    { value: "Side Tables", label: "Side Tables" },
    { value: "Wooden door with Frames", label: "Wooden door with Frames" },
    { value: "TV Unit", label: "TV Unit" },
    { value: "Cupboards", label: "Cupboards" },
  ],
  [
    { value: "बेड", label: "बेड" },
    { value: "साइड टेबल", label: "साइड टेबल" },
    { value: "वूडन डोर फ्रेम्स के साथ", label: "वूडन डोर फ्रेम्स के साथ" },
    { value: "टीवी यूनिट", label: "टीवी यूनिट" },
    { value: "कपबोर्ड", label: "कपबोर्ड" },
  ],
  [
    { value: "பெட்", label: "பெட்" },
    { value: "சைடு டேபிள்கள்", label: "சைடு டேபிள்கள்" },
    { value: "பிரேமுடன் கூடிய மர கதவு", label: "பிரேமுடன் கூடிய மர கதவு" },
    { value: "டிவி யூனிட்", label: "டிவி யூனிட்" },
    { value: "கப்போர்டுகள்", label: "கப்போர்டுகள்" },
  ],
  [
    { value: "బెడ్‌", label: "బెడ్‌" },
    { value: "సైడ్ టేబుల్స్‌", label: "సైడ్ టేబుల్స్‌" },
    { value: "ఫ్రేమ్‌లతో చెక్క తలుపు", label: "ఫ్రేమ్‌లతో చెక్క తలుపు" },
    { value: "టీవీ యూనిట్‌", label: "టీవీ యూనిట్‌" },
    { value: "కప్‌బోర్డ్స్‌", label: "కప్‌బోర్డ్స్‌" },
  ],
  [
    { value: "ಬೆಡ್", label: "ಬೆಡ್" },
    { value: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು", label: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು" },
    {
      value: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
      label: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
    },
    { value: "TV ಯುನಿಟ್", label: "TV ಯುನಿಟ್" },
    { value: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು", label: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು" },
  ],
  [
    { value: "ബെഡ്", label: "ബെഡ്" },
    { value: "സൈഡ് ടേബിളുകൾ", label: "സൈഡ് ടേബിളുകൾ" },
    {
      value: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
      label: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
    },
    { value: "ടിവി യൂണിറ്റ്", label: "ടിവി യൂണിറ്റ്" },
    { value: "കബോഡുകള്‍", label: "കബോഡുകള്‍" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "ছাইড টেবুলছ", label: "ছাইড টেবুলছ" },
    { value: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ", label: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপব’ৰ্ডছ", label: "কাপব’ৰ্ডছ" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "সাইড টেব্‌ল", label: "সাইড টেব্‌ল" },
    { value: "ফ্রেম সহ কাঠের চেয়ার", label: "ফ্রেম সহ কাঠের চেয়ার" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপবোর্ড", label: "কাপবোর্ড" },
  ],
  [
    { value: "ବେଡ୍", label: "ବେଡ୍" },
    { value: "ସାଇଡ ଟେବୁଲ୍", label: "ସାଇଡ ଟେବୁଲ୍" },
    { value: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ", label: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ" },
    { value: "ଟିଭି ୟୁନିଟ୍", label: "ଟିଭି ୟୁନିଟ୍" },
    { value: "କପ୍‌ବୋର୍ଡ", label: "କପ୍‌ବୋର୍ଡ" },
  ],
];

export const BedRoom3 = [
  [
    { value: "Bed", label: "Bed" },
    { value: "Side Tables", label: "Side Tables" },
    { value: "Wooden door with Frames", label: "Wooden door with Frames" },
    { value: "TV Unit", label: "TV Unit" },
    { value: "Cupboards", label: "Cupboards" },
  ],
  [
    { value: "बेड", label: "बेड" },
    { value: "साइड टेबल", label: "साइड टेबल" },
    { value: "वूडन डोर फ्रेम्स के साथ", label: "वूडन डोर फ्रेम्स के साथ" },
    { value: "टीवी यूनिट", label: "टीवी यूनिट" },
    { value: "कपबोर्ड", label: "कपबोर्ड" },
  ],
  [
    { value: "பெட்", label: "பெட்" },
    { value: "சைடு டேபிள்கள்", label: "சைடு டேபிள்கள்" },
    { value: "பிரேமுடன் கூடிய மர கதவு", label: "பிரேமுடன் கூடிய மர கதவு" },
    { value: "டிவி யூனிட்", label: "டிவி யூனிட்" },
    { value: "கப்போர்டுகள்", label: "கப்போர்டுகள்" },
  ],
  [
    { value: "బెడ్‌", label: "బెడ్‌" },
    { value: "సైడ్ టేబుల్స్‌", label: "సైడ్ టేబుల్స్‌" },
    { value: "ఫ్రేమ్‌లతో చెక్క తలుపు", label: "ఫ్రేమ్‌లతో చెక్క తలుపు" },
    { value: "టీవీ యూనిట్‌", label: "టీవీ యూనిట్‌" },
    { value: "కప్‌బోర్డ్స్‌", label: "కప్‌బోర్డ్స్‌" },
  ],
  [
    { value: "ಬೆಡ್", label: "ಬೆಡ್" },
    { value: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು", label: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು" },
    {
      value: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
      label: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
    },
    { value: "TV ಯುನಿಟ್", label: "TV ಯುನಿಟ್" },
    { value: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು", label: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು" },
  ],
  [
    { value: "ബെഡ്", label: "ബെഡ്" },
    { value: "സൈഡ് ടേബിളുകൾ", label: "സൈഡ് ടേബിളുകൾ" },
    {
      value: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
      label: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
    },
    { value: "ടിവി യൂണിറ്റ്", label: "ടിവി യൂണിറ്റ്" },
    { value: "കബോഡുകള്‍", label: "കബോഡുകള്‍" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "ছাইড টেবুলছ", label: "ছাইড টেবুলছ" },
    { value: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ", label: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপব’ৰ্ডছ", label: "কাপব’ৰ্ডছ" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "সাইড টেব্‌ল", label: "সাইড টেব্‌ল" },
    { value: "ফ্রেম সহ কাঠের চেয়ার", label: "ফ্রেম সহ কাঠের চেয়ার" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপবোর্ড", label: "কাপবোর্ড" },
  ],
  [
    { value: "ବେଡ୍", label: "ବେଡ୍" },
    { value: "ସାଇଡ ଟେବୁଲ୍", label: "ସାଇଡ ଟେବୁଲ୍" },
    { value: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ", label: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ" },
    { value: "ଟିଭି ୟୁନିଟ୍", label: "ଟିଭି ୟୁନିଟ୍" },
    { value: "କପ୍‌ବୋର୍ଡ", label: "କପ୍‌ବୋର୍ଡ" },
  ],
];

export const BedRoom4 = [
  [
    { value: "Bed", label: "Bed" },
    { value: "Side Tables", label: "Side Tables" },
    { value: "Wooden door with Frames", label: "Wooden door with Frames" },
    { value: "TV Unit", label: "TV Unit" },
    { value: "Cupboards", label: "Cupboards" },
  ],
  [
    { value: "बेड", label: "बेड" },
    { value: "साइड टेबल", label: "साइड टेबल" },
    { value: "वूडन डोर फ्रेम्स के साथ", label: "वूडन डोर फ्रेम्स के साथ" },
    { value: "टीवी यूनिट", label: "टीवी यूनिट" },
    { value: "कपबोर्ड", label: "कपबोर्ड" },
  ],
  [
    { value: "பெட்", label: "பெட்" },
    { value: "சைடு டேபிள்கள்", label: "சைடு டேபிள்கள்" },
    { value: "பிரேமுடன் கூடிய மர கதவு", label: "பிரேமுடன் கூடிய மர கதவு" },
    { value: "டிவி யூனிட்", label: "டிவி யூனிட்" },
    { value: "கப்போர்டுகள்", label: "கப்போர்டுகள்" },
  ],
  [
    { value: "బెడ్‌", label: "బెడ్‌" },
    { value: "సైడ్ టేబుల్స్‌", label: "సైడ్ టేబుల్స్‌" },
    { value: "ఫ్రేమ్‌లతో చెక్క తలుపు", label: "ఫ్రేమ్‌లతో చెక్క తలుపు" },
    { value: "టీవీ యూనిట్‌", label: "టీవీ యూనిట్‌" },
    { value: "కప్‌బోర్డ్స్‌", label: "కప్‌బోర్డ్స్‌" },
  ],
  [
    { value: "ಬೆಡ್", label: "ಬೆಡ್" },
    { value: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು", label: "ಸೈಡ್ ಟೇಬಲ್‌ಗಳು" },
    {
      value: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
      label: "ಫ್ರೇಮ್‌ಗಳನ್ನು ಹೊಂದಿರುವ ವುಡನ್ ಡೋರ್",
    },
    { value: "TV ಯುನಿಟ್", label: "TV ಯುನಿಟ್" },
    { value: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು", label: "ಕಪ್‌ಬೋರ್ಡ್‌ಗಳು" },
  ],
  [
    { value: "ബെഡ്", label: "ബെഡ്" },
    { value: "സൈഡ് ടേബിളുകൾ", label: "സൈഡ് ടേബിളുകൾ" },
    {
      value: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
      label: "ഫ്രെയിമുകളുള്ള തടികൊണ്ടുള്ള വാതിൽ",
    },
    { value: "ടിവി യൂണിറ്റ്", label: "ടിവി യൂണിറ്റ്" },
    { value: "കബോഡുകള്‍", label: "കബോഡുകള്‍" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "ছাইড টেবুলছ", label: "ছাইড টেবুলছ" },
    { value: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ", label: "ফ্ৰেমছৰ সৈতে কাঠৰ ডোৰ" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপব’ৰ্ডছ", label: "কাপব’ৰ্ডছ" },
  ],
  [
    { value: "বেড", label: "বেড" },
    { value: "সাইড টেব্‌ল", label: "সাইড টেব্‌ল" },
    { value: "ফ্রেম সহ কাঠের চেয়ার", label: "ফ্রেম সহ কাঠের চেয়ার" },
    { value: "টিভি ইউনিট", label: "টিভি ইউনিট" },
    { value: "কাপবোর্ড", label: "কাপবোর্ড" },
  ],
  [
    { value: "ବେଡ୍", label: "ବେଡ୍" },
    { value: "ସାଇଡ ଟେବୁଲ୍", label: "ସାଇଡ ଟେବୁଲ୍" },
    { value: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ", label: "ଫ୍ରେମ୍ ଥିବା କାଠର କବାଟ" },
    { value: "ଟିଭି ୟୁନିଟ୍", label: "ଟିଭି ୟୁନିଟ୍" },
    { value: "କପ୍‌ବୋର୍ଡ", label: "କପ୍‌ବୋର୍ଡ" },
  ],
];

export const OtherAreas = [
  [
    { value: "Wooden flooring", label: "Wooden flooring" },
    { value: "Wooden railing/staircase", label: "Wooden railing/staircase" },
    { value: "Wooden ceiling", label: "Wooden ceiling" },
    { value: "Wall paneling", label: "Wall paneling" },
  ],
  [
    { value: "वूडन फ्लोरिंग", label: "वूडन फ्लोरिंग" },
    { value: "वूडन रेलिंग/सीढियां", label: "वूडन रेलिंग/सीढियां" },
    { value: "वूडन सीलिंग", label: "वूडन सीलिंग" },
    { value: "वाल पैनलिंग", label: "वाल पैनलिंग" },
  ],
  [
    { value: "மர ஃப்ளோரிங்", label: "மர ஃப்ளோரிங்" },
    { value: "மர ரைலிங்/படிக்கட்டு", label: "மர ரைலிங்/படிக்கட்டு" },
    { value: "மர சீலிங்", label: "மர சீலிங்" },
    { value: "வால் பேனலிங்", label: "வால் பேனலிங்" },
  ],
  [
    { value: "చెక్క ఫ్లోరింగ్‌", label: "చెక్క ఫ్లోరింగ్‌" },
    { value: "చెక్క రెయిలింగ్/మెట్లు", label: "చెక్క రెయిలింగ్/మెట్లు" },
    { value: "చెక్క సీలింగ్‌", label: "చెక్క సీలింగ్‌" },
    { value: "వాల్‌ ప్యానెలింగ్‌", label: "వాల్‌ ప్యానెలింగ్‌" },
  ],
  [
    { value: "ವುಡನ್ ಫ್ಲೋರಿಂಗ್", label: "ವುಡನ್ ಫ್ಲೋರಿಂಗ್" },
    { value: "ವುಡನ್ ರೇಲಿಂಗ್/ಸ್ಟೇರ್‌ಕೇಸ್", label: "ವುಡನ್ ರೇಲಿಂಗ್/ಸ್ಟೇರ್‌ಕೇಸ್" },
    { value: "ವುಡನ್ ಸೀಲಿಂಗ್", label: "ವುಡನ್ ಸೀಲಿಂಗ್" },
    { value: "ವಾಲ್ ಪ್ಯಾನೆಲಿಂಗ್", label: "ವಾಲ್ ಪ್ಯಾನೆಲಿಂಗ್" },
  ],
  [
    { value: "തടി ഫ്ലോറിംഗ്", label: "തടി ഫ്ലോറിംഗ്" },
    {
      value: "തടികൊണ്ടുള്ള റെയിലിംഗ്/പടി",
      label: "തടികൊണ്ടുള്ള റെയിലിംഗ്/പടി",
    },
    { value: "തടി സീലിംഗ്", label: "തടി സീലിംഗ്" },
    { value: "വാള്‍ പാനലിംഗ്", label: "വാള്‍ പാനലിംഗ്" },
  ],
  [
    { value: "কাঠৰ ফ্ল’ৰিং", label: "কাঠৰ ফ্ল’ৰিং" },
    { value: "কাঠৰ ৰেইলিং/ষ্টেয়াৰকেছ", label: "কাঠৰ ৰেইলিং/ষ্টেয়াৰকেছ" },
    { value: "কাঠৰ চিলিং", label: "কাঠৰ চিলিং" },
    { value: "ৱাল পেনেলিং", label: "ৱাল পেনেলিং" },
  ],
  [
    { value: "কাঠের ফ্লোরিং", label: "কাঠের ফ্লোরিং" },
    { value: "কাঠের রেলিং/সিঁড়ি", label: "কাঠের রেলিং/সিঁড়ি" },
    { value: "উডেন সিলিং", label: "উডেন সিলিং" },
    { value: "ওয়াল প্যানেলিং", label: "ওয়াল প্যানেলিং" },
  ],
  [
    { value: "କାଠର ଫ୍ଲୋରିଂ", label: "କାଠର ଫ୍ଲୋରିଂ" },
    { value: "କାଠ ରେଲିଂ/ପାହାଚ", label: "କାଠ ରେଲିଂ/ପାହାଚ" },
    { value: "କାଠ ସିଲିଂ", label: "କାଠ ସିଲିଂ" },
    { value: "ୱାଲ୍ ପ୍ୟାନେଲିଂ", label: "ୱାଲ୍ ପ୍ୟାନେଲିଂ" },
  ],
];

export const Room = [
  [
    { value: "Exterior + Entrance", label: "Exterior + Entrance" },
    { value: "Kitchen + Dining Room", label: "Kitchen + Dining Room" },
    { value: "Living Room", label: "Living Room" },
    { value: "Bed Room 1", label: "Bed Room 1" },
    { value: "Bed Room 2", label: "Bed Room 2" },
    { value: "Bed Room 3", label: "Bed Room 3" },
    { value: "Bed Room 4", label: "Bed Room 4" },
    { value: "Other Areas", label: "Other Areas" },
  ],
  [
    { value: "एक्सटीरियर + प्रवेश", label: "एक्सटीरियर + प्रवेश" },
    { value: "किचन + डाइनिंग रुम", label: "किचन + डाइनिंग रुम" },
    { value: "लिविंग रुम", label: "लिविंग रुम" },
    { value: "बेड रुम 1", label: "बेड रुम 1" },
    { value: "बेड रुम 2", label: "बेड रुम 2" },
    { value: "बेड रुम 3", label: "बेड रुम 3" },
    { value: "बेड रुम 4", label: "बेड रुम 4" },
    { value: "अन्य एरिया", label: "अन्य एरिया" },
  ],
  [
    { value: "எக்ஸ்டீரியர் + முன்வாசல்", label: "எக்ஸ்டீரியர் + முன்வாசல்" },
    { value: "கிச்சன் + டைனிங் ரூம்", label: "கிச்சன் + டைனிங் ரூம்" },
    { value: "லிவ்விங் ரூம்", label: "லிவ்விங் ரூம்" },
    { value: "பெட் ரூம் 1", label: "பெட் ரூம் 1" },
    { value: "பெட் ரூம் 2", label: "பெட் ரூம் 2" },
    { value: "பெட் ரூம் 3", label: "பெட் ரூம் 3" },
    { value: "பெட் ரூம் 4", label: "பெட் ரூம் 4" },
    { value: "பிற ஏரியாக்கள்", label: "பிற ஏரியாக்கள்" },
  ],
  [
    { value: "ఎక్స్‌టీరియర్ + ప్రవేశం", label: "ఎక్స్‌టీరియర్ + ప్రవేశం" },
    { value: "కిచెన్ + డైనింగ్‌ రూమ్‌", label: "కిచెన్ + డైనింగ్‌ రూమ్‌" },
    { value: "లివింగ్ రూమ్‌", label: "లివింగ్ రూమ్‌" },
    { value: "బెడ్‌ రూమ్‌ 1", label: "బెడ్‌ రూమ్‌ 1" },
    { value: "బెడ్‌ రూమ్‌ 2", label: "బెడ్‌ రూమ్‌ 2" },
    { value: "బెడ్‌ రూమ్‌ 3", label: "బెడ్‌ రూమ్‌ 3" },
    { value: "బెడ్‌ రూమ్‌ 4", label: "బెడ్‌ రూమ్‌ 4" },
    { value: "ఇతర ఏరియాస్‌", label: "ఇతర ఏరియాస్‌" },
  ],
  [
    {
      value: "ಎಕ್ಸ್‌ಟೀರಿಯರ್ + ಎಂಟ್ರಾನ್ಸ್",
      label: "ಎಕ್ಸ್‌ಟೀರಿಯರ್ + ಎಂಟ್ರಾನ್ಸ್",
    },
    { value: "ಕಿಚನ್ + ಡೈನಿಂಗ್ ರೂಮ್", label: "ಕಿಚನ್ + ಡೈನಿಂಗ್ ರೂಮ್" },
    { value: "ಲೀವಿಂಗ್ ರೂಮ್", label: "ಲೀವಿಂಗ್ ರೂಮ್" },
    { value: "ಬೆಡ್ ರೂಮ್ 1", label: "ಬೆಡ್ ರೂಮ್ 1" },
    { value: "ಬೆಡ್ ರೂಮ್ 2", label: "ಬೆಡ್ ರೂಮ್ 2" },
    { value: "ಬೆಡ್ ರೂಮ್ 3", label: "ಬೆಡ್ ರೂಮ್ 3" },
    { value: "ಬೆಡ್ ರೂಮ್ 4", label: "ಬೆಡ್ ರೂಮ್ 4" },
    { value: "ಇತರ ಏರಿಯಾಗಳು", label: "ಇತರ ಏರಿಯಾಗಳು" },
  ],
  [
    {
      value: "എക്സ്റ്റീരിയര്‍+ എന്‍ട്രന്‍സ്‌",
      label: "എക്സ്റ്റീരിയര്‍+ എന്‍ട്രന്‍സ്‌",
    },
    { value: "കിച്ചന്‍+ ഡൈനിംഗ് റൂം", label: "കിച്ചന്‍+ ഡൈനിംഗ് റൂം" },
    { value: "ലിവിംഗ് റൂം", label: "ലിവിംഗ് റൂം" },
    { value: "ബെഡ് റൂം 1", label: "ബെഡ് റൂം 1" },
    { value: "ബെഡ് റൂം 2", label: "ബെഡ് റൂം 2" },
    { value: "ബെഡ് റൂം 3", label: "ബെഡ് റൂം 3" },
    { value: "ബെഡ് റൂം 4", label: "ബെഡ് റൂം 4" },
    { value: "മറ്റ് ഏരിയകൾ", label: "മറ്റ് ഏരിയകൾ" },
  ],
  [
    { value: "এক্সটিৰিঅ’ৰ + প্ৰৱেশ", label: "এক্সটিৰিঅ’ৰ + প্ৰৱেশ" },
    { value: "কিচ্চেন + ডাইনিং ৰূম", label: "কিচ্চেন + ডাইনিং ৰূম" },
    { value: "লিভিং ৰূম", label: "লিভিং ৰূম" },
    { value: "বেড ৰূম 1", label: "বেড ৰূম 1" },
    { value: "বেড ৰূম 2", label: "বেড ৰূম 2" },
    { value: "বেড ৰূম 3", label: "বেড ৰূম 3" },
    { value: "বেড ৰূম 4", label: "বেড ৰূম 4" },
    { value: "অন্যান্য এৰিয়াজ", label: "অন্যান্য এৰিয়াজ" },
  ],
  [
    { value: "এক্সটিরিয়র + প্রবেশদ্বার", label: "এক্সটিরিয়র + প্রবেশদ্বার" },
    { value: "বেড রুম ১", label: "বেড রুম ১" },
    { value: "লিভিং রুম", label: "লিভিং রুম" },
    { value: "বেড রুম ১", label: "বেড রুম ১" },
    { value: "বেড রুম ২", label: "বেড রুম ২" },
    { value: "বেড রুম ৩", label: "বেড রুম ৩" },
    { value: "বেড রুম ৪", label: "বেড রুম ৪" },
    { value: "অন্যান্য এরিয়া", label: "অন্যান্য এরিয়া" },
  ],
  [
    { value: "ଏକ୍ସଟେରିଅର୍+ ଏଣ୍ଟ୍ରାନ୍ସ", label: "ଏକ୍ସଟେରିଅର୍+ ଏଣ୍ଟ୍ରାନ୍ସ" },
    { value: "କିଚେନ୍ + ଡାଇନିଂ ରୁମ୍", label: "କିଚେନ୍ + ଡାଇନିଂ ରୁମ୍" },
    { value: "ଲିଭିଂ ରୁମ୍", label: "ଲିଭିଂ ରୁମ୍" },
    { value: "ବେଡ ରୁମ୍ 1", label: "ବେଡ ରୁମ୍ 1" },
    { value: "ବେଡ ରୁମ୍ 2", label: "ବେଡ ରୁମ୍ 2" },
    { value: "ବେଡ ରୁମ୍ 3", label: "ବେଡ ରୁମ୍ 3" },
    { value: "ବେଡ ରୁମ୍ 4", label: "ବେଡ ରୁମ୍ 4" },
    { value: "ଅନ୍ୟ ଏରିଆଗୁଡ଼ିକ", label: "ଅନ୍ୟ ଏରିଆଗୁଡ଼ିକ" },
  ],
];

export const Products = [
  //ENGLISH
  [
    {
      value: "WoodTech Emporio Acrylic PU",
      label: "WoodTech Emporio Acrylic PU",
    },
    { value: "WoodTech Aquadur PU", label: "WoodTech Aquadur PU" },
    { value: "WoodTech Emporio PU", label: "WoodTech Emporio PU" },
    {
      value: "WoodTech Emporio PU Palette",
      label: "WoodTech Emporio PU Palette",
    },
    { value: "WoodTech Insignia", label: "WoodTech Insignia" },
    { value: "WoodTech Polyester", label: "WoodTech Polyester" },
    { value: "WoodTech PU Palette", label: "WoodTech PU Palette" },
    { value: "WoodTech Ingenio PU", label: "WoodTech Ingenio PU" },
    { value: "WoodTech Epoxy", label: "WoodTech Epoxy" },
  ],
  //HINDI
  [
    {
      value: "वूडटेक एम्पोरियो एक्रिलिक पीयू",
      label: "वूडटेक एम्पोरियो एक्रिलिक पीयू",
    },
    { value: "वूडटेक एक्वाडूर पीयू", label: "वूडटेक एक्वाडूर पीयू" },
    { value: "वूडटेक एम्पोरियो पीयू", label: "वूडटेक एम्पोरियो पीयू" },
    {
      value: "वूडटेड एम्पोरियो पीयू पैलेट",
      label: "वूडटेड एम्पोरियो पीयू पैलेट",
    },
    { value: "वूडटेक इनसिग्निया", label: "वूडटेक इनसिग्निया" },
    { value: "वूडटेक पॉलिस्टर", label: "वूडटेक पॉलिस्टर" },

    { value: "वुडटेक इंजिनियो पीयू", label: "वुडटेक इंजिनियो पीयू" },
    { value: "वुडटेक पीयू पैलेट", label: "वुडटेक पीयू पैलेट" },
    { value: "वुडटेक एपॉक्सी", label: "वुडटेक एपॉक्सी" },
  ],
  //TAMIL
  [
    {
      value: "வுட்டெக் எம்போரியோ அக்ரிலிக் பியு",
      label: "வுட்டெக் எம்போரியோ அக்ரிலிக் பியு",
    },
    { value: "வுட்டெக் அக்வாடுர் பியு", label: "வுட்டெக் அக்வாடுர் பியு" },
    { value: "வுட்டெக் எம்போரியோ பியு", label: "வுட்டெக் எம்போரியோ பியு" },
    {
      value: "வுட்டெக் எம்போரியோ பியு பேலட்",
      label: "வுட்டெக் எம்போரியோ பியு பேலட்",
    },
    { value: "வுட்டெக் இன்சிக்னியா", label: "வுட்டெக் இன்சிக்னியா" },
    { value: "வுட்டெக் பாலிஸ்டர்", label: "வுட்டெக் பாலிஸ்டர்" },

    { value: "வுட்டெக் இன்ஜினியோ பியூ", label: "வுட்டெக் இன்ஜினியோ பியூ" },
    { value: "வுட்டெக் பியூ பேலட்", label: "வுட்டெக் பியூ பேலட்" },
    { value: "வூட்டெக் எபோக்சி", label: "வூட்டெக் எபோக்சி" },
  ],
  //TELEGU
  [
    {
      value: "వుడ్‌టెక్ ఎంపీరియో అక్రిలిక్ పీయూ",
      label: "వుడ్‌టెక్ ఎంపీరియో అక్రిలిక్ పీయూ",
    },
    { value: "వుడ్‌టెక్ ఆక్వాడర్ పీయూ", label: "వుడ్‌టెక్ ఆక్వాడర్ పీయూ" },
    { value: "వుడ్‌టెక్ ఎంపీరియో పీయూ", label: "వుడ్‌టెక్ ఎంపీరియో పీయూ" },
    {
      value: "వుడ్‌టెక్ ఎంపీరియో పీయూ ప్యాలెట్‌",
      label: "వుడ్‌టెక్ ఎంపీరియో పీయూ ప్యాలెట్‌",
    },
    { value: "వుడ్‌టెక్ ఇన్‌సైనియా", label: "వుడ్‌టెక్ ఇన్‌సైనియా" },
    { value: "వుడ్‌టెక్ పాలియెస్టర్‌", label: "వుడ్‌టెక్ పాలియెస్టర్‌" },

    { value: "వుడ్‌టెక్ ఇంజీనియో పీయూ", label: "వుడ్‌టెక్ ఇంజీనియో పీయూ" },
    { value: "వుడ్‌టెక్ పీయూ ప్యాలెట్", label: "వుడ్‌టెక్ పీయూ ప్యాలెట్" },
    { value: "వుడ్‌టెక్ ఎపోక్సీ", label: "వుడ్‌టెక్ ఎపోక్సీ" },
  ],

  //KANNADA
  [
    {
      value: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ ಆಕ್ರಿಲಿಕ್ PU",
      label: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ ಆಕ್ರಿಲಿಕ್ PU",
    },
    { value: "ವುಡ್‌ಟೆಕ್ ಅಕ್ವಾಡುರ್ PU", label: "ವುಡ್‌ಟೆಕ್ ಅಕ್ವಾಡುರ್ PU" },
    { value: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ PU", label: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ PU" },
    {
      value: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ PU ಪ್ಯಾಲೆಟ್",
      label: "ವುಡ್‌ಟೆಕ್ ಎಂಪೋರಿಯೋ PU ಪ್ಯಾಲೆಟ್",
    },
    { value: "ವುಡ್‌ಟೆಕ್ ಇನ್‌ಸಿಗ್ನಿಯಾ", label: "ವುಡ್‌ಟೆಕ್ ಇನ್‌ಸಿಗ್ನಿಯಾ" },
    { value: "ವುಡ್‌ಟೆಕ್ ಪಾಲಿಯೆಸ್ಟರ್", label: "ವುಡ್‌ಟೆಕ್ ಪಾಲಿಯೆಸ್ಟರ್" },

    { value: "ವುಡ್‌ಟೆಕ್ ಇಂಜೆನಿಯೋ PU", label: "ವುಡ್‌ಟೆಕ್ ಇಂಜೆನಿಯೋ PU" },
    { value: "ವುಡ್‌ಟೆಕ್ PU ಪ್ಯಾಲೆಟ್", label: "ವುಡ್‌ಟೆಕ್ PU ಪ್ಯಾಲೆಟ್" },
    { value: "ವುಡ್ಟೆಕ್ ಎಪಾಕ್ಸಿ", label: "ವುಡ್ಟೆಕ್ ಎಪಾಕ್ಸಿ" },
  ],

  //MALAYALAM
  [
    {
      value: "വുഡ്‌ടെക് എംപോറിയോ അക്രിലിക് പി.യു",
      label: "വുഡ്‌ടെക് എംപോറിയോ അക്രിലിക് പി.യു",
    },
    { value: "വുഡ്‌ടെക് അക്വാഡൂർ പി.യു", label: "വുഡ്‌ടെക് അക്വാഡൂർ പി.യു" },
    { value: "വുഡ്‌ടെക് എംപോറിയോ പി.യു", label: "വുഡ്‌ടെക് എംപോറിയോ പി.യു" },
    {
      value: "വുഡ്‌ടെക് എംപോറിയോ പിയു പാലറ്റ്",
      label: "വുഡ്‌ടെക് എംപോറിയോ പിയു പാലറ്റ്",
    },
    { value: "വുഡ്‌ടെക് ഇന്‍സൈനിയ", label: "വുഡ്‌ടെക് ഇന്‍സൈനിയ" },
    { value: "വുഡ്ടെക് പോളിയസ്റ്റർ", label: "വുഡ്ടെക് പോളിയസ്റ്റർ" },

    { value: "വുഡ്‌ടെക് ഇൻജെനിയോ പി.യു", label: "വുഡ്‌ടെക് ഇൻജെനിയോ പി.യു" },
    { value: "വുഡ്‌ടെക് പിയു പാലറ്റ്", label: "വുഡ്‌ടെക് പിയു പാലറ്റ്" }, 
    { value: "വുഡ്ടെക് എപ്പോക്സി", label: "വുഡ്ടെക് എപ്പോക്സി" }, 
  ],
  //ASSAMESE
  [
    {
      value: "উডটেক এম্প’ৰিঅ’ এক্ৰীলিক PU",
      label: "উডটেক এম্প’ৰিঅ’ এক্ৰীলিক PU",
    },
    { value: "উডটেক একুৱাডিউৰ PU", label: "উডটেক একুৱাডিউৰ PU" },
    { value: "উডটেক এম্প’ৰিঅ’ PU", label: "উডটেক এম্প’ৰিঅ’ PU" },
    { value: "উডটেক এম্প’ৰিঅ’ PU পেলেট", label: "উডটেক এম্প’ৰিঅ’ PU পেলেট" },
    { value: "উডটেক ইনছাইনিয়া", label: "উডটেক ইনছাইনিয়া" },
    { value: "উডটেক পলীয়েষ্টাৰ", label: "উডটেক পলীয়েষ্টাৰ" },

    { value: "উডটেক ইন্‌জিনিঅ’ PU", label: "উডটেক ইন্‌জিনিঅ’ PU" },
    { value: "উডটেক PU পেলেট", label: "উডটেক PU পেলেট" },
    { value: "WoodTech Epoxy", label: "WoodTech Epoxy" },
  ],

  //BENGALI
  [
    {
      value: "উডটেক এম্পোরিও অ্যাক্রিলিক পিইউ",
      label: "উডটেক এম্পোরিও অ্যাক্রিলিক পিইউ",
    },
    { value: "উডটেক অ্যাকোয়াডুর পিইউ", label: "উডটেক অ্যাকোয়াডুর পিইউ" },
    { value: "উডটেক এম্পোরিও পিইউ", label: "উডটেক এম্পোরিও পিইউ" },
    {
      value: "উডটেক এম্পোরিও পিইউ প্যালেট",
      label: "উডটেক এম্পোরিও পিইউ প্যালেট",
    },
    { value: "উডটেক ইনসিগনিয়া", label: "উডটেক ইনসিগনিয়া" },
    { value: "উডটেক পলিয়েস্টার", label: "উডটেক পলিয়েস্টার" },

    { value: "উটটেক ইনজেনিও পিইউ", label: "উটটেক ইনজেনিও পিইউ" },
    { value: "উডটেক পিইউ প্যালেট", label: "উডটেক পিইউ প্যালেট" },
    { value: "WoodTech Epoxy", label: "WoodTech Epoxy" },
  ],

  //ORIYA
  [
    {
      value: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ଆକ୍ରିଲିକ୍ ପିୟୁ",
      label: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ଆକ୍ରିଲିକ୍ ପିୟୁ",
    },
    { value: "ଉଡଟେକ୍ ଆକ୍ୱାଡୁର୍ ପିୟୁ", label: "ଉଡଟେକ୍ ଆକ୍ୱାଡୁର୍ ପିୟୁ" },
    { value: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ପିୟୁ", label: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ପିୟୁ" },
    {
      value: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ପିୟୁ ପାଲେଟ୍",
      label: "ଉଡଟେକ୍ ଏମ୍ପୋରିଓ ପିୟୁ ପାଲେଟ୍",
    },
    { value: "ଉଡଟେକ୍ ଇନସିଗନିଆ", label: "ଉଡଟେକ୍ ଇନସିଗନିଆ" },
    { value: "ଉଡଟେକ୍ ପଲିଷ୍ଟର୍", label: "ଉଡଟେକ୍ ପଲିଷ୍ଟର୍" },

    { value: "ଉଡଟେକ୍ ଇନଜେନିଓ ପିୟୁ", label: "ଉଡଟେକ୍ ଇନଜେନିଓ ପିୟୁ" },
    { value: "ଉଡଟେକ ପିୟୁ ପାଲେଟ୍", label: "ଉଡଟେକ ପିୟୁ ପାଲେଟ୍" },
    { value: "WoodTech Epoxy", label: "WoodTech Epoxy" },
  ],
];
