//Import modules

import Server from "../API";
import {
  LOGIN,
  CLEAR_LOGIN_STATUS,
  RESET_STATE,
  SAVE_SIDEBAR_ID,
  GET_USER_DETAILS,
  CHANGE_LANGUAGE,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * Call login API
 * param: params take login object
 * return: dispatch action to store userinfo
 *
 */
const LoginAction = (params) => {
  localStorage.clear();
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/helpline/login", params);
      dispatch(StopLoader());
      dispatch({
        type: LOGIN,
        payload: { user: params, server: response.data },
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Save Sidebar active index
 * param: id of sidebar active
 * return: Action to save id
 */
const GetUserDetails = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/get-info", params);
      dispatch(StopLoader());
      dispatch({
        type: GET_USER_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Logout from session
 * return: Action to reset all redux store
 */
const Logout = () => {
  //   localStorage.removeItem("UserInfo");
  localStorage.removeItem("store-state");
  return {
    type: RESET_STATE,
    payload: null,
  };
};

/**
 * Save Sidebar active index
 * param: id of sidebar active
 * return: Action to save id
 */
const SaveSidebarIndex = (id) => {
  return {
    type: SAVE_SIDEBAR_ID,
    payload: id,
  };
};

/**
 * Save Sidebar active index
 * return: Action to clear all status
 */
const ClearLoginStatus = () => {
  return {
    type: CLEAR_LOGIN_STATUS,
    payload: null,
  };
};

/**
 * Save DSR user details
 * return: Action to clear all status
 */
/* const SaveDSRUserDetails = (params) => {
  return {
    type: SAVE_DSR_DETAILS,
    payload: params,
  };
};
 */
/**
 * Change language
 * return:  set language id
 */
const SetLanguageId = (params) => {
  return {
    type: CHANGE_LANGUAGE,
    payload: params,
  };
};

// Named Exports
export {
  LoginAction,
  ClearLoginStatus,
  Logout,
  SaveSidebarIndex,
  //   SaveDSRUserDetails,
  GetUserDetails,
  SetLanguageId,
};
