import React, { Component } from "react";
import { connect } from "react-redux";
import JsZip from "jszip";
import { saveAs } from "file-saver";

import {
  ClearRegisteredStatusLog,
  DownloadFolder,
} from "../../Actions/RegisteredSites";
import w4sLogo from "../../Resources/w4s-logo.svg";
import { StartLoader, StopLoader } from "../../Actions/Common";

class DownloadFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealerInvoiceArray: [],
      furArray: [],
      prodArray: [],
      siteId: JSON.parse(decodeURIComponent(props.match.params.id)).siteId,
      leadId: JSON.parse(decodeURIComponent(props.match.params.id)).leadId,
      countOfFurnitureImages: 0,
    };
  }

  componentDidMount() {
    const ele = document.getElementsByClassName("user-header-wrapper");
    if (ele[0]) ele[0].style.display = "none";
    this.handleDownloadFiles();
  }

  componentWillUnmount() {
    const ele = document.getElementsByClassName("user-header-wrapper");
    if (ele[0]) ele[0].style.display = "flex";
  }

  componentDidUpdate() {
    //Download zip file after all files downloading
    const { folder } = this.props;
    const { furArray, prodArray, dealerInvoiceArray } = this.state;
    if (folder) {
      const { furnitureImages, productImages, dealerinvoices } = folder;
      if (furnitureImages && productImages && dealerInvoiceArray) {
        if (this.props.downloadFolderStatus) {
          let count = 0;

          //calculate the count of furniture images
          /*  for (let i = 0; i < folder.furnitureImages.length; i++) {
            count += folder.furnitureImages[i].images.length;
          } */

          //   ALTERNATE LOGIC FOR COUNT CALCULATION
          count = folder.furnitureImages.reduce((prev, current) => {
            return prev + current.images.length;
          }, 0);

          if (
            furArray.length === count &&
            prodArray.length === productImages.length &&
            dealerInvoiceArray.length === dealerinvoices.length &&
            this.props.downloadFolderStatus === 200
          ) {
            console.log("Clear the status");
            this.props.ClearRegisteredStatusLog();
            this.zip.generateAsync({ type: "blob" }).then((content) => {
              saveAs(content, `Images-${this.state.leadId}`);
            });
          }
        }
      }
    }
  }

  /**
   * handleDownloadFiles: Get images from server
   */
  handleDownloadFiles = () => {
    this.zip = new JsZip();
    this.setState(
      {
        furArray: [],
        prodArray: [],
        dealerInvoiceArray: [],
      },
      async () => {
        this.props.StartLoader();
        const reqBody = {
          siteid: this.state.siteId,
        };
        await this.props.DownloadFolder(reqBody);
        this.props.StopLoader();
        if (this.props.downloadFolderStatus === 200) {
          const { folder } = this.props;
          //   let folder = fileObject;
          if (folder) {
            let count = 0;
            if (folder.furnitureImages) {
              for (let i = 0; i < folder.furnitureImages.length; i++) {
                count += folder.furnitureImages[i].images.length;
              }
            }
            this.setState({ countOfFurnitureImages: count });
            this.downloadZipFile();
          }
        }
      }
    );
  };

  /**
   * downloadZipFile: Download images into zip file
   */
  downloadZipFile = async () => {
    const { folder } = this.props;
    // let folder = fileObject;
    console.log("download folder ", folder);
    //Create Zip structure
    let furFolder = this.zip.folder("Furniture Images");
    let prodFolder = this.zip.folder("Product Images");
    let dealer = this.zip.folder("Dealer Invoice Images");

    //Download Dealer Invoice Array
    const { dealerinvoices } = folder;
    dealerinvoices &&
      dealerinvoices.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            const list = image.link.split(".");
            const ext = list[list.length - 1];
            dealer.file(image.name + "." + ext, request.response, {
              type: Blob,
            });
            this.setState({
              dealerInvoiceArray: [...this.state.dealerInvoiceArray, index],
            });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });

    //Download Furniture images
    const { furnitureImages } = folder;
    furnitureImages &&
      furnitureImages.map(async (furniture) => {
        let furSubFolder = furFolder.folder(furniture.furniturename);
        // let downloadedArray = [];
        furniture.images &&
          furniture.images.map((image, index) => {
            let request = new XMLHttpRequest();
            request.open("GET", image.expiryLink, true);
            request.responseType = "blob";
            this.props.StartLoader();
            request.send();

            request.onload = () => {
              let reader = new FileReader();
              reader.readAsDataURL(request.response);
              reader.onload = (e) => {
                const list = image.link.split(".");
                const ext = list[list.length - 1];
                furSubFolder.file(image.name + "." + ext, request.response, {
                  type: Blob,
                });
                this.setState({ furArray: [...this.state.furArray, index] });
              };
              this.props.StopLoader();
            };
            request.onerror = () => {
              this.props.StopLoader();
            };
            return null;
          });
        // this.setState({ furArray: [...this.state.furArray, downloadedArray] });
      });
    //Download Product folder
    const { productImages } = folder;
    productImages &&
      productImages.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          const list = image.link.split(".");
          const ext = list[list.length - 1];
          reader.onload = () => {
            prodFolder.file(image.name + "." + ext, request.response);
            this.setState({ prodArray: [...this.state.prodArray, index] });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });
  };

  render() {
    return (
      <div className="download-files">
        <div className="download-files-wrapper">
          <div className="logo">
            <img src={w4sLogo} alt="Wood4sure" />
          </div>
          <div className="msg">
            Images for site with lead id: {this.state.leadId} will download
            shortly.{" "}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    downloadFolderStatus: state.regSites.downloadFolderStatus,
    folder: state.regSites.folder,
  };
};
export default connect(mapStateToProps, {
  DownloadFolder,
  StartLoader,
  StopLoader,
  ClearRegisteredStatusLog,
})(DownloadFiles);
