import React, { Component } from "react";
// import dotenv
import { Router } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import RouterComponent from "./RouterComponent";
import { SaveUserDetails } from "../Actions/SiteRegistration";
import HistoryObject from "./_Utils/History";
import { CheckMobileDevice } from "./_Utils/CheckMobileDevice";
//Paths
import {
  SITE_REGISTERATION,
  EXPIRE,
  NOTVALID,
  REGISTERED_SITE,
  HELPLINEHOME,
  LOGIN,
} from "./_Utils/Routes";
import { connect } from "react-redux";
import {
  ClearLoginStatus,
  GetUserDetails,
  Logout,
  //   SaveDSRUserDetails,
  SaveSidebarIndex,
} from "../Actions/AuthAction";
import { StopLoader } from "../Actions/Common";
import {
  CONTRACTOR_USER_TYPE,
  HELPLINE_USER,
  NEW_REGISTRATION_LINK,
  NON_AWA_USER,
  REGISTERED_SITES_LINK,
  UPDATE_VERSION,
} from "../Actions/Types";
import { CheckNonContractorUser } from "./_Utils/CheckNonContractorUser";
require("dotenv").config();

class App extends Component {
  constructor(props) {
    super(props);
    CheckMobileDevice();
  }

  handlePathWithToken = async (userInfo) => {
    this.props.Logout();
    document.cookie = "flag = true";
    userInfo.language =
      userInfo.userType === CONTRACTOR_USER_TYPE ? userInfo.language : 1;
    this.props.SaveUserDetails(userInfo, userInfo);

    //GET USER DETAILS FROM BACKEND
    await this.props.GetUserDetails({ phonenum: userInfo.phonenum });
    if (this.props.getUserDetailsStatus === 200) {
      //CLEAR THE STATUS
      this.props.ClearLoginStatus();

      const { userType } = this.props;
      const linkType = userInfo.type;
      //   console.log("link type ", linkType, userType);

      //HANDLING FOR NON-CONTRACTOR-USERS
      if (CheckNonContractorUser(userType)) {
        // this.props.SaveDSRUserDetails(userInfo);
        if (linkType === NEW_REGISTRATION_LINK) this.props.SaveSidebarIndex(2);
        else if (linkType === REGISTERED_SITES_LINK)
          this.props.SaveSidebarIndex(0);
        // else if (linkType === DASHBOARD_LINK) this.props.SaveSidebarIndex(3);
        // HistoryObject.push(DSR_HOME);
        HistoryObject.push(`/w4s/${userType}`);
        return;
      }

      //IF USER IS NON AWA THEN ROUTE TO IN VALID
      else if (userType === NON_AWA_USER) {
        console.log("Not valid popup");
        HistoryObject.push(NOTVALID);
      }
      //IF THE TOKEN FOR CONTRACTOR
      else if (userType === CONTRACTOR_USER_TYPE) {
        if (linkType === NEW_REGISTRATION_LINK)
          HistoryObject.push(SITE_REGISTERATION);
        else if (linkType === REGISTERED_SITES_LINK)
          HistoryObject.push(REGISTERED_SITE);
        else {
          console.log("Not valid popup");
          HistoryObject.push(NOTVALID);
        }
      } else {
        console.log("Not valid popup");
        HistoryObject.push(NOTVALID);
      }
    }
  };

  componentDidMount() {
    CheckMobileDevice();
    window.mobileCheck();

    //CHECK VERSION OF UPDATE
    this.checkVersion();

    //Stop loader
    this.props.StopLoader();

    window.onpopstate = function (event) {
      console.log(event);
      if (!event.state) {
        HistoryObject.goBack();
      }
    };

    //Get token and path from url
    let token = window.location.pathname.split("=")[1];
    const path = window.location.pathname;

    if (
      !path.startsWith("/w4s/helpline") &&
      !path.startsWith("/w4s/download/")
    ) {
      if (!document.cookie) {
        console.log("Not valid popup");
        HistoryObject.push(NOTVALID);
      }
    }

    // IF THE ROUTE STARTS WITH HELPLINE THEN ITS HELPLINE USER
    if (path.startsWith("/w4s/helpline")) {
      //IF USER ALREADY LOGGED ON SAME DEVICE THEN ROUTE TO DASHBOARD OR ELSE TO LOGIN SCREEN
      if (this.props.userType === HELPLINE_USER && this.props.username) {
        HistoryObject.push(HELPLINEHOME);
      } else {
        HistoryObject.push(LOGIN);
      }
    }

    //IF THE ROUTE FOR DOWNLOAD FILES
    else if (path.startsWith("/w4s/download/")) {
    }

    //HANDLE INVALID LOGIN
    else if ((path === "/w4s" || path === "/w4s/" || path === "/") && !token) {
      console.log("Not valid popup");
      HistoryObject.push(NOTVALID);
    } else if (!path.startsWith("/w4s/token") && !this.props.username) {
      console.log("Not valid popup");
      HistoryObject.push(NOTVALID);
    } else if (!this.props.username && !token) {
      console.log("Not valid popup");
      HistoryObject.push(NOTVALID);
    }

    //IF WE GOT TOKEN THEN CHECK FOR EXPIRY AND USER INFO
    else if (token) {
      try {
        //DECODE URL USING
        const encoded = decodeURIComponent(token);
        let tokenStr = atob(encoded);
        let userInfo;
        userInfo = JSON.parse(tokenStr);
        let timestamp = new Date(userInfo.timestamp);
        let timeDiff = new Date().getTime() - timestamp.getTime();

        //IF THE EXPIRY TIME IS LESS THAN 15 MIN THEN ROUTE TO EXPIRY PAGE
        if (timeDiff && timeDiff > 900000) {
          // if (timeDiff && timeDiff > 3000000000000000) {
          HistoryObject.push(EXPIRE);
        }
        //WE GOT CORRECT TIME
        else {
          this.handlePathWithToken(userInfo);
        }
      } catch (e) {
        console.log(e);
        console.log("Not valid popup");
        HistoryObject.push(NOTVALID);
      }
    }
  }

  //Check version of the updates
  checkVersion = () => {
    const version = localStorage.getItem("version");
    if (version !== UPDATE_VERSION) {
      console.log("remove store state if version mismatched");
      //clear update version
      localStorage.clear();
      localStorage.removeItem("store-state");
      localStorage.setItem("version", UPDATE_VERSION);
      window.location.reload(true);
    }
  };

  dummyInput = async (e) => {
    let obj = {};
    await readXlsxFile(e.target.files[0]).then((rows) => {
      rows.forEach((row) => {
        obj[row[0]] = row[8];
      });
    });
    console.log(obj);
  };

  render() {
    return (
      <Router history={HistoryObject}>
        {/* <input type="file" onChange={this.dummyInput} /> */}
        <RouterComponent language={this.props.language} />
        {/* {window.outerWidth} X {window.outerHeight} */}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    type: state.auth.type,
    // user: state.auth.user,
    userType: state.auth.userType,
    language: state.auth.language,
    getUserDetailsStatus: state.auth.getUserDetailsStatus,
  };
};

export default connect(mapStateToProps, {
  SaveUserDetails,
  Logout,
  StopLoader,
  //   SaveDSRUserDetails,
  SaveSidebarIndex,
  ClearLoginStatus,
  GetUserDetails,
})(App);
