import Server from "../API";
import { GET_DEALER_INFO, CLEAR_DEALER_STATUS } from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * AddProductImages: Add product images API
 * param: request body
 * return: dispatch action
 */
const GetDealerInfo = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post("/paint/w4s/user/get-dealer", params);
      dispatch(StopLoader());
      dispatch({
        type: GET_DEALER_INFO,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch({
        type: CLEAR_DEALER_STATUS,
        payload: 422,
      });
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};
/**
 * clearSiteLogs: Clear Site logs
 * param: request body
 * return: dispatch action
 */
const ClearDealerInfo = () => {
  return { type: CLEAR_DEALER_STATUS, payload: null };
};

export { GetDealerInfo, ClearDealerInfo };
