import React, { Component } from "react";
import { connect } from "react-redux";
//UTILITY
import historyObj from "../_Utils/History";
import { EDIT_REGISTERED_SITE, SITE_REGISTERATION } from "../_Utils/Routes";
import { langData } from "../Common/Language";
import userAvatar from "../../Resources/user-avatar.svg";

//COMPONENTS
import SiteList from "./SiteList";

//ACTIONS
import {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  SetSiteDetails,
  GetRegisteredSites,
} from "../../Actions/RegisteredSites";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import { ClearAddedSite } from "../../Actions/SiteRegistration";
import searchNotFound from "../../Resources/search-not-found.svg";
import { PAGE_SIZE } from "../../Actions/Types";
import { PersistSearchSiteInfo, SavePageNumber } from "../../Actions/Common";
/**
 * RegisteredSites: Registered sites Page for user
 */
class RegisteredSites extends Component {
  constructor(props) {
     const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    super(props);
    this.state = {
      openSiteReg: false,
      searchQuery: "",
      pageNum: props.pageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: langData[lan].searchBy },
      showSearchResults: false,
    };
  }

  getSitesOnLoad = async () => {
    await this.getRegisteredSitesList();
    if (this.props.getRegisteredSites !== 200){ 
		console.log("REFRESH WHEN DATA IS NOT LOADED")
		window.location.reload();
	}
  };

  componentDidMount() {
    /* const reqBody = {
      phonenum: this.props.userPhoneNum,
    };
    this.props.GetRegisteredSites(reqBody, this.props.pageNum, PAGE_SIZE); */
    const { searchSiteDetails } = this.props;
    if (searchSiteDetails) {
      this.setState(
        {
          selectedSearchOption: searchSiteDetails.searchBy,
          searchQuery: searchSiteDetails.searchQuery,
          showSearchResults: true,
        },
        () => this.getSitesOnLoad()
      );
    } else {
      this.getSitesOnLoad();
    }
  }

  /**
   * handleContractorClick: Get site info on site click
   */
  handleContractorClick = async (openedSite) => {
    // const { visitedSiteId, visitedSites, SetSiteDetails, SetFurnitureDetails } =
    //   this.props;
    // if (visitedSiteId && visitedSiteId.includes(openedSite.siteid)) {
    //   let index = visitedSiteId.indexOf(openedSite.siteid);
    //   //If found then fetch that
    //   let site = visitedSites[index];
    //   SetSiteDetails(visitedSites[index]);
    //   SetFurnitureDetails(site.furnitureList);
    //   historyObj.push(EDIT_REGISTERED_SITE);
    // } else {
    const reqBody = { leadid: openedSite.leadid };
    await this.props.GetDetailsOfSite(reqBody);
    if (this.props.getSiteDetails === 200) {
      this.setState({ openSiteReg: true });
      historyObj.push(EDIT_REGISTERED_SITE);
    }
    // }
  };

  /**
   * handleCloseSites: close opened site
   */
  handleCloseSites = () => {
    this.setState({ openSiteReg: false });
  };

  /**
   * handleNewRegClick: new registration for user
   */
  handleNewRegClick = () => {
    this.props.ClearAddedSite();
    historyObj.push(SITE_REGISTERATION);
    this.props.SetFurnitureDetails([]);
    this.props.PersistSearchSiteInfo(null);
  };

  //GET REGISTERED SITES FROM BACKEND
  getRegisteredSitesList = async () => {
    const { userPhoneNum, siteSortDetails } = this.props;
    const { pageSize, selectedSearchOption, searchQuery } = this.state;
    // console.log("Get sites for ", this.state.pageNum);
    await this.props.GetRegisteredSites(
      {
        phonenum: userPhoneNum,
        searchValue: searchQuery.trim() || undefined,
        searchKey: selectedSearchOption.value || undefined,
        sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
        sortOrder: siteSortDetails
          ? siteSortDetails.sortOrder === 2
            ? "DESC"
            : "ASC"
          : undefined,
      },
      this.state.pageNum,
      pageSize
    );
  };

  //HANDLE SEARCH BUTTON
  handleSearch = async (e) => {
    this.setState({ pageNum: 0 }, async () => {
      await this.getRegisteredSitesList();
      if (this.props.getRegisteredSites === 200) {
        this.props.ClearRegisteredStatusLog();
        this.props.SavePageNumber(0);
        //IF SEARCH IS SUCCESSFUL THEM SET THE SEARCH DETAILS IN REDUX
        this.props.PersistSearchSiteInfo({
          searchBy: this.state.selectedSearchOption,
          searchQuery: this.state.searchQuery,
        });
        this.setState({ showSearchResults: true });
      }
    });
  };

  //SEARCH BY HANDLING
  getSearchByOptions = () => {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    return [
      {
        label: langData[lan].status || langData[0].status,
        value: "status",
        placeholder: "Enter site status",
        maxLength: "25",
        type: "status",
      },
      {
        label: langData[lan].leadId || langData[0].leadId,
        value: "leadId",
        placeholder: "Enter 6 digit lead id",
        maxLength: "6",
      },
      {
        label: langData[lan].date || langData[0].date,
        value: "dateOfRegistration",
        placeholder: "",
        type: "date",
      },
      {
        label: langData[lan].custName || langData[0].custName,
        value: "leadId",
        placeholder: langData[lan].namePh || langData[0].namePh,
        maxLength: "50",
      },
      {
        label: langData[lan].contactNo || langData[0].contactNo,
        value: "leadId",
        placeholder: langData[lan].contactPh || langData[0].contactPh,
        maxLength: "12",
      },
      {
        label: langData[lan].email || langData[0].email,
        value: "leadId",
        placeholder: langData[lan].emailPh || langData[0].emailPh,
      },
      {
        label: langData[lan].address || langData[0].address,
        value: "leadId",
        placeholder: langData[lan].address || langData[0].address,
      },
      {
        label: langData[lan].pinCode || langData[0].pinCode,
        value: "leadId",
        placeholder: langData[lan].pinPh || langData[0].pinPh,
        maxLength: "6",
      },
      {
        label: langData[lan].dCode || langData[0].dCode,
        value: "leadId",
        placeholder: langData[lan].dealerCodePh || langData[0].dealerCodePh,
        maxLength: "6",
      },
      {
        label: langData[lan].dName || langData[0].dName,
        value: "leadId",
        placeholder: langData[lan].advanceSearchOptions.dealerName,
        maxLength: "50",
      },
      {
        label: langData[lan].dPhone || langData[0].dPhone,
        value: "leadId",
        placeholder: langData[lan].advanceSearchOptions.dealerPhone,
        maxLength: "12",
      },
      {
        label: langData[lan].expSiteValue || langData[0].expSiteValue,
        value: "leadId",
        placeholder: langData[lan].siteValuePh || langData[0].siteValuePh,
        maxLength: "10",
      },
      {
        label: langData[lan].warrantyId || langData[0].warrantyId,
        value: "leadId",
        placeholder: langData[lan].advanceSearchOptions.warrantyId,
      },
    ];
  };

  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.getRegisteredSitesList();
        this.props.PersistSearchSiteInfo(null);
        this.props.SavePageNumber(0);
        this.setState({ pageNum: 0 });
      }
    );
  };

  handleSearchByBtn = (e) => {
    e.stopPropagation();
    this.setState({ showSearchOptions: !this.state.showSearchOptions });
  };

  handleInputChange = (e) => {
    if (!e.target.value.trim()) {
      this.setState({ showNotFound: false });
      //   this.props.handleShowList(true);
    }
    this.setState({ searchQuery: e.target.value });
  };

  /**
   * render: return JSX for registered site page
   */
  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    //prettier-ignore
    const { showSearchResults } = this.state;
    const { registeredSites } = this.props;
    return (
      <div
        className="registered-main-container"
        onClick={() => this.setState({ showSearchOptions: false })}
      >
        <div className="registered-container">
          <div className="registered-header-container">
            <div>{langData[lan].registeredSites}</div>
          </div>

          <div className="search-contractor-result-header">
            <div
              className="search-contractor-result-header-btn bk-blue"
              onClick={this.handleNewRegClick}
            >
              {langData[lan].newRegistration}
            </div>
            <div className="search-contractor-result-header-info">
              <div className="search-contractor-result-header-info-name">
                {this.props.username} <span>({this.props.userType})</span>
              </div>
              <div className="search-contractor-result-header-info-contact">
                <img src={userAvatar} alt="phn-icon" />
                {this.props.registrationNum}
              </div>
              <div className="search-contractor-result-header-info-contact">
                <img src="/Assets/contact.svg" alt="Contact" />
                <span>+{this.props.userPhoneNum}</span>
              </div>
            </div>
          </div>
          {registeredSites && registeredSites.sitesList ? (
            <SiteList
              handleContractorClick={this.handleContractorClick}
              user={true}
              customClass="registered-list-container"
            />
          ) : (
            <div className="search-page-notFound">
              <div className="search-page-notFound-div">
                <img src={searchNotFound} alt="Search not found" />
                <div>
                  {showSearchResults
                    ? langData[lan].noSites
                    : langData[lan].noSites}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.auth.username,
    userPhoneNum: state.auth.userPhoneNum,
    // username: state.site.username,
    // phoneNum: state.site.phoneNum,
    getSiteDetails: state.regSites.getSiteDetails,
    visitedSites: state.regSites.visitedSites,
    visitedSiteId: state.regSites.visitedSiteId,
    registeredSites: state.regSites.registeredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,

    pageNum: state.common.pageNum,
    userType: state.auth.userType,
    registrationNum: state.auth.registrationNum,
    searchSiteDetails: state.common.searchSiteDetails,
    siteSortDetails: state.common.siteSortDetails,
  };
};
export default connect(mapStateToProps, {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  SetFurnitureDetails,
  ClearAddedSite,
  SetSiteDetails,
  GetRegisteredSites,
  PersistSearchSiteInfo,
  SavePageNumber,
})(RegisteredSites);
