import React, { Component } from "react";
import { connect } from "react-redux";
import { PAGE_SIZE, WSO_TABLE, WSO_USER } from "../../Actions/Types";
import { GetWSOList } from "../../Actions/UsersAction";
import UserTable from "../UserTable";

class SearchWSO extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getWSOList();
  }

  getWSOList = async () => {
    const {
      searchWSODetails,
    //   sortingColumn,
    } = this.props;
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      userType: WSO_USER,
      searchKey: searchWSODetails
        ? searchWSODetails.searchBy.value || undefined
        : undefined,
      searchValue: searchWSODetails ? searchWSODetails.searchQuery.trim() || undefined : undefined,
      /* sortBy: sortingColumn.sortBy || undefined,
      sortOrder:
        sortingColumn.sortOrder === 2
          ? "DESC"
          : sortingColumn.sortOrder === 1
          ? "ASC"
          : undefined, */
    };
    await this.props.GetWSOList(reqBody, this.props.WSOPageNum, PAGE_SIZE);
  };

  render() {
    return (
      <div
        className="dashboard-main-container font-poppins"
        onClick={() => this.setState({ showSearchOptions: true })}
      >
        <UserTable activeTable={WSO_TABLE} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
	  userPhoneNum: state.auth.userPhoneNum,
	  searchWSODetails: state.user.searchWSODetails,
	  WSOPageNum: state.user.WSOPageNum,
	};
  };
  export default connect(mapStateToProps,{GetWSOList})(SearchWSO);

