import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";

import { DeleteImage, GetLinkForUpload } from "../../Actions/AssetsAction";
import {
  SetProgressLoader,
  ShowAlert,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import {
  ClearRegisteredStatusLog,
  UploadWarrantyDoc,
} from "../../Actions/RegisteredSites";

import closeIcon from "../../Resources/close-icon.svg";
import delIcon from "../../Resources/del-icon.svg";
import { GenerateKeyForUpload } from "../_Utils/GenerateKeyForUpload";

class UploadWarrantyDocModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warrantyDoc: {
        warrantyDocLink: null,
        warrantyDocFObject: null,
      },

      warrantyDocError: false,

      //REPLACED FILES
      docToBeDeleted: [],
    };
  }

  //UPLOAD WARRANTY DOCUMENT
  handleWarrantyDocument = async (e) => {
    const warrantyDoc = e.target.files && e.target.files[0];
    if (!warrantyDoc) return;
    e.target.value = "";

    if (warrantyDoc) {
      this.props.SetProgressLoader(true, 0);
      const key = GenerateKeyForUpload(warrantyDoc.name);
      const url = await GetLinkForUpload({ key: key });
      if (!url) {
        this.props.ShowAlert({
          msg: "Failed to upload",
          alertType: "Error",
        });
        // this.props.StopLoader();
        this.props.SetProgressLoader(false, null);
        return;
      }
      /* fetch(url, { method: "PUT", body: warrantyDoc })
        .then((data) => {
          //IF THERE IS ALREADY SELECTED FILE THEN SAVE IT IN STATE
          if (
            this.state.warrantyDoc &&
            this.state.warrantyDoc.warrantyDocLink
          ) {
            this.setState({
              docToBeDeleted: [
                ...this.state.docToBeDeleted,
                this.state.warrantyDoc.warrantyDocLink,
              ],
            });
          }
          this.setState({
            warrantyDoc: {
              warrantyDocLink: key,
              warrantyDocFObject: warrantyDoc,
            },
            warrantyDocError: false,
          });
          this.props.StopLoader();
        })
        .catch((err) => {
          this.props.ShowAlert({
            msg: "Failed to upload",
            alertType: "Error",
          });
          console.log(err);
          this.props.StopLoader();
        }); */

      const config = {
        headers: {
          "content-type": warrantyDoc.type,
        },
        onUploadProgress: (progressEvent) => {
          this.props.SetProgressLoader(
            true,
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      };
      //AXIOS CODE
      axios
        .put(url, warrantyDoc, config)
        .then((res) => {
          //IF THERE IS ALREADY SELECTED FILE THEN SAVE IT IN STATE
          if (
            this.state.warrantyDoc &&
            this.state.warrantyDoc.warrantyDocLink
          ) {
            this.setState({
              docToBeDeleted: [
                ...this.state.docToBeDeleted,
                this.state.warrantyDoc.warrantyDocLink,
              ],
            });
          }
          this.setState({
            warrantyDoc: {
              warrantyDocLink: key,
              warrantyDocFObject: warrantyDoc,
            },
            warrantyDocError: false,
          });
          this.props.SetProgressLoader(false, null);
        })
        .catch((err) => {
          this.props.ShowAlert({
            msg: "Failed to upload",
            alertType: "Error",
          });
          console.log(err);
          this.props.SetProgressLoader(false, null);
        });
    }

    e.target.value = "";
  };

  deleteWarrantyDoc = async () => {
    if (this.state.warrantyDoc.warrantyDocLink) {
      await this.props.DeleteImage({
        key: [this.state.warrantyDoc.warrantyDocLink],
      });

      this.setState({
        warrantyDoc: {
          warrantyDocLink: null,
          warrantyDocFObject: null,
        },
      });
    }
  };

  handleDeleteFile = (e) => {
    e.stopPropagation();
    this.deleteWarrantyDoc();
  };

  //Show selected file
  previewFile = () => {
    const { warrantyDoc } = this.state;
    if (!warrantyDoc) {
      return;
    }
    if (warrantyDoc.warrantyDocFObject)
      window.open(URL.createObjectURL(warrantyDoc.warrantyDocFObject));
    else window.open(warrantyDoc.warrantyDocLink);
  };

  //SUBMIT UPLOAD WARRANTY DOC
  onSubmitClick = async () => {
    const { siteInfo } = this.props;

    //VALIDATE DOC
    if (!this.validateDoc()) return;
    if (siteInfo && siteInfo.warrantyInfo) {
      const reqBody = {
        warrantyid: siteInfo.warrantyInfo.warrantyid,
        warrantydoc: this.state.warrantyDoc.warrantyDocLink,
      };
      await this.props.UploadWarrantyDoc(reqBody);
      if (this.props.uploadWarrantyDocStatus === 200) {
        this.props.ClearRegisteredStatusLog();
        this.props.ShowAlert({
          msg: "Warranty document uploaded",
          alertType: "Success",
        });
        this.props.closeModal();
      }
    }
  };

  handleCloseModal = async () => {
    //DELETE CURRENT ONE
    await this.deleteWarrantyDoc();
    this.props.closeModal();
  };

  //VALIDATE DOC
  validateDoc = () => {
    const { warrantyDoc } = this.state;
    if (warrantyDoc.warrantyDocLink) {
      this.setState({ warrantyDocError: false });
      return true;
    } else {
      this.setState({ warrantyDocError: true });
      return false;
    }
  };

  render() {
    const { warrantyDoc, warrantyDocError } = this.state;

    const { siteInfo } = this.props;
    return (
      <div className="upload-warranty-doc-modal">
        <div className="close">
          <img src={closeIcon} alt="Close" onClick={this.handleCloseModal} />
        </div>
        <div className="warranty-id">
          Warranty ID:{" "}
          <span>{siteInfo && siteInfo.warrantyInfo.warrantyid}</span>
        </div>

        <div className="warranty-upload-label compulsory">
          Upload Warranty document
        </div>

        {/* UPLOADED FILE NAME */}
        {(warrantyDoc.warrantyDocFObject || warrantyDoc.warrantyDocLink) && (
          <div className="warranty-uploaded-file" onClick={this.previewFile}>
            {warrantyDoc && warrantyDoc.warrantyDocFObject
              ? warrantyDoc.warrantyDocFObject.name
              : warrantyDoc.warrantyDocLink}
            <img
              src={delIcon}
              alt="Delete Icon"
              onClick={this.handleDeleteFile}
            />
          </div>
        )}

        {/* NOTE */}
        {warrantyDoc.warrantyDocLink && (
          <div className="note">
            Note: Uploaded warranty document will be shared with the contractor.
            Please confirm before uploading!
          </div>
        )}

        {!warrantyDoc.warrantyDocLink && (
          <div className="warranty-upload-file">
            <label
              htmlFor="warranty-doc"
              style={warrantyDocError ? { border: "1px solid red" } : {}}
            >
              Choose file
            </label>
            <input
              type="file"
              name=""
              accept="application/pdf"
              id="warranty-doc"
              onChange={this.handleWarrantyDocument}
            />
          </div>
        )}

        <div className="modal-submit">
          <div className="modal-submit-button" onClick={this.onSubmitClick}>
            Submit
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadWarrantyDocStatus: state.regSites.uploadWarrantyDocStatus,
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps, {
  StartLoader,
  StopLoader,
  UploadWarrantyDoc,
  ClearRegisteredStatusLog,
  ShowAlert,
  DeleteImage,
  SetProgressLoader,
})(UploadWarrantyDocModal);
