import React, { Component } from "react";
import ReactModal from "react-modal";
import LinkExpired from "../_Utils/LinkExpired";

/**
 * Expired: Component for expiry page
 */
class Expired extends Component {
  componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    localStorage.removeItem("store-state");
  }
  render() {
    return (
      <ReactModal
        isOpen={true}
        overlayClassName="link-expired-overlay"
        className="link-expired-modal"
        ariaHideApp={false}
      >
        <LinkExpired />
      </ReactModal>
    );
  }
}

export default Expired;
