import React, { Component } from "react";
import { connect } from "react-redux";
import { DSR_TABLE, DSR_USER_TYPE, PAGE_SIZE } from "../../Actions/Types";
import { GetDSRList } from "../../Actions/UsersAction";
import UserTable from "../UserTable";

class SearchDSR extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getDSRList();
  }

  getDSRList = async () => {
    const {
      searchDSRDetails,
    //   sortingColumn,
    } = this.props;
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      userType: DSR_USER_TYPE,
      searchKey: searchDSRDetails
        ? searchDSRDetails.searchBy.value || undefined
        : undefined,
      searchValue: searchDSRDetails ? searchDSRDetails.searchQuery.trim() || undefined : undefined,
      /* sortBy: sortingColumn.sortBy || undefined,
      sortOrder:
        sortingColumn.sortOrder === 2
          ? "DESC"
          : sortingColumn.sortOrder === 1
          ? "ASC"
          : undefined, */
    };
	
    await this.props.GetDSRList(reqBody, this.props.DSRPageNum, PAGE_SIZE);
  };

  render() {
    return (
      <div
        className="dashboard-main-container font-poppins"
        onClick={() => this.setState({ showSearchOptions: true })}
      >
        <UserTable activeTable={DSR_TABLE} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
	  userPhoneNum: state.auth.userPhoneNum,
	  searchDSRDetails: state.user.searchDSRDetails,
	  DSRPageNum: state.user.DSRPageNum,
	};
  };
  export default connect(mapStateToProps, {
	GetDSRList,
  })(SearchDSR);
