import React, { Component } from "react";
import * as EmailValidator from "email-validator";
import { connect } from "react-redux";
import { debounce } from "throttle-debounce";

//language
import { langData } from "../../Common/Language";
//Actions
import {
  RegisterSite,
  EditSite,
  clearSiteLogs,
} from "../../../Actions/SiteRegistration";
import { GetDealerInfo, ClearDealerInfo } from "../../../Actions/DealerAction";
import {
  GetContractorDetails,
  ClearContractorStatus,
} from "../../../Actions/ContractorAction";

import { isNumeric, NameValidation } from "../../_Utils/Validation";
import { ShowAlert } from "../../../Actions/Common";
import { CONTRACTOR_USER_TYPE, HELPLINE_USER } from "../../../Actions/Types";
import { CheckNonContractorUser } from "../../_Utils/CheckNonContractorUser";

class Step1 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNum: "",
      date: "",
      pincode: "",
      dealerCode: "",
      dealerName: "",
      dealerPhone: "",
      siteValue: "",
      address: "",

      //NEWLY ADDED
      contractorBPNo: "",
      contractorPhone: "",
      contractorName: "",

      //error indicators
      nameError: "",
      pincodeError: "",
      dealerCodeError: "",
      dealerNameError: "",
      siteValueError: "",
      emailError: "",
      phoneError: "",
      dealerPhoneError: "",
      contractorBPNoError: "",
      contractorPhoneError: "",
      contractorNameError: "",

      searchBPNo: "",
      selectedContractor: null,
      showContractorSuggestion: false,
      fillContractorDetails: false,
    };

    this.handleSearchDebounce = debounce(500, this.getContractorsOnBPNo);
  }

  componentDidMount() {
    this.setAllFieldsForEdit();
  }

  setAllFieldsForEdit = () => {
    const { siteId, siteData } = this.props;
    if (siteId) {
      this.setState(
        {
          name: siteData.customername || "",
          email: siteData.customeremail || "",
          phoneNum: siteData.customerphone || "",
          pincode: siteData.pincode || "",
          dealerCode: siteData.dealercode || "",
          dealerName: siteData.dealername || "",
          dealerPhone: siteData.dealerphone || "",
          siteValue: siteData.sitevalue || "",
          address: siteData.customeraddress || "",

          contractorBPNo: siteData.contractorBPNo || "",
          contractorName: siteData.contractorName || "",
          contractorPhone: siteData.contractorPhone || "",
          fillContractorDetails: siteData.contractorBPNo ? true : false,
        },
        () => {
          this.props.handleShowNextButton();
        }
      );
    }
  };

  handleDealerCode = (e) => {
    if (!e.target.value) {
      this.setState({ dealerCode: "", dealerPhone: "", dealerName: "" });
      return;
    }
    if (isNumeric(e.target.value) || e.target.value === "") {
      this.setState({ dealerCode: e.target.value }, async () => {
        // this.validateDcode();
        if (this.state.dealerCode.trim().length === 6) {
          await this.props.GetDealerInfo({
            dealerpcc: this.state.dealerCode,
          });
          if (this.props.getDealerInfo === 200) {
            this.props.ClearDealerInfo();
            this.setState({
              dealerName: this.props.dealerInfo.dealername,
              dealerPhone: this.props.dealerInfo.dealerphone,
            });
          } else {
            this.props.ClearDealerInfo();
            this.setState({ dealerCode: "", dealerPhone: "", dealerName: "" });
          }
        }
      });
    }
  };

  validateName = () => {
    if (!this.state.name.trim()) {
      this.setState({ nameError: true });
      return false;
    } else if (!NameValidation(this.state.name)) {
      this.setState({ nameError: true });
      return false;
    }
    this.setState({ nameError: false });
    return true;
  };

  validatePhone = () => {
    let retValue1;
    if (this.state.phoneNum.trim().length < 10) {
      this.setState({ phoneError: true });
      retValue1 = false;
    } else {
      this.setState({ phoneError: false });
      retValue1 = true;
    }
    return retValue1;
  };

  validateEmail = () => {
    if (this.state.email && !EmailValidator.validate(this.state.email.trim())) {
      this.setState({ emailError: "Enter correct email id" });
      return false;
    } else {
      this.setState({ emailError: "" });
      return true;
    }
  };

  validatePincode = () => {
    if (this.state.pincode.trim().length < 6) {
      this.setState({ pincodeError: true });
      return false;
    }
    this.setState({ pincodeError: false });
    return true;
  };

  //VALIDATE CONTRACTOR DETAILS
  validateContractorBPNo = () => {
    if (!this.state.contractorBPNo.trim()) {
      this.setState({
        contractorBPNoError: true,
      });
      return false;
    } else {
      this.setState({
        contractorBPNoError: false,
      });
      return true;
    }
  };

  validateData = () => {
    console.log(this.props.userType);
    let nStatus = this.validateName();
    let pStatus = this.validatePhone();
    let eStatus = this.validateEmail();
    let bpStatus =
      this.props.userType !== CONTRACTOR_USER_TYPE &&
      this.props.userType !== HELPLINE_USER
        ? this.validateContractorBPNo()
        : true;
    // let dPStatus = this.validateDealerPhone();
    let pinStatus = this.state.pincode
      ? this.validatePincode()
      : (() => {
          this.setState({ pincodeError: false });
          return true;
        })();
    // let dC = this.validateDcode();
    // let dN = this.validateDName();
    // let sv = this.validateSiteValue();

    if (eStatus && pStatus && nStatus && pinStatus && bpStatus) {
      return true;
    } else return false;
  };

  onSubmitClick = async () => {
    const { userType } = this.props;
    if (this.validateData()) {
      let reqBody = {
		hluserid: this.props.userType ===HELPLINE_USER ? this.props.userId : undefined,		//send this key if user is helpline
		requesterId: this.props.userType !==HELPLINE_USER ? this.props.userId : undefined,  //send this key if user is non-helpline
        siteid: this.props.siteId ? this.props.siteId : undefined,
        phonenum:
          userType === CONTRACTOR_USER_TYPE
            ? this.props.userPhoneNum
            : userType === HELPLINE_USER
            ? this.props.contractorPhoneNum
            : this.state.contractorPhone,
        customername: this.state.name.trim(),
        customeremail: this.state.email ? this.state.email.trim() : undefined,
        customerphone: this.state.phoneNum.trim(),
        customeraddress: this.state.address
          ? this.state.address.trim()
          : undefined,
        pincode: this.state.pincode ? this.state.pincode.trim() : undefined,
        dealercode: this.state.dealerCode
          ? this.state.dealerCode.trim()
          : undefined,
        dealername: this.state.dealerName
          ? this.state.dealerName.trim()
          : undefined,
        dealerphone: this.state.dealerPhone
          ? this.state.dealerPhone.trim()
          : undefined,
        sitevalue: this.state.siteValue
          ? this.state.siteValue.trim()
          : undefined,

        //newly added
        languageid: !this.props.siteId ? this.props.language : undefined,
      };

      const contractorDetails =
        CheckNonContractorUser(userType) || userType === HELPLINE_USER
          ? {
              contractorBPNo: this.state.contractorBPNo,
              contractorName: this.state.contractorName,
              contractorPhone: this.state.contractorPhone,
            }
          : {};

      if (this.props.siteId) {
        await this.props.EditSite(reqBody, contractorDetails);
        if (this.props.editSiteResponse === 200) {
          this.props.ShowAlert({
            msg: langData[this.lan].siteEdit,
            alertType: "Success",
          });
          this.props.clearSiteLogs();
        }
      } else {
        await this.props.RegisterSite(reqBody, contractorDetails);
        if (this.props.registerSiteResponse === 200) {
          this.props.ShowAlert({
            msg: langData[this.lan].siteAdd + " " + this.props.leadId,
            alertType: "Success",
          });
          this.props.clearSiteLogs();
          this.props.onSubmit(2);
        }
      }
    }
  };

  handleResetButton = () => {
    if (this.props.siteId) {
      this.setAllFieldsForEdit();
    } else
      this.setState({
        name: "",
        email: "",
        phoneNum: "",
        date: "",
        pincode: "",
        dealerCode: "",
        dealerName: "",
        dealerPhone: "",
        siteValue: "",
        address: "",
        contractorBPNo: "",
        contractorName: "",
        contractorPhone: "",
        fillContractorDetails: false,
        searchBPNo: "",
      });
  };

  getContractorsOnBPNo = async () => {
    if (!this.state.searchBPNo.trim()) return;
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      searchString: this.state.searchBPNo,
    };
    await this.props.GetContractorDetails(reqBody);
    if (this.props.getContractorDetails === 200) {
      this.props.ClearContractorStatus();
      this.setState({ showContractorSuggestion: true });
    }
  };

  //HANDLE CONTRACTOR BP NO
  handleContractorBPNo = async (e) => {
    if (!e) return;
    this.setState(
      { searchBPNo: e.target.value, fillContractorDetails: false },
      () => {
        if (this.state.searchBPNo.trim()) this.handleSearchDebounce();
        else this.setState({ showContractorSuggestion: false });
      }
    );
  };

  //SAVE SELECTED CONTRACTOR
  handleSelectContractor = (selectedContractor) => {
    this.setState({
      contractorBPNo: selectedContractor.registrationnum,
      contractorName: selectedContractor.name,
      contractorPhone: selectedContractor.phonenum,
      showContractorSuggestion: false,
      searchBPNo: "",
      fillContractorDetails: true,
      contractorBPNoError: false,
    });
  };

  render() {
    const { userType, contractorSuggestions } = this.props;
    const {
      showContractorSuggestion,
      contractorBPNo,
      contractorName,
      contractorPhone,
      fillContractorDetails,
      searchBPNo,
    } = this.state;
    return (
      <div className="page slide-page">
        {CheckNonContractorUser(userType) && (
          <React.Fragment>
            {/* CONTRACTOR BP NUMBER */}
            <div className="field contractor-bp-no-input">
              <div className="label compulsory">
                {langData[this.lan].contractorBPNo}
              </div>
              <input
                type="text"
                placeholder={langData[this.lan].contractorBPNoPh}
                onChange={this.handleContractorBPNo}
                maxLength={200}
                value={!fillContractorDetails ? searchBPNo : contractorBPNo}
                style={
                  this.state.contractorBPNoError
                    ? { borderColor: "#ff0000b5" }
                    : {}
                }
                disabled={
                  this.props.siteData &&
                  Object.entries(this.props.siteData).length
                    ? true
                    : false
                }
              />

              {/* CONTRACTOR SUGGESTIONS */}
              {showContractorSuggestion && (
                <div className="contractor-suggestions">
                  {contractorSuggestions && contractorSuggestions.length ? (
                    contractorSuggestions.map((val, index) => (
                      <div
                        className="contractor-suggestions-item"
                        onClick={() => this.handleSelectContractor(val)}
                        key={index}
                      >
                        {val.name}({val.registrationnum})
                      </div>
                    ))
                  ) : (
                    <div className="contractor-suggestions-item">
                      No contractor found
                    </div>
                  )}
                </div>
              )}
              {this.state.contractorBPNoError ? (
                <div className="input-error">
                  {langData[this.lan].contractorBPNoError}
                </div>
              ) : null}
            </div>

            {/* CONTRACTOR PHONE NUMBER */}
            <div className="field">
              <div className="label compulsory">
                {langData[this.lan].contractorNumber}
              </div>
              <input
                type="text"
                placeholder={langData[this.lan].contractorNumberPh}
                maxLength={200}
                value={contractorPhone || ""}
                style={
                  this.state.contractorPhoneError
                    ? { borderColor: "#ff0000b5" }
                    : {}
                }
                disabled
              />
              {this.state.contractorPhoneError ? (
                <div className="input-error">
                  {langData[this.lan].contractorPhoneError}
                </div>
              ) : null}
            </div>

            {/* CONTRACTOR NAME */}
            <div className="field">
              <div className="label compulsory">
                {langData[this.lan].contractorName}
              </div>
              <input
                type="text"
                placeholder={langData[this.lan].contractorNamePh}
                maxLength={200}
                value={contractorName}
                style={
                  this.state.contractorNameError
                    ? { borderColor: "#ff0000b5" }
                    : {}
                }
                disabled
              />
              {this.state.contractorNameError ? (
                <div className="input-error">
                  {langData[this.lan].contractorNameError}
                </div>
              ) : null}
            </div>
          </React.Fragment>
        )}
        {/* CUSTOMER NAME */}
        <div className="field">
          <div className="label compulsory">{langData[this.lan].name}</div>
          <input
            type="text"
            placeholder={langData[this.lan].namePh}
            onChange={(e) =>
              this.setState({ name: e.target.value }, this.validateName)
            }
            maxLength={50}
            value={this.state.name}
            style={this.state.nameError ? { borderColor: "#ff0000b5" } : {}}
          />
          {this.state.nameError ? (
            <div className="input-error">{langData[this.lan].nameError}</div>
          ) : null}
        </div>

        {/* PHONE NUM */}
        <div className="field">
          <div className="label compulsory">{langData[this.lan].phone}</div>
          <input
            type="text"
            placeholder={langData[this.lan].contactPh}
            onChange={(e) => {
              if (isNumeric(e.target.value) || e.target.value === "")
                this.setState({ phoneNum: e.target.value }, this.validatePhone);
            }}
            value={this.state.phoneNum}
            maxLength={10}
            style={this.state.phoneError ? { borderColor: "#ff0000b5" } : {}}
          />
          {this.state.phoneError ? (
            <div className="input-error">{langData[this.lan].phoneError} </div>
          ) : null}
        </div>

        {/* EMAIL */}
        <div className="field">
          <div className="label  ">{langData[this.lan].email}</div>
          <input
            type="text"
            placeholder={langData[this.lan].emailPh}
            onChange={(e) =>
              this.setState({ email: e.target.value }, this.validateEmail)
            }
            value={this.state.email}
            maxLength={100}
            style={this.state.emailError ? { borderColor: "#ff0000b5" } : {}}
          />
          {this.state.emailError ? (
            <div className="input-error">{langData[this.lan].emailError} </div>
          ) : null}
        </div>

        {/* ADDRESS */}
        <div className="field">
          <div className="label  ">{langData[this.lan].address}</div>
          <input
            type="text"
            placeholder={langData[this.lan].addressPh}
            onChange={(e) => {
              this.setState({ address: e.target.value });
            }}
            maxLength={100}
            value={this.state.address}
          />
        </div>

        {/* PINCODE */}
        <div className="field">
          <div className="label">{langData[this.lan].pincode}</div>
          <input
            type="text"
            placeholder={langData[this.lan].pinPh}
            onChange={(e) => {
              if (isNumeric(e.target.value) || e.target.value === "")
                this.setState(
                  { pincode: e.target.value },
                  this.validatePincode
                );
            }}
            maxLength={6}
            value={this.state.pincode}
            style={this.state.pincodeError ? { borderColor: "#ff0000b5" } : {}}
          />
          {this.state.pincodeError ? (
            <div className="input-error">{langData[this.lan].pinError}</div>
          ) : null}
        </div>

        {/* DEALER CODE */}
        <div className="field">
          <div className="label">{langData[this.lan].dCode}</div>
          <input
            type="text"
            placeholder={langData[this.lan].dealerCodePh}
            onChange={this.handleDealerCode}
            value={this.state.dealerCode}
            maxLength={6}
            // style={
            //   this.state.dealerCodeError ? { borderColor: "#ff0000b5" } : {}
            // }
          />
          {/* {this.state.dealerCodeError ? (
            <div className="input-error">{langData[this.lan].dCodeError}</div>
          ) : null} */}
        </div>

        {/* DEALER NAME */}
        <div className="field">
          <div className="label">{langData[this.lan].dName}</div>
          <input
            type="text"
            placeholder={langData[this.lan].dealerNamePh}
            onChange={(e) =>
              this.setState(
                { dealerName: e.target.value }
                // this.validateDName
              )
            }
            value={this.state.dealerName}
            maxLength={200}
            // style={
            //   this.state.dealerNameError ? { borderColor: "#ff0000b5" } : {}
            // }
            disabled={true}
          />
          {/* {this.state.dealerNameError ? (
            <div className="input-error">{langData[this.lan].dNameError}</div>
          ) : null} */}
        </div>

        {/* DEALER PHONE */}
        <div className="field">
          <div className="label">{langData[this.lan].dPhone}</div>
          <input
            type="text"
            placeholder={langData[this.lan].dealerPhonePh}
            onChange={(e) => {
              if (isNumeric(e.target.value) || e.target.value === "")
                this.setState(
                  { dealerPhone: e.target.value }
                  // this.validateDealerPhone
                );
            }}
            value={this.state.dealerPhone}
            maxLength={10}
            // style={
            //   this.state.dealerPhoneError ? { borderColor: "#ff0000b5" } : {}
            // }
            disabled={true}
          />
          {/* {this.state.dealerPhoneError ? (
            <div className="input-error">{langData[this.lan].dPhoneError} </div>
          ) : null} */}
        </div>

        {/* SITE VALUE */}
        <div className="field">
          <div className="label">{langData[this.lan].siteValue}</div>
          <input
            type="text"
            placeholder={langData[this.lan].siteValuePh}
            onChange={(e) =>
              this.setState(
                { siteValue: e.target.value }
                // this.validateSiteValue
              )
            }
            value={this.state.siteValue}
            // style={
            //   this.state.siteValueError ? { borderColor: "#ff0000b5" } : {}
            // }
            maxLength={40}
          />
          {/* {this.state.siteValueError ? (
            <div className="input-error">{langData[this.lan].siteError}</div>
          ) : null} */}
        </div>

        {/* SUBMIT AND RESET*/}
        <div className="btn-wrapper">
          <div className="field-btn">
            <button onClick={() => this.onSubmitClick()}>
              {this.props.siteId
                ? langData[this.lan].save
                : langData[this.lan].submit}
            </button>
          </div>

          <div className="field-btn">
            <button className="reset" onClick={this.handleResetButton}>
              {langData[this.lan].reset || langData[0].reset}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteId: state.site.siteId,
    leadId: state.site.leadId,
    siteData: state.site.siteData,
    // phoneNum: state.site.phoneNum,
    registerSiteResponse: state.site.registerSiteResponse,
    editSiteResponse: state.site.editSiteResponse,
    dealerInfo: state.dealer.dealerInfo,
    getDealerInfo: state.dealer.getDealerInfo,

    contractorSuggestions: state.contractor.contractorSuggestions,
    getContractorDetails: state.contractor.getContractorDetails,

    userType: state.auth.userType,

    userPhoneNum: state.auth.userPhoneNum,
    userId: state.auth.userId,

    language: state.auth.language,
  };
};

export default connect(mapStateToProps, {
  RegisterSite,
  EditSite,
  clearSiteLogs,
  GetDealerInfo,
  ClearDealerInfo,
  ShowAlert,
  GetContractorDetails,
  ClearContractorStatus,
})(Step1);
