// SetAlert to dispatch the alert action
import { SetAlert, StopLoader } from "./Common";

/**
 * HandleExceptionWithSecureCatch: Handle exceptions coming from server
 * param: exception occurred
 * return: dispatch action to show alert
 */
const HandleExceptionWithSecureCatch = (exception) => {
  console.log("Exception Occurred ", exception);

  return (dispatch, getState) => {
    dispatch(StopLoader());
    let statusCode;
    if (exception.message === "Network Error") {
      if (!window.navigator.onLine) {
        statusCode = 13;
      } else {
        statusCode = 14;
      }
    } else {
      statusCode = exception.response ? exception.response.status : 500;
    }
    let message = exception.response
      ? exception.response.data.message
      : "Couldn't Complete Your Request, Try Again Later!.";

    switch (statusCode) {
      case 13:
        dispatch(
          SetAlert({
            msg: "Network Unavailable!",
            alertType: "Error",
          })
        );
        break;

      //if cors error occurred
      case 14:
        dispatch(
          SetAlert({
            msg: "Servers are busy! Please try again later",
            alertType: "Error",
          })
        );
        break;

      case 200:
        dispatch(
          SetAlert({
            msg: exception.response ? exception.response.data.message : message,
            alertType: "Error",
          })
        );
        break;

      case 500:
        dispatch(
          SetAlert({
            msg: exception.response ? exception.response.data.message : message,
            alertType: "Error",
          })
        );
        break;

      default:
        dispatch(
          SetAlert({
            msg: message,
            alertType: "Error",
          })
        );
        break;
    }
  };
};

export { HandleExceptionWithSecureCatch };
