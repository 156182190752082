import Server from "../API";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";
// import { DELETE_IMAGE, GET_IMAGE_UPLOAD_LINK } from "./Types";

const DeleteImage = (params) => {
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      await Server.post("/paint/w4s/user/delete-file", params);
      dispatch(StopLoader());
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

const GetLinkForUpload = async (params) => {
  try {
    const response = await Server.post("/paint/w4s/user/signed-upload", params);
    return response.data.data;
  } catch (e) {
    console.log(e);
  }
};

const GetUploadPartsURL = async (params) => {
  try {
    const response = await Server.post(
      "/paint/w4s/user/signed-upload-parts",
      params
    );
    //     console.log(response);
    return response;
  } catch (e) {
    console.log(e);
  }
};

const CompleteMultipartRequest = async (params) => {
  try {
    const response = await Server.post(
      "/paint/w4s/user/complete-multipart-upload",
      params
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};

const AbortMultiPartRequest = async (params) => {
  try {
    const response = await Server.post(
      "/paint/w4s/user/abort-multipart-upload",
      params
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

//export the setAlert and the deleteAlert action
export {
  GetLinkForUpload,
  DeleteImage,
  GetUploadPartsURL,
  CompleteMultipartRequest,
  AbortMultiPartRequest,
};
