import React, { Component } from "react";
import { connect } from "react-redux";

//Actions
import {
  ClearRegisteredStatusLog,
  GetMonthYearWiseSites,
  GetRegisteredSites,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
//RESOURCES
import downloadFile from "../../Resources/file-download.svg";
import uploadDoc from "../../Resources/upload-icon.svg";
import review from "../../Resources/review.svg";

//UTIL
import { langData } from "../Common/Language";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

import {
  CONTRACTOR_USER_TYPE,
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import ReviewSite from "../ReviewSite/ReviewSite";
import {
  ClearCommonStatus,
  GetDocumentExpiryLink,
  PersistSearchSiteInfo,
  SavePageNumber,
  SaveSortingInfo,
} from "../../Actions/Common";
import InitiateWarrantySuccess from "../InitiateWarrantySuccess/InitiateWarrantySuccess";
import DataNotFound from "../Common/DataNotFound";
import CustomTable from "../CustomTable/CustomTable";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
/**
 * SiteList: Component for registered site list
 */
class SiteList extends Component {
  constructor(props) {
    super(props);
    const { userType } = props;
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    this.state = {
      openReview: false,
      reviewFormData: {},
      siteInfo: {},
      showInitiateWarrantySuccess: false,
      sortingId: [
        { sortBy: "sitestatus", sortOrder: 1 },
        { sortBy: "leadid", sortOrder: 1 },
        { sortBy: "createdat", sortOrder: 1 },
        { sortBy: "customername", sortOrder: 1 },
        { sortBy: "customerphone", sortOrder: 1 },
        { sortBy: "customeremail", sortOrder: 1 },
        { sortBy: "customeraddress", sortOrder: 1 },
        { sortBy: "pincode", sortOrder: 1 },
        { sortBy: "dealercode", sortOrder: 1 },
        { sortBy: "dealername", sortOrder: 1 },
        { sortBy: "dealerphone", sortOrder: 1 },
        { sortBy: "warrantyid", sortOrder: 1 },
        { sortBy: "registrationnum", sortOrder: 1 },
        { sortBy: "name", sortOrder: 1 },
        { sortBy: "phonenum", sortOrder: 1 },
      ],
      siteHeader: [
        {
          label: langData[lan].status,
          sortBy: "sitestatus",
          customClass: "status",
          sortOrder: 1,
        },
        {
          label: langData[lan].leadId,
          sortBy: "leadid",
          customClass: "id",
          sortOrder: 1,
        },
        {
          label: langData[lan].date,
          sortBy: "createdat",
          customClass: "date",
          sortOrder: 1,
        },
        userType !== CONTRACTOR_USER_TYPE && {
          label: langData[lan].contractorBPNo,
          sortBy: "registrationnum",
          customClass: "BP-id",
          sortOrder: 1,
        },
        userType !== CONTRACTOR_USER_TYPE && {
          label: langData[lan].contractorName,
          sortBy: "name",
          customClass: "name",
          sortOrder: 1,
        },
        userType !== CONTRACTOR_USER_TYPE && {
          label: langData[lan].contractorNumber,
          sortBy: "phonenum",
          customClass: "num",
          sortOrder: 1,
        },
        {
          label: langData[lan].custName,
          sortBy: "customername",
          customClass: "name",
          sortOrder: 1,
        },
        {
          label: langData[lan].contactNo,
          sortBy: "customerphone",
          customClass: "num",
          sortOrder: 1,
        },
        {
          label: langData[lan].customerEmail,
          sortBy: "customeremail",
          customClass: "email",
          sortOrder: 1,
        },
        {
          label: langData[lan].address,
          sortBy: "customeraddress",
          customClass: "address",
          sortOrder: 1,
        },
        {
          label: langData[lan].customerPin,
          sortBy: "pincode",
          customClass: "pin",
          sortOrder: 1,
        },
        {
          label: langData[lan].dCode,
          sortBy: "dealercode",
          customClass: "id",
          sortOrder: 1,
        },
        {
          label: langData[lan].dName,
          sortBy: "dealername",
          customClass: "name",
          sortOrder: 1,
        },
        {
          label: langData[lan].dPhone,
          sortBy: "dealerphone",
          customClass: "num",
          sortOrder: 1,
        },
        { label: langData[lan].expSiteValue, customClass: "site" },
        { label: langData[lan].droppedRemark, customClass: "remark" },

        {
          label: langData[lan].warrantyId,
          sortBy: "warrantyid",
          customClass: "warranty",
          sortOrder: 1,
        },

        /* (userType === HELPLINE_USER || CheckNonContractorUser(userType)) && */
        {
          label: langData[lan].review,
          customClass: "review",
        },
        { label: langData[lan].warrantyDoc, customClass: "review" },
        {
          label:
            langData[lan].warrantyClaimRemark ||
            langData[0].warrantyClaimRemark,
          customClass: "remark",
        },
        {
          label:
            langData[lan].warrantyClaimDocument ||
            langData[0].warrantyClaimDocument,
          customClass: "claim-document",
        },
      ],

      //search variables
      searchQuery: "",
      showNotFound: false,
      pageNum: props.pageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
    };
  }

  componentDidMount() {
    const { siteSortDetails } = this.props;
    if (siteSortDetails) {
      this.setState({
        siteHeader: this.state.siteHeader.map((val) => {
          if (val.sortBy === siteSortDetails.sortBy) {
            return { ...val, sortOrder: siteSortDetails.sortOrder };
          } else return val;
        }),
      });
    }
    const { searchSiteDetails } = this.props;
    if (searchSiteDetails) {
      this.setState(
        {
          selectedSearchOption: searchSiteDetails.searchBy,
          searchQuery: searchSiteDetails.searchQuery,
          showSearchResults: true,
        }
        // () => this.getRegisteredSitesList()
      );
    }
    // else this.getRegisteredSitesList();
  }

  /*   static getDerivedStateFromProps(nextProps, currentState) {
    if (currentState.pageNum !== nextProps.pageNum) {
      return {
        pageNum: nextProps.pageNum,
      };
    }
    return null;
  } */

  //HANDLE REVIEW ICON CLICK
  handleReviewForm = (siteInfo, reviewFormData) => {
    this.setState({
      openReview: !this.state.openReview,
      reviewFormData,
      siteInfo,
    });
  };

  //HANDLE OPEN REVIEW FORM
  handleOpenReviewForm = (item) => {
    const { userType } = this.props;
    const { sitestatus } = item;
    if (sitestatus === 0 || sitestatus === 4) {
      return;
    } else if (
      (CheckNonContractorUser(userType) || userType === CONTRACTOR_USER_TYPE) &&
      item.reviewInfo
      //   (sitestatus === 2 || sitestatus === 3)
    )
      this.handleReviewForm(item, item.reviewInfo);
    else if (userType === HELPLINE_USER)
      this.handleReviewForm(item, item.reviewInfo);
    /* if (item.sitestatus === 1 && item.reviewInfo) {
      if (item.reviewInfo.sitereviewed) return;
      else this.handleReviewForm(item, item.reviewInfo);
    } if(sitestatus === 2 && item.reviewInfo) {
		this.handleReviewForm(item,item.reviewInfo)
	}
    if (sitestatus === 1 && !item.reviewInfo) {
      this.handleReviewForm(item, item.reviewInfo);
    } */
  };

  isReviewDisabled = (item) => {
    const { userType } = this.props;
    const { sitestatus } = item;
    if (sitestatus === 0 || sitestatus === 4) return true;
    else if (
      (CheckNonContractorUser(userType) || userType === CONTRACTOR_USER_TYPE) &&
      !item.reviewInfo
      //   sitestatus === 1
    )
      return true;
    return false;
  };

  //SUCCESS POPUP FOR REVIEW FORM
  handleInitiateWarrantySuccess = () => {
    this.setState({
      showInitiateWarrantySuccess: true,
    });
  };
  //close warranty success
  handleCloseInitiateWarrantySuccess = () => {
    this.setState({
      showInitiateWarrantySuccess: false,
    });
  };

  //GET SITES ON PREV AND NEXT CLICK
  getRegisteredSitesList = async () => {
    const { userType, userPhoneNum, registeredSites, sitesType } = this.props;
    const { pageSize, sortingColumn, searchQuery, selectedSearchOption } =
      this.state;

    //COMMON REQUEST BODY FOR ALL USERS
    let reqBody = {
      phonenum:
        this.props.tabName === "contractor-sites"
          ? registeredSites.phonenum
          : undefined,
      /*  phonenum:
        this.props.tabName === "contractor-sites"
          ? this.props.searchContractorDetails?.searchQuery || undefined
          : undefined, */
      searchValue: searchQuery.trim() || undefined,
      searchKey: selectedSearchOption.value || undefined,
      sortBy: sortingColumn.sortBy,
      sortOrder: sortingColumn.sortOrder
        ? sortingColumn.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
    };

    //MONTH AND YEAR WISE SITES
    if (sitesType === "monthWiseSites" || sitesType === "yearWiseSites") {
      reqBody["requesterPhone"] = userPhoneNum;
      if (sitesType === "monthWiseSites") reqBody["month"] = this.props.month;
      else if (sitesType === "yearWiseSites") reqBody["year"] = this.props.year;
      await this.props.GetMonthYearWiseSites(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    }

    //IF USER TYPE IS HELPLINE
    else if (userType === HELPLINE_USER) {
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    }
    //CONTRACTOR USER
    else if (userType === CONTRACTOR_USER_TYPE) {
      reqBody["phonenum"] = userPhoneNum;
      await this.props.GetRegisteredSites(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    }
    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      reqBody["phonenum"] = registeredSites
        ? registeredSites.phonenum
        : undefined;
      reqBody["requesterPhone"] = this.props.userPhoneNum;
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }
  };

  convertBase64ToBlob = (e) => {
    //convert the base64 to blob
    var byteCharacters = atob(e.target.result.split(",")[1]);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: "application/pdf;base64" });

    var fileURL = URL.createObjectURL(file);
    //open file or new window
    window.open(fileURL);
  };

  //Show selected file
  previewFile = (e, doc, siteId) => {
    e.stopPropagation();

    //GET THE FILE FROM SERVER
    let request = new XMLHttpRequest();
    request.open("GET", doc, true);
    request.responseType = "blob";
    request.send();

    //AFTER SUCCESSFUL REQUEST READ THE FILE CONTENT
    request.onload = () => {
      //READ THE FILE
      let reader = new FileReader();
      //READ DATA FROM FILE
      reader.readAsDataURL(request.response);
      reader.onload = (e) => {
        this.convertBase64ToBlob(e);
      };
    };

    request.onerror = async (err) => {
      const key = doc.split("/")[3].split("?")[0];
      await this.props.GetDocumentExpiryLink(key, siteId);
      if (this.props.getDocumentExpiryLink === 200) {
        this.props.ClearCommonStatus();

        //GET THE FILE FROM SERVER
        let request = new XMLHttpRequest();
        request.open("GET", this.props.docExpiryLink, true);
        request.responseType = "blob";
        request.send();

        //AFTER SUCCESSFUL REQUEST READ THE FILE CONTENT
        request.onload = () => {
          //READ THE FILE
          let reader = new FileReader();
          //READ DATA FROM FILE
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            this.convertBase64ToBlob(e);
          };
        };
      }
    };
  };

  //SITE STATUS
  getSiteStatus = (item) => {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    const { userType } = this.props;
    let siteStatus;
    if (
      userType === HELPLINE_USER &&
      item.sitestatus === 1 &&
      item.reviewInfo &&
      item.reviewInfo.sitereviewed
    ) {
      siteStatus = [
        langData[lan].nonClosed || langData[0].nonClosed,
        "Initiated for warranty",
        langData[lan].warrantyIssue || langData[0].warrantyIssue,
        langData[lan].warrantyClaim || langData[0].warrantyClaim,
        langData[lan].droppedSite || langData[0].droppedSite,
      ];
    } else
      siteStatus = [
        langData[lan].nonClosed || langData[0].nonClosed,
        langData[lan].underReview || langData[0].underReview,
        langData[lan].warrantyIssue || langData[0].warrantyIssue,
        langData[lan].warrantyClaim || langData[0].warrantyClaim,
        langData[lan].droppedSite || langData[0].droppedSite,
      ];
    return siteStatus;
  };

  getPageChangeData = () => {
    this.getRegisteredSitesList();
    this.props.SavePageNumber(this.state.pageNum);
  };

  //HANDLE PAGE CHANGE
  handlePageChange = (e) => {
    const { selected } = e;
    if (selected === this.props.pageNum) return;
    this.setState({ pageNum: selected }, async () => {
      const { searchSiteDetails } = this.props;
      if (searchSiteDetails) {
        this.getPageChangeData();
      } else if (
        !searchSiteDetails &&
        (this.state.selectedSearchOption.value || this.state.searchQuery)
      ) {
        this.setState(
          {
            selectedSearchOption: { value: null, label: "Search by" },
            searchQuery: "",
          },
          async () => {
            this.getPageChangeData();
          }
        );
      } else {
        this.getPageChangeData();
      }
    });
  };

  //HANDLE COLUMN SORTING
  handleSorting = (column) => {
    this.setState(
      {
        sortingColumn: {
          sortBy: column.sortBy,
          sortOrder: column.sortOrder,
        },
        siteHeader: this.state.siteHeader.map((val) => {
          if (val.sortBy === column.sortBy)
            return {
              ...val,
              sortOrder: column.sortOrder === 1 ? 2 : 1,
            };
          else return val;
        }),
      },
      () => {
        this.getRegisteredSitesList();
        //SAVE SORTING ID IN REDUX
        this.props.SaveSortingInfo(this.state.sortingColumn);
      }
    );
  };

  siteListTableBody = () => {
    const { registeredSites, userType } = this.props;
    if (!registeredSites || !registeredSites.sitesList) return;
    return registeredSites.sitesList.map((item) => {
      return {
        data: [
          //   SITE STATUS
          {
            data: this.getSiteStatus(item)[item.sitestatus],
            customClass: "status",
            toolTip: this.getSiteStatus(item)[item.sitestatus],
          },
          //   LEAD ID
          {
            data: item.leadid,
            customClass: "id",
            dataWithIcon: true,
            icon: downloadFile,
            iconClick: (e) => {
              this.props.handleDownloadFiles &&
                this.props.handleDownloadFiles(e, item);
            },
          },
          //   DATE OF REGISTRATION
          {
            data: new Date(item.createdat).toLocaleDateString(),
            customClass: "date",
          },

          //   CONTRACTOR BP NO
          userType !== CONTRACTOR_USER_TYPE && {
            data: item.registrationnum,
            customClass: "BP-id",
          },

          //   CONTRACTOR USER TYPE
          userType !== CONTRACTOR_USER_TYPE && {
            data: item.name,
            customClass: "name",
            toolTip: item.name,
          },

          //   CONTRACTOR PHONE NUM
          userType !== CONTRACTOR_USER_TYPE && {
            data: item.phonenum,
            customClass: "num",
          },
          //   CUSTOMER NAME
          {
            data: item.customername,
            customClass: "name",
            toolTip: item.customername,
          },
          //   CUSTOMER PHONE
          { data: item.customerphone, customClass: "num" },
          //   CUSTOMER EMAIL ID
          {
            data: item.customeremail,
            customClass: "email",
            toolTip: item.customeremail,
          },
          //   CUSTOMER ADDRESS
          {
            data: item.customeraddress,
            customClass: "address",
            toolTip: item.customeraddress,
          },
          //   CUSTOMER PINCODE
          { data: item.pincode, customClass: "pin" },
          //   DEALER CODE
          { data: item.dealercode, customClass: "id" },
          //   DEALER NAME
          {
            data: item.dealername,
            customClass: "name",
            toolTip: item.dealername,
          },
          //   DEALER PHONE
          { data: item.dealerphone, customClass: "num" },
          // EXPECTED SITE VALUE
          { data: item.sitevalue, customClass: "site" },
          //DROPPED SITE REMARK
          { data: item.droppedremark, customClass: "remark" },
          //   WARRANTY INFO
          {
            data: item && item.warrantyInfo?.warrantyid,
            customClass: "warranty",
          },
          //   REVIEW SITE
          //   (userType === HELPLINE_USER || CheckNonContractorUser(userType)) &&
          {
            icon: review,
            customClass: "review",
            toolTip: "Review",
            onClickHandler: (e) => {
              e.stopPropagation();
              this.handleOpenReviewForm(item);
            },
            iconClass: this.isReviewDisabled(item) ? "disabled-button" : "",
          },
          //   WARRANTY DOC
          {
            icon: uploadDoc,
            customClass: "review",
            toolTip: "Warranty document",
            iconClass:
              item.sitestatus === 0 ||
              item.siteStatus === 1 ||
              !item.warrantyInfo ||
              !item.warrantyInfo.warrantydoc
                ? "disabled-button"
                : "",
            onClickHandler: (e) => {
              e.stopPropagation();
              item &&
                item.warrantyInfo &&
                item.warrantyInfo.warrantydoc &&
                this.previewFile(e, item.warrantyInfo.warrantydoc, item.siteid);
            },
          },
          //Warranty Claim reason
          {
            data: item.warrantyInfo && item.warrantyInfo.warrantyclaimreason,
            customClass: "name",
            toolTip: item.warrantyInfo && item.warrantyInfo.warrantyclaimreason,
          },
          //   WARRANTY CLAIM DOCUMENT
          {
            icon: uploadDoc,
            customClass: "claim-document",
            toolTip: "Warranty claim document",
            iconClass:
              item.sitestatus === 0 ||
              item.siteStatus === 1 ||
              item.siteStatus === 2 ||
              !item.warrantyInfo ||
              !item.warrantyInfo.warrantyclaimdoc
                ? "disabled-button"
                : "",
            onClickHandler: (e) => {
              e.stopPropagation();
              item &&
                item.warrantyInfo &&
                item.warrantyInfo.warrantyclaimdoc &&
                this.previewFile(
                  e,
                  item.warrantyInfo.warrantyclaimdoc,
                  item.siteid
                );
            },
          },
        ].filter((val) => val),
        actualObject: item,
        style: item.sitestatus ? { backgroundColor: "#FFF8D0" } : {},
      };
    });
  };

  //   HANDLE ADVANCE SEARCH FOR SITE LIST

  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.getRegisteredSitesList();
        this.props.PersistSearchSiteInfo(null);
        this.props.SavePageNumber(0);
        this.setState({ pageNum: 0 });
      }
    );
  };

  handleSearch = async (e) => {
    this.setState({ pageNum: 0 }, async () => {
      await this.getRegisteredSitesList();
      if (this.props.getRegisteredSites === 200) {
        this.props.ClearRegisteredStatusLog();
        this.props.SavePageNumber(0);
        //IF SEARCH IS SUCCESSFUL THEM SET THE SEARCH DETAILS IN REDUX
        this.props.PersistSearchSiteInfo({
          searchBy: this.state.selectedSearchOption,
          searchQuery: this.state.searchQuery,
        });
        this.setState({ showSearchResults: true });
      }
    });
  };

  handleInputChange = (e) => {
    if (!e.target.value.trim()) {
      this.setState({ showNotFound: false });
      //   this.props.handleShowList(true);
    }
    this.setState({ searchQuery: e.target.value });
  };

  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    const { countOfData, searchable = true, sortable = true } = this.props;
    const { showInitiateWarrantySuccess, siteHeader } = this.state;
    //prettier-ignore
    const { selectedSearchOption, searchQuery,showSearchResults } = this.state;
    return (
      <React.Fragment>
        {/* ADVANCE SEARCH  */}
        {searchable && (
          <AdvanceSearch
            searchQuery={searchQuery}
            selectedSearchOption={selectedSearchOption}
            showSearchResults={showSearchResults}
            showClearButton={this.props.searchSiteDetails}
            handleSearch={this.handleSearch}
            handleInputChange={this.handleInputChange}
            handleClearSearch={this.handleClearSearch}
            handleSearchByOptionSelection={this.handleSearchByOptionSelection}
            handleClearAllSearch={this.handleClearAllSearch}
          />
        )}
        {this.props.registeredSites &&
        this.props.registeredSites.sitesList &&
        this.props.registeredSites.sitesList.length ? (
          <React.Fragment>
            <CustomTable
              tableHeader={siteHeader.filter((val) => val)}
              tableBody={this.siteListTableBody().filter((val) => val)}
              countOfData={countOfData}
              pageNum={this.props.contractorPageNum}
              pageSize={PAGE_SIZE}
              paginationId="site-pagination"
              pagination={true}
              handlePageChange={this.handlePageChange}
              rowClickHandler={this.props.handleContractorClick}
              rowClickable={true}
              handleSort={this.handleSorting}
              tableWrapperClass="site-list-table-wrapper"
              tableClass="site-list-table"
              sortable={sortable}
            />
            {/* REVIEW SITE */}
            {this.state.openReview && (
              <div className="review-site-overlay">
                <ReviewSite
                  closeModal={this.handleReviewForm}
                  reviewFormData={this.state.reviewFormData}
                  siteInfo={this.state.siteInfo}
                  fromList={true}
                  handleInitiateWarrantySuccess={
                    this.handleInitiateWarrantySuccess
                  }
                />
              </div>
            )}
            {showInitiateWarrantySuccess && (
              <InitiateWarrantySuccess
                handleInitiateWarrantySuccess={
                  this.handleCloseInitiateWarrantySuccess
                }
              />
            )}
          </React.Fragment>
        ) : (
          <DataNotFound
            msg={langData[lan].noSites || "Sites are not registered"}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPhoneNum: state.auth.userPhoneNum,
    // user: state.auth.user,
    registeredSites: state.regSites.registeredSites,
    // registeredSites: null,
    isLast: state.regSites.isLast,
    getRegisteredSites: state.regSites.getRegisteredSites,
    countOfData: state.regSites.countOfData,

    userType: state.auth.userType,
    sidebarId: state.auth.sidebarId,

    getDocumentExpiryLink: state.common.getDocumentExpiryLink,
    docExpiryLink: state.common.docExpiryLink,
    pageNum: state.common.pageNum,
    searchSiteDetails: state.common.searchSiteDetails,
    siteSortDetails: state.common.siteSortDetails,
    searchContractorDetails: state.common.searchContractorDetails,
  };
};

export default connect(mapStateToProps, {
  GetRegisteredSites,
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
  SavePageNumber,
  GetDocumentExpiryLink,
  ClearCommonStatus,
  SaveSortingInfo,
  PersistSearchSiteInfo,
  GetMonthYearWiseSites,
})(SiteList);

/* PAGINATION */
/*  

  //HANDLE PREVIOUS BUTTON
  handlePrevious = () => {
    const { pageNum } = this.state;
    if (pageNum === 0) return;
    this.setState({ pageNum: pageNum - 1 }, async () => {
      this.getRegisteredSitesList();
      this.props.SavePageNumber(this.state.pageNum);
    });
  };

  //HANDLE NEXT BUTTON
  handleNext = () => {
    const { pageNum } = this.state;

    this.setState({ pageNum: pageNum + 1 }, async () => {
      this.getRegisteredSitesList();
      this.props.SavePageNumber(this.state.pageNum);
    });
  };

<div
          className="pagination"
          style={userType === CONTRACTOR_USER_TYPE ? { width: "100%" } : {}}
        >
          <div className="previous-wrapper">
            {this.state.pageNum ? (
              <div
                className={`prev bk-blue `}
                //   ${this.state.pageNum === 0 && "disabled-pagination"}
                onClick={() => this.state.pageNum && this.handlePrevious()}
              >
                <img src={prevArrow} alt="" /> {langData[lan].prev}
              </div>
            ) : null}
          </div>
          {!this.props.isLast ? (
            <div
              className={`next bk-blue 
			  `}
              //   ${this.props.isLast && "disabled-pagination"}
              onClick={() => !this.props.isLast && this.handleNext()}
            >
              {langData[lan].next} <img src={nextArrow} alt="" />
            </div>
          ) : null}
        </div>
 */
