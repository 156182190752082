import React, { Component } from "react";
import Compressor from "compressorjs";
import { connect } from "react-redux";
import _ from "lodash";
import delImage from "../../Resources/close-img.svg";
import editIcon from "../../Resources/edit.svg";

//ACTIONS
import { AddProductImages } from "../../Actions/SiteRegistration";
import {
  DeleteDealerInvoice,
  DeleteProductImages,
  EditProductImages,
  ClearRegisteredStatusLog,
  GetExpiryLink,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { DeleteImage, GetLinkForUpload } from "../../Actions/AssetsAction";
import {
  ShowAlert,
  StopLoader,
  StartLoader,
  SaveImageFiles,
  SetProgressLoader,
} from "../../Actions/Common";

import History from "../_Utils/History";
import { langData } from "../Common/Language";
import ReactModal from "react-modal";
import ImagePreview from "../Common/ImagePreview";
import { SUCCESSFULSUBMIT } from "../_Utils/Routes";
import {
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
  CONTRACTOR_USER_TYPE,
} from "../../Actions/Types";
import { SaveSidebarIndex } from "../../Actions/AuthAction";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import EditImage from "../Common/EditImage";
import axios from "axios";
import { GenerateKeyForUpload } from "../_Utils/GenerateKeyForUpload";

/**
 * Step3: Class component for step3
 * Used to edit step3 of registered sites
 */
class Step3 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;
  constructor(props) {
    super(props);
    this.state = {
      dealerInvoice: [],
      productImages: [],
      invoiceError: false,
      prodImgError: false,
      openWebCamera: false,
      imagePreview: false,
      images: [],
      imageIndex: 0,
      showSubmitButton: false,

      //STATES FOR EDIT IMAGE
      openUploadImagePopup: false,
      editImageObject: null,
      deleteImageObject: null,
      deleteFor: null,
    };
  }

  //Get images expiry link
  getImageLink = async () => {
    if (this.props.siteDetails) {
      let dealer = [];
      for (let i = 0; i < this.props.siteDetails.dealerInvoices.length; i++) {
        const temp = this.props.siteDetails.dealerInvoices[i];
        let urlLink = "";
        dealer.push({ ...temp, expiryLink: urlLink });
      }
      let prodImage = [];
      for (let i = 0; i < this.props.siteDetails.productImages.length; i++) {
        const temp = this.props.siteDetails.productImages[i];
        let urlLink = "";
        prodImage.push({ ...temp, expiryLink: urlLink });
      }
      this.setState({
        dealerInvoice: dealer,
        productImages: prodImage,
      });
    }
  };

  getImageLinkForNew = async () => {
    let { addedDealerIn, addedProdImages } = this.props;

    //Remove file objects and add new added objects
    let tempDealerInvoice = [];
    for (let i = 0; i < this.state.dealerInvoice.length; i++) {
      const temp = this.state.dealerInvoice[i];
      if (!temp.file) {
        tempDealerInvoice.push(temp);
      }
    }
    let tempProductImages = [];
    for (let i = 0; i < this.state.productImages.length; i++) {
      const temp = this.state.productImages[i];
      if (!temp.file) {
        tempProductImages.push(temp);
      }
    }

    //Create temporary links
    let tempDealerLinks = [];
    let tempProdLinks = [];

    for (let i = 0; i < addedDealerIn.length; i++) {
      const image = addedDealerIn[i];
      let urlLink = "";
      tempDealerLinks.push({ ...image, expiryLink: urlLink });
    }

    for (let i = 0; i < addedProdImages.length; i++) {
      const image = addedProdImages[i];
      let urlLink = "";
      tempProdLinks.push({ ...image, expiryLink: urlLink });
    }

    this.setState({
      dealerInvoice: [...tempDealerInvoice, ...tempDealerLinks],
      productImages: [...tempProductImages, ...tempProdLinks],
    });
  };

  componentDidMount() {
    // this.getImageLink();
    const { siteDetails, userType } = this.props;
    if (!siteDetails) return;
    let arr = document.querySelectorAll("input[type=file]");
    //IF USER IS HELPLINE THEN FOR OPEN, DROPPED SITE AND UNDER REVIEW SITE , SITE IS EDITABLE
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 ||
        siteDetails.sitestatus === 1 ||
        siteDetails.sitestatus === 4)
    ) {
      arr.forEach((element) => {
        element.disabled = false;
      });
    }
    //FOR OTHER USERS, SITE IS EDITABLE ONLY FOR OPEN AND UNDER REVIEW STATUS
    else if (
      userType !== HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1)
    ) {
      let fileArr = document.querySelectorAll("input[type=file]");
      if (window.innerWidth > 768)
        fileArr.forEach((element) => {
          element.disabled = true;
        });
      else
        fileArr.forEach((element) => {
          element.disabled = false;
        });
    } else {
      arr.forEach((element) => {
        element.disabled = true;
      });
    }

    if (this.props.siteDetails)
      this.setState({
        dealerInvoice: this.props.siteDetails.dealerInvoices,
        productImages: this.props.siteDetails.productImages,
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isPrevClicked === 3 || nextProps.siteCloseButtonClicked) {
      nextProps.upgradeImagesToParent(
        prevState.dealerInvoice.filter((item) => !item.dealerinvoiceid),
        prevState.productImages.filter((item) => !item.productimgid)
      );
    }

    if (
      nextProps.siteDetails /* (nextProps.userType !== HELPLINE_USER &&
        nextProps.siteDetails.sitestatus === 1) || */ &&
      nextProps.userType === HELPLINE_USER &&
      nextProps.siteDetails.sitestatus === 2
    ) {
      let arr = document.querySelectorAll("input[type=file]");
      arr.forEach((element) => {
        element.disabled = true;
      });
      return {
        dealerInvoice: prevState.dealerInvoice.filter(
          (item) => item.dealerinvoiceid
        ),
        productImages: prevState.productImages.filter(
          (item) => item.productimgid
        ),
      };
    }
    // if (nextProps.siteDetails) {
    //   return {
    //     dealerInvoice: nextProps.siteDetails.dealerInvoices,
    //     productImages: nextProps.siteDetails.productImages,
    //   };
    // }
    return null;
  }

  validateInvoice = () => {
    if (!this.state.dealerInvoice || !this.state.dealerInvoice.length) {
      this.setState({ invoiceError: true });
      return false;
    }
    this.setState({ invoiceError: false });
    return true;
  };

  validateProdImg = () => {
    if (!this.state.productImages || !this.state.productImages.length) {
      this.setState({ prodImgError: true });
      return false;
    }
    this.setState({ prodImgError: false });
    return true;
  };

  validateAll = () => {
    let a = this.validateInvoice();
    let b = this.validateProdImg();
    if (a && b) return true;
    else return false;
  };

  //IF NOT REQUIRED THEN REMOVE IT
  getSiteList = async () => {
    const { userType, userPhoneNum, registeredSites } = this.props;

    //IF USER TYPE IS HELPLINE
    if (userType === HELPLINE_USER) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum },
        0,
        PAGE_SIZE
      );
    }

    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum, requesterPhone: userPhoneNum },
        0,
        PAGE_SIZE,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }
    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
    }
  };

  onSubmitClick = async () => {
    const { productImages, dealerInvoice } = this.state;
    if (!this.validateAll()) return;
    const reqBody = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: this.props.siteDetails.siteid,
      phonenum: this.props.siteDetails.phonenum,
      dealerinvoices: dealerInvoice
        .filter((file) => !file.dealerinvoiceid)
        .map((file) => file.imagelink),
      productImages: productImages
        .filter((file) => !file.productimgid)
        .map((file) => file.imagelink),
    };

    const productObj = {
      dealerInvoices: this.state.dealerInvoice,
      productImages: productImages,
    };

    await this.props.EditProductImages(reqBody, productObj);
    if (this.props.editProductImages === 200) {
      localStorage.removeItem("saveFiles");
      this.setState({
        productImages: this.props.siteDetails.productImages,
        dealerInvoice: this.props.siteDetails.dealerInvoices,
        showSubmitButton: false,
      });
      this.props.ShowAlert({
        msg: langData[this.lan].editProdImgs,
        alertType: "Success",
      });

      //   this.getSiteList();

      this.props.ClearRegisteredStatusLog();

      //HANDLING OF SUBMIT PAGE FOR DIFFERENT USER
      if (this.props.userType === CONTRACTOR_USER_TYPE)
        History.push(SUCCESSFULSUBMIT);
      else if (CheckNonContractorUser(this.props.userType)) {
        this.props.SaveSidebarIndex(0);
        History.push(SUCCESSFULSUBMIT);
      }
    }

    // this.getImageLinkForNew();
    // this.props.handleCancelBtn();
  };

  /**
   * handleAddProductImage: Upload  product images to cloud
   */
  handleAddProductImage = async (e, file) => {
    if (file) {
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          /* fetch(url, { method: "PUT", body: compressedResult })
            .then(() => {
              this.setState({
                productImages: [
                  { imagelink: key, file: compressedResult },
                  ...this.state.productImages,
                ],
                showSubmitButton: true,
              });
              this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.StopLoader();
            })
            .catch((err) => {
              console.log(err);
              this.props.StopLoader();
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            }); */
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.setState({
                productImages: [
                  { imagelink: key, file: compressedResult },
                  ...this.state.productImages,
                ],
                showSubmitButton: true,
              });
              this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.SetProgressLoader(false, null);
              console.log(err);
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            });
        },
        error: () => {
          this.props.StopLoader();
        },
      });
    }
    if (e) e.target.value = "";
  };

  /**
   * handleDealerInvoice: Upload  dealer invoice to cloud
   */
  handleDealerInvoice = (e, file) => {
    if (file) {
      this.props.SetProgressLoader(true, 0);
      new Compressor(file, {
        quality: 0.2,
        success: async (compressedResult) => {
          const key = GenerateKeyForUpload(file.name);
          const url = await GetLinkForUpload({ key: key });
          /*  fetch(url, { method: "PUT", body: compressedResult })
            .then((data) => {
              console.log(data);
              this.setState(
                {
                  dealerInvoice: [
                    {
                      imagelink: key,
                      file: compressedResult,
                    },
                    ...this.state.dealerInvoice,
                  ],
                  showSubmitButton: true,
                },
                this.validateInvoice
              );
              //close edit image popup if opened
              this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.StopLoader();
            })
            .catch((err) => {
              console.log(err);
              this.props.StopLoader();
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            }); */
          const config = {
            headers: {
              "content-type": compressedResult.type,
            },
            onUploadProgress: (progressEvent) => {
              this.props.SetProgressLoader(
                true,
                Math.floor((progressEvent.loaded / progressEvent.total) * 100)
              );
            },
          };
          //AXIOS CODE
          axios
            .put(url, compressedResult, config)
            .then((res) => {
              this.setState(
                {
                  dealerInvoice: [
                    {
                      imagelink: key,
                      file: compressedResult,
                    },
                    ...this.state.dealerInvoice,
                  ],
                  showSubmitButton: true,
                },
                this.validateInvoice
              );
              //close edit image popup if opened
              this.handleCloseEditImage();
              let images = [];
              let files = JSON.parse(localStorage.getItem("saveFiles"));
              if (files) {
                images = files;
              }
              images.push(key);
              this.props.SaveImageFiles(images);
              this.props.SetProgressLoader(false, null);
            })
            .catch((err) => {
              this.props.SetProgressLoader(false, null);
              console.log(err);
              this.props.ShowAlert({
                msg: langData[this.lan].failedToUp,
                alertType: "Error",
              });
            });
        },
        error: () => {
          this.props.SetProgressLoader(false, null);
        },
      });
    }
    if (e) e.target.value = "";
  };

  /**
   * deleteDealerInvoiceFromCloud: delete dealer invoice from cloud
   */
  deleteDealerInvoiceFromCloud = async (file) => {
    this.props.DeleteImage({ key: [file.imagelink] });
    // this.props.ShowAlert({
    //   msg: langData[this.lan].failedToDel,
    //   alertType: "Error",
    // });
    this.setState({
      dealerInvoice: this.state.dealerInvoice.filter((image) => image !== file),
    });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images) {
      console.log(file);
      _.remove(images, (e) => {
        return e === file.imagelink;
      });
    }
    if (images.length < 1) {
      localStorage.removeItem("saveFiles");
    } else {
      this.props.SaveImageFiles(images);
    }
  };

  deleteDealerInvoice = async () => {
    const file = this.state.deleteImageObject;
    let reqBody = {
      hluserid:
        this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
      requesterId:
        this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
      siteid: this.props.siteDetails.siteid,
      phonenum: this.props.siteDetails.phonenum,
      dealerinvoiceid: file.dealerinvoiceid,
    };
    this.props.StartLoader();
    //       this.props.SetProgressLoader(true, 0);
    await this.props.DeleteDealerInvoice(reqBody);
    if (this.props.deleteDealerInvoiceStatus === 200) {
      /*  this.props.ShowAlert({
        msg: langData[this.lan].deleteDealerInvoice,
        alertType: "Success",
      }); */
      this.props.ClearRegisteredStatusLog();
      this.setState({
        dealerInvoice: this.state.dealerInvoice.filter(
          (image) => image !== file
        ),
      });
      this.props.StopLoader();
      this.handleDealerInvoice(null, this.state.editImageObject);
    } else {
      this.props.StopLoader();
    }
  };

  /**
   * deleteImageFromCloud: delete product image from cloud
   */
  deleteImageFromCloud = async (file) => {
    this.setState({
      productImages: this.state.productImages.filter((image) => image !== file),
    });
    this.props.DeleteImage({ key: [file.imagelink] });
    const images = JSON.parse(localStorage.getItem("saveFiles"));
    if (images) {
      console.log(file);
      _.remove(images, (e) => {
        return e === file.imagelink;
      });
    }
    if (images.length < 1) {
      localStorage.removeItem("saveFiles");
    } else {
      this.props.SaveImageFiles(images);
    }
  };

  /**
   * deleteProductImage: delete product image from cloud+server
   */
  deleteProductImage = async () => {
    const file = this.state.deleteImageObject;
    if (file.productimgid) {
      const reqBody = {
        hluserid:
          this.props.userType === HELPLINE_USER ? this.props.userId : undefined, //send this key if user is helpline
        requesterId:
          this.props.userType !== HELPLINE_USER ? this.props.userId : undefined, //send this key if user is non-helpline
        siteid: file.siteid,
        phonenum: this.props.siteDetails.phonenum,
        productimgid: file.productimgid,
      };
      this.props.StartLoader();
      await this.props.DeleteProductImages(reqBody);
      if (this.props.deleteProductImages === 200) {
        this.props.ClearRegisteredStatusLog();
        /* this.props.ShowAlert({
          msg: langData[this.lan].deleteProdImgs,
          alertType: "Success",
        }); */
        this.setState({
          productImages: this.state.productImages.filter(
            (image) => image !== file
          ),
        });
        this.props.StopLoader();
        this.handleAddProductImage(null, this.state.editImageObject);
      } else {
        this.props.StopLoader();
      }
    }
  };

  handleCloseImagePreview = () => {
    this.setState({ imagePreview: false, images: [] });
  };
  handleOpenImagePreview = (id, index) => {
    this.setState({
      imagePreview: true,
      images: id === 1 ? this.state.dealerInvoice : this.state.productImages,
      imageIndex: index,
    });
  };

  //HANDLE RESET BUTTON
  handleResetButton = () => {
    let { dealerInvoice, productImages } = this.state;
    dealerInvoice = dealerInvoice.filter((val) => !val.dealerinvoiceid);
    productImages = productImages.filter((val) => !val.productimgid);

    if (dealerInvoice && dealerInvoice.length) {
      this.props.DeleteImage({
        key: dealerInvoice.map((val) => val.imagelink),
      });
    }
    if (productImages && productImages.length) {
      this.props.DeleteImage({
        key: productImages.map((val) => val.imagelink),
      });
    }

    if (this.props.siteDetails)
      this.setState({
        dealerInvoice: this.props.siteDetails.dealerInvoices,
        productImages: this.props.siteDetails.productImages,
      });
  };

  //HANDLE OPEN POPUP FOR UPLOAD
  handleOpenUploadPopup = (deleteImageObject, deleteFor) => {
    this.setState({ openUploadImagePopup: true, deleteImageObject, deleteFor });
  };

  //HANDLE CLOSE POPUP
  handleCloseEditImage = () => {
    this.setState({
      openUploadImagePopup: false,
      deleteImageObject: null,
      editImageObject: null,
      deleteFor: null,
    });
  };

  handleEditImageYes = (editImageObject) => {
    this.setState(
      {
        editImageObject,
      },
      () => {
        if (this.state.deleteFor === "dealer") this.deleteDealerInvoice();
        else if (this.state.deleteFor === "product") this.deleteProductImage();
      }
    );
  };

  showEditButton = () => {
    const { siteDetails, userType } = this.props;
    if (!siteDetails) return false;
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 ||
        siteDetails.sitestatus === 1 ||
        siteDetails.sitestatus === 4)
    ) {
      return true;
    } else if (
      userType !== HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1)
    )
      return true;
    return false;
  };

  render() {
    const {
      productImages,
      dealerInvoice,
      invoiceError,
      prodImgError,
      showSubmitButton,
      openUploadImagePopup,
    } = this.state;

    return (
      <div className="step3-page">
        <div className="field">
          <div className="label">{langData[this.lan].upInvoice}</div>
          <div className="img-container">
            <div className="container">
              <div className="product-img">
                <input
                  type="file"
                  accept="image/*"
                  className="file-input"
                  id="upload-dealer-invoice"
                  onChange={(event) => {
                    let file = event.target.files[0];
                    if (file) {
                      this.handleDealerInvoice(event, file);
                    }
                  }}
                  disabled={false}
                />
                <label htmlFor="upload-dealer-invoice" className="upload-icon">
                  <img
                    src="/Assets/upload.svg"
                    alt="uploadImg"
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </div>
            </div>
            {dealerInvoice &&
              dealerInvoice.map((obj, index) => {
                return (
                  <div className="container" key={index}>
                    <div className="container-image-div">
                      <img
                        className="product-img"
                        src={
                          obj.file
                            ? URL.createObjectURL(obj.file)
                            : obj.expiryLink
                        }
                        alt="Dealer Invoice"
                        onClick={() => this.handleOpenImagePreview(1, index)}
                        onError={() => {
                          this.props.GetExpiryLink(obj, 2);
                        }}
                      />
                      {obj.file ? (
                        <img
                          src={delImage}
                          alt="delete"
                          className="delete-icon"
                          onClick={() => this.deleteDealerInvoiceFromCloud(obj)}
                        />
                      ) : null}
                    </div>

                    {/* SHOW DELETE ICON IF THERE ARE MORE THAN 1 IMAGE */}
                    {dealerInvoice.filter((val) => val.dealerinvoiceid)
                      .length !== 1 &&
                    obj.dealerinvoiceid &&
                    this.showEditButton() ? (
                      <img
                        className="del-icon"
                        src={editIcon}
                        onClick={() =>
                          this.handleOpenUploadPopup(obj, "dealer")
                        }
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      />
                    ) : /* <img
                        className="del-icon"
                        src="/Assets/del-icon.svg"
                        onClick={() => this.deleteDealerInvoice(obj)}
                        alt="Delete"
                        style={{ cursor: "pointer" }}
                      /> */
                    null}
                  </div>
                );
              })}
          </div>

          {invoiceError ? (
            <div className="input-error">{langData[this.lan].upInvoiceEr}</div>
          ) : null}
        </div>

        {/* PRODUCT IMAGES */}
        <div className="field">
          <div className="label">{langData[this.lan].upProdImg}</div>
          <div className="img-container">
            <div className="container">
              <div className="product-img">
                <input
                  type="file"
                  accept="image/*"
                  className="file-input"
                  id="upload-product"
                  onChange={(event) => {
                    let file = event.target.files[0];
                    if (file) {
                      this.handleAddProductImage(event, file);
                    }
                  }}
                  disabled={false}
                  //   capture={true}
                />
                <label htmlFor="upload-product" className="upload-icon">
                  <img
                    src="/Assets/upload.svg"
                    alt="uploadImg"
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </div>
            </div>

            {productImages.map((obj, index) => (
              <div className="container" key={index}>
                <div className="container-image-div">
                  <img
                    className="product-img"
                    id={`dealer${index}`}
                    src={
                      obj.file ? URL.createObjectURL(obj.file) : obj.expiryLink
                    }
                    alt="productImg"
                    onClick={() => this.handleOpenImagePreview(2, index)}
                    onError={() => {
                      this.props.GetExpiryLink(obj, 3);
                      document.getElementById(`dealer${index}`).src =
                        obj.expiryLink;
                    }}
                  />
                  {!obj.productimgid ? (
                    <img
                      src={delImage}
                      alt="delete"
                      className="delete-icon"
                      onClick={() => this.deleteImageFromCloud(obj)}
                    />
                  ) : null}
                </div>
                {productImages.filter((val) => val.productimgid).length !== 1 &&
                obj.productimgid &&
                this.showEditButton() ? (
                  <img
                    className="del-icon"
                    src={editIcon}
                    alt="delIcon"
                    onClick={() => this.handleOpenUploadPopup(obj, "product")}
                  />
                ) : /*  <img
                    className="del-icon"
                    src="/Assets/del-icon.svg"
                    alt="delIcon"
                    onClick={() => this.deleteProductImage(obj)}
                  /> */
                null}
              </div>
            ))}
          </div>
          {prodImgError ? (
            <div className="input-error">{langData[this.lan].upProdImgEr} </div>
          ) : null}
        </div>
        {this.showEditButton() && showSubmitButton ? (
          <div className="step-save-div">
            <div
              className="step-save-div-btn bk-blue"
              onClick={() => this.onSubmitClick()}
            >
              {langData[this.lan].submit}
            </div>
            <div
              className="step-save-div-btn bk-white reset-button"
              onClick={() => this.handleResetButton()}
            >
              {langData[this.lan].reset || langData[0].reset}
            </div>
          </div>
        ) : null}
        {openUploadImagePopup && (
          <EditImage
            handleCloseEditImage={this.handleCloseEditImage}
            handleEditImageYes={this.handleEditImageYes}
          />
        )}
        <ReactModal
          onRequestClose={this.handleCloseImagePreview}
          isOpen={this.state.imagePreview}
          className="image-preview"
          overlayClassName="image-preview-overlay"
        >
          <ImagePreview
            handleCloseImagePreview={this.handleCloseImagePreview}
            images={this.state.images}
            imageIndex={this.state.imageIndex}
          />
        </ReactModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userPhoneNum: state.site.userPhoneNum,

    siteDetails: state.regSites.siteDetails,
    deleteDealerInvoiceStatus: state.regSites.deleteDealerInvoiceStatus,
    deleteProductImages: state.regSites.deleteProductImages,
    addedProdImages: state.regSites.addedProdImages,
    addedDealerIn: state.regSites.addedDealerIn,
    editProductImages: state.regSites.editProductImages,
    registeredSites: state.regSites.registeredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,

    userType: state.auth.userType,
    sidebarId: state.auth.sidebarId,
    userId: state.auth.userId,
  };
};

export default connect(mapStateToProps, {
  AddProductImages,
  DeleteDealerInvoice,
  DeleteProductImages,
  EditProductImages,
  ShowAlert,
  StartLoader,
  StopLoader,
  DeleteImage,
  GetExpiryLink,
  SaveImageFiles,
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
  SaveSidebarIndex,
  SetProgressLoader,
})(Step3);
