import React, { Component } from "react";
import { connect } from "react-redux";
import JsZip from "jszip";
import { saveAs } from "file-saver";

//RESOURCES
// import contactIcon from "../../Resources/contact-icon.svg";
// import searchNotFound from "../../Resources/search-not-found.svg";
// import search from "../../Resources/search.svg";

//COMPONENTS
// import SiteList from "../RegisteredSites/SiteList";

//ACTIONS
import {
  GetRegisteredSites,
  ClearSearchResult,
  DownloadImages,
  ClearRegisteredStatusLog,
  GetDetailsOfSite,
  SetSiteDetails,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import {
  PersistSearchContractorInfo,
  PersistSearchSiteInfo,
  SaveContractorPageNumber,
  SavePageNumber,
  ShowAlert,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import { SaveUserDetails } from "../../Actions/SiteRegistration";
import ContractorList from "./ContractorList";
import {
  ClearContractorStatus,
  GetContractorList,
  GetContractorListForHelpline,
} from "../../Actions/ContractorAction";
import {
  CONTRACTOR_USER_TYPE,
  HELPLINE_GET_SITE_IMAGES,
  HELPLINE_USER,
  NON_CONTACTOR_GET_SITE_IMAGES,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
// import { CreateSignedURL } from "../../API/CreateSignedURL";
import UserInfo from "../Common/UserInfo";
import ContractorSiteList from "./ContractorSiteList";
// import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
// import CustomTable from "../CustomTable/CustomTable";

/**
 * Class SearchContractor:
 * render search contractor and sitelist
 */
class SearchContractor extends Component {
  //Zip object
  zip = new JsZip();
  constructor(props) {
    super(props);
    this.state = {
      dealerInvoiceArray: [],
      furArray: [],
      prodArray: [],
      siteId: null,
      showSiteList: props.showSiteList,

      searchQuery: "",
      //   showSearchOptions: false,
      //   selectedSearchOption: { value: null, label: "Search by" },
      //   showSearchResults: false,
      //   showSearchSuggestions: false,
    };
  }

  componentDidMount() {
    //Get registered sites
    /*   const { registeredSites } = this.props;
    if (registeredSites && registeredSites.phonenum) {
      const reqBody = {
        phonenum: registeredSites.phonenum,
      };
      this.props.GetRegisteredSitesForHelpLine(reqBody);
    } */
  }

  getContractorList = async () => {
    const { searchContractorDetails, userPhoneNum, DSRDetails } = this.props;
    let reqBody = {
      searchKey: searchContractorDetails
        ? searchContractorDetails.searchBy.value
        : undefined,
      searchValue: searchContractorDetails
        ? searchContractorDetails.searchQuery.trim() || undefined
        : undefined,
    };
    if (CheckNonContractorUser(this.props.userType)) {
      reqBody["requesterPhone"] = DSRDetails
        ? DSRDetails.phonenum
        : userPhoneNum;
      await this.props.GetContractorList(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    } else if (this.props.userType === HELPLINE_USER) {
      await this.props.GetContractorListForHelpline(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
    }
  };

  componentDidUpdate() {
    //Download zip file after all files downloading
    const { images } = this.props;
    const { furArray, prodArray, dealerInvoiceArray } = this.state;
    if (images) {
      const { furnitureImages, productImages, dealerinvoices } = images;
      if (furnitureImages && productImages && dealerInvoiceArray)
        if (
          furArray.length === furnitureImages.length &&
          prodArray.length === productImages.length &&
          dealerInvoiceArray.length === dealerinvoices.length &&
          this.props.downloadStatus === 200
        ) {
          this.props.ClearRegisteredStatusLog();
          this.zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, this.state.siteId);
          });
        }
    }
  }

  /**
   * handleSearchContractor: Function
   * search sites of contractor
   */
  handleSearchContractor = async (e) => {
    if (e.keyCode === 13) {
      this.getContractorSites();
    }
  };

  handleSearchChange = (e) => {
    if (this.props.userType === HELPLINE_USER) {
      this.setState({ searchQuery: e.target.value }, () => {
        if (
          this.state.searchQuery.trim().length === 10 ||
          this.state.searchQuery.trim().length === 12
        ) {
          this.props.userType === HELPLINE_USER && this.getContractorSites();
        }
      });
    } else this.setState({ searchQuery: e.target.value });
  };

  //GET CONTRACTOR SITES
  getContractorSites = async () => {
    const { userPhoneNum, userType } = this.props;
    const reqBody = {
      phonenum: this.state.searchQuery.trim(),
    };

    if (this.props.userType === HELPLINE_USER)
      await this.props.GetRegisteredSitesForHelpLine(reqBody, 0, PAGE_SIZE);
    else if (userType === CONTRACTOR_USER_TYPE)
      await this.props.GetRegisteredSites(reqBody, 0, PAGE_SIZE);
    else {
      const reqBody = {
        requesterPhone: userPhoneNum,
        phonenum: this.state.searchQuery.trim(),
      };
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        0,
        PAGE_SIZE,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }

    if (this.props.getRegisteredSites !== 200) {
      this.props.ClearRegisteredStatusLog();
      this.props.ShowAlert({ msg: "Invalid number", alertType: "Error" });
    } else if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
      if (this.props.registeredSites && this.props.registeredSites.sitesList) {
        this.setState({ showSiteList: true });
      } else if (
        this.props.registeredSites &&
        !this.props.registeredSites.phonenum
      ) {
        this.props.ShowAlert({
          msg: "Contractor not found",
          alertType: "Error",
        });
      }
    }
  };

  /**
   * handleSiteClick: On site click get the site info
   */
  handleSiteClick = async (item) => {
    const reqBody = { leadid: item.leadid };
    await this.props.GetDetailsOfSite(reqBody);
    if (this.props.getSiteInfo === 200) {
      this.props.handleNewReg();
      this.props.ClearRegisteredStatusLog();
    }
    // }
  };

  /**
   * downloadZipFile: Download images into zip file
   */
  downloadZipFile = async (item) => {
    const { images } = this.props;
    //Create Zip structure
    let furFolder = this.zip.folder("Furniture Images");
    let prodFolder = this.zip.folder("Product Images");
    let dealer = this.zip.folder("Dealer Invoice Images");

    //Download Dealer Invoice Array
    const { dealerinvoices } = images;
    dealerinvoices &&
      dealerinvoices.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            const list = image.link.split(".");
            const ext = list[list.length - 1];
            dealer.file(image.name + "." + ext, request.response, {
              type: Blob,
            });
            this.setState({
              dealerInvoiceArray: [...this.state.dealerInvoiceArray, index],
            });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });

    //Download Furniture images
    const { furnitureImages } = images;
    furnitureImages &&
      furnitureImages.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          reader.onload = (e) => {
            const list = image.link.split(".");
            const ext = list[list.length - 1];
            furFolder.file(image.name + "." + ext, request.response, {
              type: Blob,
            });
            this.setState({ furArray: [...this.state.furArray, index] });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });
    //Download Product images
    const { productImages } = images;
    productImages &&
      productImages.map(async (image, index) => {
        let request = new XMLHttpRequest();
        request.open("GET", image.expiryLink, true);
        request.responseType = "blob";
        this.props.StartLoader();
        request.send();

        request.onload = () => {
          let reader = new FileReader();
          reader.readAsDataURL(request.response);
          const list = image.link.split(".");
          const ext = list[list.length - 1];
          reader.onload = () => {
            prodFolder.file(image.name + "." + ext, request.response);
            this.setState({ prodArray: [...this.state.prodArray, index] });
          };
          this.props.StopLoader();
        };
        request.onerror = () => {
          this.props.StopLoader();
        };
        return null;
      });
  };

  /**
   * handleDownloadFiles: Get images from server
   */
  handleDownloadFiles = (e, item) => {
    e.stopPropagation();
    this.zip = new JsZip();
    this.setState(
      {
        furArray: [],
        prodArray: [],
        dealerInvoiceArray: [],
        siteId: item.leadid,
      },
      async () => {
        this.props.StartLoader();
        const { userType, userPhoneNum } = this.props;
        const reqBody = {
          siteid: item.siteid,
          requesterPhone: userType !== HELPLINE_USER ? userPhoneNum : undefined,
        };
        await this.props.DownloadImages(
          reqBody,
          userType === HELPLINE_USER
            ? HELPLINE_GET_SITE_IMAGES
            : NON_CONTACTOR_GET_SITE_IMAGES
        );
        this.props.StopLoader();
        if (this.props.downloadStatus === 200) {
          const { images } = this.props;
          if (images) {
            this.downloadZipFile(item);
          }
        }
      }
    );
  };

  /**
   * handleNewSiteReg: open new site reg for helpline user
   */
  handleNewSiteReg = (contractorDetails) => {
    // const { registeredSites } = this.props;
    this.props.SetFurnitureDetails([]);
    /*  this.props.SaveUserDetails(
      {
        name: registeredSites.name,
        phonenum: registeredSites.phonenum,
      },
    ); */
    this.props.handleNewSiteReg(contractorDetails);
  };

  //HANDLE CONTRACTOR CLICK
  handleSelectContractor = async (contractor) => {
    if (contractor && contractor.phonenum) {
      let reqBody = {
        phonenum: contractor.phonenum,
        requesterPhone: this.props.userPhoneNum,
      };
      this.props.SavePageNumber(0);
      //FOR NON-CONTRACTORS GET REGISTERED SITES
      if (CheckNonContractorUser(this.props.userType)) {
        reqBody["requesterPhone"] = this.props.userPhoneNum;
        await this.props.GetRegisteredSitesForHelpLine(
          reqBody,
          0,
          PAGE_SIZE,
          NON_CONTRACTOR_GET_REGISTERED_SITES
        );
      }
      //FOR HELPLINE GET REGISTERED SITES
      else if (this.props.userType === HELPLINE_USER) {
        await this.props.GetRegisteredSitesForHelpLine(reqBody, 0, PAGE_SIZE);
      }

      if (this.props.getRegisteredSites === 200) {
        // this.props.ClearRegisteredStatusLog();
        await this.props.PersistSearchSiteInfo(null);
        this.setState({
          showSiteList: true,
        });
        this.props.DSRDetails && this.props.UserContractorClicked(contractor);
      }
    }
  };

  handleBackButton = () => {
    this.setState({ showSiteList: false });
	this.props.handleBackButton && this.props.handleBackButton()
  };

  /**
   * render: return JSX to show search and site list for helpline user
   */
  render() {
    const { registeredSites, userType } = this.props;
    const { showSiteList, searchQuery } = this.state;
    const { showUserDetails = true } = this.props;
    return (
      <div className="search-contractor font-poppins">
        {userType !== HELPLINE_USER && showUserDetails && <UserInfo />}

        {!showSiteList /* || userType === HELPLINE_USER */ && (
          <div className="search-page-header" style={{ textAlign: "center" }}>
            Search contractor
          </div>
        )}
        {/* SEARCH */}
        {/*  {userType === HELPLINE_USER && (
          <div className="helpline-search-page-input">
            <div className="helpline-search-page-input-container-wrapper">
              <input
                type="text"
                placeholder="Enter contractor phone number"
                onKeyDown={this.handleSearchContractor}
                onChange={this.handleSearchChange}
                maxLength={12}
              />
              <img src={search} alt="" onClick={this.handleClearSearch} />
            </div>
          </div>
        )} */}

        {/* BACK BUTTON */}
        {showSiteList && /*  && CheckNonContractorUser(userType) */  (
            <div className="site-reg-page-close">
              <span onClick={this.handleBackButton} className="bk-blue">
                Back
              </span>
            </div>
          )}
        {showSiteList && registeredSites ? (
          <ContractorSiteList
            handleNewSiteReg={this.handleNewSiteReg}
            searchQuery={searchQuery}
            handleSiteClick={this.handleSiteClick}
            handleDownloadFiles={this.handleDownloadFiles}
			showNewSiteReg={this.props.showNewSiteReg}
          />
        ) : null}

        {!showSiteList /* && CheckNonContractorUser(userType) */ && (
          <ContractorList
            handleSelectContractor={this.handleSelectContractor}
            DSRDetails={this.props.DSRDetails}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    registeredSites: state.regSites.registeredSites,
    downloadStatus: state.regSites.downloadStatus,
    images: state.regSites.images,
    getRegisteredSites: state.regSites.getRegisteredSites,

    visitedSites: state.regSites.visitedSites,
    visitedSiteId: state.regSites.visitedSiteId,

    contractorList: state.contractor.contractorList,
    getContractorList: state.contractor.getContractorList,
    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    searchContractorDetails: state.common.searchContractorDetails,
  };
};

export default connect(mapStateToProps, {
  GetRegisteredSites,
  GetRegisteredSitesForHelpLine,
  GetDetailsOfSite,
  ClearSearchResult,
  DownloadImages,
  ClearRegisteredStatusLog,
  ShowAlert,
  SaveUserDetails,
  SavePageNumber,
  SetSiteDetails,
  SetFurnitureDetails,
  StopLoader,
  StartLoader,
  ClearContractorStatus,
  GetContractorList,
  PersistSearchContractorInfo,
  SaveContractorPageNumber,
  PersistSearchSiteInfo,
  GetContractorListForHelpline,
})(SearchContractor);
