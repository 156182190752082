import {
  GET_REGISTERED_SITE,
  CLOSE_SITE,
  CLEAR_REGISTERED_SITE_LOG,
  RESET_STATE,
  GET_SITE_DETAILS,
  EDIT_REG_SITE,
  EDIT_PRODUCT_IMAGES,
  DELETE_DEALER_INVOICE,
  DELETE_PRODUCT_IMAGES,
  CLEAR_SEARCHED_RESULT,
  DOWNLOAD_IMAGES,
  GET_REGISTERED_SITE_FOR_HELPLINE,
  GET_LASTEST_REG_SITE,
  SET_SITE_DETAILS,
  GET_IMAGE_EXPIRY_LINK,
  UPDATE_SITE_REVIEWS,
  UPLOAD_WARRANTY_DOC,
  ADD_WARRANTY_CLAIM,
  GET_ALL_REGISTERED_SITES,
  UPDATE_EXPIRED_DOC_LINK,
  GET_REGISTERED_SITE_REPORT,
  DOWNLOAD_FOLDER,
  GET_MONTH_YEAR_WISE_SITES,
  DROPPED_SITE,
  //   UPDATE_VISITED_SITES,
} from "../Actions/Types";
const INIT_STATE = {
  getRegisteredSites: null,
  registeredSites: null,
  closeSiteStatus: null,
  siteDetails: null,
  getSiteDetails: null,
  editRegSiteStatus: null,

  deleteDealerInvoiceStatus: null,
  deleteProductImages: null,
  images: null,
  visitedSites: [],
  visitedSiteId: [],

  editProductImages: null,
  addedDealerIn: [],
  addedProdImages: [],

  updateSiteReviewStatus: null,
  uploadWarrantyDocStatus: null,
  addWarrantyClaimStatus: null,

  isLast: false,
  countOfData: 0,

  allRegisteredSites: [],
  getAllRegisteredSites: null,

  detailedSitesReport: [],
  getRegSiteReportStatus: null,

  downloadFolderStatus: null,
  folder: [],

  droppedSiteStatus: null,
};

const RegisteredSites = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_REGISTERED_SITE:
      return {
        ...state,
        getRegisteredSites: payload.success,
        registeredSites: payload.data,
        isLast: payload.isLast,
        countOfData: payload.count,
      };

    case GET_REGISTERED_SITE_FOR_HELPLINE:
      return {
        ...state,
        getRegisteredSites: payload.success,
        registeredSites: {
          ...payload.data,
          sitesList: payload.data.sites
            ? payload.data.sites
            : payload.data.sitesList,
        },
        isLast: payload.isLast,
        countOfData: payload.count,

        //site analytics
        openedSiteCount: payload.data.open,
        closedSiteCount: payload.data.closed,
        warrantyIssuedCount: payload.data.warrantyIssued,
        warrantyClaimedCount: payload.data.warrantyClaimed,
        droppedSites: payload.data.droppedSites,
      };

    case GET_MONTH_YEAR_WISE_SITES:
      return {
        getRegisteredSites: payload.success,
        registeredSites: { sitesList: payload.data },
        isLast: payload.isLast,
        countOfData: payload.count,
      };

    //UPDATE SITE STATUS IN SITE LIST AND SITE DETAILS OBJECT
    case CLOSE_SITE:
      return {
        ...state,
        siteDetails: { ...state.siteDetails, sitestatus: 1 },
        closeSiteStatus: payload.success,
        registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === state.siteDetails.siteid)
              return { ...val, sitestatus: 1 };
            return val;
          }),
        },
      };

    case GET_SITE_DETAILS:
      return {
        ...state,
        siteDetails: payload.data,
        getSiteDetails: payload.success,
        leadId: payload.data.leadid,
        // visitedSites: [...state.visitedSites, payload.data],
        // visitedSiteId: [...state.visitedSiteId, payload.data.siteid],
      };

    case SET_SITE_DETAILS:
      return {
        ...state,
        siteDetails: payload,
        leadId: payload.leadid,
      };

    // case UPDATE_VISITED_SITES:
    //   if (state.visitedSiteId.includes(payload.siteId)) {
    //     let index = state.visitedSiteId.indexOf(payload.siteId);
    //     state.visitedSites[index] = {
    //       ...state.siteDetails,
    //       furnitureList: payload.furnitureDetails,
    //     };
    //   }
    //   return state;

    case EDIT_REG_SITE:
      return {
        ...state,
        siteDetails: { ...state.siteDetails, ...payload.siteData },
        editRegSiteStatus: payload.server.success,
      };

    case EDIT_PRODUCT_IMAGES:
      return {
        ...state,
        siteDetails: {
          ...state.siteDetails,
          productImages: [
            ...state.siteDetails.productImages,
            ...payload.server.data.productImages,
          ],
          dealerInvoices: [
            ...state.siteDetails.dealerInvoices,
            ...payload.server.data.dealerinvoices,
          ],
        },
        addedProdImages: payload.server.data.productImages,
        addedDealerIn: payload.server.data.dealerinvoices,
        editProductImages: payload.server.success,
      };

    case DELETE_DEALER_INVOICE:
      let dealerInvoices = state.siteDetails.dealerInvoices.filter(
        (obj) => obj.dealerinvoiceid !== payload.deletedImage.dealerinvoiceid
      );
      return {
        ...state,
        siteDetails: {
          ...state.siteDetails,
          dealerInvoices: dealerInvoices,
        },
        deleteDealerInvoiceStatus: payload.server.success,
      };

    case DELETE_PRODUCT_IMAGES:
      let prodImg = state.siteDetails.productImages.filter(
        (obj) => obj.productimgid !== payload.deletedImage.productimgid
      );
      return {
        ...state,
        siteDetails: { ...state.siteDetails, productImages: prodImg },
        deleteProductImages: payload.server.success,
      };

    case GET_LASTEST_REG_SITE:
      return {
        ...state,
        openedSiteCount: payload.data.open,
        closedSiteCount: payload.data.closed,
        registeredSites: { sitesList: payload.data.sites },
        isLast: payload.isLast,
        countOfData: payload.count,
      };

    case GET_IMAGE_EXPIRY_LINK:
      if (payload.id === 2) {
        state.siteDetails.dealerInvoices.forEach((element) => {
          if (element.dealerinvoiceid === payload.object.dealerinvoiceid)
            element.expiryLink = payload.object.expiryLink;
        });
      } else if (payload.id === 3) {
        state.siteDetails.productImages.forEach((element) => {
          if (element.productimgid === payload.object.productimgid)
            element.expiryLink = payload.object.expiryLink;
        });
      }

      return {
        ...state,
        siteDetails: state.siteDetails,
      };

    case UPDATE_SITE_REVIEWS:
      return {
        ...state,
        updateSiteReviewStatus: payload.success,
        siteDetails: {
          ...state.siteDetails,
          reviewInfo: payload.data,
          sitestatus: payload.data.sitereviewed
            ? 2
            : state.siteDetails?.sitestatus,
          warrantyInfo:
            payload.data.sitereviewed === true
              ? {
                  warrantyid: payload.data.warrantyid,
                }
              : state.siteDetails.warrantyInfo,
        },
        registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === Number(payload.data.siteid))
              return {
                ...val,
                reviewInfo: payload.data,
                sitestatus: payload.data.sitereviewed ? 2 : val.sitestatus,
                warrantyInfo:
                  payload.data.sitereviewed === true
                    ? {
                        warrantyid: payload.data.warrantyid,
                      }
                    : val.warrantyInfo,
              };
            return val;
          }),
        },
      };

    //UPLOAD WARRANTY DOC AND SAVE INFO IN RESPECTIVE OBJECT
    case UPLOAD_WARRANTY_DOC:
      return {
        ...state,
        uploadWarrantyDocStatus: payload.success,
        siteDetails: {
          ...state.siteDetails,
          warrantyInfo: {
            ...state.siteDetails.warrantyInfo,
            warrantydoc: payload.data,
          },
          sitestatus: 2,
        },

        registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === state.siteDetails.siteid) {
              return {
                ...val,
                warrantyInfo: {
                  ...state.siteDetails.warrantyInfo,
                  warrantydoc: payload.data,
                },
                sitestatus: 2,
              };
            }
            return val;
          }),
        },
      };

    //UPDATE WARRANTY CLAIM STATUS INSIDE SITE DETAILS OBJECT AND SITE LIST
    case ADD_WARRANTY_CLAIM:
      return {
        ...state,
        addWarrantyClaimStatus: payload.server.success,
        siteDetails: {
          ...state.siteDetails,
          sitestatus: 3,
          warrantyInfo: {
            ...state.siteDetails.warrantyInfo,
            warrantyclaimdoc: payload.server.data,
            warrantyclaimreason:
              payload.warrantyClaimDetails.warrantyclaimreason,
          },
        },

        registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === state.siteDetails.siteid) {
              return {
                ...val,
                warrantyInfo: {
                  ...state.siteDetails.warrantyInfo,
                  warrantyclaimdoc: payload.server.data,
                  warrantyclaimreason:
                    payload.warrantyClaimDetails.warrantyclaimreason,
                },
                sitestatus: 3,
              };
            }
            return val;
          }),
        },
      };

    case GET_ALL_REGISTERED_SITES:
      return {
        ...state,
        allRegisteredSites: payload.data,
        getAllRegisteredSites: payload.success,
      };

    //UPDATE EXPIRED DOC LINK IN SITE LIST
    case UPDATE_EXPIRED_DOC_LINK:
      return {
        ...state,
        registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === payload.siteId) {
              return {
                ...val,
                warrantyInfo: {
                  ...val.warrantyInfo,
                  warrantydoc: payload.server.data,
                },
              };
            }
            return val;
          }),
        },
      };

    case GET_REGISTERED_SITE_REPORT:
      return {
        ...state,
        detailedSitesReport: payload.data,
        getRegSiteReportStatus: payload.success,
      };

    case DROPPED_SITE:
      return {
        ...state,
        droppedSiteStatus: payload.success,
        siteDetails: { ...state.siteDetails, sitestatus: 4 },
        /* registeredSites: {
          ...state.registeredSites,
          sitesList: state.registeredSites.sitesList.map((val) => {
            if (val.siteid === state.siteDetails.siteid)
              return { ...val, sitestatus: 4 };
            return val;
          }),
        }, */
      };

    case CLEAR_REGISTERED_SITE_LOG:
      return {
        ...state,
        closeSiteStatus: null,
        getRegisteredSites: null,
        getSiteDetails: null,
        editRegSiteStatus: null,
        deleteProductImages: null,
        deleteDealerInvoiceStatus: null,
        editProductImages: null,
        downloadStatus: null,
        updateSiteReviewStatus: null,
        uploadWarrantyDocStatus: null,
        addWarrantyClaimStatus: null,
        getAllRegisteredSites: null,
        getRegSiteReportStatus: null,
        downloadFolderStatus: null,
        droppedSiteStatus: null,
      };

    case CLEAR_SEARCHED_RESULT:
      return {
        ...state,
        registeredSites: null,
      };

    case DOWNLOAD_IMAGES:
      return {
        ...state,
        images: payload.data,
        downloadStatus: payload.success,
      };

    case DOWNLOAD_FOLDER:
      return {
        ...state,
        folder: payload.data,
        downloadFolderStatus: payload.success,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default RegisteredSites;
