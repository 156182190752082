//UPDATE VERSION
export const UPDATE_VERSION = "0.0.13";
//PAGE SIZE
export const PAGE_SIZE = 50;

//Login
export const LOGIN = "login";
export const LOGOUT = "LOGOUT";
export const CLEAR_LOGIN_STATUS = "clear-login-status";
export const SAVE_SIDEBAR_ID = "save-sidebar-id";

//SiteRegistration Related
export const REGISTER_SITE = "register-site";
export const EDIT_SITE = "edit-site";
export const CLEAR_SITE_LOG = "clear-site-log";
export const CLEAR_ADDED_SITE = "clear-added-site";
export const DELETE_IMAGE = "delete-image";
//Dealer code
export const GET_DEALER_INFO = "get-dealer-info";
export const CLEAR_DEALER_STATUS = "clear-delaer-status";

//FURNITURE Related
export const ADD_FURNITURE = "add-furniture";
export const EDIT_FURNITURE = "edit-furniture";
export const DELETE_FURNITURE = "delete-furniture";
export const DELETE_FURNITURE_IMAGE = "delete-furniture-image";
export const CLEAR_FURNITURE_LOG = "clear-furniture-log";
export const SAVE_USER_INFO = "save-user-info";
export const SET_FURNITURE_DETAILS = "set-furniture-details";
export const GET_EXPIRY_LINK_FURNITURE = "get-expiry-for-furniture";

//Product Images
export const ADD_PRODUCT_IMAGES = "add-product-images";
export const DELETE_PRODUCT_IMAGES = "delete-product-images";
export const EDIT_PRODUCT_IMAGES = "edit-product-images";
export const DELETE_DEALER_INVOICE = "delete-dealer-invoice";

//Common
export const RESET_STATE = "reset-store";
export const SET_ALERT = "Set-alert";
export const DELETE_ALERT = "delete-alert";
export const REMOVE_ALERT = "remove-alert";
export const START_LOADER = "start-loader";
export const STOP_LOADER = "stop-loader";
export const GET_IMAGE_UPLOAD_LINK = "get-image-upload-link";
export const UPDATE_EXPIRED_LINK = "update-expired-link";
export const SAVE_FILES = "save-files";
export const GET_USER_DETAILS = "get-user-details";
export const CHANGE_LANGUAGE = "change-language";
export const CLEAR_COMMON_STATUS = "clear-common-language";
export const SAVE_PAGE_NUMBER = "save-page-number";
export const SAVE_CONTRACTOR_PAGE_NUMBER = "save-contractor-page-number";
export const UPDATE_EXPIRED_DOC_LINK = "update-expired-doc-link";
export const SET_SEARCH_SITE_INFO = "set-search-site-info";
export const SET_SEARCH_CONTRACTOR_INFO = "set-search-contractor-info";
export const CLEAR_ALL_SEARCH_INFO = "clear-all-search-info";
export const SET_SITE_SORTING_COLUMN = "set-sorting-column";
export const SET_SEARCH_INFO = "set-search-info";
export const SAVE_PAGE_NUM = "save-page-num";
export const SET_PROGRESS_LOADER = "set-progress-loader";
export const SAVE_FILE_ERROR_LOG = "save-file-error-log";

//Registered Sites
export const GET_REGISTERED_SITE = "get-registered-site";
export const GET_REGISTERED_SITE_FOR_HELPLINE =
  "get-registered-site-for-helpline";
export const CLOSE_SITE = "close-site";
export const CLEAR_REGISTERED_SITE_LOG = "clear-registered-site-log";
export const GET_SITE_DETAILS = "get-site-details";
export const EDIT_REG_SITE = "edit-reg-site";
export const GET_LASTEST_REG_SITE = "get-lastest-reg-site";
export const SET_SITE_DETAILS = "set-site-details";
export const UPDATE_VISITED_SITES = "update-visited-sites";
export const GET_IMAGE_EXPIRY_LINK = "get-image-expiry-link";
export const GET_SITE_REVIEWS = "get-site-reviews";
export const UPDATE_SITE_REVIEWS = "update-site-reviews";
export const UPLOAD_WARRANTY_DOC = "upload-warranty-doc";
export const ADD_WARRANTY_CLAIM = "add-warranty-claim";
export const GET_DOC_EXPIRY_LINK = "get-doc-expiry-link";
export const GET_ALL_REGISTERED_SITES = "get-all-registered-sites";
export const GET_REGISTERED_SITE_REPORT = "get-registered-site-report";
export const DOWNLOAD_FOLDER = "download-folder";
export const DROPPED_SITE = "dropped-site";

//CONTACTOR
export const GET_CONTRACTOR_LIST = "get-contractor-list";
export const GET_CONTRACTOR_LIST_HELPLINE = "get-contractor-list-helpline";
export const GET_CONTRACTOR_DETAILS = "get-contractor-details";
export const CLEAR_CONTRACTOR_STATUS = "clear-contractor-status";
export const SAVE_DSR_DETAILS = "save-dsr-user-details";

//Helpline
export const CLEAR_SEARCHED_RESULT = "clear-searched-result";
export const DOWNLOAD_IMAGES = "download-images";

//DASHBOARD
export const GET_DASHBOARD_SUMMARY = "get-dashboard-summary";
export const DASHBOARD_MONTH_FILTER = "month-filter-dashboard";
export const DASHBOARD_YEAR_FILTER = "year-filter-dashboard";
export const CLEAR_DASHBOARD_STATUS = "clear-dashboard-status";
export const GET_SUMMARY_LIST = "get-summary-list";
export const GET_MONTH_YEAR_WISE_SITES = "get-month-year-wise-sites";

//USER TABLE RELATED
export const GET_SSO_LIST = "get-sso-list";
export const GET_WSO_LIST = "get-wso-list";
export const GET_DSR_LIST = "get-dsr-list";
export const CLEAR_USER_STATUS = "clear-user-status";
export const GET_EXPORT_DATA = "get-export-data";
//USER TYPES
export const DSR_USER_TYPE = "DSR";
export const CONTRACTOR_USER_TYPE = "AWA";
export const HELPLINE_USER = "HELPLINE";
export const NON_AWA_USER = "NON AWA";
export const WSO_USER = "WSO";
export const SSO_USER = "SSO";
export const AMFP_USER = "AFMP";

//SITE STATUS
export const SITE_STATUS = [
  "Open",
  "Under Review",
  "Warranty Issued",
  "Warranty Claimed",
  "Dropped site",
];

//LINK TYPES
export const NEW_REGISTRATION_LINK = "NEW_REGISTRATION";
export const REGISTERED_SITES_LINK = "REGISTERED_SITES";
export const DASHBOARD_LINK = "DASHBOARD";

//API ROUTES
export const HELPLINE_GET_REGISTERED_SITES =
  "/paint/w4s/helpline/get-registered";
export const NON_CONTRACTOR_GET_REGISTERED_SITES =
  "/paint/w4s/non-contractor/get-registered";

export const HELPLINE_GET_SITE_IMAGES = "/paint/w4s/helpline/get-site-images";
export const NON_CONTACTOR_GET_SITE_IMAGES =
  "/paint/w4s/non-contractor/get-site-images";

export const DASHBOARD_SIDEBAR_INDEX = 3;
export const NEW_REG_SIDEBAR_INDEX = 2;
export const REGISTERED_SIDEBAR_INDEX = 0;
export const CONTRACTOR_SIDEBAR_INDEX = 1;
export const SSO_SIDEBAR_INDEX = 4;
export const WSO_SIDEBAR_INDEX = 5;
export const DSR_SIDEBAR_INDEX = 6;

export const SSO_TABLE = "SSO-table";
export const WSO_TABLE = "WSO-table";
export const DSR_TABLE = "DSR-table";
export const CONTRACTOR_TABLE = "CONTRACTOR-table";
export const SITE_TABLE = "SITE-table";
