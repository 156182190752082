import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";

import { DeleteImage, GetLinkForUpload } from "../../Actions/AssetsAction";
import {
  SetProgressLoader,
  ShowAlert,
  StartLoader,
  StopLoader,
} from "../../Actions/Common";
import {
  AddWarrantyClaim,
  ClearRegisteredStatusLog,
  UploadWarrantyDoc,
} from "../../Actions/RegisteredSites";

import closeIcon from "../../Resources/close-icon.svg";
import delIcon from "../../Resources/del-icon.svg";
import { GenerateKeyForUpload } from "../_Utils/GenerateKeyForUpload";

class WarrantyClaimModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimDoc: {
        claimDocLink: "",
        claimDocFObject: null,
      },
      warrantyclaimreason: "",
      warrantyReasonError: false,
      claimDocError: false,

      //REPLACED FILES
      docToBeDeleted: [],
    };
  }

  handleDeleteFile = (e) => {
    e.stopPropagation();
    this.deleteWarrantyDoc();
  };

  //UPLOAD WARRANTY DOCUMENT
  handleClaimDocument = async (e) => {
    const claimDoc = e.target.files && e.target.files[0];
    if (!claimDoc) return;

    e.target.value = "";

    if (claimDoc) {
      //       this.props.StartLoader();
      this.props.SetProgressLoader(true, 0);

      const key = GenerateKeyForUpload(claimDoc.name);
      const url = await GetLinkForUpload({ key: key });
      if (!url) {
        this.props.ShowAlert({
          msg: "Failed to upload",
          alertType: "Error",
        });
        this.props.SetProgressLoader(false, null);
        return;
      }
      /*       fetch(url, { method: "PUT", body: claimDoc })
        .then((data) => {
          if (this.state.claimDoc && this.state.claimDoc.claimDocLink) {
            this.setState({
              docToBeDeleted: [
                ...this.state.docToBeDeleted,
                this.state.claimDoc.claimDocLink,
              ],
            });
          }

          this.setState({
            claimDoc: {
              claimDocLink: key,
              claimDocFObject: claimDoc,
            },
            claimDocError: false,
          });
          this.props.StopLoader();
        })
        .catch((err) => {
          this.props.ShowAlert({
            msg: "Failed to upload",
            alertType: "Error",
          });
          console.log(err);
          this.props.StopLoader();
        });
 */
      const config = {
        headers: {
          "content-type": claimDoc.type,
        },
        onUploadProgress: (progressEvent) => {
          this.props.SetProgressLoader(
            true,
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          );
        },
      };
      //AXIOS CODE
      axios
        .put(url, claimDoc, config)
        .then((res) => {
          if (this.state.claimDoc && this.state.claimDoc.claimDocLink) {
            this.setState({
              docToBeDeleted: [
                ...this.state.docToBeDeleted,
                this.state.claimDoc.claimDocLink,
              ],
            });
          }

          this.setState({
            claimDoc: {
              claimDocLink: key,
              claimDocFObject: claimDoc,
            },
            claimDocError: false,
          });
          this.props.SetProgressLoader(false, null);
        })
        .catch((err) => {
          this.props.ShowAlert({
            msg: "Failed to upload",
            alertType: "Error",
          });
          console.log(err);
          this.props.SetProgressLoader(false, null);
        });
    }

    e.target.value = "";
  };

  validateReason = () => {
    const { warrantyclaimreason } = this.state;
    if (warrantyclaimreason.trim()) {
      this.setState({ warrantyReasonError: false });
      return true;
    } else {
      this.setState({ warrantyReasonError: true });
      return false;
    }
  };

  validateDoc = () => {
    const { claimDoc } = this.state;
    if (claimDoc.claimDocLink) {
      this.setState({ claimDocError: false });
      return true;
    } else {
      this.setState({ claimDocError: true });
      return false;
    }
  };

  deleteWarrantyDoc = async () => {
    if (this.state.claimDoc.claimDocLink)
      await this.props.DeleteImage({
        key: [this.state.claimDoc.claimDocLink],
      });

    this.setState({
      claimDoc: {
        claimDocFObject: null,
        claimDocLink: "",
      },
    });
  };

  handleSubmit = async () => {
    const { siteInfo } = this.props;
    const { claimDoc, warrantyclaimreason } = this.state;
    const reason = this.validateReason();
    const doc = this.validateDoc();
    if (!reason || !doc) return;

    if (siteInfo && siteInfo.warrantyInfo) {
      const reqBody = {
        warrantyid: siteInfo.warrantyInfo.warrantyid,
        warrantyclaimdoc: claimDoc.claimDocLink,
        warrantyclaimreason,
      };

      await this.props.AddWarrantyClaim(reqBody);
      if (this.props.addWarrantyClaimStatus === 200) {
        this.props.ClearRegisteredStatusLog();
        this.props.ShowAlert({
          msg: "Warranty claim added",
          alertType: "Success",
        });

        this.props.closeModal();
      }
    }
  };

  //Show selected file
  previewFile = () => {
    const { claimDoc } = this.state;
    if (!claimDoc) {
      return;
    }
    if (claimDoc.claimDocFObject)
      window.open(URL.createObjectURL(claimDoc.claimDocFObject));
    else window.open(claimDoc.claimDocLink);
  };

  handleCloseModal = async () => {
    //DELETE CURRENT ONE
    await this.deleteWarrantyDoc();
    this.props.closeModal();
  };

  render() {
    const { claimDoc, warrantyReasonError, claimDocError } = this.state;
    return (
      <div className="warranty-claim-modal">
        <div className="heading">
          Warranty Claim
          <img src={closeIcon} alt="Close" onClick={this.handleCloseModal} />
        </div>
        {/* REASON */}
        <div className="reason">
          <div className="label compulsory">Reason</div>
          <textarea
            cols="5"
            rows="5"
            placeholder="Write reason"
            onChange={(e) => {
              this.setState(
                { warrantyclaimreason: e.target.value },
                this.validateReason
              );
            }}
            style={warrantyReasonError ? { border: "1px solid red" } : {}}
          ></textarea>
        </div>

        <div className="warranty-upload-label">Upload document</div>
        {/* WARRANTY DOC */}
        {claimDoc.claimDocLink && (
          <div className="warranty-uploaded-file" onClick={this.previewFile}>
            {claimDoc && claimDoc.claimDocFObject
              ? claimDoc.claimDocFObject.name
              : claimDoc.claimDocDocLink}
            <img
              src={delIcon}
              alt="Delete Icon"
              onClick={this.handleDeleteFile}
            />
          </div>
        )}

        {/* NOTE */}
        {claimDoc.claimDocLink && (
          <div className="note">
            Note: Uploaded warranty claim document will be shared with the
            contractor. Please confirm before uploading!
          </div>
        )}

        {!claimDoc.claimDocLink && (
          <div className="warranty-upload-file">
            <label
              htmlFor="warranty-doc"
              style={claimDocError ? { border: "1px solid red" } : {}}
            >
              Choose file
            </label>
            <input
              type="file"
              name=""
              id="warranty-doc"
              accept="application/pdf"
              onChange={this.handleClaimDocument}
            />
          </div>
        )}

        {/* SUBMIT */}
        <div className="modal-submit">
          <div className="modal-submit-button" onClick={this.handleSubmit}>
            Submit
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadWarrantyDocStatus: state.regSites.uploadWarrantyDocStatus,
    addWarrantyClaimStatus: state.regSites.addWarrantyClaimStatus,
  };
};

export default connect(mapStateToProps, {
  StartLoader,
  StopLoader,
  UploadWarrantyDoc,
  ClearRegisteredStatusLog,
  ShowAlert,
  AddWarrantyClaim,
  DeleteImage,
  SetProgressLoader,
})(WarrantyClaimModal);
