import {
  REGISTER_SITE,
  EDIT_SITE,
  ADD_PRODUCT_IMAGES,
  RESET_STATE,
  CLEAR_SITE_LOG,
  CLEAR_ADDED_SITE,
} from "../Actions/Types";

const INIT_STATE = {
  registerSite: null,
  registerSiteResponse: null,
  editSiteResponse: null,
  addFurnitureStatus: null,
  furnitureDetails: [],
  addProductImgStatus: null,

  siteData: null,
};

const SiteRegistration = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    /*    case SAVE_USER_INFO:
      return {
        ...state,
        username: payload.name,
        phoneNum: payload.phonenum,
        type: payload.type,
        language: payload.language,
      };
 */
    case REGISTER_SITE:
      return {
        ...state,
        siteData: { ...payload.customerData, ...payload.contractorDetails },
        leadId: payload.serverRes.data.leadid,
        registerSiteResponse: payload.serverRes.success,
        siteId: payload.serverRes.data.siteid,
      };

    case EDIT_SITE:
      return {
        ...state,
        siteData: payload.customerData,
        editSiteResponse: payload.serverRes.success,
      };

    case ADD_PRODUCT_IMAGES:
      return {
        ...state,
        addProductImgStatus: payload.success,
      };

    case CLEAR_ADDED_SITE:
      return {
        ...state,
        siteData: {},
        siteId: null,
        leadId: null,
      };

    case CLEAR_SITE_LOG:
      return {
        ...state,
        editSiteResponse: null,
        registerSiteResponse: null,
        addFurnitureStatus: null,
        addProductImgStatus: null,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default SiteRegistration;
