import React, { Component } from "react";
import { connect } from "react-redux";
import { DSR_USER_TYPE, PAGE_SIZE } from "../../Actions/Types";
import AdvanceSearch from "../AdvanceSearch/AdvanceSearch";
import CustomTable from "../CustomTable/CustomTable";
import {
  ClearUsersStatus,
  GetDSRList,
  PersistSearchInfo,
  GetDataForExport,
  SaveTablePageNumber,
} from "../../Actions/UsersAction";
import { ExportSheet } from "../Common/ExportSheet";
import { ExportJSONToSheet } from "../_Utils/ExportJSONToSheet";
import DataNotFound from "../Common/DataNotFound";
import { NumberConversion } from "../Common/NumberConversion";

class DSRTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //DSR TABLE HEADER
      dsrTableHeader: [
        {
          label: "DSR Name",
          sortBy: "name",
          customClass: "name",
          sortOrder: 1,
        },
        {
          label: "DSR Phone No.",
          sortBy: "phonenum",
          customClass: "num",
          sortOrder: 1,
        },
        {
          label: "No. of contractors",
          customClass: "id",
        },
        {
          label: "No. of sites registered by contractor",
          customClass: "id",
        },
        {
          label: "No. of warranty issued",
          customClass: "id",
        },
        {
          label: "No.of warranty claimed",
          customClass: "id",
        },
        {
          label: "No. of sites Pending",
          customClass: "id",
        },
      ],

      searchQuery: "",
      showNotFound: false,
      pageNum: props.DSRPageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
    };
  }

  componentDidMount() {
    const { searchDSRDetails } = this.props;
    if (searchDSRDetails) {
      this.setState({
        selectedSearchOption: searchDSRDetails.searchBy,
        searchQuery: searchDSRDetails.searchQuery,
      });
    }
  }

  //MANAGE SEARCH AND PAGE NUM IN REDUX
  SaveSearchDetails = () => {
    this.props.PersistSearchInfo("searchDSRDetails", {
      searchBy: this.state.selectedSearchOption,
      searchQuery: this.state.searchQuery,
    });
    this.props.SaveTablePageNumber("DSRPageNum", this.state.pageNum);
  };

  ClearSearchDetails = () => {
    this.props.PersistSearchInfo("searchDSRDetails", null);
    this.props.SaveTablePageNumber("DSRPageNum", 0);
  };

  /* ------------------------ GET DSR LIST FROM BACKEND AND PAGINATION ----------------------- */
  getDSRList = async (exportCount) => {
    const { WSODetails } = this.props;
    const {
      pageNum,
      pageSize,
      selectedSearchOption,
      searchQuery,
      sortingColumn,
    } = this.state;
    const reqBody = {
      requesterPhone: WSODetails
        ? WSODetails.phonenum
        : this.props.userPhoneNum,
      userType: DSR_USER_TYPE,
      searchKey: selectedSearchOption.value
        ? selectedSearchOption.value
        : undefined,
      searchValue: searchQuery.trim() || undefined,
      sortBy: sortingColumn.sortBy || undefined,
      sortOrder:
        sortingColumn.sortOrder === 2
          ? "DESC"
          : sortingColumn.sortOrder === 1
          ? "ASC"
          : undefined,
    };
    if (!exportCount)
      await this.props.GetDSRList(
        reqBody,
        pageNum,
        exportCount ? exportCount : pageSize
      );
    else await this.props.GetDataForExport(reqBody, 0, exportCount);
  };

  getDataForPageChange = async () => {
	await this.getDSRList();
	if (this.props.getDSRListStatus === 200) {
	  this.props.ClearUsersStatus();
	  this.props.SaveTablePageNumber("DSRPageNum", this.state.pageNum);
	}
  };

  handlePageChange = (e) => {
    if (e.selected === this.state.pageNum) return;
    this.setState(
      {
        pageNum: e.selected,
      },
      async () => {
		const { searchDSRDetails } = this.props;
        if (searchDSRDetails) {
          this.getDataForPageChange();
        } else if (
          !searchDSRDetails &&
          (this.state.selectedSearchOption.value || this.state.searchQuery)
        ) {
          this.setState(
            {
              selectedSearchOption: { value: null, label: "Search by" },
              searchQuery: "",
            },
            () => {
              this.getDataForPageChange();
            }
          );
        } else {
          this.getDataForPageChange();
        }
      }
    );
  };

  /* ------------------------ handle search sso details ----------------------- */
  //CALL SEARCH API
  handleSearch = async () => {
    this.setState({ pageNum: 0 }, async () => {
      await this.getDSRList();
      this.props.SaveTablePageNumber("SSOPageNum", 0);
      if (this.props.getDSRListStatus === 200) {
        this.SaveSearchDetails();
        this.props.ClearUsersStatus();
      }
    });
  };

  //HANDLE INPUT CHANGE
  handleInputChange = (e) => {
    if (!e.target.value.trim()) {
      this.setState({ showNotFound: false });
    }
    this.setState({ searchQuery: e.target.value });
  };

  //CLEAR INPUT FIELD
  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  //HANDLE CLEAR SEARCH
  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.ClearSearchDetails();
        this.getDSRList();
        this.setState({ pageNum: 0 });
      }
    );
  };

  //SEARCH BY OPTIONS SELECTION
  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  handleSorting = (col) => {
    this.setState(
      {
        sortingColumn: col,
        dsrTableHeader: this.state.dsrTableHeader.map((val) => {
          if (col.sortBy === val.sortBy)
            return { ...val, sortOrder: val.sortOrder === 1 ? 2 : 1 };
          else return { ...val, sortOrder: 1 };
        }),
      },
      this.getDSRList
    );
    if (this.props.getDSRListStatus === 200) {
      this.props.ClearUsersStatus();
    }
  };

  dsrTableData = () => {
    const { dsrList } = this.props;
    if (!dsrList || !dsrList.length) return;
    return dsrList.map((item) => {
      return {
        data: [
          { data: item.name, customClass: "name" },
          { data: item.phonenum, customClass: "num" },
          { data: item.regSumContrCount, customClass: "address" },
          { data: item.regSumRegSiteCount, customClass: "address" },
          { data: item.warrantyIssuedSites, customClass: "address" },
          { data: item.warrantyClaimedSites, customClass: "address" },
          { data: item.pendingSites, customClass: "address" },
        ],
        actualObject: item,
      };
    });
  };
  //	click handler of sso row
  DSRClickHandler = (item) => {
    this.props.onUserClick(item);
  };

  getDataForExport=async()=>{
	await this.getDSRList(this.props.dsrCount);
    if (this.props.getExportDataStatus === 200) {
      this.props.ClearUsersStatus();
      const { exportUserList } = this.props;
      let dataToExport = [];
      if (exportUserList) {
        for (let i = 0; i < exportUserList.length; i++) {
          const dsr = exportUserList[i];
          let dsrDetails = {};
          dsrDetails["DSR name"] = dsr.name;
          dsrDetails["DSR Phone Number"] = NumberConversion(dsr.phonenum);
          dsrDetails["No. of contractors"] = NumberConversion(
            dsr.regSumContrCount
          );
          dsrDetails["No. of sites registered by contractor "] =
            NumberConversion(dsr.regSumRegSiteCount);
          dsrDetails["No. of warranty issued"] = NumberConversion(
            dsr.warrantyIssuedSites
          );
          dsrDetails["No. of warranty claimed"] = NumberConversion(
            dsr.warrantyClaimedSites
          );
          dsrDetails["No. of sites pending"] = NumberConversion(
            dsr.pendingSites
          );

          dataToExport.push(dsrDetails);
        }
        ExportJSONToSheet(dataToExport, "DSR", 7);
      }
    }
  }

  handleExportDataButton = async () => {
	const { searchDSRDetails } = this.props;
    if (searchDSRDetails) {
      this.getDataForExport();
    } else if (
      !searchDSRDetails &&
      (this.state.selectedSearchOption.value || this.state.searchQuery)
    ) {
      this.setState(
        {
          selectedSearchOption: { value: null, label: "Search by" },
          searchQuery: "",
        },
        () => {
          this.getDataForExport();
        }
      );
    } else {
      this.getDataForExport();
    }
  };

  render() {
    const {
      /*  showSiteList,
      showSearchOptions, */
      selectedSearchOption,
      searchQuery,
      showSearchResults,
    } = this.state;
    return (
      <div className="search-sso">
        <div className="search-sso-header">Search DSR</div>
        <AdvanceSearch
          searchQuery={searchQuery}
          selectedSearchOption={selectedSearchOption}
          showSearchResults={showSearchResults}
          showClearButton={this.props.searchDSRDetails}
          searchOptionsFor="DSR"
          handleSearch={this.handleSearch}
          handleInputChange={this.handleInputChange}
          handleClearSearch={this.handleClearSearch}
          handleSearchByOptionSelection={this.handleSearchByOptionSelection}
          handleClearAllSearch={this.handleClearAllSearch}
        />

        {/* EXPORT DATA */}
       { this.props.dsrCount ?
	    <ExportSheet onClickHandler={this.handleExportDataButton} /> : null
		}

        {this.props.dsrList && this.props.dsrList.length ? (
          <CustomTable
            tableHeader={this.state.dsrTableHeader}
            tableBody={this.dsrTableData()}
            tableWrapperClass="user-table-wrapper"
            tableClass="search-sso-table"
            rowClickable={true}
            rowClickHandler={this.DSRClickHandler}
            pagination={true}
            handlePageChange={this.handlePageChange}
            pageNum={this.state.pageNum}
            pageSize={this.state.pageSize}
            countOfData={this.props.dsrCount}
            handleSort={this.handleSorting}
          />
        ) : (
          <DataNotFound msg={"Not found!"} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userPhoneNum: state.auth.userPhoneNum,
    searchDSRDetails: state.user.searchDSRDetails,
    dsrList: state.user.dsrList,
    getDSRListStatus: state.user.getDSRListStatus,
    dsrCount: state.user.dsrCount,
    DSRPageNum: state.user.DSRPageNum,

    getExportDataStatus: state.user.getExportDataStatus,
    exportUserList: state.user.exportUserList,
  };
};
export default connect(mapStateToProps, {
  PersistSearchInfo,
  SaveTablePageNumber,
  GetDSRList,
  ClearUsersStatus,
  GetDataForExport,
})(DSRTable);
