import React, { Component } from "react";

//RESOURCES
import contactIcon from "../../Resources/contact-icon.svg";

//COMPONENTS
import SiteList from "../RegisteredSites/SiteList";

import {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { connect } from "react-redux";
import {
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import {
  PersistSearchSiteInfo,
  SavePageNumber,
  SaveSortingInfo,
} from "../../Actions/Common";
import { SaveSidebarIndex } from "../../Actions/AuthAction";

class ContractorSiteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showNotFound: false,
      pageNum: props.pageNum,
      pageSize: PAGE_SIZE,
      showSearchOptions: false,
      selectedSearchOption: { value: null, label: "Search by" },
      showSearchResults: false,
      sortingColumn: { sortBy: undefined, sortOrder: undefined },
    };
  }
  componentDidMount() {
    //SET SORTING INFO TO NULL ON REFRESH
    this.props.SaveSortingInfo(null);
    const { searchSiteDetails } = this.props;
    if (searchSiteDetails) {
      this.setState({
        selectedSearchOption: searchSiteDetails.searchBy,
        searchQuery: searchSiteDetails.searchQuery,
        showSearchResults: true,
      });
    }
    //   this.getRegisteredSitesList()
  }

  //GET SITES ON PREV AND NEXT CLICK
  getRegisteredSitesList = async () => {
    const { userType, userPhoneNum, siteSortDetails, registeredSites } =
      this.props;
    const { pageSize, selectedSearchOption, searchQuery } = this.state;
    let reqBody = {
      phonenum: registeredSites.phonenum,
      searchValue: searchQuery.trim() || undefined,
      searchKey: selectedSearchOption.value || undefined,
      requesterPhone: CheckNonContractorUser(userType)
        ? userPhoneNum
        : undefined,
      sortBy: siteSortDetails ? siteSortDetails.sortBy : undefined,
      sortOrder: siteSortDetails
        ? siteSortDetails.sortOrder === 2
          ? "DESC"
          : "ASC"
        : undefined,
    };

    //IF USER TYPE IS HELPLINE
    if (userType === HELPLINE_USER) {
      //USER IS ON SEARCH CONTRACTOR PAGE THEN GET REGISTERED SITES WITH MOBILE NUMBER
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize
      );
    }
    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      /* const reqBody = {
			phonenum: userPhoneNum,
			searchBy: selectedSearchOption.value,
			searchQuery: searchQuery.trim(),
		  }; */
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }
  };

  //SEARCH BY HANDLING
  getSearchByOptions = () => {
    return [
      {
        label: "Status",
        value: "leadId",
        placeholder: "Enter site status",
        maxLength: "25",
        type: "status",
      },
      {
        label: "Lead ID",
        value: "leadId",
        placeholder: "Enter 6 digit lead id",
        maxLength: "6",
      },
      {
        label: "Date of Registration",
        value: "leadId",
        placeholder: "",
        type: "date",
      },
      {
        label: "Contractor BP No.",
        value: "leadId",
        placeholder: "Enter contractor BP number",
        maxLength: "10",
      },
      {
        label: "Contractor Name",
        value: "leadId",
        placeholder: "Enter contractor name",
        maxLength: "50",
      },
      {
        label: "Contractor number",
        value: "leadId",
        placeholder: "Enter contractor phone number",
        maxLength: "12",
      },
      {
        label: "Customer name",
        value: "leadId",
        placeholder: "Enter customer name",
        maxLength: "50",
      },
      {
        label: "Customer number",
        value: "leadId",
        placeholder: "Enter customer phone number",
        maxLength: "12",
      },
      {
        label: "Customer email",
        value: "leadId",
        placeholder: "Enter customer email",
      },
      {
        label: "Customer address",
        value: "leadId",
        placeholder: "Enter customer address",
      },
      {
        label: "Customer pincode",
        value: "leadId",
        placeholder: "Enter customer pincode",
        maxLength: "6",
      },
      {
        label: "Dealer code",
        value: "leadId",
        placeholder: "Enter 6 digit dealer code",
        maxLength: "6",
      },
      {
        label: "Dealer name",
        value: "leadId",
        placeholder: "Enter dealer name",
        maxLength: "50",
      },
      {
        label: "Dealer number",
        value: "leadId",
        placeholder: "Enter dealer phone number",
        maxLength: "12",
      },
      {
        label: "Expected site value",
        value: "leadId",
        placeholder: "Enter expected site value",
        maxLength: "10",
      },
      {
        label: "Warranty id",
        value: "leadId",
        placeholder: "Enter warranty ID",
      },
    ];
  };

  handleSearchByOptionSelection = (selectedSearchOption) => {
    this.setState({
      selectedSearchOption,
      showSearchOptions: false,
      searchQuery: "",
    });
  };

  handleClearSearch = () => {
    this.setState({
      searchQuery: "",
      showSearchOptions: false,
    });
  };

  handleClearAllSearch = () => {
    this.setState(
      {
        searchQuery: "",
        selectedSearchOption: { value: null, label: "Search by" },
        showSearchResults: false,
      },
      () => {
        this.getRegisteredSitesList();
        this.props.PersistSearchSiteInfo(null);
        this.props.SavePageNumber(0);
        this.setState({ pageNum: 0 });
      }
    );
  };

  handleSearchByBtn = (e) => {
    e.stopPropagation();
    this.setState({ showSearchOptions: !this.state.showSearchOptions });
  };

  handleSearch = async (e) => {
    this.setState({ pageNum: 0 }, async () => {
      await this.getRegisteredSitesList();
      if (this.props.getRegisteredSites === 200) {
        this.props.ClearRegisteredStatusLog();
        this.props.SavePageNumber(0);
        //IF SEARCH IS SUCCESSFUL THEM SET THE SEARCH DETAILS IN REDUX
        this.props.PersistSearchSiteInfo({
          searchBy: this.state.selectedSearchOption,
          searchQuery: this.state.searchQuery,
        });
        this.setState({ showSearchResults: true });
      }
    });
  };

  handleInputChange = (e) => {
    if (!e.target.value.trim()) {
      this.setState({ showNotFound: false });
      //   this.props.handleShowList(true);
    }
    this.setState(
      { searchQuery: e.target.value }
      /* 	() => {
      if (this.state.searchQuery.trim().length === 6) {
        this.getSiteDetails();
      }
    } */
    );
  };

  render() {
    const { registeredSites, showNewSiteReg = true } = this.props;

   /*  const { sitesList } = this.props.registeredSites
      ? this.props.registeredSites
      : []; */
/*     const {
      showSearchOptions,
      selectedSearchOption,
      searchQuery,
      showSearchResults,
    } = this.state; */
    return (
      <div className="search-contractor-result ">
        {registeredSites && registeredSites.phonenum ? (
          <div className="search-contractor-result-header search-contractor-result-helpline">
            <div className="search-contractor-result-header-info">
              <div className="search-contractor-result-header-info-name">
                {registeredSites.name}
              </div>
              <div className="search-contractor-result-header-info-contact">
                <img src={contactIcon} alt="Phone" />
                <span>{registeredSites.phonenum}</span>
              </div>
            </div>
          {showNewSiteReg &&  <div
              className="search-contractor-result-header-btn bk-blue"
              onClick={() => 
				this.props.userType === HELPLINE_USER ?
                this.props.handleNewSiteReg({
                  name: registeredSites.name,
                  contractorPhoneNum: registeredSites.phonenum,
                  registrationNum: registeredSites.registrationnum,
                }) : this.props.SaveSidebarIndex(2)
              }
            >
              New Registration
            </div>}
          </div>
        ) : null}
        {/* {sitesList && sitesList.length ? ( */}
          <SiteList
            handleContractorClick={this.props.handleSiteClick}
            helpLine={true}
            contractorDetails={this.props.searchQuery}
            handleDownloadFiles={this.props.handleDownloadFiles}
            searchContractor={true}
            customClass="search-contractor-result-wrapper"
			tabName="contractor-sites"
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getSiteInfo: state.regSites.getSiteDetails,
    siteInfo: state.regSites.siteDetails,
    closedSiteCount: state.regSites.closedSiteCount,
    openedSiteCount: state.regSites.openedSiteCount,
    allRegisteredSites: state.regSites.allRegisteredSites,
    getAllRegisteredSites: state.regSites.getAllRegisteredSites,
    getRegisteredSites: state.regSites.getRegisteredSites,
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
    pageNum: state.common.pageNum,
    searchSiteDetails: state.common.searchSiteDetails,
    siteSortDetails: state.common.siteSortDetails,

    registeredSites: state.regSites.registeredSites,
  };
};
export default connect(mapStateToProps, {
  GetDetailsOfSite,
  ClearRegisteredStatusLog,
  GetAllRegisteredSites,
  PersistSearchSiteInfo,
  GetRegisteredSitesForHelpLine,
  SaveSortingInfo,
  SavePageNumber,
  SaveSidebarIndex
})(ContractorSiteList);
