import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
// import { PAGE_SIZE } from "../../Actions/Types";
import { langData } from "../Common/Language";
//RESOURCES
import descending from "../../Resources/descending.svg";
import ascending from "../../Resources/ascending.svg";
import { PAGE_SIZE } from "../../Actions/Types";
import { Tooltip } from "react-tippy";

class CustomTable extends Component {
  render() {
    const {
      tableHeader,
      tableBody,
      tableWrapperClass,
      tableClass,
      countOfData,
      language,
      pageNum = 0,
      pageSize = PAGE_SIZE,
      pagination = false,
      sortable = true,
      paginationId = "",
      rowClickable = false,
      handlePageChange = () => null,
      rowClickHandler = () => null,
      handleSort = () => null,
    } = this.props;
    // console.log(tableHeader, tableBody);
    return (
      <React.Fragment>
        <div className={`generic-table-wrapper ${tableWrapperClass}`}>
          <div className={`generic-table font-poppins ${tableClass}`}>
            <div className="generic-table-header">
              {tableHeader.map((val, index) => {
                return (
                  <div
                    className={`generic-table-header-item ${val.customClass}`}
                    key={index}
                  >
                    {val.label}
                    {sortable && val.sortBy && (
                      <img
                        src={val.sortOrder === 1 ? ascending : descending}
                        alt="Sort"
                        className="sorting-icon"
                        onClick={() => handleSort(val)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="generic-table-body">
              {tableBody &&
                tableBody.map((row, index1) => {
                  if (!row) return null;
                  return (
                    <div
                      className={`generic-table-body-row ${
                        rowClickable && "clickable-row"
                      }`}
                      key={index1}
                      style={row.style ? row.style : {}}
                      onClick={() => rowClickHandler(row.actualObject)}
                    >
                      {row.data.map((item, index2) => {
                        if (!item) return null;
                        return (
                          <Tooltip
                            title={item.toolTip || ""}
                            disabled={!item.toolTip}
                            position="bottom"
                            delay={700}
                            duration={200}
                            distance={0}
                            arrow={true}
                            key={index2}
                          >
                            <div className={`body-item ${item.customClass}`}>
                              {item.data ? (
                                item.dataWithIcon ? (
                                  <React.Fragment>
                                    <img
                                      src={item.icon}
                                      alt="Download"
                                      onClick={item.iconClick}
                                      className="id-icon"
                                    />{" "}
                                    <span>{item.data || "--"}</span>
                                  </React.Fragment>
                                ) : (
                                  item.data || "--"
                                )
                              ) : item.icon ? (
                                <img
                                  src={item.icon}
                                  alt="icon"
                                  onClick={item.onClickHandler}
                                  className={item.iconClass}
                                />
                              ) : (
                                "--"
                              )}
                            </div>
                          </Tooltip>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        {/* PAGINATION WRAPPER */}
        {pagination && (
          <div className="pagination-wrapper" id={paginationId}>
            <ReactPaginate
              previousLabel={langData[language - 1]?.prev || "Previous"}
              nextLabel={langData[language - 1]?.next || "Next"}
              initialPage={pageNum}
              forcePage={pageNum}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={Math.ceil(countOfData / pageSize)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.auth.language,
  };
};

export default connect(mapStateToProps, {})(CustomTable);
