import React, { Component } from "react";
import { connect } from "react-redux";
import ReactModal from "react-modal";
//COMPONENTS
import Contractor from "../Contractor";
import SearchSitePage from "../SearchSitePage";
import Sidebar from "../Sidebar";
import SiteRegisteration from "../SiteRegisteration";
import { LOGIN } from "../_Utils/Routes";
import ConfirmationPopup from "../ConfirmationPopup";
import History from "../_Utils/History";
import Dashboard from "../Dashboard/Dashboard";

//ACTIONS
import { ClearAddedSite } from "../../Actions/SiteRegistration";
import { SetFurnitureDetails } from "../../Actions/FurnitureActions";
import { Logout, SaveSidebarIndex } from "../../Actions/AuthAction";
import {
  AMFP_USER,
  CONTRACTOR_USER_TYPE,
  DSR_SIDEBAR_INDEX,
  DSR_USER_TYPE,
  HELPLINE_USER,
  SSO_SIDEBAR_INDEX,
  SSO_USER,
  WSO_SIDEBAR_INDEX,
  WSO_USER,
} from "../../Actions/Types";
import {
  ClearAllSearchDetails,
  SaveContractorPageNumber,
  SavePageNumber,
} from "../../Actions/Common";

import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

//Resources
import sidebarIcon from "../../Resources/sidebar-icon.svg";
import closeIcon from "../../Resources/close-img.svg";
import logoutIcon from "../../Resources/logout.svg";
import w4sLogo from "../../Resources/w4s-logo.svg";
import DRSNewSiteRegistration from "../DSRNewSiteRegistration/DSRNewSiteRegistration";
import SearchSSO from "../SearchSSO/SearchSSO";
import SearchWSO from "../SearchWSO/SearchWSO";
import SearchDSR from "../SearchDSR/SearchDSR";
import {
  PersistSearchInfo,
  SaveTablePageNumber,
} from "../../Actions/UsersAction";
/**
 * Home: class component for helpline home
 */
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSidebar: 0,
      openSiteReg: false,
      newSiteReg: false,
      openConfirmation: false,
      contractorDetails: { name: "", contractorPhoneNum: "" },

      showSidebar: false,
    };
  }

  componentDidMount() {
    window.addEventListener("load", () => {
      this.ClearUserSearchSort();
    });
  }

  ClearUserSearchSort = () => {
  /*   this.props.PersistSearchInfo("searchSSODetails", null);
    this.props.SaveTablePageNumber("SSOPageNum", 0);
    this.props.PersistSearchInfo("searchWSODetails", null);
    this.props.SaveTablePageNumber("WSOPageNum", 0);
    this.props.PersistSearchInfo("searchDSRDetails", null);
    this.props.SaveTablePageNumber("DSRPageNum", 0); */
	// this.props.ClearAllSearchDetails()
  };

  /*   componentDidMount() {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };
    // localStorage.removeItem("store-state");
  } */

  handleLogout = async () => {
    this.props.Logout();
    localStorage.clear();
    History.push(LOGIN);
  };

  handleOpenConfirmation = () => {
    this.setState({ openConfirmation: !this.state.openConfirmation });
  };

  componentWillUnmount() {
    this.props.SetFurnitureDetails([]);
  }

  /**
   * handleSidebarClick: change active option
   */
  handleSidebarClick = (e) => {
    this.setState({ activeSidebar: Number(e.target.id), showSidebar: false });
    this.props.SaveSidebarIndex(Number(e.target.id));

    //CLEAR SAVED PAGE NUMBERS FOR THAT TAB
    this.props.SaveContractorPageNumber(0);
    this.props.SavePageNumber(0);
    this.props.ClearAllSearchDetails();
  };

  /**
   * handleNewSiteReg: toggle open site registration
   */
  handleNewSiteReg = (contractorDetails = {}) => {
    const { userType } = this.props;
    if (CheckNonContractorUser(userType)) {
      this.props.SaveSidebarIndex(2);
    } else if (userType === HELPLINE_USER)
      this.setState({ newSiteReg: !this.state.newSiteReg, contractorDetails });
  };

  handleCloseSiteReg = () => {
    this.props.ClearAddedSite();
    this.setState({ newSiteReg: false });
  };

  handleShowSidebar = () => {
    this.setState({
      showSidebar: !this.state.showSidebar,
    });
  };

  showPortalName = () => {
    const { userType } = this.props;
    return userType === DSR_USER_TYPE
      ? "DSR Portal"
      : userType === WSO_USER
      ? "WSO Portal"
      : userType === SSO_USER
      ? "SSO Portal"
      : userType === AMFP_USER
      ? "AFMP Portal"
      : "";
  };
  /**
   * render: render sidebar and main page
   */
  render() {
    const { userType } = this.props;
    const { showSidebar } = this.state;
    //IF USER IS NOT HELPLINE NOR DSR
    if (userType === CONTRACTOR_USER_TYPE) {
      return <div></div>;
    } else {
      return (
        <div className="helpline-page">
          <div className="helpline-page-header">
            <div className="logo">
              <img src={w4sLogo} alt="Wood4Sure" />
            </div>
            <div
              className={`title ${
                CheckNonContractorUser(userType) && "dsr-title"
              }`}
            >
              {CheckNonContractorUser(userType)
                ? this.showPortalName()
                : "Helpline Portal"}
            </div>
            {userType !== CONTRACTOR_USER_TYPE && (
              <div className="right-container">
                <div className="sidebar-icon">
                  <img
                    src={showSidebar ? closeIcon : sidebarIcon}
                    alt="Sidebar"
                    onClick={this.handleShowSidebar}
                  />
                </div>
                {userType === HELPLINE_USER && (
                  <div className="logout">
                    <img
                      src={logoutIcon}
                      alt="Logout"
                      onClick={this.handleOpenConfirmation}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="helpline-page-container">
            <Sidebar
              activeSidebar={this.props.sidebarId}
              handleSidebarClick={this.handleSidebarClick}
              showSidebar={this.state.showSidebar}
            />

            {this.props.sidebarId === 0 ? (
              <SearchSitePage />
            ) : this.props.sidebarId === 1 ? (
              this.state.newSiteReg ? (
                <SiteRegisteration
                  helpline={true}
                  handleNewSiteReg={this.handleNewSiteReg}
                  handleCloseSiteReg={this.handleCloseSiteReg}
                  contractorDetails={this.state.contractorDetails}
                />
              ) : (
                <Contractor
                  handleSidebarClick={this.handleSidebarClick}
                  handleNewSiteReg={this.handleNewSiteReg}
                />
              )
            ) : this.props.sidebarId === 2 ? (
              <DRSNewSiteRegistration
                handleNewSiteReg={this.handleNewSiteReg}
              />
            ) : this.props.sidebarId === 3 ? (
              <Dashboard />
            ) : this.props.sidebarId === SSO_SIDEBAR_INDEX ? (
              <SearchSSO />
            ) : this.props.sidebarId === WSO_SIDEBAR_INDEX ? (
              <SearchWSO />
            ) : this.props.sidebarId === DSR_SIDEBAR_INDEX ? (
              <SearchDSR />
            ) : null}
          </div>
          <ReactModal
            isOpen={this.state.openConfirmation}
            onRequestClose={this.handleOpenConfirmation}
            className="confirmation-popup-modal"
            overlayClassName="confirmation-popup-overlay"
            ariaHideApp={false}
          >
            <ConfirmationPopup
              handleSiteClosure={this.handleLogout}
              handleOpenConfirmation={this.handleOpenConfirmation}
              msg="Do you want logout?"
            />
          </ReactModal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    // user: state.auth.user,
    sidebarId: state.auth.sidebarId,
    userType: state.auth.userType,
  };
};

export default connect(mapStateToProps, {
  SetFurnitureDetails,
  SaveSidebarIndex,
  ClearAddedSite,
  Logout,
  SavePageNumber,
  SaveContractorPageNumber,
  ClearAllSearchDetails,
  PersistSearchInfo,
  SaveTablePageNumber,
})(Home);
