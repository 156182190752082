import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


export function ExportJSONToSheet(data, filename,colCount){
  const fileType =
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const ws = XLSX.utils.json_to_sheet(data);

  //SET WIDTH FOR COLUMNS
  var wscols = [...Array(colCount).keys()].map(() => ({ wch: 25 }));
  ws["!cols"] = wscols;

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const dataFromExcel = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataFromExcel, filename + fileExtension);

}