/*
 * Function: isNumeric
 * Params: string
 * Returns: boolean
 * Description: Check whether the given string is numeric or not
 */
export const isNumeric = (str) => {
  if (typeof str !== "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

/*
 * Function: validateName
 * Params: string
 * Returns: boolean
 * Description: Check whether the given string contains alphanumeric or not
 */
export const NameValidation = (name) => {
  var regex = /^[a-zA-Z ]*$/;
  return regex.test(name);
};

/*
 * Function: NumberValidation
 * Params: string
 * Returns: boolean
 * Description: Check whether the given string contains numeric or not
 */
export const NumberValidation = (num) => {
  var regex = /^[0-9]*.[0-9]*$/;
  return regex.test(num);
};
