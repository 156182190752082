import Server from "../API";
import {
  GET_DASHBOARD_SUMMARY,
  CLEAR_DASHBOARD_STATUS,
  DASHBOARD_MONTH_FILTER,
  GET_SUMMARY_LIST,
  DASHBOARD_YEAR_FILTER,
  PAGE_SIZE,
} from "./Types";
import { HandleExceptionWithSecureCatch } from "./CombinedCatch";
import { StartLoader, StopLoader } from "./Common";

/**
 * Get dashboard data
 * return: dispatch action to get dsr summary
 *
 */
const GetDashboardData = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  localStorage.clear();
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/non-contractor/dashboard/get-complete?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_DASHBOARD_SUMMARY,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Filter data month wise
 * param: params take login object
 * return: dispatch action to get contractor summary
 *
 */
const FilterMonthWise = (params) => {
  localStorage.clear();
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/non-contractor/dashboard/get-month",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DASHBOARD_MONTH_FILTER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Filter data year wise
 * param: params take login object
 * return: dispatch action to get contractor summary
 *
 */
const FilterYearWise = (params) => {
  localStorage.clear();
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        "/paint/w4s/non-contractor/dashboard/get-year",
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: DASHBOARD_YEAR_FILTER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Get summary table data
 * param: params take login object
 * return: dispatch action to get summary data
 *
 */
const GetSummaryData = (params, pageNum = 0, pageSize = PAGE_SIZE) => {
  localStorage.clear();
  return async (dispatch) => {
    try {
      dispatch(StartLoader());
      const response = await Server.post(
        `/paint/w4s/non-contractor/dashboard/get-summary-table?page=${pageNum}&size=${pageSize}`,
        params
      );
      dispatch(StopLoader());
      dispatch({
        type: GET_SUMMARY_LIST,
        payload: response.data,
      });
    } catch (e) {
      dispatch(StopLoader());
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };
};

/**
 * Call login API
 * param: params take login object
 * return: dispatch action to store userinfo
 *
 */
const ClearDashboardStatus = (params) => {
  return {
    type: CLEAR_DASHBOARD_STATUS,
  };
};

export {
  GetDashboardData,
  ClearDashboardStatus,
  FilterMonthWise,
  FilterYearWise,
  GetSummaryData,
};
