import React, { Component } from "react";
import { connect } from "react-redux";

//ACTION
import {
  LoginAction,
  ClearLoginStatus,
  Logout,
  SaveSidebarIndex,
} from "../../Actions/AuthAction";
import { HELPLINE_USER } from "../../Actions/Types";

//UTILITY
import History from "../_Utils/History";
import { HELPLINEHOME } from "../_Utils/Routes";
import HistoryObject from "../_Utils/History";
import w4sLogo from "../../Resources/w4s-logo.svg";

/**
 * Login: Class Component for login page
 */
class Login extends Component {
  state = {
    password: "",
    username: "",
    usernameError: false,
    passwordError: false,
  };

  componentDidMount() {
    if (this.props.username && this.props.userType === HELPLINE_USER)
		HistoryObject.push(HELPLINEHOME);
  }

  /**
   * validateUsername: validate user name
   */
  validateUsername = () => {
    if (!this.state.username.trim()) {
      this.setState({ usernameError: true });
      return false;
    }
    this.setState({ usernameError: false });
    return true;
  };

  /**
   * validatePassword: validate password
   */
  validatePassword = () => {
    if (!this.state.password.trim()) {
      this.setState({ passwordError: true });
      return false;
    }
    this.setState({ passwordError: false });
    return true;
  };

  /**
   * handleLogin: Call Action to login
   */
  handleLogin = async () => {
    let a = this.validateUsername();
    let b = this.validatePassword();
    if (a && b) {
      const reqBody = {
        username: this.state.username.trim(),
        password: this.state.password.trim(),
      };

      //Clear all Data before login
      this.props.Logout();
      await this.props.LoginAction(reqBody);
      if (this.props.loginStatus === 200) {
        History.push(HELPLINEHOME);
		this.props.SaveSidebarIndex(0)
        this.props.ClearLoginStatus();
      }
    }
  };

  handleSignInOnEnter = (e) => {
    if (e.keyCode === 13) {
      this.handleLogin();
    }
  };

  /**
   * render: return JSX for login page
   */
  render() {
    return (
      <div className="login-page">
        <div className="login-container">
          <div className="logo">
            <img src={w4sLogo} alt="" />
          </div>
          <div className="login-container-header font-blue company-name">
            Wood4Sure Helpline
          </div>
          {/* <div className="login-container-header font-blue">Login</div> */}
          <div className="login-container-username">
            <input
              type="text"
              placeholder="Username"
              value={this.state.username}
              onChange={(e) => this.setState({ username: e.target.value })}
              maxLength={100}
              style={
                this.state.usernameError ? { borderColor: "#f23b3bd4" } : {}
              }
              onKeyDown={this.handleSignInOnEnter}
            />
            {this.state.usernameError ? (
              <div className="input-error">Required</div>
            ) : null}
          </div>
          <div className="login-container-password">
            <input
              type="password"
              placeholder="Password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              maxLength={15}
              style={
                this.state.passwordError ? { borderColor: "#f23b3bd4" } : {}
              }
              onKeyDown={this.handleSignInOnEnter}
            />
            {this.state.passwordError ? (
              <div className="input-error">Required</div>
            ) : null}
          </div>
          <div
            className="login-container-btn bk-blue"
            onClick={this.handleLogin}
          >
            Login
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.auth.loginStatus,
    // user: state.auth.user,
    username: state.auth.username,
    userType: state.auth.userType,
  };
};
export default connect(mapStateToProps, {
  LoginAction,
  ClearLoginStatus,
  Logout,
  SaveSidebarIndex
})(Login);
