import React, { Component } from "react";
import { connect } from "react-redux";
import {
  GetDashboardData,
  FilterMonthWise,
  ClearDashboardStatus,
  FilterYearWise,
  GetSummaryData,
} from "../../Actions/DashboardAction";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Analytics from "./Analytics";
import CustomTable from "../CustomTable/CustomTable";
import {
  AMFP_USER,
  DSR_USER_TYPE,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
  SSO_USER,
  WSO_USER,
} from "../../Actions/Types";
import DashboardSites from "./DashboardSites";
import DashboardContractorList from "./DashboardContractorList";
import {
  ClearContractorStatus,
  GetContractorList,
  DashboardGetContractors,
} from "../../Actions/ContractorAction";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";
import {
  ClearRegisteredStatusLog,
  GetMonthYearWiseSites,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import {
  PersistSearchContractorInfo,
  PersistSearchSiteInfo,
  SaveContractorPageNumber,
  SavePageNumber,
} from "../../Actions/Common";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationSummary: [
        {
          count: 0,
          title: "Total no. of Contractors",
          onClickHandler: () =>
            this.handleAnalyticCardClick(2, "totalNoOfContractors"),
        },
        {
          count: 0,
          title: "Total no. of contractors with Wood4Sure sites",
          onClickHandler: () =>
            this.handleAnalyticCardClick(2, "totalNoOfContractorsWithSites"),
        },
        {
          count: 0,
          title: "No. of site registered",
          onClickHandler: () =>
            this.handleAnalyticCardClick(
              1,
              "noOfSitesRegistered",
              "No. of sites registered: "
            ),
        },
        {
          count: 0,
          title: "Total no. of sites with warranty issued",
          onClickHandler: () =>
            this.handleAnalyticCardClick(
              1,
              "warrantyIssuedSites",
              "No. of warranty issued sites"
            ),
        },
        {
          count: 0,
          title: "Total no. of sites which are Pending",
          onClickHandler: () =>
            this.handleAnalyticCardClick(1, "pendingSites", "Pending sites "),
        },
        {
          count: 0,
          title: "Total no. of sites which are warranty claimed",
          onClickHandler: () =>
            this.handleAnalyticCardClick(
              1,
              "warrantyClaimed",
              "Warranty Claimed "
            ),
        },
        {
          count: 0,
          title: "Total no. of sites which are dropped",
          onClickHandler: () =>
            this.handleAnalyticCardClick(1, "droppedSite", "Dropped Sites "),
        },
      ],

      DSRMonthSummary: [
        {
          count: 0,
          title: "No. of sites registered",
          onClickHandler: () =>
            this.handleAnalyticCardClick(
              1,
              "monthWiseSites",
              "No. of sites registered"
            ),
        },
        {
          count: 0,
          title: "Count of Contractor",
          onClickHandler: () =>
            this.handleAnalyticCardClick(2, "monthWiseContractors"),
        },
      ],
      DSRYearSummary: [
        {
          count: 0,
          title: "No. of sites registered",
          onClickHandler: () =>
            this.handleAnalyticCardClick(
              1,
              "yearWiseSites",
              "No. of sites registered"
            ),
        },
        {
          count: 0,
          title: "Count of Contractor",
          onClickHandler: () =>
            this.handleAnalyticCardClick(2, "yearWiseContractors"),
        },
      ],

      selectedDate: "",
      customRangeStart: "",
      customRangeEnd: "",
      monthToDate: "",
      yearToDate: "",
      showFilterOptions: false,
      selectedOption: null,
      filterLabel: "Select filter",
      selectedMonthFilter: `${new Date().getFullYear()}-${
        new Date().getMonth() >= 10
          ? new Date().getMonth() + 1
          : "0" + (new Date().getMonth() + 1)
      }`,
      selectedYearFilter: new Date(),

      pageNum: 0,
      pageSize: PAGE_SIZE,
      contractorPageNum: props.contractorPageNum,
      analyticsClicked: null,
      msg: "",
    };
  }

  componentDidMount() {
    this.getDashboardData();
    // const reqBody ={}
    // this.props.GetSummaryData( reqBody,
    // this.state.pageNum,
    // this.state.pageSize)
  }

  getDashboardData = async () => {
    const { userPhoneNum } = this.props;
    await this.props.GetDashboardData(
      { requesterPhone: userPhoneNum },
      this.state.pageNum,
      this.state.pageSize
    );
    if (this.props.getDashboardDataStatus === 200) {
      this.props.ClearDashboardStatus();
      //set data of year and month summary

      if (this.props.dashboardData) {
        this.setRegisteredSummary();
        this.setCountsForFilteredSummary();
      }
    }
  };

  //SET DATA OF FILTERED SUMMARY
  setCountsForFilteredSummary = () => {
    const { dashboardData } = this.props;
    const { DSRMonthSummary, DSRYearSummary } = this.state;
    this.setState({
      DSRMonthSummary: [
        { ...DSRMonthSummary[0], count: dashboardData.monthSiteCount },
        {
          ...DSRMonthSummary[1],
          count: dashboardData.monthContractorCount,
        },
      ],

      DSRYearSummary: [
        { ...DSRYearSummary[0], count: dashboardData.yearSiteCount },
        {
          ...DSRYearSummary[1],
          count: dashboardData.yearContractorCount,
        },
      ],
    });
  };

  //SET DATA FOR REGISTERED SUMMARY
  setRegisteredSummary = () => {
    const { dashboardData } = this.props;
    const { registrationSummary } = this.state;
    this.setState({
      registrationSummary: [
        {
          ...registrationSummary[0],
          count: dashboardData.regSumContrCount,
        },
        {
          ...registrationSummary[1],
          count: dashboardData.regSumContrW4sSite,
        },
        {
          ...registrationSummary[2],
          count: dashboardData.regSumRegSiteCount,
        },
        {
          ...registrationSummary[3],
          count: dashboardData.warrantyIssuedSites,
        },
        {
          ...registrationSummary[4],
          count: dashboardData.pendingSites,
        },
        {
          ...registrationSummary[5],
          count: dashboardData.warrantyClaimedSites,
        },
        {
          ...registrationSummary[6],
          count: dashboardData.droppedSites,
        },
      ],
    });
  };

  //HANDLE FILTER BUTTON
  handleFilterButton = (e) => {
    e.stopPropagation();
    this.setState({ showFilterOptions: !this.state.showFilterOptions });
  };

  handleFilterInputChange = (e, id) => {
    if (id === 1) {
      this.setState({ selectedMonthFilter: e.target.value }, () => {
        if (!this.state.selectedMonthFilter) return;
        this.getFilteredData(1);
      });
    } else if (id === 2)
      this.setState({ selectedYearFilter: e }, () => this.getFilteredData(2));
  };

  //GET FILTERED DATA
  getFilteredData = async (id) => {
    const { selectedMonthFilter, selectedYearFilter } = this.state;
    const { userPhoneNum } = this.props;
    //month wise filter
    if (id === 1) {
      await this.props.FilterMonthWise({
        requesterPhone: userPhoneNum,
        month: selectedMonthFilter,
      });
      if (this.props.getFilteredDataStatus === 200) {
        const { dashboardData } = this.props;
        this.props.ClearDashboardStatus();
        const { DSRMonthSummary } = this.state;
        console.log(dashboardData);
        this.setState({
          DSRMonthSummary: [
            {
              ...DSRMonthSummary[0],
              count: dashboardData.monthSiteCount,
            },
            {
              ...DSRMonthSummary[1],
              count: dashboardData.monthContractorCount,
            },
          ],
        });
      }
    }
    //year wise filter
    else if (id === 2) {
      await this.props.FilterYearWise({
        requesterPhone: userPhoneNum,
        year: new Date(selectedYearFilter).getFullYear(),
      });
      if (this.props.getFilteredDataStatus === 200) {
        this.props.ClearDashboardStatus();
        const { dashboardData } = this.props;
        const { DSRYearSummary } = this.state;
        this.setState({
          DSRYearSummary: [
            {
              ...DSRYearSummary[0],
              count: dashboardData.yearSiteCount,
            },
            { ...DSRYearSummary[1], count: dashboardData.yearContractorCount },
          ],
        });
      }
    }
  };

  //GET SITES
  getRegisteredSitesList = async (type) => {
    const { userType, userPhoneNum } = this.props;
    const { pageSize } = this.state;
    let reqBody = {
      requesterPhone: CheckNonContractorUser(userType)
        ? userPhoneNum
        : undefined,
    };
    if (type === "warrantyIssuedSites") {
      reqBody["searchKey"] = "sitestatus";
      reqBody["searchValue"] = 2;
    } else if (type === "pendingSites") {
      reqBody["searchKey"] = "sitestatus";
      reqBody["searchValue"] = 1;
    } else if (type === "warrantyClaimed") {
      reqBody["searchKey"] = "sitestatus";
      reqBody["searchValue"] = 3;
    } else if (type === "droppedSite") {
      reqBody["searchKey"] = "sitestatus";
      reqBody["searchValue"] = 4;
    }
    //NON-CONTRACTOR USER
    if (CheckNonContractorUser(userType)) {
      await this.props.GetRegisteredSitesForHelpLine(
        reqBody,
        this.state.pageNum,
        pageSize,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }
  };

  //HEADER FOR SUMMARY TABLE
  summaryHeader = [
    {
      label: "Roles",
      customClass: "role",
    },
    {
      label: "WSO/DSR Names",
      customClass: "name",
    },
    {
      label: "Total no. of contractors",
      customClass: "numeric",
    },
    {
      label: "No. of sites registered",
      customClass: "numeric",
    },
    {
      label: "Total no. of warranty issued",
      customClass: "numeric",
    },
    {
      label: "Total no. of pending sites",
      customClass: "numeric",
    },
    {
      label: "Total no. of warranty claimed",
      customClass: "numeric",
    },
    {
      label: "Total no. of dropped sites",
      customClass: "numeric",
    },
  ];

  //BODY OF SUMMARY TABLE
  summaryBody = () => {
    const { summaryList } = this.props;
    if (!summaryList) return;
    return summaryList.map((item) => {
      return {
        data: [
          { data: item.userType, customClass: "role" },
          { data: item.name, customClass: "name" },
          { data: item.regSumContrCount, customClass: "numeric" },
          { data: item.regSumRegSiteCount, customClass: "numeric" },
          { data: item.warrantyIssuedSites, customClass: "numeric" },
          { data: item.pendingSites, customClass: "numeric" },
          { data: item.warrantyClaimedSites, customClass: "numeric" },
          { data: item.droppedSites, customClass: "numeric" },
        ],
      };
    });
  };

  handlePageChange = (e) => {
    const { selected } = e;
    //Get summary list
    this.setState({ pageNum: selected }, async () => {
      const reqBody = {
        requesterPhone: this.props.userPhoneNum,
      };
      await this.props.GetSummaryData(
        reqBody,
        this.state.pageNum,
        this.state.pageSize
      );
      if (this.props.getSummaryDataStatus === 200) {
        this.props.ClearDashboardStatus();
      }
    });
  };

  analyticsGetSites = async (analyticsClicked, type, msg) => {
    await this.getRegisteredSitesList(type);
    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
      this.props.PersistSearchSiteInfo({
        searchBy: {
          label: "Status",
          maxLength: "25",
          placeholder: "Enter site status",
          type: "status",
          value: "sitestatus",
        },
        searchQuery: type === "warrantyIssuedSites" ? "2" : type === "warrantyClaimed" ? "3" : type === "droppedSite" ? "4" :"",
      });
      this.setState({ analyticsClicked, type, msg });
    }
  };

  //Analytics get contractors
  analyticsGetContractors = async (analyticsClicked, type, msg) => {
    let reqBody = {
      requesterPhone: this.props.userPhoneNum,
    };
    if (type === "monthWiseContractors")
      reqBody["month"] = this.state.selectedMonthFilter;
    else if (type === "yearWiseContractors")
      reqBody["year"] = this.state.selectedYearFilter.getFullYear();
    await this.props.DashboardGetContractors(reqBody, 0, this.state.pageSize);
    if (this.props.getContractorList === 200) {
      this.props.ClearContractorStatus();
      this.setState({ analyticsClicked, type, msg });
    }
  };

  handleAnalyticCardClick = async (analyticsClicked, type, msg) => {
    //CLEAR ALL SEARCH AND PAGE NUMBER
    this.props.PersistSearchContractorInfo(null);
    this.props.PersistSearchSiteInfo(null);
    this.props.SaveContractorPageNumber(0);
    this.props.SavePageNumber(0);

    //IF TOTAL NUMBER OF CONTRACTORS FOR REGISTERED SUMMARY
    if (type === "totalNoOfContractors") {
      this.props.GetContractorList(
        { requesterPhone: this.props.userPhoneNum },
        this.state.contractorPageNum,
        this.state.pageSize
      );
      this.setState({ analyticsClicked, type, msg });
    }
    //HANDLING FOR NO OF REGISTERED SITES
    else if (type === "noOfSitesRegistered") {
      this.getRegisteredSitesList();
      this.setState({ analyticsClicked, type, msg });
    }

    //HANDLING FOR WARRANTY ISSUED
    else if (type === "warrantyIssuedSites") {
      this.analyticsGetSites(analyticsClicked, type, msg);
    }

    //HANDLING FOR PENDING SITES
    else if (type === "pendingSites") {
      this.analyticsGetSites(analyticsClicked, type, msg);
    }

    //HANDLING FOR CLAIMED SITES
    else if (type === "warrantyClaimed")
      this.analyticsGetSites(analyticsClicked, type, msg);
    //HANDLING FOR PENDING SITES
    else if (type === "droppedSite")
      this.analyticsGetSites(analyticsClicked, type, msg);
	  
    //HANDLING FOR TOTAL NO OF CONTRACTORS WITH SITES
    else if (type === "totalNoOfContractorsWithSites") {
      this.analyticsGetContractors(analyticsClicked, type, msg);
    }

    //HANDLING FOR MONTH AND YEAR WISE SITES
    else if (type === "monthWiseSites" || type === "yearWiseSites") {
      let reqBody = {
        requesterPhone: this.props.userPhoneNum,
      };
      if (type === "monthWiseSites")
        reqBody["month"] = this.state.selectedMonthFilter;
      else if (type === "yearWiseSites")
        reqBody["year"] = this.state.selectedYearFilter.getFullYear();
      await this.props.GetMonthYearWiseSites(reqBody, 0, this.state.pageSize);
      this.setState({ analyticsClicked, type, msg });
    }

    //HANDLING FOR MONTH AND YEAR WISE CONTRACTORS
    else if (
      type === "monthWiseContractors" ||
      type === "yearWiseContractors"
    ) {
      this.analyticsGetContractors(analyticsClicked, type, msg);
    } else {
      this.setState({ analyticsClicked, type, msg });
    }
  };

  handleBackButton = () => {
    this.setState({ analyticsClicked: null });
  };

  render() {
    const {
      registrationSummary,
      DSRYearSummary,
      DSRMonthSummary,
      selectedYearFilter,
      selectedMonthFilter,
      analyticsClicked,
    } = this.state;
    const { userType, countOfData } = this.props;
    return (
      <div className="dashboard-main-container font-roboto">
        {!analyticsClicked ? (
          <div className="dashboard-main-container-wrapper">
            <div className="registration-summary">
              <div className="registration-summary-header">
                <div className="registration-summary-header-title">
                  {userType} summary
                </div>
              </div>

              {/* FILTER */}
              <div className="filter-wrapper">
                <div className="period-wise-summary">
                  {/* MONTH WISE SUMMARY */}
                  <div className="date-picker-wrapper">
                    <div className="title">Month</div>
                    <input
                      type="month"
                      value={selectedMonthFilter}
                      name="selectedMonthFiler"
                      onChange={(e) => this.handleFilterInputChange(e, 1)}
                    />
                  </div>
                  <Analytics data={DSRMonthSummary} />
                </div>

                {/* YEAR WISE SUMMARY */}
                <div className="period-wise-summary">
                  <div className="date-picker-wrapper">
                    <div className="title">Year</div>
                    <DatePicker
                      selected={selectedYearFilter}
                      name="selectedYearFilter"
                      onChange={(e) => this.handleFilterInputChange(e, 2)}
                      dateFormat="yyyy"
                      showYearPicker
                      showPopperArrow
                      placeholderText="Select year"
                    />
                  </div>
                  <Analytics data={DSRYearSummary} />
                </div>
              </div>
            </div>

            {/* REGISTRATION SUMMARY */}
            {(userType === WSO_USER || userType === DSR_USER_TYPE) && (
              <div className="registration-summary">
                <div className="registration-summary-header">
                  <div className="registration-summary-header-title">
                    Registration summary
                  </div>
                </div>
                <Analytics data={registrationSummary} />
              </div>
            )}
            {/* SUMMARY FOR SSO AND AFMP */}
            {(userType === SSO_USER || userType === AMFP_USER) && (
              <div className="summary-table">
                <div className="registration-summary-header">
                  <div className="registration-summary-header-title">
                    Summary
                  </div>
                </div>

                {/* TABLE */}
                <CustomTable
                  tableHeader={this.summaryHeader}
                  tableBody={this.summaryBody()}
                  countOfData={countOfData}
                  pageNum={this.state.pageNum}
                  pageSize={this.state.pageSize}
                  paginationId="summary-pagination"
                  pagination={true}
                  handlePageChange={this.handlePageChange}
                  tableWrapperClass="summary-table-wrapper"
                />
              </div>
            )}
          </div>
        ) : analyticsClicked === 1 ? (
          <DashboardSites
            handleBackButton={this.handleBackButton}
            msg={this.state.msg}
            sitesType={this.state.type}
            month={this.state.selectedMonthFilter}
            year={this.state.selectedYearFilter.getFullYear()}
          />
        ) : analyticsClicked === 2 ? (
          <DashboardContractorList
            handleBackButton={this.handleBackButton}
            month={this.state.selectedMonthFilter}
            year={this.state.selectedYearFilter.getFullYear()}
            dataFor={this.state.type}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dashboardData: state.dashboard.dashboardData,
    getDashboardDataStatus: state.dashboard.getDashboardDataStatus,
    userType: state.auth.userType,
    userPhoneNum: state.auth.userPhoneNum,
    getFilteredDataStatus: state.dashboard.getFilteredDataStatus,
    getRegisteredSites: state.regSites.getRegisteredSites,
    summaryList: state.dashboard.summaryList,
    countOfData: state.dashboard.countOfData,
    getSummaryDataStatus: state.dashboard.getSummaryDataStatus,

    getContractorList: state.contractor.getContractorList,
  };
};
export default connect(mapStateToProps, {
  GetDashboardData,
  FilterMonthWise,
  ClearDashboardStatus,
  FilterYearWise,
  GetSummaryData,
  GetContractorList,
  GetRegisteredSitesForHelpLine,
  ClearRegisteredStatusLog,
  PersistSearchSiteInfo,
  DashboardGetContractors,
  ClearContractorStatus,
  GetMonthYearWiseSites,
  SavePageNumber,
  SaveContractorPageNumber,
  PersistSearchContractorInfo,
})(Dashboard);

/* FILTER */

/* 	 <div className="filter-wrapper">
	 <div className="filter-wrapper-select-filter">
	   <div
		 className="filter-wrapper-select-filter-selected"
		 onClick={this.handleFilterButton}
	   >
		 <div className="label">{filterLabel}</div>
		 <img
		   src={dropdownArrow}
		   alt="Arrow"
		   style={
			 showFilterOptions ? { transform: "rotate(180deg)" } : {}
		   }
		 />
	   </div>
	   {showFilterOptions && (
		 <div className="filter-wrapper-select-filter-filter-options">
		   {this.filterArray.map((val, index) => {
			 return (
			   <div
				 className="filter-wrapper-select-filter-filter-options-item"
				 key={index}
				 onClick={(e) => {
				   e.stopPropagation();
				   this.handleFilterOption(val.id);
				 }}
				 style={
				   selectedOption === val.id
					 ? { color: "#6563ff" }
					 : {}
				 }
			   >
				 <div className="label">{val.label}</div>
				 {selectedOption === val.id ? (
				   selectedOption === "selectedDate" ? (
					 <div className="day">
					   <input
						 type="date"
						 onChange={(e) =>
						   this.handleParticularDateFilter(e, val.id)
						 }
					   />
					 </div>
				   ) : selectedOption === "customRange" ? (
					 <div className="range">
					   <input
						 type="date"
						 onChange={(e) =>
						   this.handleParticularDateFilter(
							 e,
							 val.id + "Start"
						   )
						 }
					   />{" "}
					   to
					   <input
						 type="date"
						 onChange={(e) =>
						   this.handleParticularDateFilter(
							 e,
							 val.id + "End"
						   )
						 }
					   />
					 </div>
				   ) : null
				 ) : null}
			   </div>
			 );
		   })}
		 </div>
	   )}
	 </div>

	 <div className="clear" onClick={this.clearFilter}>
	   Clear
	 </div>
   </div> */

//    NEW CHANGED

/*<div className="filter-wrapper">
              <div className="filter-wrapper-select-filter">
                <div
                  className="filter-wrapper-select-filter-selected"
                  onClick={this.handleFilterButton}
                >
                  <div className="label">{filterLabel}</div>
                  <img
                    src={dropdownArrow}
                    alt="Arrow"
                    style={
                      showFilterOptions ? { transform: "rotate(180deg)" } : {}
                    }
                  />
                </div>
                {showFilterOptions && (
                  <div className="filter-wrapper-select-filter-filter-options">
                    <div
                      className="filter-wrapper-select-filter-filter-options-item"
                      onClick={() => this.handleFilterOption(1)}
                    >
                      Month
                    </div>
                    <div
                      className="filter-wrapper-select-filter-filter-options-item"
                      onClick={() => this.handleFilterOption(2)}
                    >
                      Year
                    </div>
                  </div>
                )}
              </div>

              {selectedOption === 1 ? (
                <div className="date-picker-wrapper">
                  <input
                    type="month"
                    value={selectedMonthFilter}
                    name="selectedMonthFiler"
                    onChange={this.handleFilterInputChange}
                  />
                </div>
              ) : selectedOption === 2 ? (
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={selectedYearFilter}
                    name="selectedYearFilter"
                    onChange={this.handleFilterInputChange}
                    dateFormat="yyyy"
                    showYearPicker
                    placeholderText="year"
                  />
                </div>
              ) : null}

              {selectedOption && (
                <div className="clear" onClick={this.clearFilter}>
                  Clear
                </div>
              )}
            </div> */

//HANDLE FILTER OPTION
/*   handleFilterOption = (selectedOption) => {
    this.setState({ selectedOption }, () => {
      if (selectedOption === "monthToDate" || selectedOption === "yearToDate") {
        this.setState(
          {
            showFilterOptions: false,
          },
          this.setFilterLabel
        );
      }
    });
  };
 */
/*   handleParticularDateFilter = (e, id) => {
    this.setState(
      {
        [id]: e.target.value,
      },
      () => this.setFilterLabel()
    );
  }; */

/*  formatDate = (date) => {
    var dateObj = date;
    var month = dateObj.getUTCMonth() + 1;
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();

    return year + "-" + month + "-" + day;
  }; */

/*  setFilterLabel = () => {
    const { selectedOption, selectedDate, customRangeStart, customRangeEnd } =
      this.state;
    if (selectedOption === "selectedDate") {
      this.setState({
        filterLabel: selectedDate,
        showFilterOptions: false,
        selectedOption: null,
      });
    } else if (selectedOption === "customRange") {
      this.setState({
        filterLabel:
          customRangeStart +
          " to " +
          (customRangeEnd || this.formatDate(new Date())),
      });
      if (customRangeStart && customRangeEnd)
        this.setState({
          showFilterOptions: false,
          selectedOption: null,
        });
    } else if (selectedOption === "monthToDate") {
      this.setState({
        filterLabel:
          `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1` +
          " to " +
          this.formatDate(new Date()),
        showFilterOptions: false,
        selectedOption: null,
      });
    } else if (selectedOption === "yearToDate") {
      this.setState({
        filterLabel:
          `${new Date().getFullYear() + "-04-01"}` +
          " to " +
          this.formatDate(new Date()),
        showFilterOptions: false,
        selectedOption: null,
      });
    }
  }; */

/* filterArray = [
    { label: "Select date", id: "selectedDate" },
    { label: "Custom range", id: "customRange" },
    { label: "Month to date", id: "monthToDate" },
    { label: "Year to date", id: "yearToDate" },
  ]; */

/*   handleFilterOption = (e) => {
    this.setState({
      selectedOption: Number(e),
      filterLabel: Number(e) === 1 ? "Select Month" : "Select Year",
    });
  }; */

/*  clearFilter = (id) => {
    if (id === 1) {
      this.setState({
        selectedMonthFilter: "",
      });
    } else if (id === 2) {
      this.setState({
        selectedYearFilter: "",
      });
    }
    this.getDashboardData();
  };
 */
