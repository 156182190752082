import React from "react";
import { connect } from "react-redux";
import { DeleteAlert } from "../../Actions/Common";
import alertClose from "../../Resources/alert-close.svg";

//Class Component for the alerts
class Alerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeAlert: false,
    };
  }

  /*
   * Action Creator: renderAlert()
   * Params: void
   * Returns:JSX
   * Description: returns the JSX to show the alert
   */
  renderAlert = () => {
    //get data from props
    const { alert, DeleteAlert } = this.props;

    //if there is so error msg from server then return null
    if (alert.alertMsg === null) {
      return null;
    } else {
      return (
        // if alertType is Error then show the error otherwise
        <div
          className={
            alert.alertType === "Error"
              ? "alert-error-active"
              : alert.alertType === "Success"
              ? "alert-success-active"
              : alert.alertType === "Info"
              ? "alert-info-active"
              : "alert"
          }
        >
          {/* <span className="alert-left-border"></span> */}

          <span className="alert-icon"></span>
          <div className="alert-msg">
            <span className="alert-msg-content">{alert.alertMsg}</span>
          </div>

          <button className="alert-close" onClick={(e) => DeleteAlert(alert)}>
            <img src={alertClose} alt="close" />
          </button>
        </div>
      );
    }
  };
  /*
   * Action Creator: render()
   * Params: void
   * Returns:JSX or null
   * Description: returns the JSX to show the alert
   */
  render() {
    if (!this.props.alert) {
      return null;
    } else {
      return <div className="alertpage">{this.renderAlert()}</div>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    //alert to show
    alert: state.common,
  };
};

export default connect(mapStateToProps, { DeleteAlert })(Alerts);
