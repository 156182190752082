import {
  CHANGE_LANGUAGE,
  CLEAR_LOGIN_STATUS,
  GET_USER_DETAILS,
  CONTRACTOR_USER_TYPE,
  LOGIN,
  RESET_STATE,
  SAVE_SIDEBAR_ID,
  SAVE_USER_INFO,
} from "../Actions/Types";

const INIT_STATE = {
  username: null,
  user: null,
  sidebarId: null,
  userType: "",

  userPhoneNum: null,
  language: 1,

  getUserDetailsStatus: null,
};

const Auth = (state = INIT_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN:
      return {
        ...state,
        loginStatus: payload.server.success,
        username: payload.user.username,
        userType:"HELPLINE",
		userId: payload.server.hluserid
      };

    case GET_USER_DETAILS:
      return {
        ...state,
        username: payload.data.name,
        userType: payload.data.userType
          ? payload.data.userType.toUpperCase()
          : payload.data.userType,
        userPhoneNum: payload.data.phonenum,
        registrationNum: payload.data.registrationnum,
        getUserDetailsStatus: payload.success,
		userId: payload.data.userid
      };

    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: Number(payload),
      };

    case CLEAR_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: null,
        getUserDetailsStatus: null,
      };

    case SAVE_SIDEBAR_ID:
      return {
        ...state,
        sidebarId: payload,
      };

    //SAVE DSR DETAILS
    case SAVE_USER_INFO:
      return {
        ...state,
        username: payload.name,
        userType: payload.userType
          ? payload.userType.toUpperCase()
          : payload.userType,
        userPhoneNum: payload.phonenum,
        language:
          payload.userType === CONTRACTOR_USER_TYPE ? payload.language : 1,
        /*         username: "Swapnil",
        userType: "AWA",
        userPhoneNum: "919156193766", */
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
export default Auth;
