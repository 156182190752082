import React, { Component } from "react";
import { connect } from "react-redux";
import * as EmailValidator from "email-validator";
import { isNumeric, NameValidation } from "../_Utils/Validation";
import {
  EditRegSite,
  ClearRegisteredStatusLog,
  GetRegisteredSitesForHelpLine,
} from "../../Actions/RegisteredSites";
import { GetDealerInfo, ClearDealerInfo } from "../../Actions/DealerAction";
import { ShowAlert } from "../../Actions/Common";
import { langData } from "../Common/Language";
import {
  ClearContractorStatus,
  GetContractorDetails,
} from "../../Actions/ContractorAction";
import {
  CONTRACTOR_USER_TYPE,
  HELPLINE_USER,
  NON_CONTRACTOR_GET_REGISTERED_SITES,
  PAGE_SIZE,
} from "../../Actions/Types";
import { CheckNonContractorUser } from "../_Utils/CheckNonContractorUser";

/**
 * Step1: Class component for step1
 * Used to edit step1 of registered sites
 */
class Step1 extends Component {
  lan =
    localStorage.getItem("UserInfo") &&
    JSON.parse(localStorage.getItem("UserInfo")).language
      ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
      : 0;
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phoneNum: "",
      date: "",
      pincode: "",
      dealerCode: "",
      dealerName: "",
      dealerPhone: "",
      siteValue: "",
      address: "",

      //NEWLY ADDED
      contractorBPNo: "",
      contractorPhone: "",
      contractorName: "",
      //error indicators
      nameError: "",
      pincodeError: "",
      dealerCodeError: "",
      dealerNameError: "",
      siteValueError: "",
      emailError: "",
      phoneError: "",
      dealerPhoneError: "",

      contractorBPNoError: "",
      contractorPhoneError: "",
      contractorNameError: "",

      showSubmitButton: false,

      searchBPNo: "",
      selectedContractor: null,
      showContractorSuggestion: false,
      fillContractorDetails: false,
    };
  }

  componentDidMount() {
	//set all fields for edit info
    this.setAllFields();

    const { siteDetails, userType } = this.props;

    let arr = document.querySelectorAll("input[type=text]");
    //IF USER IS HELPLINE THEN FOR OPEN, DROPPED SITE AND UNDER REVIEW SITE , SITE IS EDITABLE
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 ||
        siteDetails.sitestatus === 1 ||
        siteDetails.sitestatus === 4)
    ) {
      arr.forEach((element) => {
        element.disabled = false;
      });
    }
    //FOR OTHER USERS, SITE IS EDITABLE ONLY FOR OPEN AND UNDER REVIEW STATUS
    else if (
      userType !== HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1)
    ) {
      arr.forEach((element) => {
        element.disabled = false;
      });
    } else
      arr.forEach((element) => {
        element.disabled = true;
      });
  }

  //   SET ALL FIELDS TO FILLED DATA
  setAllFields = () => {
    const { siteDetails } = this.props;
    this.setState({
      name: siteDetails.customername || "",
      email: siteDetails.customeremail || "",
      phoneNum: siteDetails.customerphone || "",
      pincode: siteDetails.pincode || "",
      dealerCode: siteDetails.dealercode || "",
      dealerName: siteDetails.dealername || "",
      dealerPhone: siteDetails.dealerphone || "",
      siteValue: siteDetails.sitevalue || "",
      address: siteDetails.customeraddress || "",
      fillContractorDetails: siteDetails.registrationnum ? true : false,

      contractorPhone: siteDetails.registrationnum ? siteDetails.phonenum : "",
      contractorBPNo: siteDetails.registrationnum || "",
      contractorName: siteDetails.name || "",
    });
  };

  handleDealerCode = (e) => {
    if (!e.target.value) {
      this.setState({ dealerCode: "", dealerPhone: "", dealerName: "" });
      return;
    }
    if (isNumeric(e.target.value) || e.target.value === "") {
      this.setState(
        { dealerCode: e.target.value, showSubmitButton: true },
        async () => {
          // if (this.validateDcode()) {
          if (this.state.dealerCode.trim().length === 6) {
            this.validateDcode();
            await this.props.GetDealerInfo({
              dealerpcc: this.state.dealerCode,
            });
            if (this.props.getDealerInfo === 200) {
              this.props.ClearDealerInfo();
              this.setState({
                dealerName: this.props.dealerInfo.dealername,
                dealerPhone: this.props.dealerInfo.dealerphone,
              });
            } else {
              this.props.ClearDealerInfo();
              this.setState({
                dealerCode: "",
                dealerPhone: "",
                dealerName: "",
              });
            }
          }
        }
      );
    }
  };

  validateName = () => {
    if (!this.state.name.trim()) {
      this.setState({ nameError: true });
      return false;
    } else if (!NameValidation(this.state.name)) {
      this.setState({ nameError: true });
      return false;
    }
    this.setState({ nameError: false });
    return true;
  };

  validatePhone = () => {
    let retValue1;
    if (this.state.phoneNum.trim().length < 10) {
      this.setState({ phoneError: true });
      retValue1 = false;
    } else {
      this.setState({ phoneError: false });
      retValue1 = true;
    }
    return retValue1;
  };
  validateEmail = () => {
    if (this.props.siteDetails.customeremail && !this.state.email.trim()) {
      this.setState({ emailError: true });
      return false;
    } else if (!this.props.siteDetails.customeremail) {
      this.setState({ emailError: false });
      return true;
    } else if (!EmailValidator.validate(this.state.email.trim())) {
      this.setState({ emailError: true });
      return false;
    } else {
      this.setState({ emailError: false });
      return true;
    }
  };

  validateDcode = () => {
    if (
      this.props.siteDetails.dealercode &&
      this.state.dealerCode.trim().length !== 6
    ) {
      this.setState({ dealerCodeError: true });
      return false;
    }
    this.setState({ dealerCodeError: false });
    return true;
  };

  validatePincode = () => {
    if (
      (this.state.pincode && this.state.pincode.trim().length < 6) ||
      (this.props.siteDetails.pincode && this.state.pincode.trim().length < 6)
    ) {
      this.setState({ pincodeError: true });
      return false;
    }
    this.setState({ pincodeError: false });
    return true;
  };

  validateDName = () => {
    if (this.props.siteDetails.dealername && !this.state.dealerName.trim()) {
      this.setState({ dealerNameError: true });
      return false;
    } else {
      this.setState({ dealerNameError: false });
      return true;
    }
  };

  validateSiteValue = () => {
    if (this.props.siteDetails.sitevalue && !this.state.siteValue.trim()) {
      this.setState({ siteValueError: true });
      return false;
    }
    this.setState({ siteValueError: false });
    return true;
  };

  validateDealerPhone = () => {
    let retValue2;
    if (
      this.props.siteDetails.dealerphone &&
      this.state.dealerPhone.trim().length < 10
    ) {
      this.setState({ dealerPhoneError: true });
      retValue2 = false;
    } else {
      this.setState({ dealerPhoneError: false });
      retValue2 = true;
    }
    return retValue2;
  };

  //VALIDATE ALL FIELDS
  validateData = () => {
    let pStatus = this.validatePhone();
    let nStatus = this.validateName();
    let eStatus = this.validateEmail();
    let dPStatus = this.validateDealerPhone();
    let pinStatus = this.validatePincode();
    let dC = this.validateDcode();
    let dN = this.validateDName();
    let sv = this.validateSiteValue();
    let bpStatus =
      this.props.userType !== CONTRACTOR_USER_TYPE
        ? this.validateContractorBPNo()
        : true;
    if (
      pStatus &&
      nStatus &&
      eStatus &&
      dPStatus &&
      pinStatus &&
      dC &&
      dN &&
      sv &&
      bpStatus
    ) {
      return true;
    } else return false;
  };

  getSiteList = async () => {
    const { userType, userPhoneNum, registeredSites } = this.props;

    //IF USER TYPE IS HELPLINE
    if (userType === HELPLINE_USER) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum },
        0,
        PAGE_SIZE
      );
    }

    //NON-CONTRACTOR USER
    else if (CheckNonContractorUser(userType)) {
      await this.props.GetRegisteredSitesForHelpLine(
        { phonenum: registeredSites.phonenum, requesterPhone: userPhoneNum },
        0,
        PAGE_SIZE,
        NON_CONTRACTOR_GET_REGISTERED_SITES
      );
    }

    if (this.props.getRegisteredSites === 200) {
      this.props.ClearRegisteredStatusLog();
    }
  };

  onSubmitClick = async () => {
    if (this.validateData()) {
      let reqBody = {
		hluserid: this.props.userType ===HELPLINE_USER ? this.props.userId : undefined,		//send this key if user is helpline
		requesterId: this.props.userType !==HELPLINE_USER ? this.props.userId : undefined,  //send this key if user is non-helpline
        siteid: this.props.siteDetails.siteid,
        phonenum: this.props.siteDetails.phonenum,
        customername: this.state.name.trim(),
        customeremail: this.state.email ? this.state.email.trim() : undefined,
        customerphone: this.state.phoneNum,
        customeraddress: this.state.address
          ? this.state.address.trim()
          : undefined,
        pincode: this.state.pincode ? this.state.pincode.trim() : undefined,
        dealercode: this.state.dealerCode
          ? this.state.dealerCode.trim()
          : undefined,
        dealername: this.state.dealerName
          ? this.state.dealerName.trim()
          : undefined,
        dealerphone: this.state.dealerPhone
          ? this.state.dealerPhone.trim()
          : undefined,
        sitevalue: this.state.siteValue
          ? this.state.siteValue.trim()
          : undefined,
      };
      await this.props.EditRegSite(reqBody);
      if (this.props.editRegSiteStatus === 200) {
        this.props.ClearRegisteredStatusLog();
        // this.props.handleCancelBtn();
        this.props.ShowAlert({
          msg: langData[this.lan].siteEdit,
          alertType: "Success",
        });
        // this.getSiteList();
      }
    }
  };

  //VALIDATE CONTRACTOR DETAILS
  validateContractorBPNo = () => {
    if (!this.state.contractorBPNo.trim()) {
      this.setState({
        contractorBPNoError: true,
      });
      return false;
    } else {
      this.setState({
        contractorBPNoError: false,
      });
      return true;
    }
  };

  handleResetButton = () => {
    this.setState(
      {
        name: "",
        email: "",
        phoneNum: "",
        date: "",
        pincode: "",
        dealerCode: "",
        dealerName: "",
        dealerPhone: "",
        siteValue: "",
        address: "",
        showSubmitButton: false,

        //NEWLY ADDED
        contractorBPNo: "",
        contractorPhone: "",
        contractorName: "",
      },
      () => this.setAllFields()
    );
  };

  getContractorsOnBPNo = async () => {
    const reqBody = {
      requesterPhone: this.props.userPhoneNum,
      searchString: this.state.searchBPNo,
    };
    await this.props.GetContractorDetails(reqBody);
    if (this.props.getContractorDetails === 200) {
      this.props.ClearContractorStatus();
      this.setState({ showContractorSuggestion: true });
    }
  };

  //HANDLE CONTRACTOR BP NO
  handleContractorBPNo = async (e) => {
    if (!e) return;
    this.setState(
      {
        searchBPNo: e.target.value,
        fillContractorDetails: false,
      },
      () => {
        if (this.state.searchBPNo.trim()) this.getContractorsOnBPNo();
        else this.setState({ showContractorSuggestion: false });
      }
    );
  };

  //SAVE SELECTED CONTRACTOR
  handleSelectContractor = (selectedContractor) => {
    this.setState({
      contractorBPNo: selectedContractor.registrationnum,
      contractorName: selectedContractor.name,
      contractorPhone: selectedContractor.phonenum,
      showContractorSuggestion: false,
      searchBPNo: "",
      fillContractorDetails: true,
      showSubmitButton: true,
    });
  };

  showEditButton = () => {
    const { siteDetails, userType } = this.props;
    if (!siteDetails) return false;
    if (
      userType === HELPLINE_USER &&
      (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1 || siteDetails.sitestatus === 4)
    ) {
      return true;
    } else if (userType !== HELPLINE_USER && (siteDetails.sitestatus === 0 || siteDetails.sitestatus === 1))
      return true;
    return false;
  };

  render() {
    //prettier-ignore
    const {
      name,email,pincode,phoneNum,address,dealerCode,dealerName,siteValue,dealerPhone,nameError,emailError,phoneError,pincodeError,dealerCodeError,siteValueError,showSubmitButton,showContractorSuggestion,contractorBPNo,contractorName,contractorPhone,fillContractorDetails,searchBPNo,
    } = this.state;

    const { userType, contractorSuggestions } = this.props;

    return (
      <div className="step1">
        <div className="step1-page" id="step1">
          {userType !== CONTRACTOR_USER_TYPE && (
            <React.Fragment>
              {/* CONTRACTOR BP NUMBER */}
              <div className="input-container ">
                <div className="input-container-label compulsory">
                  {langData[this.lan].contractorBPNo}
                </div>
                <div className="step1-edit-contractor-bp">
                  <div className="input-container-input">
                    <input
                      placeholder={langData[this.lan].contractorBPNoPh}
                      onChange={this.handleContractorBPNo}
                      maxLength={200}
                      value={
                        !fillContractorDetails ? searchBPNo : contractorBPNo
                      }
                      style={
                        this.state.contractorBPNoError
                          ? { borderColor: "#ff0000b5" }
                          : {}
                      }
                      disabled={true}
                    />
                {this.state.contractorBPNoError ? (
                      <div className="input-error">
                        {langData[this.lan].contractorBPNoError}
                      </div>
                    ) : null} 
                  </div>

                  {/* CONTRACTOR SUGGESTIONS */}
                  {showContractorSuggestion && (
                    <div className="contractor-suggestions">
                      {contractorSuggestions && contractorSuggestions.length ? (
                        contractorSuggestions.map((val) => (
                          <div
                            className="contractor-suggestions-item"
                            onClick={() => this.handleSelectContractor(val)}
                          >
                            {val.name}({val.registrationnum})
                          </div>
                        ))
                      ) : (
                        <div className="contractor-suggestions-item">
                          No contractor found
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* CONTRACTOR PHONE NUMBER */}
              <div className="input-container">
                <div className="input-container-label compulsory">
                  {langData[this.lan].contractorNumber}
                </div>
                <div className="input-container-input">
                  <input
                    value={(fillContractorDetails && contractorPhone) || ""}
                    disabled={true}
                  />
                </div>
              </div>

              {/* CONTRACTOR NAME */}
              <div className="input-container">
                <div className="input-container-label compulsory">
                  {langData[this.lan].contractorName}
                </div>
                <div className="input-container-input">
                  <input
                    // type="text"
                    value={(fillContractorDetails && contractorName) || ""}
                    disabled={true}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {/* CUSTOMER NAME */}
          <div className="input-container">
            <div className="input-container-label compulsory">
              {langData[this.lan].name}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                disabled={false}
                onChange={(e) =>
                  this.setState(
                    { name: e.target.value, showSubmitButton: true },
                    this.validateName
                  )
                }
                maxLength={50}
                value={name}
                style={nameError ? { borderColor: "#ff0000b5" } : {}}
              />
              {nameError ? (
                <div className="input-error">
                  {langData[this.lan].nameError}
                </div>
              ) : null}
            </div>
          </div>

          {/* CUSTOMER PHONE NUM */}
          <div className="input-container">
            <div className="input-container-label compulsory">
              {langData[this.lan].phone}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={phoneNum}
                onChange={(e) => {
                  if (isNumeric(e.target.value) || e.target.value === "")
                    this.setState(
                      { phoneNum: e.target.value, showSubmitButton: true },
                      this.validatePhone
                    );
                }}
                style={phoneError ? { borderColor: "red" } : {}}
                maxLength={10}
              />
              {phoneError ? (
                <div className="input-error">
                  {langData[this.lan].phoneError}
                </div>
              ) : null}
            </div>
          </div>

          {/* EMAIL ID */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].email}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={email}
                onChange={(e) =>
                  this.setState(
                    { email: e.target.value, showSubmitButton: true },
                    this.validateEmail
                  )
                }
                maxLength={100}
                style={emailError ? { borderColor: "red" } : {}}
              />
              {emailError ? (
                <div className="input-error">
                  {langData[this.lan].emailError}
                </div>
              ) : null}
            </div>
          </div>

          {/* CUSTOMER ADDRESS */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].address}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={address}
                onChange={(e) => {
                  this.setState({
                    address: e.target.value,
                    showSubmitButton: true,
                  });
                }}
                maxLength={100}
              />
            </div>
          </div>

          {/* PINCODE */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].pincode}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={pincode}
                onChange={(e) => {
                  if (isNumeric(e.target.value) || e.target.value === "")
                    this.setState(
                      { pincode: e.target.value, showSubmitButton: true },
                      this.validatePincode
                    );
                }}
                maxLength={6}
                style={pincodeError ? { borderColor: "#ff0000b5" } : {}}
              />
              {pincodeError ? (
                <div className="input-error">{langData[this.lan].pinError}</div>
              ) : null}
            </div>
          </div>

          {/* DEALER CODE */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].dCode}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={dealerCode}
                onChange={this.handleDealerCode}
                maxLength={6}
                style={dealerCodeError ? { borderColor: "#ff0000b5" } : {}}
              />
              {dealerCodeError ? (
                <div className="input-error">
                  {langData[this.lan].dCodeError}
                </div>
              ) : null}
            </div>
          </div>

          {/* DEALER NAME */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].dName}
            </div>
            <div className="input-container-input">
              <input value={dealerName} maxLength={200} disabled={true} />
            </div>
          </div>

          {/*DEALER PHONE NUMBER */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].dPhone}
            </div>
            <div className="input-container-input">
              <input value={dealerPhone} maxLength={10} disabled={true} />
            </div>
          </div>

          {/* EXPECTED SITE VALUE */}
          <div className="input-container">
            <div className="input-container-label">
              {langData[this.lan].siteValue}
            </div>
            <div className="input-container-input">
              <input
                type="text"
                value={siteValue}
                onChange={(e) =>
                  this.setState(
                    { siteValue: e.target.value, showSubmitButton: true },
                    this.validateSiteValue
                  )
                }
                maxLength={40}
                style={siteValueError ? { borderColor: "#ff0000b5" } : {}}
              />
              {siteValueError ? (
                <div className="input-error">
                  {langData[this.lan].siteError}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {this.showEditButton() && showSubmitButton ? (
          <div className="step-save-div">
            <div
              className="step-save-div-btn bk-blue"
              onClick={() => this.onSubmitClick()}
            >
              {langData[this.lan].submit}
            </div>
            <div
              className="step-save-div-btn bk-white reset-button"
              onClick={this.handleResetButton}
            >
              {langData[this.lan].reset || langData[0].reset}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // phoneNum: state.regSites.phoneNum,
    siteDetails: state.regSites.siteDetails,
    editRegSiteStatus: state.regSites.editRegSiteStatus,
    dealerInfo: state.dealer.dealerInfo,
    getDealerInfo: state.dealer.getDealerInfo,

    userPhoneNum: state.auth.userPhoneNum,
    userType: state.auth.userType,
    userId: state.auth.userId,

    contractorSuggestions: state.contractor.contractorSuggestions,
    getContractorDetails: state.contractor.getContractorDetails,

    registeredSites: state.regSites.registeredSites,
    sidebarId: state.auth.sidebarId,
  };
};
export default connect(mapStateToProps, {
  EditRegSite,
  ClearRegisteredStatusLog,
  ShowAlert,
  GetDealerInfo,
  ClearDealerInfo,
  GetContractorDetails,
  ClearContractorStatus,
  GetRegisteredSitesForHelpLine,
})(Step1);
