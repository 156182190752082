import React, { Component } from "react";
import search from "../../Resources/search.svg";
import closeIcon from "../../Resources/close-img.svg";
import { langData } from "../Common/Language";
import { connect } from "react-redux";
import { CONTRACTOR_USER_TYPE } from "../../Actions/Types";
import arrow from "../../Resources/next-arrow.svg";

class AdvanceSearch extends Component {
  searchByRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      showSearchOptions: false,
      inputFieldError: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //Outside click handler for report dropdown and action dropdown
  handleClickOutside = (event) => {
    if (
      this.searchByRef.current &&
      !this.searchByRef.current.contains(event.target)
    ) {
      this.setState({
        showSearchOptions: false,
      });
    }
  };

  handleSearchByBtn = (e) => {
    e.stopPropagation();
    this.setState({ showSearchOptions: !this.state.showSearchOptions });
  };

  //HANDLE SEARCH INPUT CHANGE
  handleInputChange = (e) => {
    this.props.handleInputChange(e);
  };

  handleClearSearch = () => {
    this.setState(
      {
        searchQuery: "",
        showSearchOptions: false,
      },
      this.props.handleClearSearch
    );
  };

  //SEARCH BY HANDLING
  getSearchByOptions = () => {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;
    const { userType } = this.props;

    const contractorSearchOptions = [
      {
        label: "Contractor name",
        value: "name",
        placeholder: "Enter contractor name",
        maxLength: "50",
      },
      {
        label: "Contractor phone number",
        value: "phonenum",
        placeholder: "Enter contractor phone number",
        maxLength: "10",
      },
      {
        label: "BP number",
        value: "registrationnum",
        placeholder: "Enter contractor BP number ",
        maxLength: "8",
      },
    ];

    const siteSearchOptions = [
      {
        label: langData[lan].status || langData[0].status,
        value: "sitestatus",
        placeholder: "Enter site status",
        maxLength: "25",
        type: "status",
      },
      {
        label: langData[lan].leadId || langData[0].leadId,
        value: "leadid",
        placeholder: langData[lan].advanceSearchOptions.leadId,
        maxLength: "6",
      },
      {
        label: langData[lan].date || langData[0].date,
        value: "createdat",
        placeholder: "",
        type: "date",
      },
      userType !== CONTRACTOR_USER_TYPE && {
        label: "Contractor BP No.",
        value: "registrationnum",
        placeholder: "Enter contractor BP number",
        maxLength: "10",
      },
      userType !== CONTRACTOR_USER_TYPE && {
        label: "Contractor Name",
        value: "name",
        placeholder: "Enter contractor name",
        maxLength: "50",
      },
      userType !== CONTRACTOR_USER_TYPE && {
        label: "Contractor number",
        value: "phonenum",
        placeholder: "Enter contractor phone number",
        maxLength: "12",
      },
      {
        label: langData[lan].custName || langData[0].custName,
        value: "customername",
        placeholder: langData[lan].namePh || langData[0].namePh,
        maxLength: "50",
      },
      {
        label: langData[lan].contactNo || langData[0].contactNo,
        value: "customerphone",
        placeholder: langData[lan].contactPh || langData[0].contactPh,
        maxLength: "12",
      },
      {
        label: langData[lan].email || langData[0].email,
        value: "customeremail",
        placeholder: langData[lan].emailPh || langData[0].emailPh,
      },
      {
        label: langData[lan].address || langData[0].address,
        value: "customeraddress",
        placeholder: langData[lan].address || langData[0].address,
      },
      {
        label: langData[lan].pinCode || langData[0].pinCode,
        value: "pincode",
        placeholder: langData[lan].pinPh || langData[0].pinPh,
        maxLength: "6",
      },
      {
        label: langData[lan].dCode || langData[0].dCode,
        value: "dealercode",
        placeholder: langData[lan].dealerCodePh || langData[0].dealerCodePh,
        maxLength: "6",
      },
      {
        label: langData[lan].dName || langData[0].dName,
        value: "dealername",
        placeholder:langData[lan].advanceSearchOptions.dealerName,
        maxLength: "50",
      },
      {
        label: langData[lan].dPhone || langData[0].dPhone,
        value: "dealerphone",
        placeholder: langData[lan].advanceSearchOptions.dealerPhone,
        maxLength: "12",
      },
      {
        label: langData[lan].warrantyId || langData[0].warrantyId,
        value: "warrantyid",
        placeholder: langData[lan].advanceSearchOptions.warrantyId,
      },
    ];

    const wsoSearchOptions = [
      {
        label: "WSO Emp ID",
        value: "wsoempid",
        placeholder: "Enter WSO Employee ID",
        maxLength: "50",
      },
      {
        label: "WSO Name",
        value: "name",
        placeholder: "Enter WSO Name",
        maxLength: "50",
      },
      {
        label: "WSO Phone Number",
        value: "phonenum",
        placeholder: "Enter WSO Phone number",
        maxLength: "12",
      },
    ];
    const ssoSearchOptions = [
      {
        label: "SSO Name",
        value: "name",
        placeholder: "Enter SSO Name",
        maxLength: "50",
      },
      {
        label: "SSO Phone Number",
        value: "phonenum",
        placeholder: "Enter SSO Phone number",
        maxLength: "12",
      },
    ];
    const dsrSearchOptions = [
      {
        label: "DSR Name",
        value: "name",
        placeholder: "Enter DSR Name",
        maxLength: "50",
      },
      {
        label: "DSR Phone Number",
        value: "phonenum",
        placeholder: "Enter DSR Phone number",
        maxLength: "12",
      },
    ];

    return this.props.searchOptionsFor === "contractor"
      ? contractorSearchOptions
      : this.props.searchOptionsFor === "WSO"
      ? wsoSearchOptions
      : this.props.searchOptionsFor === "SSO"
      ? ssoSearchOptions
      : this.props.searchOptionsFor === "DSR"
      ? dsrSearchOptions
      : siteSearchOptions;
  };

  getStatusOptions = () => {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    return [
      { value: "", label: langData[lan].status },
      { value: 0, label: langData[lan].nonClosed },
      { value: 1, label: langData[lan].underReview },
      { value: 2, label: langData[lan].warrantyIssue },
      { value: 3, label: langData[lan].warrantyClaim },
      { value: 4, label: langData[lan].droppedSite ||langData[0].droppedSite },
    ];
    /* this.props.userType === HELPLINE_USER && {
        value: -1,
        label: "Initiated for warranty",
      }, */
  };

  handleSearchOnEnter = (e) => {
    if (e.keyCode === 13) {
      this.handleSearchButton();
    }
  };
  
  handleSearchButton = () => {
    if (!this.props.searchQuery || !this.props.searchQuery.trim()) {
      this.setState({
        inputFieldError: "Please provide search option and search query",
      });
    } else {
      this.setState({ inputFieldError: false });
      this.props.handleSearch();
    }
  };

  handleClearButtonClick = () => {
    this.setState({ inputFieldError: false });
    this.props.handleClearAllSearch();
  };

  handleSearchByOptionSelection = (val) => {
    this.setState({ showSearchOptions: false });
    this.props.handleSearchByOptionSelection(val);
  };

  render() {
    const lan =
      localStorage.getItem("UserInfo") &&
      JSON.parse(localStorage.getItem("UserInfo")).language
        ? JSON.parse(localStorage.getItem("UserInfo")).language - 1
        : 0;

    const {
      selectedSearchOption = { value: undefined, label: "Search by" },
      showSearchResults,
      searchQuery,
      showClearButton,
    } = this.props;
    const { inputFieldError } = this.state;
    const { showSearchOptions } = this.state;
	
    return (
      <div className="search-page-input">
        {/* SEARCH BY */}
        <div className="search-by" ref={this.searchByRef}>
          <div className="search-by-selected" onClick={this.handleSearchByBtn}>
            <div className="label">{selectedSearchOption.label}</div>
            <img
              src={arrow}
              alt="Open"
              style={showSearchOptions ? { transform: "rotate(270deg)" } : {}}
            />
          </div>
          {showSearchOptions && (
            <div className="search-by-options">
              {this.getSearchByOptions().map((val, index) => {
                if (!val) return null;
                return (
                  <div
                    className="search-by-options-data"
                    key={index}
                    onClick={() => {
                      this.handleSearchByOptionSelection(val);
                    }}
                  >
                    {val.label}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* SEARCH FIELD */}
        <div className="search-page-input-container">
          <div className="search-page-input-container-wrapper">
            {selectedSearchOption.type === "date" ? (
              <input
                type="date"
                onKeyDown={this.handleSearchOnEnter}
                value={searchQuery}
                onChange={this.handleInputChange}
                maxLength={selectedSearchOption.maxLength}
                style={inputFieldError ? { borderColor: "red" } : {}}
                disabled={
                  !selectedSearchOption || !selectedSearchOption.value
                    ? true
                    : false
                }
              />
            ) : selectedSearchOption.type === "status" ? (
              <select
                onChange={this.handleInputChange}
                value={searchQuery}
                style={inputFieldError ? { borderColor: "red" } : {}}
                disabled={
                  !selectedSearchOption || !selectedSearchOption.value
                    ? true
                    : false
                }
              >
                {this.getStatusOptions().map((val, index) => {
                  return (
                    <option value={val.value} key={index}>
                      {val.label}
                    </option>
                  );
                })}
              </select>
            ) : (
              <input
                type="text"
                placeholder={
                  selectedSearchOption.placeholder || langData[lan].searchQuery
                }
                value={searchQuery}
                onChange={this.handleInputChange}
                onKeyDown={this.handleSearchOnEnter}
                maxLength={selectedSearchOption.maxLength}
                style={inputFieldError ? { borderColor: "red" } : {}}
                disabled={
                  !selectedSearchOption || !selectedSearchOption.value
                    ? true
                    : false
                }
              />
            )}
            {selectedSearchOption.type !== "date" &&
              selectedSearchOption.type !== "status" && (
                <img
                  src={
                    this.props.searchQuery && !showSearchResults
                      ? closeIcon
                      : search
                  }
                  alt=""
                  onClick={() =>
                    this.props.searchQuery &&
                    !showSearchResults &&
                    this.handleClearSearch()
                  }
                />
              )}
          </div>
          {/*   {showSearchSuggestions && (
            <div className="suggestions">
              {[1, 2, 3, 4].map((val, index) => (
                <div className="suggestions-item" key={index}>
                  hello
                </div>
              ))}
            </div>
          )} */}
        </div>

        {(searchQuery && searchQuery.trim()) || selectedSearchOption.value ? (
          <React.Fragment>
            <div
              className={`search-button`}
              // onClick={showSearchResults ? handleClearAllSearch : handleSearch}
              onClick={this.handleSearchButton}
            >
              {langData[lan].search || langData[0].search}
            </div>
            {showClearButton && (
              <div
                className={`search-button clear-button`}
                //   onClick={handleClearAllSearch}
                onClick={this.handleClearButtonClick}
              >
                {langData[lan].clear || langData[0].clear}
              </div>
            )}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

const mapStateFromProps = (state) => {
  return {
    userType: state.auth.userType,
    searchSiteDetails: state.common.searchSiteDetails,
  };
};

export default connect(mapStateFromProps, {})(AdvanceSearch);

/* 
{
	label: "No of contractors",
	value: "name",
	placeholder: "Enter number of contractors",
	maxLength: "10",
  },
  {
	label: "No. of sites registered by contractor",
	value: "name",
	placeholder: "Enter no. of sites registered by contractor",
	maxLength: "10",
  },
  {
	label: "No. of warranty issued",
	value: "name",
	placeholder: "Enter no. of warranty issued",
	maxLength: "10",
  },
  {
	label: "No. of warranty claimed",
	value: "name",
	placeholder: "Enter no. of warranty claimed",
	maxLength: "10",
  },
  {
	label: "No. of pending sites",
	value: "name",
	placeholder: "Enter no. of pending sites",
	maxLength: "10",
  }, */
