import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import closeIcon from "../../Resources/close-icon.svg";
class ImagePreview extends Component {
  render() {
    return (
      <div className="image-preview-page">
        <div className="image-preview-page-close">
          <img
            src={closeIcon}
            alt="close"
            onClick={this.props.handleCloseImagePreview}
          />
        </div>
        <div className="image-preview-page-carousal">
          <Carousel
            className="image-preview-page-carousal-container"
            selectedItem={this.props.imageIndex}
          >
            {this.props.images &&
              this.props.images.map((image, index) => {
                return (
                  <div
                    className="image-preview-page-carousal-container-image"
                    key={index}
                  >
                    <img
                      src={
                        image.file
                          ? URL.createObjectURL(image.file)
                          : image.expiryLink
                      }
                      alt="Dealer"
                    />
                  </div>
                );
              })}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default ImagePreview;
